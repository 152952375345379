import classnames from "classnames";
import { Container, Paper, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

import { useBoxStyles } from "../../../../layout/commonStyles/boxStyles";
import { useHeatRowStyles } from "../../../../layout/schedule/heatRowStyles";
import { useDaySelectorStyles } from "../../../../layout/schedule/useDaySelectorStyles";
import { useEventHeaderStyles } from "../../../../layout/event/useEventHeaderStyles";
import { Breadcrumbs } from "../../../../layout/navigation/Breadcrumbs";
import { Fake } from "../../../../layout/skeleton/Fake";
import { T } from "../../../../util/t";

const useStyles = makeStyles(theme => ({
    margin: {
        display: "flex",
        marginBottom: theme.spacing(2)
    }
}));
export const SchedulePageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();
    const classes = useStyles();

    return (
        <Container className="skeleton">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <Typography variant="h3" component="h1" className={headerClasses.name}><T>Schedule</T></Typography>
            </div>

            <nav className={classes.margin}>
                <FakeDaySelector/>
                <FakeDaySelector/>
                <FakeDaySelector/>
            </nav>

            <Typography variant="caption" component="p" className={classes.margin}><Fake size={50}/></Typography>

            <FakeHeatRow division={16} round={35}/>
            <FakeHeatRow division={9} round={30}/>
            <FakeHeatRow division={16} round={27}/>
            <FakeHeatRow division={9} round={41}/>
        </Container>
    );
};

const FakeDaySelector = () => {
    const boxClasses = useBoxStyles();
    const classes = useDaySelectorStyles();

    return (
        <Paper variant="outlined" className={classnames(boxClasses.box, classes.box)}>
            <Typography variant="body2"><Fake size={9}/></Typography>
            <Typography variant="h7" component="p"><Fake size={5}/></Typography>
            <Typography variant="label2"><Fake size={8}/></Typography>
        </Paper>
    );
};

const FakeHeatRow = ({ division, round }) => {
    const classes = useHeatRowStyles();

    return (
        <div className={classes.row}>
            <div className={classes.time}><Fake size={12}/></div>

            <section className={classes.heat}>
                <div className={classes.division}>
                    <Fake size={division}/>
                </div>

                <Fake size={round}/>
            </section>
        </div>
    );
};
