import { Typography } from "@mui/material";
import { PreviewRoundSkeleton } from "./PreviewRoundSkeleton";
import { Fake } from "../../skeleton/Fake";
import { FakeFormField } from "../../forms/FakeFormField";
import { usePreviewRoundStyles } from "./PreviewRound";

export const PreviewDrawSkeleton = () =>
    <>
        <PreviewRoundWithFakeFields round={23} numberOfHeats={3}/>
        <PreviewRoundWithFakeFields round={31} numberOfHeats={2}/>
        <PreviewRoundWithFakeFields round={18} numberOfHeats={1}/>
    </>;

const PreviewRoundWithFakeFields = ({ round, numberOfHeats }) => {
    const classes = usePreviewRoundStyles();

    return (
        <section className={`skeleton ${classes.section}`} role="status" aria-label="loading">
            <header className={classes.header}>
                <Typography variant="h7"><Fake size={round}/></Typography>
                <Typography variant="subtitle2"><Fake size={23}/></Typography>
            </header>

            <FakeFormField label={30} field={50}/>
            <FakeFormField label={43} field={50}/>

            <div className={classes.round}>
                <PreviewRoundSkeleton numberOfHeats={numberOfHeats}/>
            </div>
        </section>
    );
};
