import classnames from "classnames";
import { useEffect, useRef, useState } from "react";
import { makeStyles } from "../../../providers/makeStyles";
import { useBaseInputStyles } from "../fields/baseInputStyles";
import { useInputStyles, useSharedInputStyles } from "../fields/Input";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        "&&": {
            "&&": {
                padding: theme.spacing(0, 1)
            }
        },
        input: {
            width: 30,
            padding: theme.spacing(0.5, 0)
        }
    }
}));

const parseTime = v => {
    const hours = v && (Math.floor(v / 3600)).toString().padStart(2, "0");
    const minutes = v && (Math.floor((v % 3600) / 60)).toString().padStart(2, "0");
    const seconds = v && (Math.floor(v % 60)).toString().padStart(2, "0");
    const milliseconds =  v && ((v % 1) * 100).toFixed(0).padStart(2, "0") ;

    return { hours, minutes, seconds, milliseconds };
};

export const convertFromStringToSeconds = time => {
    const match = time.match(/((\d+):)?((\d+):)?(\d+).(\d\d)/);
    const milliseconds = match[6], seconds = match[5], minutes = match[4] || match[2], hours = match[4] && match[2];
    return convertToSeconds({ hours, minutes, seconds, milliseconds });
};

const convertToSeconds = (values) => {
    const hours = parseInt(values.hours || "0") * 3600;
    const minutes = parseInt(values.minutes || "0") * 60;
    const seconds = parseInt(values.seconds || "0");
    const milliseconds = parseInt(values.milliseconds || "0") / 100;

    return (hours + minutes + seconds + milliseconds).toFixed(2);
};

const TimeUnit = ({ name, value, placeholder, onChangeTime, onBlurTime, timeRef, field }) => {
    const classes = useInputStyles();

    useEffect(() => {
        timeRef.current.addEventListener("wheel", function (e) { e.preventDefault(); }, { passive: false });
    }, []);

    return (
        <input className={classes.input}
               name={name}
               type={"number"}
               value={value}
               placeholder={placeholder}
               maxLength={2}
               pattern={"[0-9]*"}
               onChange={onChangeTime}
               onBlur={onBlurTime}
               disabled={field.disabled}
               ref={timeRef} />
    );
};

export const TimeUnitField =  ({ value, onChange, onBlur, field = {} }) => {
    const hoursRef = useRef();
    const minutesRef = useRef();
    const secondsRef = useRef();
    const millisecondsRef = useRef();
    const [ timeValues, setTimeValues ]  = useState(parseTime(value));
    const wrapperClass = useStyles();
    const baseClasses = useBaseInputStyles();
    const sharedClasses = useSharedInputStyles();

    const onChangeTime = (e, maxValue, nextRef) => {
        let value = e.target.value && e.target.value >= 0 && e.target.value;
        if (value > Math.floor(maxValue / 10)) {
            value = `0${value}`;
        }
        const updatedTimeValues = { ...timeValues, [e.target.name]: value.toString().slice(-2) };
        setTimeValues(updatedTimeValues);
        onChange(e, convertToSeconds(updatedTimeValues));
        if (value.toString().length >= 2) {
            nextRef?.current?.focus();
            nextRef?.current?.select();
        }
    };

    const onBlurTime = (e) => {
        const inputName = e.target.name;
        const value = e.target.value;
        setTimeValues({ ...timeValues, [inputName]: value.toString().length === 1 ? `0${value}` : value });
        onBlur(e);
    };

    return (
        <Typography variant="bodyMono" component="div" className={classnames(wrapperClass.inputWrapper, baseClasses.baseInput, sharedClasses.wrapper, { disabled: field.disabled })}>
            <TimeUnit name={"hours"} value={timeValues.hours} placeholder={value ? "00": "HH"} timeRef={hoursRef}
                      onChangeTime={e => onChangeTime(e, 99, minutesRef)}
                      onBlurTime={onBlurTime}
                      field={field}
            />
            <Typography variant="bodyMono" color="text.lighter">:</Typography>
            <TimeUnit name={"minutes"} value={timeValues.minutes} placeholder={value ? "00": "MM"} timeRef={minutesRef}
                      onChangeTime={e => onChangeTime(e, 59, secondsRef)}
                      onBlurTime={onBlurTime}
                      field={field}
            />
            <Typography variant="bodyMono" color="text.lighter">:</Typography>
            <TimeUnit name={"seconds"} value={timeValues.seconds} placeholder={value ? "00": "SS"} timeRef={secondsRef}
                      onChangeTime={e => onChangeTime(e, 59, millisecondsRef)}
                      onBlurTime={onBlurTime}
                      field={field}
            />
            <Typography variant="bodyMono" color="text.lighter">.</Typography>
            <TimeUnit name={"milliseconds"} value={timeValues.milliseconds} placeholder={value ? "00": "MS"} timeRef={millisecondsRef}
                      onChangeTime={e => onChangeTime(e, 99)}
                      onBlurTime={onBlurTime}
                      field={field}
            />
        </Typography>
    );
};
