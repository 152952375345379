import { Typography } from "@mui/material";
import { AthleteInfo } from "./AthleteInfo";
import { useHeatHeaderStyles } from "./heatStyles";
import { TeamBreakdown } from "./TeamBreakdown";
import { Result } from "models/Result";
import { RankCell } from "./RankCell";

export const TimingRow = ({ result: r, heat, competitor, inDialog }) => {
    const result = Result(r);
    const classes = useHeatHeaderStyles();
    const { athlete, bib, teamName, position, teamMembers } = competitor;
    const jersey = (heat.config.jerseyOrder || [])[position];
    const displayResult = heat.config.teamConfig?.appraisalLevel === "individual";
    const { place, type } = result.placeToDisplay();

    return (
        <>
            <RankCell type={type}>{place}</RankCell>
            <AthleteInfo {...{ athlete, bib, teamName, jersey }}/>

            <section className={classes.result}>
                <Typography variant="h7" className={classes.total}>
                    {result.formattedTotal(heat.config)}
                </Typography>
            </section>

            {inDialog && heat.config.isTeams &&
                <TeamBreakdown teamConfig={heat.config.teamConfig}
                               teamMembers={teamMembers}
                               result={displayResult && result}
                               hasModifier={type === "modifier"}/>
            }
        </>
    );
};
