import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { randomize } from "actions/eventDirector";
import { Button } from "@mui/material";
import { T } from "components/util/t";
import { ConfirmationDialog } from "components/layout/dialogs/ConfirmationDialog";
import { useDialogState } from "../../../../hooks/useDialogState";
import { useEvent } from "../../../routes/events/id/EventProvider";
import { convertToJS } from "../../../eventDivisions/helpers";

export const RandomizeButton = ({ eventId }) => {
    const dispatch = useDispatch();
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const onRandomize = useCallback(() => dispatch(randomize(eventId)).then(closeDialog), [eventId]);
    const event = convertToJS(useEvent());
    const randomized = event.eventDivisions.some(ed => ed.formatDefinition?.entriesRandomized);

    return (
        <>
            <Button variant="contained" onClick={openDialog}>
                <T>{randomized ? "Re-randomise entries" : "Randomise entries"}</T>
            </Button>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeDialog}
                action={onRandomize}
                title="Close and randomise entries?"
                content={randomized
                    ? "This will re-randomise entries and close entries for this event. Any previous competitor positions will be lost."
                    : "This will randomise entries and close entries for this event. It's no biggie if you do, you can always reopen entries and re-randomise later."}
                buttonTitle={randomized ? "Re-randomise entries" : "Randomise entries"}
            />
        </>
    );
};
