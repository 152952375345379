import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useDialogState } from "../../../hooks/useDialogState";
import { T } from "../../util/t";
import { PropertyWidget } from "./PropertyWidget";

export const CreatePropertyWidget = ({ organisationId, disabled }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <>
            <Button variant="outlined" disabled={disabled} onClick={openDialog} startIcon={<Add/>}>
                <T>Create field</T>
            </Button>

            <PropertyWidget
                organisationId={organisationId}
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
            />
        </>
    );
};
