import Immutable from "immutable";

import createStore from "./createStore";

export const reducer = createStore({
    SET_CURRENT_USER: (state, action) => {
        const currentUser = action.data ? Immutable.fromJS(action.data) : undefined;

        if (currentUser) {
            window.dataLayer?.push( {
                "user_id": currentUser.get("id")
            });
        }

        return state
            .set("currentUserLoaded", true)
            .set("current", currentUser);
    },

    LOGOUT_USER: (state) => state.delete("current")
});
