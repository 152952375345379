import { useEffect, useRef } from "react";
import { useDragDropManager } from "react-dnd";

const TOP_THRESHOLD = 50;
const BOTTOM_THRESHOLD = 100;
const SPEED = 0.15;

export const useEnableScroll = () => {
    const { monitor } = useDragDropManager();
    const animationFrame = useRef();
    const isDragging = !!monitor.getItem();

    useEffect(() => {
        if (isDragging) onDragStart();
        if (!isDragging) onDragEnd();
    }, [isDragging]);

    const onDragStart = () => {
        const clientOffset = monitor.getClientOffset();
        if (!isDragging || !clientOffset) return;

        if (clientOffset.y > window.innerHeight - BOTTOM_THRESHOLD) scrollDown((BOTTOM_THRESHOLD - Math.max(window.innerHeight - clientOffset.y, 1)) * SPEED);
        else if (clientOffset.y < TOP_THRESHOLD) scrollUp((TOP_THRESHOLD - Math.max(clientOffset.y, 1)) * SPEED * (BOTTOM_THRESHOLD / TOP_THRESHOLD));

        animationFrame.current = window.requestAnimationFrame(onDragStart);
    };

    const onDragEnd = () => animationFrame.current && window.cancelAnimationFrame(animationFrame.current);
    const scroll = pageYOffset => pageYOffset !== window.pageYOffset && window.scrollTo(window.pageXOffset, pageYOffset);
    const scrollDown = scrollOffset => scroll(window.pageYOffset + Math.ceil(scrollOffset));
    const scrollUp = scrollOffset => scroll(window.pageYOffset - Math.ceil(scrollOffset));
};
