import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import { T } from "../../../../util/t";
import { Close } from "@mui/icons-material";
import { api } from "../../../../../actions/api";

export const EditDocusealTemplateDialog = ({ onLoad, onChange, organisationId, templateId, closeDialog }) => {
    const [Docuseal, setDocuseal] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        import(/* webpackChunkName: "docuseal" */ "@docuseal/react").then(docuseal => setDocuseal(docuseal));
        api.post(`docuseal/${organisationId}/templates`, { template_id: templateId })
            .then(({ data }) => setToken(data.token));
    }, []);

    return (
        <>
            <DialogTitle id="edit-docuseal-template-title" display="flex" alignItems="center" justifyContent="space-between" className="sticky-dialog-title">
                <Typography variant="h6" component="p" display="flex">
                    <T>Upload and create document to sign</T>
                </Typography>
                <IconButton color="inherit" size="small" onClick={closeDialog}><Close/></IconButton>
            </DialogTitle>
            <DialogContent>
                {(!token || !Docuseal) ?
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={60}/>
                    </Box> :
                    <Docuseal.DocusealBuilder
                        token={token}
                        withSendButton={false}
                        withSignYourselfButton={false}
                        fieldTypes={["signature", "text", "date", "phone"]}
                        roles={["Athlete/Guardian"]}
                        onChange={onChange}
                        onLoad={onLoad}
                    />
                }
            </DialogContent>
            <DialogActions className="sticky-dialog-actions">
                <Button variant="contained" onClick={closeDialog}><T>Done</T></Button>
            </DialogActions>
        </>
    );
};
