import { Link, useRouteMatch } from "react-router-dom";
import { manageEventPagesPath, newManageEventPagesPath } from "../../application";
import { EventStatus } from "../../events/manage/eventStatus";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useIsMobile } from "hooks/useIsMobile";
import { UserMenu } from "./UserMenu";
import { PoweredBy } from "../PoweredBy";
import { useIsEmbedded } from "../../../hooks/useIsEmbedded";
import { makeStyles } from "../../providers/makeStyles";
import { NavMenuLinks } from "./NavMenuLinks";
import { MobileNavigationMenu } from "./MobileNavigationMenu";
import { HelpMenuWrapper } from "./HelpMenu";
import logoWithText from "assets/logos/LockupHorizontalBrandRed.svg";
import logo from "assets/logos/LogomarkBrandRed.svg";
import { Box, Stack } from "@mui/material";
import { useMenuState } from "../../../hooks/useMenuState";

const useIsManagementPage = () => {
    const eventManagementPathMatch = useRouteMatch(manageEventPagesPath);
    const newEventManagementPathMatch = useRouteMatch(newManageEventPagesPath);
    return eventManagementPathMatch || newEventManagementPathMatch;
};

const useStyles = makeStyles((theme, { transparentBackground }) => ({
    header: {
        background: transparentBackground ? "none !important" : theme.palette.background.lowest,
        position: "sticky",
        left: 0,
        width: "calc(100vw - ( 100vw - 100% ) )",
        maxWidth: "100vw",
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0.5, 2),
        zIndex: 5,
        borderBottom: `1px solid ${theme.palette.border.light}`,
        "@media print": {
            display: "none"
        }
    },
    logoStatus: {
        display: "flex",
        alignItems: "center",
        minWidth: 0,
    },
    logoLink: {
        lineHeight: 0
    },
    navMenuLinks: {
        paddingLeft: theme.spacing(1),
        whiteSpace: "nowrap",

        "a": {
            display: "inline-flex",
            marginRight: theme.spacing(4),
            whiteSpace: "nowrap"
        }
    }
}));

export const SiteHeader = () => {
    const { loaded, currentUser } = useCurrentUser();
    const isMobile = useIsMobile();
    const isHome = useRouteMatch("/").isExact;
    const isManagementPage = useIsManagementPage();
    const isEmbedded = useIsEmbedded();
    const classes = useStyles({ transparentBackground: isHome });
    const [anchorEl, openMenu, closeMenu] = useMenuState();

    return isEmbedded ?
        <PoweredBy /> :
        <header className={classes.header}>
            {isMobile && <Link className={classes.logoLink} to="/"><img src={logoWithText} alt="Logo" height={32}/></Link>}

            {!isMobile &&
            (isManagementPage ?
                <div className={classes.logoStatus}>
                    <Link className={classes.logoLink} to="/">
                        <img src={logo} alt="Logo" height={32}/>
                    </Link>
                    <EventStatus eventId={isManagementPage.params.id}/>
                </div> :
                <Link className={classes.logoLink} to="/">
                    <img src={logoWithText} alt="Logo" height={32}/>
                </Link>
            )}

            <Box display="flex" justifyContent="flex-end" alignItems="center" flex={1}>
                {!isMobile && loaded && <NavMenuLinks className={classes.navMenuLinks} anchorEl={anchorEl} openMenu={openMenu} closeMenu={closeMenu} user={currentUser}/>}
                <Stack direction="row" spacing={1} alignItems="center">
                    <HelpMenuWrapper currentUserLoaded={loaded} currentUser={currentUser}/>
                    <UserMenu currentUserLoaded={loaded} currentUser={currentUser}/>
                    {isMobile && <MobileNavigationMenu currentUser={currentUser} isManagementPage={isManagementPage} anchorEl={anchorEl} openMenu={openMenu} closeMenu={closeMenu} />}
                </Stack>
            </Box>
        </header>;
};
