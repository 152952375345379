import { useCallback } from "react";
import { makeStyles } from "components/providers/makeStyles";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import * as JudgeActions from "actions/judge";
import { T } from "components/util/t";
import { useCurrentUser } from "components/providers/CurrentUserProvider";
import { SelfSubmittingField } from "components/layout/forms/selfSubmittingField/SelfSubmittingField";
import { scoreParser } from "components/forms/parsers/scoreParser";
import { useRecordNextPlace } from "./useRecordNextPlace";
import { ModifierButton } from "../../../events/judging/modifiers/ModifierButton";
import { Modifier } from "../../../events/judging/modifiers/Modifier";

const placingScoreParser = scoreParser(1e10, 0);
export const placingField = {
    name: "score",
    type: "number",
    width: 80,
    inputProps: { pattern: "[0-9]*" },
    parse: placingScoreParser,
};
const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.border.main}`,
        minHeight: 68
    },
    name: {
        marginBottom: theme.spacing(1)
    },
    team: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    nameSection: {
        minWidth: 0
    },
    placingSection: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        "& input": {
            fontSize: theme.typography.pxToRem(24),
            textAlign: "center",
            padding: 4,
        }
    },
    modifier: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
        width: 80,
        fontSize: theme.typography.pxToRem(21),
        fontWeight: 700,
    },
    button: {
        minWidth: 80
    },
    modifiersButton: {
        marginLeft: theme.spacing(1)
    }
}));
export const SpotPlacing = ({ heat, competitor, nextPlace }) => {
    const { id: eventId } = useParams();
    const { currentUser: { id: judgeId } } = useCurrentUser();
    const heatId = heat.get("id");
    const recordNextPlace = useRecordNextPlace({ heatId, nextPlace });
    const onPlaceAthlete = () => {
        competitor.place = nextPlace;
        return recordNextPlace(competitor.competitorId, competitor.athleteId);
    };

    const place = competitor.place;
    const modifier = competitor.modifier;
    const modifierName = modifier && modifier.type.split("::")[1];
    const classes = useStyles();
    const dispatch = useDispatch();
    const dispatchParams = {
        event_id: eventId,
        heat_id: heatId,
        dispatch_heat_id: heatId,
        ride_index: 0,
        dispatch_ride_index: 0,
    };

    const onSubmit = useCallback((values) => {
        const place = values.score;
        competitor.place = place;
        return dispatch(JudgeActions.addScore(eventId, heatId, heatId, 0, judgeId, competitor.competitorId, {
            score: place,
            judge_id: judgeId,
            athlete_id: competitor.athleteId,
            ride: 0
        }));
    }, [eventId, heatId, judgeId, competitor.competitorId, competitor.athleteId]);

    return (
        <div className={classes.item}>
            <section className={classes.nameSection}>
                <Typography className={classes.name} variant="h7">{competitor.athlete.name}</Typography>
                {competitor.athlete.teamName &&
                <Typography variant="subtitle2" className={classes.team} component="div">{competitor.athlete.teamName}</Typography>}
            </section>
            <section className={classes.placingSection}>
                {modifier ?
                    <div className={`ride modified ${modifierName} ${classes.modifier}`}>
                        <Modifier modifier={modifier}/>
                    </div> :

                    (place ?
                        <SelfSubmittingField onSubmit={onSubmit} initialValue={place?.toString()} field={placingField} /> :
                        <Button className={classes.button} variant="contained" onClick={onPlaceAthlete}>
                            <T place={nextPlace}>ordinal</T>
                        </Button>
                    )
                }
                <div className={classes.modifiersButton}>
                    <ModifierButton dispatchParams={dispatchParams} competitor={competitor} modifier={modifier} heatConfig={heat.get("config")}/>
                </div>
            </section>
        </div>
    );
};
