import { PureComponent } from "react";
import Immutable from "immutable";
import { T } from "../../util/t";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";

import { LHIconButton } from "../../actions/LHIconButton";
import { useWithPriority } from "./useWithPriority";
import { Close, Edit, PersonAdd } from "@mui/icons-material";

export const ToggleEditButton = ({ edit, className = "" }) =>
    <LHIconButton className={className} aria-label="Toggle edit names mode" title="Toggle edit names mode">
        {edit ? <Close /> : <Edit/>}
    </LHIconButton>;

export const JudgeCardRowSkeleton = ({ jersey, isHeadJudge }) =>
    <tr className={jersey}>
        <th className="jersey"/>
        <th className="name"/>
        {isHeadJudge && <td className="overall"/>}
        <td/>
    </tr>;

export const JudgeCardTableSkeleton = ({ isHeadJudge }) =>
    <table className="judge-card skeleton">
        <thead><tr>
            <th className="jersey fixed">#</th>
            <th className="name fixed"><T>Athlete</T></th>
            {isHeadJudge && <th className="overall"><T>Total</T></th>}
            <th/>
        </tr></thead>
        <tbody>
            {Immutable.List(["red", "blue", "white", "yellow"]).map(jersey =>
                <JudgeCardRowSkeleton key={jersey} jersey={jersey} isHeadJudge={isHeadJudge}/>)}
        </tbody>
    </table>;

const useTableHeaderStyles = makeStyles((theme, { isHeadJudge }) => ({
    tr: {
        "@media print": {
            "table.judge-card && th": {
                fontWeight: 500,
                position: "initial"
            }
        }
    },
    priority: {
        width: theme.spacing(isHeadJudge ? 8 : 5),
        borderRight: !isHeadJudge && `1px solid ${theme.palette.border.main}`
    }
}));

export const JudgeCardTableHeader = ({ heat, isHeadJudge, JerseyCellRenderer, heatStarted, toggleEdit, hideNames, editState, isTeams, appraiseTeamMembers, isPlacing, isTiming, maxRidesPerAthlete = Immutable.List(), athleteRidesLimit, categoryNames, hasPriority, heatEnded }) => {
    const classes = useTableHeaderStyles({ isHeadJudge });
    const withPriority = useWithPriority(hasPriority, heatEnded);

    return <thead>
        <tr className={classes.tr}>
            <JerseyCellRenderer editState={editState} />

            {isHeadJudge && heat.getIn(["config", "has_startlist"]) && heat.getIn(["config", "calculator"]) !== "placing" &&
            <th className="jersey fixed">
                {heat.getIn(["config", "uses_lanes"]) ? <T>Lane</T> : <T>start_order_header</T>}
            </th>}

            {(!hideNames || isHeadJudge) &&
                <th className="name fixed" onClick={toggleEdit}>
                    {isHeadJudge && <ToggleEditButton className="tiny edit-heat" edit={editState}/>}
                    <span><T>
                        {isTeams ?
                            (editState ? "Edit teams" : "Team") :
                            (editState ? "Edit athletes" : "Athlete")}
                    </T></span>
                </th>
            }
            {withPriority && <th className={classnames(classes.priority, "priority")}><T>{isHeadJudge ? "Priority" : "P"}</T></th>}
            {isHeadJudge && (!isPlacing || appraiseTeamMembers) && <th className="overall"><T>Total</T></th>}

            {((heatStarted && isHeadJudge) || !isHeadJudge) && maxRidesPerAthlete.map((maxRides, i) => {
                const ridesLimit = Immutable.List([athleteRidesLimit || (isPlacing ? 1 : Infinity), maxRides]).max();
                return Immutable.List().setSize(maxRides + 1).map((n, j) => {
                    const isVisible = j < ridesLimit;
                    return <th key={`${i}-${j}`} className={classnames({ "ride": j !== maxRides || appraiseTeamMembers, "ride-with-category": isHeadJudge && !categoryNames.isEmpty(), "athlete-separator": i !== 0 && j === 0, "athlete-filler": isTeams && j >= athleteRidesLimit, "empty-cell": !isVisible })}>
                        <div>
                            {(appraiseTeamMembers && j === 0) && <div><T>Athlete</T> {i + 1}</div>}
                            {isVisible &&
                                <>
                                    {isTiming && ((maxRides > 0 && heat.getIn(["config", "uses_lanes"])) ? <><T>Lap</T> </> : <><T>Time</T> </>)}
                                    {isPlacing && !isTiming && !appraiseTeamMembers && <T>Place</T>}
                                    {(!isPlacing || maxRides > 1) && j + 1}
                                </>
                            }

                            {(isHeadJudge && !categoryNames.isEmpty() && isVisible) &&
                            <div className="categories">
                                {categoryNames.map(category => <div key={category} className="category">{category}</div>)}
                            </div>}
                        </div>
                    </th>;
                });
            })}

            {!isPlacing && !heatStarted && <th></th>}
            {appraiseTeamMembers && <th className="team-filler"/>}
        </tr>
    </thead>;
};

const useTableBodyStyles = makeStyles((theme, { isHeadJudge }) => ({
    tbody: {
        "@media print": {
            "table.judge-card && td:not(.ride), table.judge-card && th": {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1)
            },
            "table.judge-card && .total, table.judge-card && .place": {
                fontSize: "18px",
            },
            "table.judge-card && .ride-total": {
                fontSize: "16px",
            },
            "table.judge-card && .categories": {
                fontSize: "13px",
            },
            "table.judge-card && tr": {
                height: "auto"
            },
        }
    },
    priorityCell: {
        borderRight: `1px solid ${theme.palette.border.main}`
    },
    priority: {
        margin: "0 auto",
        background: theme.palette.text.primary,
        color: "white",
        width: !isHeadJudge && theme.spacing(3),
        height: !isHeadJudge && theme.spacing(3)
    }
}));

export const JudgeCardTableBody = ({ isHeadJudge, HeadJudgeTotalCell, AthleteCellNameRenderer, RideRenderer, heat, editState, spots, heatStarted, isTeams, appraiseTeamMembers, isPlacing, isTiming, teamPositions, categoryJudges, athleteRidesLimit, maxRidesPerAthlete, maxRidesPerAthletePerTeam, missingRides = Immutable.Map(), nextAthlete, hasPriority, heatEnded }) => {
    const totalRides = maxRidesPerAthlete.reduce((total, max) => total + max, 0) + maxRidesPerAthlete.size;
    const classes = useTableBodyStyles({ isHeadJudge });
    const withPriority = useWithPriority(hasPriority, heatEnded);

    return <tbody className={classes.tbody}>
        {spots.map((spot, i) => {
            const rides = spot ? spot.getIn(["result", "rides"]) : Immutable.Map(),
                jersey = heat.getIn(["config", "jersey_order", i]),
                usesLanes = heat.getIn(["config", "uses_lanes"]),
                spotTotalRides = rides.valueSeq().reduce((total, athleteRides) => total + athleteRides.size, 0),
                parentAthleteId = spot && "" + spot.getIn(["athlete", "id"]),
                athleteHeatId = spot && spot.getIn(["athlete", "athlete_heat_id"]),
                teamPosition = spot && Immutable.List([teamPositions.get(parentAthleteId), maxRidesPerAthletePerTeam.get(i).findIndex(r => !r)]).max(),
                nonDisplayedRides = spot && maxRidesPerAthlete.slice(teamPosition),
                missingSpotRides = spot && (nonDisplayedRides.reduce((total, m) => total + m, 0) + nonDisplayedRides.size),
                teamMembersByOrder = spot && appraiseTeamMembers && Immutable.Map(spot.getIn(["athlete", "athletes"]).map(a => [a.get("order"), a])),
                canAddTeamMember = spot && appraiseTeamMembers && ((isPlacing || isTiming) ?
                    !!teamMembersByOrder.get(teamPosition) :
                    teamPosition < heat.getIn(["config", "team_config", "athletes_per_team"])),
                athletes = !spot ? Immutable.List() :
                    appraiseTeamMembers ?
                        Immutable.List([...Array(teamPosition)].map((_, athleteI) =>  teamMembersByOrder.get(athleteI) || (isPlacing ? null : Immutable.Map()))) :
                        Immutable.List([spot.get("athlete")]),
                priority = spot && spot.getIn(["athlete", "priority"]);

            return (
                <tr key={spot ? `row-athlete-${athleteHeatId}` : `empty-${i}`} className={classnames({ [jersey]: jersey, "empty": !spot })}>
                    <th className="jersey">
                        {spot && spot.getIn(["athlete", "bib"]) &&
                        <>
                            <span className="bib">{spot.getIn(["athlete", "bib"])}</span>
                            <br/>
                        </>}
                        {(isHeadJudge && spot) &&
                        ((isPlacing && !isTiming) ?
                            <span>{spot.getIn(["athlete", "position"]) + 1}</span> :
                            <span className="place">{heatStarted && spot.get("place")}</span>
                        )}
                    </th>

                    {isHeadJudge && heat.getIn(["config", "has_startlist"]) && heat.getIn(["config", "calculator"]) !== "placing" &&
                    <th className="start">
                        {spot && (usesLanes ? spot.getIn(["athlete", "position"]) : spot.getIn(["athlete", "position"]) + 1)}
                    </th>}

                    <AthleteCellNameRenderer spot={spot} editState={editState} totalRides={spotTotalRides} position={i} isTeams={isTeams}/>

                    {withPriority && <td className={classnames(!isHeadJudge && classes.priorityCell, "priority-cell")}>{priority && <Avatar className={classes.priority}><Typography variant="label1">{priority === 1 ? "P" : priority}</Typography></Avatar>}</td>}
                    {isHeadJudge && <HeadJudgeTotalCell spot={spot} index={i}/>}

                    {((isHeadJudge && (spot && heatStarted)) || !isHeadJudge) && <>
                        {athletes.filter(a => a).map((athlete, aI) => {
                            const athleteId = athlete.get("id") ? "" + athlete.get("id") : undefined,
                                athleteRides = spot.getIn(["result", "rides", athleteId || aI], Immutable.List()),
                                displayRides = athleteRides.filter((ride, rideIndex) => !missingRides.getIn([athleteId, rideIndex])),
                                totalAthleteRides = displayRides.size,
                                allRides = displayRides.map((ride, j) =>
                                    <RideRenderer key={`${i}-ride-${aI}-${j}`}
                                                  className={classnames("ride", { "athlete-separator": aI !== 0 && j === 0 })}
                                                  index={j}
                                                  athleteHeatId={athleteHeatId}
                                                  teamPosition={aI}
                                                  athleteId={athleteId}
                                                  parentAthleteId={parentAthleteId}
                                                  ride={ride}
                                                  heat={heat}
                                                  jersey={jersey}
                                                  athlete={athlete}
                                                  categoryJudges={categoryJudges}
                                    />);

                            return allRides.push((totalAthleteRides >= (athleteRidesLimit || (isPlacing ? 1 : Infinity))) ?
                                <td key={`${i}-ride-${athleteId}-${totalAthleteRides}`} className={classnames("empty-cell", { "athlete-filler": appraiseTeamMembers })} colSpan={(maxRidesPerAthlete.get(aI) || 0) - totalAthleteRides + 1}/> :
                                <RideRenderer key={`${i}-ride-${aI}-${totalAthleteRides}`}
                                              className={classnames({ "athlete-separator": aI !== 0 && totalAthleteRides === 0 })}
                                              index={totalAthleteRides}
                                              colSpan={(maxRidesPerAthlete.get(aI) || 0) - totalAthleteRides + 1}
                                              athleteHeatId={athleteHeatId}
                                              teamPosition={aI}
                                              athleteId={athleteId}
                                              parentAthleteId={parentAthleteId}
                                              heat={heat}
                                              jersey={jersey}
                                              athlete={athlete}
                                              categoryJudges={categoryJudges}
                                />);
                        })}
                    </> }

                    {(isHeadJudge && !(spot && heatStarted)) && !isPlacing && <td className="overall"/>}

                    {(spot && appraiseTeamMembers && (!isHeadJudge || heatStarted)) &&
                    (canAddTeamMember ?
                        <td className="athlete-separator next-athlete" colSpan={missingSpotRides + 1} onClick={() => nextAthlete(parentAthleteId, "" + spot.getIn(["athlete", "athletes"]).getIn([teamPosition, "id"], teamPosition))}>
                            <div>
                                <PersonAdd />
                                <br/>
                                <T>Next Athlete</T>
                            </div>
                        </td> :
                        <td className="team-filler"/>)}

                    {isPlacing && !spot && heatStarted && <td colSpan={totalRides + (appraiseTeamMembers ? 1 : 0)}/>}
                    {!isPlacing && (!spot || !heatStarted) && <td colSpan={totalRides + (appraiseTeamMembers ? 1 : 0)}/>}
                </tr>
            );
        })}
    </tbody>;
};

export class JudgeCardTable extends PureComponent {
    state = {
        teamAthletesPositions: Immutable.Map()
    };

    getTeamPositions = () =>
        this.state.teamAthletesPositions.map(athletesPosition =>  Immutable.List([
            athletesPosition.map((position, athleteId) =>
                position - (this.props.missingRides || Immutable.Map()).get("" + athleteId, Immutable.Map()).filter(ride => ride).size
            ).valueSeq().findLastIndex(rides => rides > 0) + 1,
            1
        ]).max());

    getTeamAthletesPositions = (reset) => {
        const { heat } = this.props;
        return heat.getIn(["config", "is_teams"])
            ? Immutable.Map(heat.get("result").map(teamResult => {
                const teamId = "" + teamResult.get("athlete_id");
                const teamMembersByOrder = Immutable.Map(heat.getIn(["athletes", teamId, "athletes"]).map(a => [a.get("order"), a]));
                const teamSize = heat.getIn(["config", "team_config", "athletes_per_team"]);
                const athletes = Immutable.List([...Array(teamSize)].map((_, athleteI) =>  teamMembersByOrder.get(athleteI) || Immutable.Map()));
                return [
                    teamId,
                    Immutable.Map(athletes.map((athlete, i) => {
                        const athleteId = "" + athlete.get("id", i);
                        return [
                            athleteId,
                            Immutable.List([
                                teamResult.getIn(["rides", athleteId])?.size || 0,
                                reset ? 0 : this.state.teamAthletesPositions.getIn([teamId, athleteId], 0),
                            ]).max()
                        ];
                    }))
                ];
            }))
            : null;
    };

    nextAthlete = (teamId, nextAthleteId) => this.setState({ teamAthletesPositions: this.state.teamAthletesPositions.updateIn([teamId, "" + nextAthleteId], rides => (rides || 0) + 1) });

    recalculatePositions = (reset) => this.setState({ teamAthletesPositions: this.getTeamAthletesPositions(reset) });

    resetState = () => this.recalculatePositions(true);

    componentDidMount = () => this.resetState();

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { heat } =  this.props;
        if (prevProps.heat.get("id") !== heat.get("id")) this.resetState();
        else if (prevProps.heat.get("result") !== heat.get("result")) this.recalculatePositions();
    }

    render = () => {
        const { isHeadJudge, toggleEdit, editState, heatStarted, className, HeadJudgeTotalCell, JerseyCellRenderer, hideNames, isPlacing, athleteRidesLimit, heat, spots, AthleteCellNameRenderer, RideRenderer, maxRidesPerAthletePerTeam = Immutable.List(), missingRides, categoryNames, categoryJudges } = this.props,
            isTeams = heat.getIn(["config", "is_teams"]),
            hasPriority = heat.getIn(["config", "has_priority"]),
            heatEnded = !!heat.get("end_time"),
            isTiming = heat.getIn(["config", "calculator"]) === "timing",
            appraiseTeamMembers = isTeams && heat.getIn(["config", "team_config", "appraisal_level"]) === "individual",
            teamPositions = this.state.teamAthletesPositions ? this.getTeamPositions() : Immutable.Map(),
            maxAthletes = teamPositions.valueSeq().max(),
            maxRidesPerAthlete = Immutable.List(Array(maxAthletes)).map((v, i) => maxRidesPerAthletePerTeam.filter(s => s).map(m => m.get(i, 0)).max() || 0);

        return (
            <table className={className}>
                <JudgeCardTableHeader
                    heat={heat}
                    JerseyCellRenderer={JerseyCellRenderer}
                    hideNames={hideNames}
                    isTeams={isTeams}
                    appraiseTeamMembers={appraiseTeamMembers}
                    isPlacing={isPlacing}
                    isTiming={isTiming}
                    maxRidesPerAthlete={maxRidesPerAthlete}
                    athleteRidesLimit={athleteRidesLimit}
                    isHeadJudge={isHeadJudge}
                    toggleEdit={toggleEdit}
                    categoryNames={categoryNames}
                    editState={editState}
                    heatStarted={heatStarted}
                    hasPriority={hasPriority}
                    heatEnded={heatEnded}
                />

                <JudgeCardTableBody
                    heat={heat}
                    spots={spots}
                    AthleteCellNameRenderer={AthleteCellNameRenderer}
                    RideRenderer={RideRenderer}
                    isTeams={isTeams}
                    appraiseTeamMembers={appraiseTeamMembers}
                    isPlacing={isPlacing}
                    isTiming={isTiming}
                    nextAthlete={this.nextAthlete}
                    athleteRidesLimit={athleteRidesLimit}
                    maxRidesPerAthlete={maxRidesPerAthlete}
                    maxRidesPerAthletePerTeam={maxRidesPerAthletePerTeam}
                    teamPositions={teamPositions}
                    missingRides={missingRides}
                    isHeadJudge={isHeadJudge}
                    HeadJudgeTotalCell={HeadJudgeTotalCell}
                    editState={editState}
                    heatStarted={heatStarted}
                    categoryJudges={categoryJudges}
                    hasPriority={hasPriority}
                    heatEnded={heatEnded}
                />
            </table>
        );
    };
}
