import Immutable from "immutable";

import createStore from "./createStore";

export const reducer = createStore({
    SET_NOTIFICATION: (state, action) =>
        state.set(action.notificationType, Immutable.fromJS(action.notification)),

    OPEN_NOTIFICATION: (state, action) =>
        state.setIn([action.notificationType, "open"], "open"),

    HIDE_NOTIFICATION: (state, action) =>
        state.deleteIn([action.notificationType, "open"]),

    REMOVE_NOTIFICATION: (state, action) =>
        state.delete(action.notificationType)
});
