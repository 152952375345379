import { makeStyles } from "components/providers/makeStyles";

export const useTableStyles = makeStyles(theme => ({
    table: {
        borderSpacing: 0,
        width: "100%",
        "& thead th": {
            textAlign: "left",
            paddingBottom: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.border.light}`
        },
        "& tbody td": {
            width: "0.01%",
            padding: 0,
            borderBottom: `1px solid ${theme.palette.border.light}`,
        }
    }
}));

export const useRowStyles = makeStyles(theme => ({
    rank: {
        textAlign: "center"
    },
    clickable: {
        cursor: "pointer",
    },
    padded: {
        "&&": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            }
        }
    },
    athlete: {
        height: 60,
        "&&": {
            width: "100%"
        }
    },
    filler: {
        "&&": {
            width: "100%"
        }
    }
}));

export const useStickyTableStyles = makeStyles((theme) => ({
    table: {
        minWidth: "100%"
    },
    stickyColumn: {
        position: "sticky",
        background: "white"
    },
    lastStickyColumn: {
        borderRight: `2px solid ${theme.palette.border.light}`
    }
}));
