import { makeStyles } from "components/providers/makeStyles";

export const useUserRowStyles = makeStyles(theme => ({
    athlete: {
        "&&": {
            width: "40%"
        }
    },
    column: {
        "&&": {
            width: "30%"
        }
    },
    chevron: {
        textAlign: "right"
    },
    row: {
        cursor: "pointer",
    }
}));
