import { Typography } from "@mui/material";

import { useEventHeaderStyles } from "../event/useEventHeaderStyles";
import { Breadcrumbs } from "../navigation/Breadcrumbs";
import { Fake } from "../skeleton/Fake";

export const EventDivisionHeaderSkeleton = () => {
    const headerClasses = useEventHeaderStyles();
    return (
        <div className={`skeleton ${headerClasses.container}`}>
            <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
            <Typography variant="h3" component="h1" className={headerClasses.name}><Fake size={33}/></Typography>
        </div>
    );
};
