import { PaddedPage } from "../../../../layout/page/PaddedPage";
import { Heading } from "../../../../layout/page/Heading";
import { useParams } from "react-router-dom";
import { SkeletonTable } from "../../../../layout/skeleton/SkeletonTable";

export const DirectorTeamLeaderboardPageSkeleton = () => {
    const { id } = useParams();

    return (
        <div className="skeleton">
            <Heading title="Club leaderboard" returnTo={`/events/${id}/dashboard`}/>
            <PaddedPage><SkeletonTable/></PaddedPage>
        </div>
    );
};
