import { useMemo } from "react";

const sum = (total, item) => total + item;
export const useHasPendingRides = heat =>
    useMemo(() =>
        !heat ?
            0 :
            heat.get("result", [])
                .map(result => result.get("rides").map(
                    athlete => athlete.filter(ride => ride.get("pending")).count()
                ).reduce(sum, 0))
                .reduce(sum, 0)
    , [heat && heat.get("result")]);
