import { T } from "../../util/t";
import { CompactHeatLeaderboardHeader } from "./CompactHeatLeaderboardHeader";

export const CompactHeatHeader = ({ sticky, heat, displayNumber }) => (
    <CompactHeatLeaderboardHeader sticky={sticky}>
        {heat.eventDivision.division.name}
        &nbsp;:&nbsp;
        <T>{heat.round}</T>

        {displayNumber &&
        <>
            &nbsp;:&nbsp;
            {heat.config.runBased ?
                <T number={heat.position + 1}>run_number</T> :
                <T number={heat.position + 1}>heat_number</T>}
        </>}
    </CompactHeatLeaderboardHeader>
);
