import { makeStyles } from "components/providers/makeStyles";

import { useTableStyles } from "../commonStyles/tableStyles";
import { Fake } from "../skeleton/Fake";
import { FormSection } from "../forms/FormSection";

const useStyles = makeStyles(theme => ({
    cell: {
        "&&": {
            padding: theme.spacing(2)
        }
    }
}));
export const PropertiesTableSkeleton = () => {
    const classes = { ...useTableStyles(), ...useStyles() };

    return (
        <section className="skeleton">
            <FormSection title={<Fake size={33}/>} subtitle={<Fake size={88}/>}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th><Fake size={37}/></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td className={classes.cell}><Fake size={12}/></td></tr>
                        <tr><td className={classes.cell}><Fake size={27}/></td></tr>
                        <tr><td className={classes.cell}><Fake size={19}/></td></tr>
                    </tbody>
                </table>
            </FormSection>
        </section>
    );
};
