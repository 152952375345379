import classnames from "classnames";
import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

import { Footer } from "../../routes/root/Footer";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 60px)", // 60px fixed navbar height
        textAlign: "center",
        "& section": {
            marginTop: theme.spacing(7)
        },
        "& .wrapper": {
            marginBottom: theme.spacing(7),
        }
    },
    header: {
        margin: theme.spacing(6, 0),
    },
    subtitle: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            marginTop: theme.spacing(1),
        }
    }
}));
export const HeroPage = ({ className, role, ariaLabel, icon, title, secondTitle, subtitle, children, withFooter = true }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classnames(classes.container, className)} role={role} aria-label={ariaLabel}>
                <div className="wrapper">
                    <header className={classes.header}>
                        <Typography variant="hero" component="h1">
                            {icon && <>{icon}<br /></>}
                            <T>{title}</T>
                        </Typography>
                        {secondTitle &&
                            <Typography variant="h2" marginTop={1}>
                                <T>{secondTitle}</T>
                            </Typography>}
                        <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary" component="p">
                            <T>{subtitle}</T>
                        </Typography>
                    </header>
                    {children}
                </div>
                
            </div>

            {withFooter && <Footer />}
        </>
    );
};
