import { makeStyles } from "components/providers/makeStyles";

export const useBaseInputStyles = makeStyles(theme  => ({
    baseInput: {
        appearance: "none",
        outline: "none",
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.input.border,
        backgroundColor: theme.palette.background.paper,
        lineHeight: "24px",
        transition: theme.transitions.create(["border-color"]),
        "&:hover": {
            borderColor: theme.palette.input.hover
        },
        "&:focus": {
            borderColor: theme.palette.input.focus,
            outline: `2px solid ${theme.palette.input.focus}`,
        },
        "&:focus-within:not(.error)": {
            borderColor: theme.palette.input.focus,
            outline: `2px solid ${theme.palette.input.focus}`,
        },
        "&.error": {
            borderColor: theme.palette.input.error
        },
        "&:disabled, &.disabled": {
            opacity: 0.6,
            borderColor: theme.palette.input.border,
        },
        width: "100%"
    }
}));
