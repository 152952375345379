import { Link, useParams } from "react-router-dom";
import { Link as MuiLink, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    link: {
        paddingRight: theme.spacing(3),
        display: "inline-flex"
    },
    titleAndStatus: {
        margin: theme.spacing(1, 0),
        wordBreak: "break-all",
    },
    title: {
        marginRight: theme.spacing(1)
    },
    status: {
        display: "inline-block",
        verticalAlign: "middle",
        marginTop: theme.spacing(-1)
    }
}));

export const FullHeatLeaderboardHeader = ({ heat, closeModal, title, status, children }) => {
    const { id } = useParams();
    const classes = useStyles();

    return (
        <header>
            <MuiLink component={Link} className={classes.link} underline="always" to={`/events/${id}/divisions/${heat.eventDivision.id}`} onClick={closeModal}>
                {heat.eventDivision.division.name}
            </MuiLink>

            <Typography variant="h3" className={classes.titleAndStatus}>
                <span className={classes.title}>{title}</span>

                <Typography variant="label1" className={classes.status}>
                    {status}
                </Typography>
            </Typography>

            {children}
        </header>
    );
};
