import { Component } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
export class Timer extends Component {
    static defaultProps = {
        tickInterval: 50
    };

    static propTypes = {
        start: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired
    };

    end = ({ start, duration }) => dayjs(start).add(duration, "minute");
    getRemainingTime = () => dayjs.duration(this.end(this.props).diff(dayjs()));
    state = { remainingTime: this.getRemainingTime() };

    componentDidMount = () => this.tick();

    componentWillUnmount = () => clearTimeout(this.timeout);

    tick = () =>
        this.timeout = setTimeout(() => {
            const remainingTime = this.getRemainingTime();

            if (remainingTime.milliseconds() <= 0) {
                this.setState({ remainingTime: dayjs.duration(0) });
            } else {
                this.setState({ remainingTime });
            }
            this.tick();
        }, this.props.tickInterval);

    pad = num => num.toString().length === 1 ? ("0" + num) : num;

    render() {
        const { remainingTime } = this.state,
            { duration } = this.props,
            remainingMinutes = remainingTime.hours() * 60 + remainingTime.minutes();

        return (
            <div className={`timer-wrapper ${remainingTime.asMilliseconds() <= 5 * 60000 && duration >= 10 ? "warning" : ""}`}>
                <div className="display-time">
                    {`${this.pad(remainingMinutes)}:${this.pad(remainingTime.seconds())}`}
                </div>
            </div>
        );
    }
}

