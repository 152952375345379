import { Box, List, Typography } from "@mui/material";
import { SimpleListItem } from "components/layout/lists/SimpleList";
import { Breadcrumbs } from "components/layout/navigation/Breadcrumbs";
import { PaddedPage } from "components/layout/page/PaddedPage";
import { Fake } from "components/layout/skeleton/Fake";
import { T } from "components/util/t";

export const EventEntryManagementPageSkeleton = () => {
    return (
        <Box className="skeleton" role="status" aria-label="loading">
            <PaddedPage>
                <Breadcrumbs className="no-background" crumbs={[{}]}/>
                <Typography variant="h3" component="h1"><T>All entries</T></Typography>
                <List sx={{ p: 0 }}>
                    <SimpleListItem subtitle={<Fake size={42}/>} />
                    <SimpleListItem subtitle={<Fake size={42}/>} />
                </List>
            </PaddedPage>
        </Box>
    );
};
