import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { ResultRowsHeaderContent } from "../heat/ResultRowsHeader";
import { RidesHeaders } from "../eventDivision/leaderboard/RidesHeaders";
import { useHeatHeaderStyles } from "../heat/heatStyles";
import { useHeatRunsStyles } from "../heat/heatStyles";
import classnames from "classnames";

export const LeaderboardRowsHeader = ({ heat, categories, maxRides }) => {
    const isMobile = useIsMobile();
    const classes = useHeatHeaderStyles();
    const runsClasses = useHeatRunsStyles({ canScroll: !isMobile });
    const HeaderComponent = useMemo(() => ({ className, children }) => <Typography variant="label2" color="textSecondary" className={classnames(classes.header, className)}>{children}</Typography>, []);

    return (
        <li className={classes.headerRow}>
            <ResultRowsHeaderContent heat={heat} runBased/>
            {!isMobile && categories && (
                <div className={runsClasses.runs}>
                    <RidesHeaders maxRides={maxRides} runBased={heat.config.runBased} categories={categories} Component={HeaderComponent} />
                </div>
            )}
        </li>
    );
};
