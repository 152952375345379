import { useEffect } from "react";
import throttle from "lodash/throttle";
import { useCallbackRef } from "./useCallbackRef";

export const useOnScroll = (callback, throttleTime = 40 ) => {
    const [scrollerRef, scrollerNode] = useCallbackRef();

    useEffect(() => {
        const nodeOrWindow = scrollerNode || window;
        const onScroll = throttle(callback, throttleTime);
        nodeOrWindow.addEventListener("scroll", onScroll);

        return () => nodeOrWindow.removeEventListener("scroll", onScroll);
    }, [callback, throttleTime, scrollerNode]);

    return { scrollerRef, scrollerNode };
};