import { makeStyles } from "components/providers/makeStyles";

export const useHeatSummaryStyles = makeStyles(theme => ({
    resultList: {
        padding: 0,
        margin: 0,
        flexGrow: 1
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        margin: 0,
        padding: theme.spacing(0.5, 2),
        minHeight: 40,
        "@media print": {
            minHeight: 20,
            padding: theme.spacing(0, 2),
        }
    }
}));

export const useHeatHeaderStyles = makeStyles(theme => ({
    headerRow: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(3, 0, 1),
        padding: theme.spacing(0, 2),
        "@media print": {
            margin: theme.spacing(1, 0, 1),
            paddingLeft: theme.spacing(1),
            "&& span": {
                fontSize: "0.5em"
            },
        }
    },
    header: {
        textTransform: "uppercase",
        "@media print": {
            fontSize: "0.5rem",
        }
    },
    place: {
        minWidth: 50,
        width: 50,
        "@media print": {
            minWidth: theme.spacing(4.5),
            width: theme.spacing(4.5),
            fontSize: "0.5rem"
        }
    },
    result: {
        marginLeft: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        textAlign: "right",
    },
    total: {
        "&&": {
            fontSize: "1.375rem",
            "@media print": {
                fontSize: "0.5rem"
            },
        },
    },
    winByOrNeeds: {
        "@media print": {
            minWidth: 40,
            width: 40,
            fontSize: "0.4rem"
        },
    },
}));

export const useHeatRunsStyles = makeStyles((theme, { canScroll }) => ({
    athlete: {
        alignSelf: "stretch",
        background: "#fff",
        position: canScroll && "sticky",
        left: canScroll && 66,
        minWidth: canScroll && theme.spacing(35),
        "@media print": {
            minWidth: theme.spacing(16),
        },
    },
    result: {
        alignSelf: "stretch",
        justifyContent: "center",
        backgroundImage: "linear-gradient(to right, #fff 89%, rgba(255, 255, 255, 0) 100%)",
        position: canScroll && "sticky",
        left: canScroll && `calc(66px + ${theme.spacing(35)})`,
        paddingRight: canScroll && theme.spacing(4),
        minWidth: canScroll && theme.spacing(14),
        "@media print": {
            left: `calc(50px + ${theme.spacing(16)})`,
            paddingRight: theme.spacing(2),
            minWidth: theme.spacing(14),
            "& .MuiTypography-h7": {
                fontSize: "1rem"
            },
            "& .MuiTypography-h4": {
                fontSize: "0.2rem"
            }
        },
    },
    runs: {
        display: "flex",
        alignSelf: "stretch",
        "& > span": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: theme.spacing(7),
            "@media print": {
                width: theme.spacing(5),
                "&, & .MuiTypography-root": {
                    fontSize: "0.8rem",
                },
            },
        }
    },
}));

export const useHeatRankStyles = makeStyles((theme, { canScroll, result }) => ({
    position: {
        width: theme.spacing(6),
        "@media print": {
            width: theme.spacing(4.75),
            paddingLeft: !result && theme.spacing(1.5),
            fontSize: "0.75rem",
            "& > .MuiTypography-h4": {
                fontSize: "0.75rem",
            }
        }
    },
    place: {
        position: canScroll && "sticky",
        background: "#fff",
        alignSelf: "stretch",
        display: "flex",
        alignItems: "center",
        left: 16,
    }
}));

export const useHeatRideCellsStyles = makeStyles(theme => ({
    firstCell: {
        "&&": {
            width: theme.spacing(9), // because of category initials
            "@media print": {
                width: theme.spacing(7),
            }
        }
    }
}));
