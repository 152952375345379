import { useRef, useState } from "react";
import { Field } from "redux-form/immutable";
import Immutable from "immutable";
import { Add } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

import { HelpTooltip } from "../../../elements/helpTooltip";
import { FormField } from "../../../forms";
import { T } from "../../../util/t";
import * as EventDirectorActions from "../../../../actions/eventDirector";
import { ProgressButton } from "components/layout/forms/fields/ProgressButton";
import { SettingsOption } from "components/layout/registrationSettings/SettingsOption";
import { SettingsItem } from "components/layout/registrationSettings/SettingsItem";
import { SettingsButton } from "components/layout/registrationSettings/SettingsButton";
import { Link } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    formField: {
        display: "inline-block",
        marginBottom: 0
    },
    uploadButton: {
        background: "none",
        "& button": {
            backgroundColor: theme.palette.background.low
        }
    }
}));

export const Terms = ({ prefix, options, change, untouch, dispatch, disabled, organisation }) => {
    const name = "terms_and_conditions_link";
    const value = options.get(name);
    const field = `${prefix}.${name}`;
    const classes = useStyles();

    const uploadInput = useRef();
    const [uploading, setUploading] = useState(false);
    const chooseFile = () => uploadInput.current.click();

    const uploadDocument = (e) => {
        setUploading(true);
        dispatch(EventDirectorActions.uploadDocument(e.target.files[0]))
            .then(fileName => {
                change(field, encodeURI(fileName));
                change(`${prefix}.terms_owner`, organisation.get("name"));
                setUploading(false);
            })
            .catch(() => setUploading(false));
    };

    const renderTermsUpload = (field) => {
        const { meta: { touched, error } } = field,
            showError = touched && error;

        return (
            <FormField type="file" showError={showError} error={error} className={classes.formField}>
                <label>
                    <HelpTooltip><T>event_terms_and_conditions</T></HelpTooltip>
                </label>
                <input type="file" accept="application/pdf" ref={uploadInput} style={{ display: "none" }} onChange={uploadDocument}/>
            </FormField>
        );
    };

    return (
        <SettingsOption
            change={change}
            field={field}
            value={value}
            untouch={untouch}
            onEdit={() => null}
            onView={({ deleteField, startEditing }) =>
                <SettingsItem disabled={disabled} onDelete={value && deleteField}>
                    {value
                        ? <>
                            <p><strong><T>Terms and conditions</T></strong></p>
                            <Link underline="always" href={value} rel="noopener noreferrer" target="_blank">{decodeURI(Immutable.List(value.split("/")).last())}</Link>
                        </>
                        : <>
                            <SettingsButton
                                Component={ProgressButton}
                                onClick={chooseFile}
                                className={classes.uploadButton}
                                disabled={disabled || uploading}
                                inProgress={uploading}
                                variant="outlined"
                                startIcon={<Add/>}
                            >
                                <T>Upload terms and conditions PDF</T>
                            </SettingsButton>
                            <Field name={field} component={renderTermsUpload} type="text"/>
                        </>}
                </SettingsItem>
            }
        />
    );
};
