import classnames from "classnames";

import { Heading } from "./Heading";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2),
        margin: "0 auto",
        width: "calc(100vw - (100vw - 100%))",
        maxWidth: `min(100vw, ${theme.shape.pageMaxWidth}px)`,
        "&& header": {
            margin: 0,
            padding: 0,
            borderBottom: 0,
            width: "auto"
        }
    }
}));
export const MaxWidthPage = ({ className, children }) => {
    const classes = useStyles();
    return (
        <main className={classnames(classes.page, className)}>
            {children}
        </main>
    );
};

export const MaxWidthPageWithHeading = ({ className, title, returnTo, actions, children }) => {
    return (
        <MaxWidthPage className={className}>
            <Heading title={title} returnTo={returnTo} actions={actions}/>
            {children}
        </MaxWidthPage>
    );
};
