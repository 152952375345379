import { useRef, useEffect, useMemo, useState } from "react";
import { VariableSizeList } from "react-window";
import throttle from "lodash/throttle";

import { RowRenderer } from "./RowRenderer";
import { syncScrollTop, loadMoreItems } from "./helpers";

export const WindowVirtualizedList = ({
    items,
    onLoadMore,
    estimatedItemSize,
    hasMoreItems,
    loadingText,
    renderItemComponent,
    LoadingComponent,
    rowElementType,
    ...rest
}) => {
    const listRef = useRef();
    const outerRef = useRef();
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    const [itemsSize, setItemsSize] = useState({});

    useEffect(() => {
        const onResize = () => setHeight(window.innerHeight);

        const onScroll = throttle(() => {
            syncScrollTop(listRef, outerRef);
            loadMoreItems(onLoadMore, loading, setLoading, hasMoreItems);
        }, 20);

        syncScrollTop(listRef, outerRef);
        loadMoreItems(onLoadMore, loading, setLoading, hasMoreItems);

        window.addEventListener("resize", onResize);
        window.addEventListener("scroll", onScroll);

        return () => {
            onScroll.cancel();
            window.removeEventListener("resize", onResize);
            window.removeEventListener("scroll", onScroll);
        };
    }, [hasMoreItems, items.length, loading, onLoadMore]);

    const itemData = useMemo(
        () => ({
            items,
            renderItemComponent,
            setItemsSize,
            listRef,
            rowElementType,
        }),
        [items, renderItemComponent, rowElementType]
    );

    return (
        <>
            <VariableSizeList {...{
                ref: listRef,
                outerRef: outerRef,
                height,
                overscanCount: 3,
                itemCount: items.length,
                estimatedItemSize: estimatedItemSize,
                itemSize: (index) => itemsSize[index] || estimatedItemSize,
                itemData,
                style: { height: "100%", overflow: "hidden" },
                ...rest,
            }}>
                {RowRenderer}
            </VariableSizeList>
            {loading && <LoadingComponent loadingText={loadingText}/>}
        </>
    );
};
