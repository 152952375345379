import classnames from "classnames";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";
import { ManageEventLinks } from "./ManageEventLinks";
import { useDialogState } from "../../../hooks/useDialogState";
import { useSideNavStyles } from "./useSideNavStyles";
import { EventStatus } from "../../events/manage/eventStatus";
import { NavMenuLinks } from "./NavMenuLinks";
import { RunEventMenu } from "../menus/RunEventMenu";

const useStyles = makeStyles((theme) => ({
    nav: {
        flex: 1,
    },
    button: {
        padding: theme.spacing(0.625)
    },
    menuText: {
        marginTop: theme.spacing(-0.5),
        fontSize: 10
    },
    drawer: {
        minWidth: theme.spacing(31.25),
        width: "45vw",
    },
    links: {
        borderTop: `1px solid ${theme.palette.border.light}`,
        borderBottom: `1px solid ${theme.palette.border.light}`,
    },
    navMenuLinks: {
        paddingTop: theme.spacing(2)
    }
}));

export const MobileNavigationMenu = ({ currentUser, isManagementPage, anchorEl, openMenu, closeMenu }) => {
    const [isOpen, openDrawer, closeDrawer] = useDialogState();

    const userInOrganisation = currentUser && (currentUser.role === "judge" || currentUser.role === "director");
    const classes = useStyles();
    const navClasses = useSideNavStyles();
    if (currentUser && !userInOrganisation) return null;

    return (

        <nav className={classes.nav}>
            <Box display="flex">
                {userInOrganisation && <Divider orientation="vertical" variant="middle" aria-hidden="true" flexItem />}
                <IconButton className={classes.button} onClick={userInOrganisation ? openDrawer : openMenu} role="button">
                    <MenuIcon fontSize="large"/>
                </IconButton>
            </Box>

            {userInOrganisation ?
                <Drawer open={isOpen} onClose={closeDrawer} classes={{ paper: classnames(navClasses.nav, classes.drawer) }}>
                    {isManagementPage &&
                    <>
                        <EventStatus eventId={isManagementPage.params.id}/>
                        <ManageEventLinks eventId={isManagementPage.params.id} clickLink={closeDrawer} className={classes.links}/>
                    </>
                    }
                    <NavMenuLinks user={currentUser} clickLink={closeDrawer} className={classes.navMenuLinks} isMobile/>
                </Drawer> :
                <RunEventMenu anchorEl={anchorEl} openMenu={openMenu} closeMenu={closeMenu} isMobile/>
            }
        </nav>
    );
};
