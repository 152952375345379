import classnames from "classnames";
import { JerseyAvatar } from "components/layout/heat/JerseyAvatar";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    bibImg: {
        display: "flex",
        alignItems: "center"
    },
    name: {
        "&&": {
            textAlign: "left",
            minWidth: 200,
            [theme.breakpoints.down("sm")]: {
                minWidth: "auto"
            },
            "&:not(.empty)": {
                textTransform: "capitalize",
            }
        }
    },
    avatar: {
        width: 40 + 2 * parseInt(theme.spacing(2)),
        minWidth: 40 + 2 * parseInt(theme.spacing(2)),
        maxWidth: 40 + 2 * parseInt(theme.spacing(2)),
        padding: theme.spacing(0.5, 0),
        ".img": {
            width: 40,
            height: 40,
            margin: theme.spacing(0, 2),
            verticalAlign: "middle",
            "&.fake": {
                width: 40,
                height: 40,
                borderRadius: 20
            },
            [theme.breakpoints.down("sm")]: {
                margin: theme.spacing(0, 1)
            }
        }
    },
}));

export const AthleteNameCell = ({ jersey, name, image, bib, teamName, avatarClassName, nameClassName }) => {
    const classes = useStyles();

    return (
        <>
            <td className={classnames("avatar", { "with-bib": !!bib }, classes.avatar, avatarClassName)}>
                {bib ?
                    <div className={classnames("bib-img", classes.bibImg)}>
                        <div><span className="bib">{bib}</span></div>
                        <JerseyAvatar className="img" jersey={jersey} alt={name} src={image}/>
                    </div> :
                    <JerseyAvatar className="img" jersey={jersey} alt={name} src={image}/>}
            </td>
            <td className={classnames("name", classes.name, nameClassName)}>
                {name}
                {teamName && <div className="team-name">{teamName}</div>}
            </td>
        </>
    );
};
