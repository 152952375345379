import { useDialogState } from "../../../../hooks/useDialogState";
import { TextAndIconMenuItem } from "../ModifierMenu";
import { DSQReasonDialog } from "./DSQReasonDialog";

export const DSQMenuItem = ({ name, Icon, addModifier }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <>
            <TextAndIconMenuItem name={name} Icon={Icon} onClick={openDialog}/>
            <DSQReasonDialog open={dialogOpen} onClose={closeDialog} addModifier={addModifier}/>
        </>
    );
};
