import { CircularProgress, MenuItem } from "@mui/material";
import { HelpTooltip } from "components/elements/helpTooltip";
import { FileButton } from "components/layout/forms/fields/FileButton";
import { T } from "components/util/t";
import { UploadDialog } from "components/util/uploadDialog";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as EventDirectorActions from "actions/eventDirector";

export const ImportAthletes = ({ closeMenu, eventDivisions, eventId, loaded }) => {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState();
    const [importFile, setImportFile] = useState();

    const fileSelected = useCallback((e) => {
        eventDivisions.some(ed => ed.get("athlete_count")) ?
            setImportFile(e.target.files[0]) :
            doFileUpload(e.target.files[0], true);
        e.target.value = "";
    }, [eventDivisions]);

    const doFileUpload = useCallback((file, overwrite) => {
        setUploading(true);
        return dispatch(EventDirectorActions.uploadEntries(eventId, file, overwrite))
            .then(closeImportDialog)
            .catch(closeImportDialog);
    }, [eventId]);

    const closeImportDialog = useCallback(() => {
        setImportFile(false);
        setUploading(false);
        closeMenu();
    }, []);

    return (
        <>
            <FileButton accept="text/csv" onChange={fileSelected} Component={MenuItem} disabled={!loaded || uploading}>
                <T>Import athletes</T>
                <HelpTooltip>
                    <T>import_to_event_tooltip</T>
                </HelpTooltip>
                {uploading && <CircularProgress size={12}/>}
            </FileButton>

            <UploadDialog
                title="Add to existing athletes?"
                description="import_to_event_dialog_description"
                open={!!importFile}
                importFile={importFile}
                closeImportDialog={closeImportDialog}
                doFileUpload={doFileUpload}/>
        </>
    );
};
