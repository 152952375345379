import { HeroPage } from "./HeroPage";
import { CheckCircle } from "@mui/icons-material";
import { Fake } from "../skeleton/Fake";

export const HeroSuccessPageSkeleton = () =>
    <HeroPage
        className="skeleton"
        role="status"
        ariaLabel="loading"
        icon={<CheckCircle sx={{ fontSize: "inherit", color: "success.dark" }}/>}
        title={<Fake size={130}/>}
        subtitle={<Fake size={100}/>}
        withFooter={false}
    />;
