import { PaymentDetailsSkeleton } from "../../../../layout/payments/PaymentDetailsSkeleton";
import { PurchaseDetailsSkeleton } from "../../../../layout/payments/PurchaseDetailsSkeleton";
import { useDetailsSectionStyles } from "./PaymentDetailsPage";
import { Heading } from "../../../../layout/page/Heading";

export const PaymentDetailsPageSkeleton = () => {
    const classes = useDetailsSectionStyles();
    return (
        <section className="skeleton">
            <Heading
                title="Payment details:"
                borderless
            />
            <section className={classes.detailsSection}>
                <PaymentDetailsSkeleton />
                <PurchaseDetailsSkeleton />
            </section>
        </section>
    );
};
