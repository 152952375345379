import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_EVENT_DIVISION } from "../../../../graphql/eventDivision";
import { redraw } from "../../../../actions/eventDivision";
import { AutoProgressButton } from "../../forms/fields/AutoProgressButton";
import { T } from "../../../util/t";

export const DrawButton = ({ formatDefinition, entryLimit, closeDialog }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, event_division_id } = useParams();
    const [updateEventDivision] = useMutation(UPDATE_EVENT_DIVISION);

    const onDraw = () =>
        new Promise((resolve, reject) => {
            updateEventDivision({
                variables: { eventDivisionId: event_division_id, formatDefinition: formatDefinition.current, entryLimit: entryLimit.current },
                onCompleted: () => dispatch(redraw(event_division_id, true))
                    .then(() => {
                        closeDialog();
                        history.push(`/events/${id}/divisions/${event_division_id}/draw`);
                        resolve();
                    }, reject),
                onError: reject
            });
        });

    return (
        <AutoProgressButton variant="contained" onClick={onDraw}><T>Draw heats</T></AutoProgressButton>
    );
};
