import { makeStyles } from "components/providers/makeStyles";

export const useRoundStyles = makeStyles((theme, { isAdminPage }) => ({
    round: {
        width: "25%",
        minWidth: theme.spacing(45),
        marginLeft: theme.spacing(3),
        "&:first-of-type": {
            marginLeft: 0
        },
        [theme.breakpoints.down("lg")]: {
            width: "31%",
        },
        "@media print": {
            flexShrink: 1,
            marginLeft: theme.spacing(1),
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            alignItems: "flex-start"
        }
    },
    roundName: {
        "&&": {
            margin: theme.spacing(0, 0, 2),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        "@media print": {
            width: "100%",
        }
    }
}));
