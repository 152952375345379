import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { keyframes } from "@mui/system";
import { useResizeObserver } from "hooks/useResizeObserver";
import { useRef, useState } from "react";
import asc from "assets/organisations/ASC Asian Surf Co logo.png";
import ils from "assets/organisations/International Life Saving ILS logo.png";
import ssnz from "assets/organisations/Snow Sports NZ logo.png";
import esa from "assets/organisations/ESA Eastern Surfing Association logo.png";
import lps from "assets/organisations/Liga Pro Skate LPS Portugal logo.png";
import sa from "assets/organisations/Surfing Australia logo.png";
import fmba from "assets/organisations/FMB Freeride Mountain Bike World Tour logo.png";
import pwa from "assets/organisations/Professional Windsurfers Association World Tour logo.png";
import usasa from "assets/organisations/USASA logo.png";
import fwt from "assets/organisations/Freeride World Tour logo.png";
import sbgb from "assets/organisations/SBGB Skateboard Great Britain logo.png";
import iwt from "assets/organisations/IWT International Windsurfing Tour logo.png";
import sls from "assets/organisations/SLS logo.png";
import { Section } from "./HomePage";

const useStyles = makeStyles((theme, { width }) => ({
    slider: {
        overflow: "hidden",
        position: "relative",
        margin: "auto"
    },
    track: {
        width,
        display: "flex",
        alignItems: "center",
        animation: `${keyframes`
            0% { transform: translateX(0) }
            100% { transform: translateX(-${width / 2}px) }
        `} ${width / 100}s linear infinite`,
    },
    slide: {
        paddingRight: theme.spacing(11),
        a: {
            display: "flex"
        },
        img: {
            height: theme.spacing(7.5),
            width: "auto"
        }
    },
    smaller: {
        img: {
            height: theme.spacing(6.25)
        }
    }
}));

export const organisations = {
    [asc]: "asiansurfco",
    [esa]: "surfesa",
    [ssnz]: "ssnz",
    [sls]: "slsa",
    [fwt]: "fwtglobal",
    [fmba]: "fmba",
    [lps]: "LIGAPROSKATE",
    [sa]: "surfingaus",
    [sbgb]: "skateboardgb",
    [usasa]: "usasa",
    [ils]: "ILSE",
    [pwa]: "WaveTour",
    [iwt]: "WaveTour",
};

export const OrganisationsLogos = () => {
    const ref = useRef();
    const [scrollWidth, setScrollWidth] = useState();
    const classes = useStyles({ width: scrollWidth });
    const logos = Object.keys(organisations);

    useResizeObserver(ref, () => setScrollWidth(ref.current?.scrollWidth));

    return (
        <Section>
            <div className={classes.slider}>
                <div className={classes.track} ref={ref}>
                    {[...logos, ...logos].map((src, i) =>
                        <div key={i} className={classnames(classes.slide, { [classes.smaller]: [esa, ssnz, fwt].includes(src) })}>
                            <a href={`/${organisations[src]}`}>
                                <img src={src} alt={organisations[src]} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </Section>
    );
};
