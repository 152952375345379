import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { Typography } from "@mui/material";
import get from "lodash/get";

const useStyles = makeStyles((theme, { backgroundColor, borderColor, color }) => ({
    pill: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        textDecoration: "none",
        padding: theme.spacing(0.25, 0.5),
        borderRadius: theme.spacing(0.5),
        backgroundColor: get(theme.palette, backgroundColor) || theme.palette.background.low,
        color: get(theme.palette, color),
        boxShadow: borderColor && `0 0 0px 1px ${get(theme.palette, borderColor)} inset`,
        "&.pointer":{
            cursor: "pointer",
        }
    }
}));

const mapStatusForColor = {
    on: { backgroundColor: "status.on", borderColor: "status.onBorder", color: "status.onText" },
    connected: { backgroundColor: "status.success", color: "status.successText" },
    open: { backgroundColor: "status.open", color: "status.openText" },
    paid: { backgroundColor: "status.success", color: "status.successText" },

    pending: { backgroundColor: "info.light" },
    refunded: { backgroundColor: "status.finished", color: "text.secondary" },
    "partially refunded": { backgroundColor: "status.finished", color: "text.secondary" },

    on_hold: { backgroundColor: "status.on_hold", color: "status.on_holdText" },
    rejected: { backgroundColor: "status.on_hold" },
    restricted: { backgroundColor: "status.on_hold" },
    restricted_soon: { backgroundColor: "warn.main" },
    "set up required": { backgroundColor: "status.on_hold" },
    active: { backgroundColor: "success.lighter" },

    finished: { backgroundColor: "status.finished", color: "status.finishedText" },
    ranking: { backgroundColor: "status.finished", color: "status.liveShadow" },

    closed: { backgroundColor: "status.finished", color: "text.secondary" },
    results_published: { backgroundColor: "status.finished" },

    online: { backgroundColor: "success.main" },
    offline: { backgroundColor: "error.light" },

    upcoming: { backgroundColor: "status.upcoming", color: "status.upcomingText" },
    scheduled: { backgroundColor: "status.upcoming", color: "status.upcomingText" },
    drawn: { backgroundColor: "status.finished" },
    published: { backgroundColor: "status.upcoming", color: "status.upcomingText" },
    draft: { backgroundColor: "status.draft", borderColor: "status.draftBorder", color: "text.secondary" },
    cancelled: { backgroundColor: "status.cancelled", color: "status.cancelledText" },

    confirmed: { backgroundColor: "status.success", color: "status.successText" },
    wailisted: { backgroundColor: "status.upcoming", color: "status.upcomingText" },
    denied: { backgroundColor: "status.cancelled", color: "status.cancelledText" },
};

export const StatusPill = ({ status, children, className, onClick, component = "div", to, target }) => {
    const classes = useStyles(mapStatusForColor[status]);

    return (
        <Typography variant="label2" component={component} className={classnames(className, classes.pill, { pointer: onClick } )} onClick={onClick} to={to} target={target}>
            {children}
        </Typography>);
};
