import Immutable from "immutable";
import { makeStyles } from "components/providers/makeStyles";
import { SetupRegistrationFields } from "./SetupRegistrationFields";
import { FormSection } from "../forms/FormSection";
import { Waitlisting } from "./fields/Waitlisting";
import { AgeRestriction } from "./fields/AgeRestriction";
import { Terms } from "components/organisations/payments/fields/terms";
import { MaxDivisionsPerAthlete } from "./fields/MaxDivisionsPerAthlete";
import { Notes } from "./fields/Notes";
import { DocsToSign } from "./fields/docsToSign/DocsToSign";
import { EmailConfirmationMessage } from "./fields/EmailConfirmationMessage";
import { Notification } from "../notifications/Notification";
import { Box } from "@mui/material";
import { EntryDates } from "./fields/EntryDates";
import { T } from "components/util/t";

const useStyles = makeStyles((theme) => ({
    formArea: {
        "& .formField": {
            marginBottom: theme.spacing(3),
        },
        "& > section:not(:last-of-type)": {
            marginBottom: theme.spacing(2)
        }
    }
}));

export const RegistrationSetupForm = (props) => {
    const classes = useStyles();
    const waitlisted = props.formState.getIn(["registration_options", "waitlisted"]);
    const paymentOptions = props.formState.get("payment_options");

    const sharedProps = {
        array: props.array,
        change: props.change,
        blur: props.blur,
        untouch: props.untouch,
        dispatch: props.dispatch,
        prefix: "registration_options",
        disabled: props.disabled,
        options: props.formState.get("registration_options")|| Immutable.Map()
    };

    return (
        <div className={classes.formArea}>
            <FormSection
                borderless
                title="Entry dates"
                subtitle={<T>entry_dates_subtitle</T>}>
                <EntryDates {...sharedProps} formState={props.formState} event={props.event} />
            </FormSection>
            <FormSection
                borderless
                title="Waitlisting"
                subtitle="waitlist_setting_help_text">
                <Waitlisting {...sharedProps}/>
                { waitlisted && paymentOptions &&
                <Notification type="info" text="As payment is required for this event, athletes will need to provide payment details during registration. However, they will only be charged if and when their entry gets confirmed by an event director."/>}
            </FormSection>

            <FormSection
                borderless
                title="Advanced registration rules"
                subtitle="Allows you to restrict registrations, for example, by athlete age, or number of divisions per athlete">
                <AgeRestriction startDate={props.startDate} divisions={props.divisions} {...sharedProps} />
                <MaxDivisionsPerAthlete {...sharedProps}/>
            </FormSection>

            <FormSection
                borderless
                title="Registration documents and information"
                subtitle="Include any notices, terms, or instructions you want people to see during and after registration">
                <DocsToSign {...sharedProps} organisationId={props.organisation.get("id")} formState={props.formState}/>
                <Box mt={2}>
                    <Notes {...sharedProps}/>
                    <Terms organisation={props.organisation} {...sharedProps}/>
                    <EmailConfirmationMessage {...sharedProps} defaultMessage={props.event.get("default_email_confirmation_message").replace("{EVENT_NAME}", props.formState.get("name"))} tooltipText="email_confirmation_message_helptext" />
                </Box>
            </FormSection>

            <SetupRegistrationFields {...props} />
        </div>
    );
};
