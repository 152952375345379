import { useQuery } from "@apollo/client";
import { GET_PURCHASE_DETAILS } from "../../../graphql/payment";
import { useParams } from "react-router-dom";
import { PaymentDetailsSection } from "./PaymentDetailsSection";
import { PurchaseDetailsSkeleton } from "./PurchaseDetailsSkeleton";
import { formatPrice } from "../../../utils/utils";
import { T } from "../../util/t";
import { makeStyles } from "../../providers/makeStyles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    athlete: {
        margin: theme.spacing(2, 0)
    },
    total: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2, 0, 1),
        borderTop: `1px solid ${theme.palette.border.main}`,
    }
}));
export const PurchaseDetails = () => {
    const { id } = useParams();
    const { data } = useQuery(GET_PURCHASE_DETAILS, { variables: { id: id }, fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }),
        payment = data?.payment;

    const classes = useStyles();
    if (!payment) return <PurchaseDetailsSkeleton/>;

    const athletes = payment.purchasedOptions.athletes.filter(athlete =>
        athlete.registrationsAttributes?.some(r => r && !r.id) ||
        athlete.extras?.some(e => e.uuid)
    );

    return (
        <PaymentDetailsSection title="Purchase details" >
            {athletes.map((athlete, i) => {
                const numberOfDivisions = athlete.registrationsAttributes?.filter(r => r && !r.id).length;
                return (
                    <div key={i} className={classes.athlete}>
                        <Typography variant="button3">{athlete.name}</Typography>

                        {numberOfDivisions > 0 &&
                        <Typography><T x={numberOfDivisions}>registered_for_x_divisions</T></Typography>}

                        {athlete.extras?.filter(e => e.uuid).map(extra =>
                            <Typography key={extra.uuid}>{extra.name}{extra.size && ` ${extra.size}`}</Typography>
                        )}
                    </div>
                );
            })}

            <div className={classes.total}>
                <Typography variant="h7"><T>Total</T></Typography>
                <Typography variant="h7">{formatPrice(payment.amount, payment.currency)}</Typography>
            </div>
        </PaymentDetailsSection>
    );
};
