import { Component } from "react";
import { ChunkErrorPage, GenericErrorPage } from "../layout/ErrorPages";

const errorComponents = {
    chunkLoadError: ChunkErrorPage,
    genericError: GenericErrorPage
};

export class ErrorBoundary extends Component {
    state = {};

    static getDerivedStateFromError(e) {
        if (e.name === "ChunkLoadError") {
            return { error: "chunkLoadError" };
        }
        return { error: "genericError" };
    }

    // eslint-disable-next-line no-unused-vars
    componentDidCatch(error, errorInfo) {
        window.Sentry?.captureException(error);
        window.Rollbar?.error(error);
    }

    render = () => {
        const { error } = this.state, ErrorComponent = errorComponents[error];

        return error ? <ErrorComponent/> : this.props.children;
    };
}
