import { api } from "./api";
import { notifyError } from "./error";
import { success } from "./notification";
import { getCurrentUser } from "./user";

export const list = () =>
    dispatch =>
        api.get("organisations")
            .then(res => dispatch({
                type: "SET_ORGANISATIONS",
                data: res.data
            }));

export const getCurrentUserOrganisation = () =>
    (dispatch, getState) =>
        api.get(`organisations/${getState().users.getIn(["current", "organisation_id"])}/details`)
            .then(res => dispatch({
                type: "SET_CURRENT_USER_ORGANISATION",
                data: res.data
            }));

export const getAccount = (organisation_id) =>
    dispatch =>
        api.get(`organisations/${organisation_id}/stripe/account`)
            .then(res => dispatch({
                type: "SET_ORGANISATION_ACCOUNT",
                organisation_id,
                data: res.data
            }));

export const linkNewAccount = (organisation_id, code) =>
    dispatch =>
        api.post(`organisations/${organisation_id}/stripe/link_account`, { code })
            .then(() => dispatch(getCurrentUser()), notifyError(dispatch))
            .then(() => dispatch(success("Stripe connected, you can now collect payments.")));

export const getSeries = (organisation_id) =>
    dispatch =>
        api.get(`organisations/${organisation_id}/series`)
            .then(res => dispatch({
                type: "SET_ORGANISATION_SERIES",
                organisation_id,
                data: res.data
            }));

export const getParentSeries = (organisation_id, event_division_id) =>
    dispatch =>
        api.get(`organisations/${organisation_id}/parent_series/${event_division_id}`)
            .then(res => dispatch({
                type: "SET_ORGANISATION_PARENT_SERIES",
                organisation_id,
                event_division_id,
                data: res.data
            }));

export const createSeries = (organisation_id, values) =>
    dispatch =>
        api.post(`organisations/${organisation_id}/series`, { series: values })
            .then((res) => dispatch(getSeries(organisation_id)).then(() => res.data), notifyError(dispatch))
            .then((series) => {
                dispatch(success("You're an icon! Your new series is now set up and ready for events💃"));
                return series;
            });

export const updateSeries = (organisation_id, series_id, values) =>
    dispatch =>
        api.put(`series/${series_id}`, { series: values })
            .then(() => dispatch(getSeries(organisation_id)), notifyError(dispatch));
