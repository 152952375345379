import { useHeatBreakdownStyles } from "./HeatLeaderboardBreakdown";
import { HeatCardSkeletonContent } from "./HeatCardSkeleton";

export const HeatBreakdownSkeleton = () => {
    const classes = useHeatBreakdownStyles();

    return (
        <section className={`${classes.mainContent} skeleton`}>
            <HeatCardSkeletonContent/>
        </section>
    );
};
