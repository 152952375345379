import { Avatar, Box } from "@mui/material";
import { Fake } from "./Fake";
import { useRowStyles } from "../commonStyles/tableStyles";

export const AthleteAvatarSkeleton = ({ withoutAvatar, size }) => {
    const classes = useRowStyles();

    return (
        <Box display="flex" alignItems="center">
            {!withoutAvatar &&
                <div className={classes.padded}>
                    <Avatar />
                </div>
            }
            <Fake size={size}/>
        </Box>
    );
};
