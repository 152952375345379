import classnames from "classnames";
import { Typography } from "@mui/material";
import { ResultRow } from "./ResultRow";
import { PlacementRow } from "./PlacementRow";
import { PlacementRowsHeader } from "./PlacementRowsHeader";
import { ResultRowsHeader } from "./ResultRowsHeader";
import { LeaderboardRowsHeader } from "../leaderboard/LeaderboardRowsHeader";
import { LeaderboardResultRow } from "../leaderboard/LeaderboardResultRow";
import { T } from "../../util/t";
import { useHeatSummaryStyles } from "./heatStyles";
import { useMemo } from "react";
import { TimingRowsHeader } from "./TimingRowsHeader";
import { TimingRow } from "./TimingRow";

export const useCompetitorsById = competitors => useMemo(() => competitors.reduce((hash, competitor) => {
    hash[competitor.athleteId] = competitor;
    return hash;
}, {}), [competitors]);

const getComponents = (config, isLeaderboard) => {
    if (config.calculator === "timing") return [TimingRowsHeader, TimingRow];
    if (config.calculator === "placing") return [PlacementRowsHeader, PlacementRow];
    if (isLeaderboard) return [LeaderboardRowsHeader, LeaderboardResultRow];
    return [ResultRowsHeader, ResultRow];
};

export const HeatSummaryResults = ({ heat, isLeaderboard, innerRef, className, ...rest }) => {
    const competitorsById = useCompetitorsById(heat.competitors);
    const [HeaderComponent, RowComponent] = getComponents(heat.config, isLeaderboard);
    const classes = useHeatSummaryStyles();

    return (
        <>
            {heat.result.length === 0
                ? <li className={classes.row}>
                    <Typography variant="body1"><T>Athlete lineup is coming soon, stay tuned.</T></Typography>
                </li>
                : <>
                    <HeaderComponent heat={heat} {...rest} />
                    {heat.result.map(athleteResult => (
                        <li key={athleteResult.athleteId} className={classnames(classes.row, className)} ref={innerRef}>
                            <RowComponent
                                competitor={competitorsById[athleteResult.athleteId]}
                                result={athleteResult}
                                heat={heat}
                                {...rest}
                            />
                        </li>
                    ))}
                </>}
        </>
    );
};
