export const PrivacyPolicyWordsEN = () => (
    <>
        <h2>Privacy Policy</h2>


        <p>
            This Privacy Policy reflects the enhanced transparency requirements of the EU General Data Protection Regulation (<strong>GDPR</strong>),  the Privacy Act 1988 (Australia) (<strong>Privacy Act</strong>) and the California Consumer Privacy Act 2018 (<strong>CCPA</strong>).
        </p>
        <p>
            In this Privacy Policy, ‘LiveHeats’ 'us' 'we' or 'our' means <strong>LiveHeats Pty Ltd</strong> (ABN 73612699564), which is the data controller for the purposes of the GDPR and the Privacy Act.  ‘Website’ means this website i.e. liveheats.com.
        </p>
        <p>
            LiveHeats is committed to respecting your privacy.  We will never sell your personal data for marketing or any other purposes.  This Privacy Policy sets out how we collect, use, store and disclose your personal data in compliance with the privacy and data protection obligations under the GDPR, [CCPA] and Privacy Act.
        </p>

        <ol>
            <li>
                <h3>Your consent in relation to personal data</h3>
                <p>
                    By providing personal data to us, you consent to our collection, use and disclosure of your personal data in accordance with this Privacy Policy.
                </p>
            </li>
            <li>
                <h3>What is personal data?</h3>
                <p>
                    Personal data means any information relating to you, provided that you can be identified or are identifiable (directly or indirectly) with such information, as defined in the GDPR.   Personal data includes information or an opinion about an individual that is reasonably identifiable. For example, this may include your name, email address, division(s) and organisation.   References to personal data are to be interpreted as interchangeable with the concepts of ‘personal information as defined in [the CCPA and] the Privacy Act.
                </p>
            </li>
            <li>
                <h3>What personal data do we collect?</h3>
                <p>
                    For the purposes of LiveHeats’ services and operations (as further described in Section 17 below), we may collect the following types of personal data, either directly from you or via organisations that have collected this data from you:
                </p>
                <ul>
                    <li>your name and contact details, including your email address and phone number;</li>
                    <li>your date of birth;</li>
                    <li>your division(s) within a relevant organisation or competition and membership details in relation to relevant organisations or competitions;</li>
                    <li>your results, scores and rankings within competitions;</li>
                    <li>your device ID, device type, geo-location information, computer and connection information, statistics on page views, traffic to and from the sites, ad data, IP address and standard web log information;</li>
                    <li>information that you provide to us through customer surveys; </li>
                    <li>membership identifiers and emergency contact details; and</li>
                    <li>other personal data that may be required by us or a relevant organiser in order to facilitate your dealings with us or that we use for the purposes of LiveHeats services and operations.</li>
                </ul>

                <p>
                    This personal data may be collected when you:
                </p>
                <ul>
                    <li>register on our website;</li>
                    <li>communicate with us through correspondence, chats, email, or when you share information with us from other social applications, services or websites; or</li>
                    <li>interact with our sites, services, content and advertising; or</li>
                    <li>provide the data to organisations that use our services.</li>
                </ul>
                <br />
            </li>
            <li>
                <h3>Why do we collect, use and disclose personal data?</h3>
                <p>
                    We may collect, hold, use and disclose your personal data for the following purposes:
                </p>
                <ul>
                    <li>to enable you to access and use LiveHeats and its features;</li>
                    <li>to operate, protect, improve and optimise LiveHeats, and our users' experience, such as to perform analytics, conduct research and for advertising and marketing;</li>
                    <li>to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you;</li>
                    <li>to send you marketing and promotional messages and other information that may be of interest to you, including messages on behalf of our business partners that we think you may find interesting;</li>
                    <li>to administer rewards, surveys, contests, or other promotional activities or events sponsored or managed by us or our business partners;</li>
                    <li>to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties.</li>
                </ul>

                <p>
                    We may also disclose your personal data to trusted third parties who also hold other information about you. This third party may combine that information in order to enable it and us to develop anonymised consumer insights so that we can better understand your preferences and interests, personalise your experience and enhance the products and services that you receive.
                </p>
                <p>
                    This processing is lawful under Article 6 of the GDPR as it is within the consent to processing that you provide to us, necessary for the performance of our contractual arrangement and/or for the purpose of our legitimate interests in operating the LiveHeats service.
                </p>
                <p>
                    If obliged to do so by law, we process personal data in order to meet duties of retention under commercial or tax law or to meet legal security requirements .  For further information on retention periods, please refer to Section 12 below.
                </p>
            </li>
            <li>
                <h3>Do we use your personal data for direct marketing?</h3>
                <p>
                    We may send you direct marketing communications and information about products and services. This may take the form of emails, or other forms of communication. You may opt-out of receiving marketing materials from us in accordance with the Spam Act (Australia) or other similar e-marketing regulations in your country of residence by contacting us using the details set out below or by using the opt-out facilities provided (e.g. an unsubscribe link).  This processing is based on consent given by you under Article 6(1)(a) of the GDPR.
                </p>
            </li>
            <li>
                <h3>To whom do we disclose your personal data?</h3>
                <p>
                    Your personal data is very important and helpful for us to provide our services. In the context of the data processing above and the respective legal bases given (contract performance, legitimate interests, consent or processing obligations under law), your data may be passed on to the following categories of recipients:
                </p>
                <ul>
                    <li>the administrators of your sporting organisation</li>
                    <li>our employees and related bodies corporate;</li>
                    <li>third party suppliers and service providers (including providers for the operation of our websites and/or our business or in connection with providing our products and services to you);</li>
                    <li>payment systems operators (e.g. merchants receiving card payments);</li>
                    <li>anyone to whom our assets or businesses (or any part of them) are transferred;</li>
                    <li>specific third parties authorised by you to receive information held by us; and/or</li>
                    <li>other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law.</li>
                </ul>

                <p>
                    Further, we are entitled to outsource the processing of personal data (completely or partially) to external service providers which are acting on our behalf as data processors in the meaning of Article 4 no. 8 GDPR. When such third-party service providers are located outside of the European Union (EU) or the European Economic Area (EEA), we will put in place appropriate safeguards in accordance with the requirements set by law and data protection authorities to ensure that your personal data is duly protected.
                </p>
            </li>
            <li>
                <h3>What are your data protection rights?</h3>
                <p>
                    LiveHeats is committed to ensuring fair and transparent processing. You can access the information we hold about you by contacting us at community@liveheats.com. If you think that any information we hold about you is inaccurate, please contact us and we will take reasonable steps to ensure that it is corrected.
                </p>
                <p>
                    If you are resident in an EU country or the UK then you have the following specific rights under the GDPR:
                </p>
                <ul>
                    <li>Right to information, Article 15 GDPR</li>
                    <li>Right to rectification, Article 16 GDPR</li>
                    <li>Right to deletion (right to be forgotten), Article 17 GDPR</li>
                    <li>Right to restrict processing, Article 18 GDPR</li>
                    <li>Right to data portability, Article 20 GDPR</li>
                    <li>Right to object, Article 21 GDPR</li>
                </ul>

                <p>
                    If you are resident outside of the EU or UK then you may have similar rights under applicable regulations.
                </p>
                <p>
                    To exercise your right, please contact us at the email listed in Section 17 below.  In order to be able to process your request, please note that we will use your personal data in accordance with Article 6 para. 1 (c) of the GDPR or any analogous regulation under applicable regulations.
                </p>
                <p>
                    If you are resident in the EU or UK then you have the right to lodge a complaint with a supervisory authority pursuant to Article 77 GDPR.
                </p>
                <p>
                    [If you are resident in California then you have the specific rights set out in Section 14 below.]
                </p>
            </li>
            <li>
                <h3>How can you withdraw your consent?</h3>
                <p>
                    If you gave us your consent to process your personal data, please note that you may withdraw this consent at any time by contacting us by email at the address listed in Section 17 below.  Please note that your consent can only be withdrawn with future effect and such a withdrawal does not have any influence on the lawfulness of past processing. In some cases, we may be entitled to continue to process your personal data on a different legal basis – to perform a contract, for example.
                </p>
                <p>
                    You may withdraw your consent to direct marketing in accordance with the process outlined in Section 5 above.   If you object to processing for direct marketing purposes, the personal data that relates to you shall no longer be processed for such purposes.
                </p>
            </li>
            <li>
                <h3>Using our website and cookies</h3>
                <p>
                    While we do not use browsing information to identify you personally, we may record certain information about your use of our website, such as which pages you visit, the time and date of your visit and the internet protocol address assigned to your computer.
                </p>
                <p>
                    Cookies are small text files that store information on your computer, TV, mobile phone or other device. They enable the entity that put the cookie on your device to recognise you across different websites, services, devices and/or browsing sessions.   We use cookies so that we can offer you the best possible website experience. This includes cookies which are necessary for the operation of the website and to manage our commercial objectives, as well as other cookies which are used solely for anonymous statistical purposes, for more comfortable website settings, or for the display of personalised content. You are free to decide which categories you would like to permit.   Alternatively, you can disable cookies through your internet browser but our websites may not work as intended for you if you do so. For more information please check the help menu of your browser.
                </p>
                <p>
                    We differentiate between cookies that are absolutely necessary for the website’s technical features and optional cookies.
                </p>
                <ul>
                    <li>
                        <p>
                            <em><u>Necessary:</u></em><br />
                            These cookies are necessary to run the core functionalities of this website, e.g. security related functions. With these cookies we can also detect if you want to stay logged into your profile to provide you with fast access to our services after revisiting our website.
                        </p>
                    </li>
                    <li>
                        <p>
                            <em><u>Statistics:</u></em><br />
                            In order to continuously improve our website, we anonymously track data for statistical and analytical purposes. With these cookies we can , for example, track the number of visits or the impact of specific pages of our web presence and therefore optimize our content.
                        </p>
                    </li>
                    <li>
                        <p>
                            <em><u>Comfort:</u></em><br />
                            We use these cookies to make using our website even more comfortable for you. For example, previously provided details may be retained so you do not need to enter all the details again.
                        </p>
                    </li>
                    <li>
                        <p>
                            <em><u>Personalization:</u></em><br />
                            These cookies are used to display personalized content matching your interests.
                        </p>
                    </li>
                </ul>
            </li>
            <li>
                <h3>Social plug-ins </h3>
                <p>
                    From time to time we may use social plug-ins on the website. Plug-ins are small extension modules used by different social networks that allow you to inform other users in the social network that you are on the website. Please note that we have only limited control over the data transmitted by these social plug-ins.   When you visit a page of our website that contains a social plug-in, your browser will establish a direct connection to the servers of the social network. The social network is informed that you are visiting the LiveHeats website and the social network stores this information in accordance with its privacy policy. The social network may also log your browser history or further information. If you interact with (click on) the social plug-in while you are logged onto the related social network, this information may be matched to your profile and shown on other sites of the internet. If you do not wish for the social network to collect data on you through the website, it may be helpful to log off from the social network before you visit our website.
                </p>
                <p>
                    For further information about the purpose and extent of the data collection and further use of your data by the social network, as well as your profile setting options to protect your privacy, please view the privacy policy of the relevant social networks.
                </p>
            </li>
            <li>
                <h3>Security</h3>
                <p>
                    We take reasonable steps to protect your personal data from misuse, interference and loss, as well as unauthorised access, modification or disclosure and we use a number of physical, administrative, personnel and technical measures to protect your personal data. For example, we use the most secure cloud database, Amazon Web Services to store and manage all user information.
                </p>
                <p>
                    Access to personal data on our databases is subject to reasonable technical safeguards and is restricted to authorized staff on a strict need-to-know basis. Further, in appropriate cases, we require our external service providers with access to personal data to sign data processing agreements (Article 28 GDPR) that require them to take the necessary and reasonable steps to protect the personal data provided to them.
                </p>
                <p>
                    Despite these reasonable steps, no security system is impenetrable and, due to the inherent nature of the internet, we cannot guarantee that information, during transmission through the internet or while stored on our systems or otherwise, will be absolutely safe from unauthorized access by others.
                </p>
            </li>
            <li>
                <h3>How long is your data stored?</h3>
                <p>
                    Your personal data is erased as soon as it is no longer required for the purposes stated. However, if necessary, we must continue to store your data until the retention periods and deadlines expire, set by the legislator or supervisory authorities. We may also retain your data until the statutory limitation periods have expired, provided that this is necessary for the establishment, exercise or defence of legal claims. After that, the relevant data will be routinely erased.
                </p>
            </li>
            <li>
                <h3>Links</h3>
                <p>
                    Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites, and have no control over or rights in those linked websites. The privacy policies that apply to those other websites may differ substantially from this Privacy Policy, so we encourage individuals to read them before using those websites.
                </p>
            </li>
            <li>
                <h3>Consumer Privacy Act (CCPA) – specific provisions for California resident</h3>
                <p>
                    The CCPA provides California resident users of LiveHeats.com (referred to in this section as “California consumers”) with specific rights regarding their personal data. This section describes California consumers’ rights and explains how they can exercise them.
                </p>
                <p>
                    <u>Access to Specific Information and Data Portability Rights:</u><br />
                    California consumers have the right to certain information about LiveHeats’ collection and use of their personal data over the past 12 months. Sections 3, 4 and 5 above describe the types of personal data LiveHeats collects, how it is processed by LiveHeats and the third parties with whom LiveHeats shares such information and the purpose for that sharing.
                </p>
                <p>
                    <u>Exercising Access, Data Portability, and Deletion Rights:</u><br />
                    California consumers have the right to request that LiveHeats deletes any of their personal data collected from them, subject to certain exceptions set out in the CCPA. These rights may be exercised by emailing the address in Section 17 below.   Only the relevant California consumer, or a person registered with the California Secretary of State that is authorized to act on a California consumer’s behalf, may make a verifiable consumer request related to that California consumer’s personal data. California consumers can also make a verifiable consumer request on behalf of their minor child.
                    California consumers may only make a verifiable consumer request for access or data portability twice within a 12-month period.
                </p>
                <p>
                    The verifiable consumer request must
                </p>
                <ul>
                    <li>provide sufficient information that allows LiveHeats to reasonably verify identity; and</li>
                    <li>describe the request with sufficient detail that allows LiveHeats to properly understand, evaluate, and respond to it.</li>
                </ul>
                <p>
                    <u>Response Timing and Format:</u><br />
                    LiveHeats will endeavour to respond to a verifiable consumer request within forty-five (45) days of its receipt. If more time is required (up to 90 days), LiveHeats will inform you of the reason and extension period in writing. There is no fee for processing verifiable consumer requests unless it is excessive, repetitive, or manifestly unfounded.
                </p>
                <p>
                    <u>Non-Discrimination:</u><br />
                    LiveHeats will not discriminate against anyone for exercising any of their CCPA rights. However, certain financial incentives may be offered to the extent permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive that is offered will reasonably relate to California consumers’ personal data’s value and contain written terms that describe the program’s material aspects. Participation in a financial incentive program would require prior opt in consent, which may be revoked at any time.
                </p>
                <p>
                    <u>Other California Privacy Rights</u><br />
                    California’s “Shine the Light” law (Civil Code Section § 1798.83) permits California consumers to request certain information regarding LiveHeats’ disclosure of personal data to third parties for their direct marketing purposes. To make such a request, please send an email to the email address in Section 17 below.
                </p>
            </li>
            <li>
                <h3>Making a complaint</h3>
                <p>
                    If you think we have breached the GDPR, CCPA or Privacy Act, or you wish to make a complaint about the way we have handled your information, you can contact us at the address listed in Section 17 below. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, you have the right to complaint to the relevant privacy regulator for your territory of residence:
                </p>
                <ul>
                    <li>in Australia you can complain to the Office of the Australian Information Commissioner – see here: <a href="https://www.oaic.gov.au/individuals/how-do-i-make-a-privacy-complaint">https://www.oaic.gov.au/individuals/how-do-i-make-a-privacy-complaint</a>;</li>
                    <li>in the EU you can complain to the supervisory authority of the Member State where you reside, where you work, or where you believe a GDPR infringement occurred;</li>
                    <li>in the United States you can contact the Federal Trade Commission and lodge a complaint through the process set out at: <a href="www.ftccomplaintassistant.gov">www.ftccomplaintassistant.gov</a></li>
                    <li>in the UK you have the right to lodge a complaint with the UK Information Commissioner’s Office which you can contact under <a href="https://ico.org.uk/global/contact-us">https://ico.org.uk/global/contact-us</a>.</li>
                </ul>
                <br />
            </li>
            <li>
                <h3>How to request data deletion</h3>
                <p>
                    In addition to the rights granted to users in an EU country or the UK (Section 7) and users who are residents of California (Section 14), LiveHeats grants the right to deletion to all users, irrespective of location. To exercise this right and request to have your personal data deleted, please contact us at the email listed in Section 17 below.
                </p>
                <br />
            </li>
            <li>
                <h3>Contact details</h3>
                <p>For further information about our Privacy Policy or practices, or to access or correct your personal data, or make a complaint, please contact us using the details set out below:</p>

                <p>
                    Chris Friend<br />
                    LiveHeats Privacy Officer<br />
                    <a href="mailto:chris@liveheats.com">chris@liveheats.com</a>
                </p>
            </li>
            <li>
                <h3>Changes</h3>
                <p>
                    LiveHeats reserves the right to change this privacy policy from time to time in compliance with the legal requirements, for example to be compliant with new laws or to add new services.
                </p>
                <p>
                    <br />
                    Date: 29 September 2021
                </p>
            </li>
        </ol>
    </>
);
