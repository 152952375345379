import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_FEDERATION_EVENT_DIVISION_PROPERTIES } from "../../graphql/organisation";
import { CustomProperties } from "../registration/CustomProperties";
import { Accordion } from "../../styleguide/patterns/expandable";

export const CustomEventDivisionProperties = ({ organisationId }) => {
    const { data } = useQuery(GET_ORGANISATION_WITH_FEDERATION_EVENT_DIVISION_PROPERTIES, { variables: { id: organisationId } }),
        federationProperties = data?.organisation?.federationProperties;

    if (!federationProperties?.length > 0) return null;

    return (
        <Accordion label="Custom fields">
            <CustomProperties basePath="properties" captureFields={federationProperties} level="event_division"/>
        </Accordion>
    );
};
