import { makeStyles } from "../../../providers/makeStyles";

export const usePriorityRowStyles = makeStyles((theme, { numberOfPositions }) => ({
    row: {
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    column: {
        height: `${50/numberOfPositions}vh`
    },
    center: {
        textAlign: "center"
    },
    priorityPosition: {
        borderRight: `1px solid ${theme.palette.border.main}`
    },
    priority: {
        display: "flex",
        flexDirection: "row"
    }
}));
