import { makeStyles } from "../../providers/makeStyles";

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: theme.spacing(2),
        "& button:not(:last-of-type)": {
            marginRight: theme.spacing(1),
        }
    },
    section: {
        margin: theme.spacing(1, 1, 0, 0),
    },
}));
export const SecondaryHeading = ({ heading, actions }) => {
    const classes = useStyles();

    return (
        <header className={classes.header}>
            <section className={classes.section}>
                {heading}
            </section>

            {actions}
        </header>
    );
};
