import { Accordion } from "../../../styleguide/patterns/expandable";
import { ParentSeriesDivisionMapping } from "./ParentSeriesDivisionMapping";
import { EventDivisionPointAllocationField } from "./EventDivisionPointAllocationField";

export const EventDivisionSeriesPointAllocationsSelectors = ({ parentDivisions, eventDivisionPointAllocations, organisationId, federationSeries }) => {
    return (parentDivisions?.size > 0 || eventDivisionPointAllocations?.size > 0) &&
        <Accordion label="Rankings">
            {parentDivisions?.map((parentDivision, i) => {
                const parentSeries = federationSeries.find(series => `${series.get("id")}` === `${parentDivision.get("series_id")}`);
                return <ParentSeriesDivisionMapping index={i} key={i} parentDivision={parentDivision} parentSeries={parentSeries} organisationId={organisationId} />;
            })}
            {eventDivisionPointAllocations?.map((eventDivisionPointAllocation, i ) => {
                const series = federationSeries.find(series => `${series.get("id")}` === `${eventDivisionPointAllocation.get("season_id")}`);
                return <EventDivisionPointAllocationField index={i} key={i} eventDivisionPointAllocation={eventDivisionPointAllocation} series={series} />;
            })}
        </Accordion>;
};
