import { Step, StepLabel, Stepper } from "@mui/material";
import { Fake } from "../skeleton/Fake";

export const RegistrationWizardSkeleton = () =>
    <section className="skeleton" role="status" aria-label="loading">
        <Stepper activeStep={0}>
            <Step><StepLabel><Fake size={23}/></StepLabel></Step>
            <Step><StepLabel><Fake size={17}/></StepLabel></Step>
            <Step><StepLabel><Fake size={17}/></StepLabel></Step>
            <Step><StepLabel><Fake size={12}/></StepLabel></Step>
        </Stepper>
    </section>;
