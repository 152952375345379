import { Card } from "./Card";
import { CardCaption } from "./CardCaption";
import { Stack, Typography } from "@mui/material";

export const EventCard = ({ title, subtitle, caption, aside, footer }) => (
    <Card>
        <Stack direction="column" flex={1}>
            <Typography variant="label2" textTransform="uppercase">
                <Stack direction="row" justifyContent="space-between" alignItems="center">{subtitle}{aside}</Stack>
            </Typography>
            <Typography variant="button3">{title}</Typography>
            <CardCaption textColor="text.secondary">{caption}</CardCaption>
        </Stack>
        {footer}
    </Card>
);
