import { PaddedPage } from "components/layout/page/PaddedPage";
import { SkeletonTable } from "components/layout/skeleton/SkeletonTable";
import { SeriesSelectorWithStyles } from "../../../layout/series/rankings/SeriesSelectorWithStyles";

export const PaddedRankingsPageSkeleton = () =>
    <PaddedPage>
        <div className="skeleton" role="status" aria-label="loading">
            <SeriesSelectorWithStyles />
            <SkeletonTable />
        </div>
    </PaddedPage>;
