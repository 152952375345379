import debounce from "lodash/debounce";

const debouncedHideNotification = debounce(dispatch => dispatch(hide("notification")), 5000);
const debouncedHideSticky = debounce(dispatch => dispatch(hide("sticky")), 5000);

let hideTimeout = null;

const notify = (notificationType, hide, notification) => dispatch => {
    dispatch({ type: "SET_NOTIFICATION", notificationType, notification });
    if (hideTimeout) clearTimeout(hideTimeout);
    setTimeout(() => dispatch({ type: "OPEN_NOTIFICATION", notificationType }));
    hide && (notificationType === "notification" ?
        debouncedHideNotification(dispatch) : debouncedHideSticky(dispatch)
    );
};

export const success = (text, hide = true) => dispatch =>
    dispatch(notify("notification", hide, { type: "success", text }));

export const error = (text, hide = true) => dispatch =>
    dispatch(notify("notification", hide, { type: "error", text }));

export const warn = (text, hide = true) => dispatch =>
    dispatch(notify("notification", hide, { type: "warn", text }));

export const hide = (notificationType) => dispatch => {
    dispatch({ type: "HIDE_NOTIFICATION", notificationType });
    hideTimeout = setTimeout(() => dispatch(close(notificationType)), 1000);
};

export const connecting = () => dispatch => {
    debouncedHideSticky.cancel();
    dispatch(notify("sticky", false, { text: "Connecting your device..." }));
};

export const connected = () => dispatch =>
    dispatch(notify("sticky", true, { type: "success", text: "Connected!" }));

export const disconnected = () => dispatch => {
    debouncedHideSticky.cancel();
    dispatch(notify("sticky", false, { text: "You're offline. You can still enter scores." }));
};

export const close = (notificationType) => ({ type: "REMOVE_NOTIFICATION", notificationType });
