import classnames from "classnames";
import { useCallback } from "react";
import { makeStyles } from "components/providers/makeStyles";
import { Done } from "@mui/icons-material";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { useScrollIntoView } from "../../../../hooks/useScrollIntoView";
import { ScrollWithShadows } from "../../utils/ScrollWithShadows";
import { useChipStyles } from "./useChipStyles";

export const ChipSelect = ({ field, input }) =>
    <ScrollWithShadows>
        <Stack direction="row" spacing={1}>
            {field.options.map(option =>
                <Chip key={option.label} value={option.value} input={input}>{option.label}</Chip>
            )}
        </Stack>
    </ScrollWithShadows>;

const useChipSelectStyles = makeStyles(theme => ({
    iconWrapper: {
        height: 20,
        "&.selected": {
            marginLeft: -theme.spacing(1),
            marginRight: theme.spacing(1),
            color: theme.palette.primary.contrastText
        }
    },
    icon: {
        "&&": {
            width: 0,
            height: 20,
            transition: theme.transitions.create("width", { duration: 150 }),
            "&.selected": {
                width: 20
            }
        }
    }
}));
const Chip = ({ value, input, children }) => {
    const selected = value === input.value;
    const classes = { ...useChipStyles(), ...useChipSelectStyles() };
    const onClick = useCallback(() => {
        input.onChange(value);
    }, []);
    const ref = useScrollIntoView(selected);

    return (
        <div className={classnames(classes.chipContainer, { selected })}>
            <ButtonBase component="div" ref={ref} className={classnames(classes.chip, { selected })} onClick={onClick}>
                <span className={classnames(classes.iconWrapper, { selected })}>
                    <Done className={classnames(classes.icon, { selected })}/>
                </span>
                <Typography variant="button">
                    {children}
                </Typography>
            </ButtonBase>
        </div>
    );
};
