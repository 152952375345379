import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";

import { useBaseInputStyles } from "./baseInputStyles";
import { useSharedInputStyles } from "./Input";
import { useTranslate } from "../../../providers/LocalizationProvider";

const useInputStyles = makeStyles(theme => ({
    textarea: {
        appearance: "none",
        outline: "none",
        border: "none",
        lineHeight: theme.spacing(3),
        padding: theme.spacing(1, 2),
        width: "100%",
        resize: "vertical",
        fontFamily: "inherit",
        fontSize: "inherit",
        minHeight: theme.spacing(5),
        "&[disabled]": {
            cursor: "not-allowed"
        }
    },
}));
export const Textarea = ({ field, input, hasError }) => {
    const baseClasses = useBaseInputStyles();
    const classes = useInputStyles();
    const sharedClasses = useSharedInputStyles();
    const placeholderText = useTranslate(field.placeholder);

    return (
        <div className={classnames(baseClasses.baseInput, sharedClasses.wrapper, { error: hasError })}>
            <textarea
                id={field.name}
                className={classes.textarea}
                placeholder={placeholderText}
                {...input}
                {...field.inputProps} />
        </div>
    );
};
