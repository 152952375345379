import { Box, Typography } from "@mui/material";
import { nSpaces } from "utils/utils";
import { Fake } from "../skeleton/Fake";
import { StatusPill } from "../StatusPill";
import { useEventHeaderStyles } from "./useEventHeaderStyles";

export const EventHeaderDetailsSkeleton = () => {
    const headerClasses = useEventHeaderStyles();

    return (
        <>
            <Box display="flex" alignItems="center">
                <Typography className={headerClasses.eventDate} variant="label2"><Fake size={25}/></Typography>
                <StatusPill status={"on"}>
                    {nSpaces(10)}
                </StatusPill>
            </Box>
            <Typography variant="h3" component="h1" className={headerClasses.name}><Fake size={43}/></Typography>
        </>
    );
};
