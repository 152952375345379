import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

export const SettingsItem = ({ children, disabled, onEdit, onDelete, className }) => (
    <div className={className}>
        {children}
        {!disabled && onEdit && <IconButton size="small" color="inherit" onClick={onEdit}><Edit fontSize="small"/></IconButton>}
        {!disabled && onDelete && <IconButton size="small" color="inherit" onClick={onDelete}><Delete fontSize="small"/></IconButton>}
    </div>
);
