import { makeStyles } from "components/providers/makeStyles";
import { ArrowDropDown } from "@mui/icons-material";

import { useBaseInputStyles } from "./baseInputStyles";
import { useTranslate } from "components/providers/LocalizationProvider";
import classnames from "classnames";

const useSelectStyles = makeStyles(theme => ({
    wrapper: {
        position: "relative",
    },
    icon: {
        position: "absolute",
        pointerEvents: "none",
        color: theme.palette.text.primary,
        right: 10,
        top: 10
    },
    select: {
        "&&": {
            padding: theme.spacing(1, 4, 1, 2),
        },
        backgroundColor: "white"
    }
}));
export const Select = ({ field, input, hasError }) => {
    const baseClasses = useBaseInputStyles();
    const classes = useSelectStyles();

    return (
        <div className={classes.wrapper}>
            <select className={classnames(baseClasses.baseInput, classes.select, { error: hasError })} {...input} {...field.inputProps}>
                {field.options.map(option => <Option key={option.value || option.label} option={option} />)}
            </select>
            <ArrowDropDown className={classes.icon}/>
        </div>
    );
};

const Option = ({ option }) => {
    const label = useTranslate(option.label);
    return <option value={option.value} disabled={option.disabled}>{label}</option>;
};
