import { Undo } from "@mui/icons-material";
import { undoStartHeat } from "actions/eventDirector";
import { AutoProgressListItem } from "components/layout/forms/fields/AutoProgressListItem";
import { T } from "components/util/t";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export const RacingUndoStart = ({ eventId, heatId, closeDrawer }) => {
    const dispatch = useDispatch();
    const onUndoStartHeat = useCallback(() => {
        closeDrawer();
        dispatch(undoStartHeat(eventId, heatId));
    }, [eventId, heatId]);

    return (
        <AutoProgressListItem onClick={onUndoStartHeat} startIcon={<Undo/>}>
            <T>Undo start</T>
        </AutoProgressListItem>
    );
};
