import { api } from "./api";
import Immutable from "immutable";
import { notifyError } from "./error";

export const getCurrentUser = () =>
    dispatch =>
        api.get("users/current")
            .then(res => dispatch({
                type: "SET_CURRENT_USER",
                data: res.data
            }));

export const login = (email, password, remember_me = true) =>
    dispatch =>
        api.post("users/sign_in", { user: { email, password, remember_me } })
            .then(res => dispatch({
                type: "SET_CURRENT_USER",
                data: res.data
            }));

export const registerFacebook = () =>
    dispatch =>
        api.post("users/auth/facebook/callback")
            .then(res => !res.data.omniauth_callback && dispatch({
                type: "SET_CURRENT_USER",
                data: res.data
            }));

export const register = (email, name, password, token) =>
    dispatch =>
        api.post("users", { token, user: { email, name, password, password_confirmation: password } })
            .then(res => dispatch({
                type: "SET_CURRENT_USER",
                data: res.data
            }));

export const update = (user) =>
    dispatch =>
        api.put("users", { user })
            .then(res => dispatch({
                type: "SET_CURRENT_USER",
                data: res.data
            }));

export const forgotPassword = (email) =>
    dispatch =>
        api.post("users/password-recover", { user: { email } })
            .then(res => dispatch({
                type: "FORGOT_PASSWORD",
                res
            }));

export const resetPassword = (password, passwordConfirmation, token) =>
    dispatch =>
        api.post("users/password-reset", { user: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: token
        } })
            .then(res => dispatch({
                type: "RESET_PASSWORD",
                res
            }));

export const logout = () => api.delete("users/sign_out");

export const uploadFile = (file) =>
    dispatch => {
        const isImage = file.type.includes("image");
        const filename = isImage ? "original" : "${filename}"; // eslint-disable-line no-template-curly-in-string

        return api.post("uploads/pre_sign", { content_type: file.type, filename }).then(res => {
            const preSignedData = Immutable.fromJS(res.data),
                formData = new FormData();

            formData.append("key", preSignedData.getIn(["fields", "key"]));
            preSignedData.get("fields").delete("key").forEach((value, key) => formData.append(key, value));
            formData.append("file", file);

            const baseUrl = process.env.NODE_ENV === "development" ? "https://staging.liveheats.com" : window.location.origin;
            const key = preSignedData.getIn(["fields", "key"]);
            const path = isImage ? key.replace("uploads/", "images/").replace("/original", ".webp") : key.replace("${filename}", file.name); // eslint-disable-line no-template-curly-in-string

            return api.postFileToS3(preSignedData.get("url"), formData)
                .then(
                    () => `${baseUrl}/${path}`,
                    (e) => notifyError(dispatch)(e)
                );
        });
    };
