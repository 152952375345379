import { Typography } from "@mui/material";
import { usePriorityStyles } from "components/layout/priority/usePriorityStyles";

export const PriorityPageSkeleton = () => {
    const totalPositions = 4;
    const classes = usePriorityStyles({ totalPositions });

    return (
        <main className={`${classes.container} skeleton`} role="status" aria-label="loading">
            {[...Array(totalPositions)].map((_, i) => (
                <Typography
                    key={i}
                    variant="h1"
                    component="span"
                    className={classes.position}
                >
                    X
                </Typography>
            ))}
        </main>
    );
};

