import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { Clear } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";

const useClearButtonStyles = makeStyles(theme => ({
    clearButtonContainer: {
        position: "absolute",
        right: 1 + parseInt(theme.spacing(5)),
        display: "flex",
        top: 4,
        bottom: 4,
        pointerEvents: "none",
    },
    clearButton: {
        cursor: "pointer",
        pointerEvents: "auto"
    },
    separator: {
        marginLeft: 3,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    }
}));

export const ClearButton = ({ input, onClear, className }) => {
    const classes = useClearButtonStyles();
    const handleClick = () => {
        input.onChange(null);
        onClear && onClear();
    };

    return (
        <div className={classnames(classes.clearButtonContainer, className)}>
            <IconButton onClick={handleClick} size="small">
                {input.value && <Clear className={classes.clearButton} />}
            </IconButton>
            <div className={classes.separator}>
                <Divider orientation="vertical" />
            </div>
        </div>
    );
};
