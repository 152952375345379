import { useDialogState } from "../../../../../hooks/useDialogState";
import { Box, IconButton, Link } from "@mui/material";
import { Close } from "@mui/icons-material";
import { T } from "../../../../util/t";
import { ConfirmationDialog } from "../../../dialogs/ConfirmationDialog";

export const EditDocusealTemplate = ({ onEdit, onDelete, label }) => {
    const [confirmDialogOpen, openConfigDialog, closeConfirmDialog] = useDialogState();
    const onRemove = () => {
        onDelete();
        closeConfirmDialog();
    };

    return (
        <>
            <Box mb={1}>
                • <Link onClick={onEdit}>{label} <T>(view/edit)</T></Link>
                <IconButton onClick={openConfigDialog}><Close/></IconButton>
            </Box>

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={closeConfirmDialog}
                action={onRemove}
                title="Remove this document?"
                content="Removing this document will remove it from your event registration process, but it will still be available in your DocuSeal account. Are you sure you want to proceed?"
                buttonTitle="Yes, remove document"
                cancelButtonTitle="Keep document"
            />
        </>
    );
};
