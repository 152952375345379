import Immutable from "immutable";

import { api } from "./api";
import { get, getCurrentHeats, getSchedule, scheduleAbortController } from "./event";
import { get as getHeat } from "./heat";
import { notifyError } from "./error";
import { uploadFile } from "./user";

import * as NotificationActions from "./notification";

export const getRankings = (series_id, division_id) =>
    dispatch =>
        api.get(`seasons/${series_id}/divisions/${division_id}/private_rankings`)
            .then(res => dispatch({
                type: "SET_SERIES_DIVISION_RANKINGS",
                series_id,
                division_id,
                data: res.data
            }));


export const getEventDivisions = event_id =>
    dispatch =>
        api.get(`events/${event_id}/event_director/divisions`)
            .then(res => dispatch({
                type: "SET_DASHBOARD_DATA_FOR_EVENT",
                event_id,
                data: res.data
            }));

export const drawHeats = (event_id) =>
    dispatch =>
        api.post(`events/${event_id}/event_director/draw`, {})
            .then(() => {
                dispatch(get(event_id));
                dispatch(getEventDivisions(event_id));
                dispatch(getCurrentHeats(event_id));
            }, notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Nicely done! You've built a heat draw and closed athlete registration. You can re-open registration in the event status at any time.")));

export const randomize = (event_id) =>
    dispatch =>
        api.post(`events/${event_id}/event_director/randomize`, {})
            .then(() => dispatch(get(event_id)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("randomize_successful")));

export const startHeats = (event_id, onStart) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/start_heats`)
            .then(() => onStart ? onStart() : dispatch(getCurrentHeats(event_id)));

export const startHeat = (event_id, heat_id) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/start_heat/${heat_id}`)
            .then(() => dispatch(getHeat(event_id, heat_id, false)));

export const undoStartHeats = (event_id, onUndo) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/undo_start_heats`)
            .then(() => onUndo ? onUndo() : dispatch(getCurrentHeats(event_id)));

export const undoStartHeat = (event_id, heat_id) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/undo_start_heat/${heat_id}`)
            .then(() => dispatch(getHeat(event_id, heat_id, false)));

export const endHeats = (event_id, did_not_compete = [], onEnd) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/end_heats`, { did_not_compete })
            .then(() => onEnd ? onEnd() : dispatch(getCurrentHeats(event_id)));

export const endHeat = (event_id, heat_id) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/end_heat/${heat_id}`)
            .then(() => dispatch(getHeat(event_id, heat_id, false)));

export const undoEndHeats = (event_id, onUndo) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/undo_end_heats`)
            .then(() => onUndo ? onUndo() : dispatch(getCurrentHeats(event_id)));

export const undoEndHeat = (event_id, heat_id) =>
    dispatch =>
        api.patch(`events/${event_id}/event_director/undo_end_heat/${heat_id}`)
            .then(() => dispatch(getHeat(event_id, heat_id, false)));

export const updateStatus = (event_id, status) =>
    dispatch => {
        dispatch({ type: "SET_EVENT_STATUS", event_id, status });
        return api.patch(`events/${event_id}/event_director/update_status`, { status })
            .then(() => dispatch(get(event_id)));
    };

const removeNulls = (schedule) =>
    schedule.set("podiums", schedule.get("podiums").map(heatIds => heatIds.map(id => id ? id : 0)));

export const saveBreak = (event_id, values) =>
    (dispatch, getState) => {
        const oldSchedule = getState().events.getIn(["schedules", event_id]),
            index = values.get("index"),
            schedule = removeNulls(index != null ?
                oldSchedule.setIn(["breaks", values.get("index"), "date"], values.get("date")) :
                oldSchedule.setIn(["breaks", oldSchedule.get("breaks").size], Immutable.fromJS({
                    date: values.get("date"),
                    position: oldSchedule.get("current_schedule_index") + 1
                })));

        return api.put(`events/${event_id}/event_director/update_schedule`, { schedule: schedule })
            .then(
                () => dispatch(getSchedule(event_id)),
                (e) => {
                    dispatch(getSchedule(event_id));
                    notifyError(dispatch)(e);
                }
            );
    };

export const deleteBreak = (event_id, index) =>
    (dispatch, getState) => {
        const oldSchedule = getState().events.getIn(["schedules", event_id]),
            schedule = removeNulls(oldSchedule.set("breaks", oldSchedule.get("breaks").delete(index)));

        return api.put(`events/${event_id}/event_director/update_schedule`, { schedule: schedule })
            .then(
                () => dispatch(getSchedule(event_id)),
                (e) => {
                    dispatch(getSchedule(event_id));
                    notifyError(dispatch)(e);
                }
            );
    };

export const beginDrag = () => {
    scheduleAbortController && scheduleAbortController.abort();
    return { type: "BEGIN_DRAG" };
};
export const cancelDrag = () => ({ type: "RESTORE_PRE_DRAG_STATE" });
export const endDrag = () => ({ type: "DRAG_ENDED" });
export const scheduleShuffle = ({ event_id, source, target }) => ({
    type: "SCHEDULE_SHUFFLE",
    event_id,
    source,
    target
});
export const endScheduleShuffle = ({ event_id, source, target }) => ({
    type: "END_SCHEDULE_SHUFFLE",
    event_id,
    source,
    target
});
export const moveBreak = ({ event_id, sourceIndex, targetPosition }) => ({
    type: "MOVE_BREAK",
    event_id,
    sourceIndex,
    targetPosition
});
export const saveSchedule = ({ event_id }) =>
    (dispatch, getState) => {
        scheduleAbortController && scheduleAbortController.abort();
        const schedule = getState().events.getIn(["schedules", event_id]);

        return api.put(`events/${event_id}/event_director/update_schedule`, { schedule: removeNulls(schedule) })
            .catch(e => {
                dispatch(cancelDrag());
                dispatch(getSchedule(event_id));
                notifyError(dispatch)(e);
            });
    };

export const swap = ({ eventId, heatId, source, target }) => ({
    type: "HEAT_SWAP_ATHLETES",
    eventId,
    heatId,
    source,
    target
});
export const saveSwap = ({ event_id, source, target }) =>
    dispatch =>
        api.post(`events/${event_id}/event_director/swap_athletes`, { source, target })
            .then(
                () => {
                    dispatch({ type: "DRAG_ENDED" });
                    Immutable.Set([source.heat_id, target.heat_id]).forEach(heatId => dispatch(getHeat(event_id, heatId)));
                },
                (e) => {
                    dispatch(cancelDrag());
                    Immutable.Set([source.heat_id, target.heat_id]).forEach(heatId => dispatch(getHeat(event_id, heatId)));
                    notifyError(dispatch)(e);
                }
            );

export const uploadEntries = (event_id, file, overwrite) =>
    dispatch => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("overwrite", overwrite);

        return api.postFile(`events/${event_id}/entries/upload_to_event`, formData)
            .then(() => dispatch(getEventDivisions(event_id)),
                (e) => {
                    dispatch(getEventDivisions(event_id));
                    notifyError(dispatch)(e);
                }
            )
            .then(() =>
                dispatch(NotificationActions.success("Epic! You've successfully imported athletes to your event. You'll find them on the seed list.")));
    };

export const uploadResults = (event_id, entries) =>
    dispatch => {
        return api.post(`events/${event_id}/entries/upload_event_results`, { entries })
            .then(() => dispatch(getEventDivisions(event_id)),
                (e) => {
                    dispatch(getEventDivisions(event_id));
                    notifyError(dispatch)(e);
                }
            )
            .then(() =>
                dispatch(NotificationActions.success("Epic! You've successfully imported results to your event. You'll find them on the Result tab of your division.")));
    };

export const uploadDocument = (file) =>
    dispatch => dispatch(uploadFile(file)).then(url => {
        dispatch(NotificationActions.success("Document uploaded successfully!"));
        return url;
    });
