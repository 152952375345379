import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AutoProgressButton } from "../forms/fields/AutoProgressButton";
import { T } from "../../util/t";

export const ConfirmationDialog = ({ open, onClose, action, title, content, buttonTitle, cancelButtonTitle, buttonProps, ...rest }) =>
    <Dialog open={open} aria-labelledby="confirmation-dialog-title" onClose={onClose} {...rest}>
        <ConfirmationDialogContent title={title} content={content} buttonTitle={buttonTitle} cancelButtonTitle={cancelButtonTitle} action={action} onClose={onClose} buttonProps={buttonProps}/>
    </Dialog>;

const ConfirmationDialogContent = ({ title, content, buttonTitle, cancelButtonTitle = "Cancel", action, onClose, buttonProps }) =>
    <>
        <DialogTitle id="confirmation-dialog-title"><T>{title}</T></DialogTitle>

        <DialogContent data-testid="confirmation-dialog-content"><T>{content}</T></DialogContent>

        <DialogActions>
            <Button variant="outlined" onClick={onClose} {...buttonProps}>
                <T>{cancelButtonTitle}</T>
            </Button>
            <AutoProgressButton variant="contained" color="primary" onClick={action} {...buttonProps}>
                <T>{buttonTitle}</T>
            </AutoProgressButton>
        </DialogActions>
    </>;
