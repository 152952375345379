import Immutable from "immutable";

import createStore from "./createStore";

export const reducer = createStore({
    SET_SERIES: (state, action) =>
        state.setIn(["series", action.series_id], Immutable.fromJS(action.data)),

    SET_SERIES_MEMBERS: (state, action) =>
        state.setIn([action.series_id, "members"], Immutable.fromJS(action.data)),

    SET_SERIES_PAYMENTS: (state, action) =>
        state.setIn([action.series_id, "payments"], Immutable.fromJS(action.data)),

    SET_SERIES_DIVISION_RANKINGS: (state, action) =>
        state.setIn(["rankings", action.series_id, action.division_id], Immutable.fromJS(action.data)),
});
