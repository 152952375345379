import { makeStyles } from "components/providers/makeStyles";
import { Timer as TimerIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { Timer } from "../../timer";
import { T } from "../../util/t";
import { HeatStatus } from "./HeatStatus";
import { FullHeatLeaderboardHeader } from "./FullHeatLeaderboardHeader";

const useStyles = makeStyles(theme => ({
    timer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 0),
        borderRadius: theme.spacing(1),
        color: "white",
        backgroundColor: theme.palette.border.selected,
        "& .timer-wrapper": {
            marginLeft: theme.spacing(1)
        }
    }
}));

export const FullHeatHeader = ({ heat, heatsInRound, closeModal }) => {
    const isFinished = !!heat.endTime,
        isLive = !isFinished && !!heat.startTime;
    const classes = useStyles();

    return (
        <FullHeatLeaderboardHeader
            heat={heat}
            title={
                <>
                    <T>{heat.round}</T>
                    {heatsInRound > 1 &&
                    <>
                        &nbsp;:&nbsp;
                        {heat.config.runBased ?
                            <T number={heat.position + 1}>run_number</T> :
                            <T number={heat.position + 1}>heat_number</T>}
                    </>}
                </>}
            status={<HeatStatus heat={heat} hideTimer/>}
            closeModal={closeModal}
        >
            {(isLive && !heat.config.hideTimer) &&
            <Typography variant="h5" component="section" className={classes.timer}>
                <TimerIcon fontSize="small"/>
                <Timer duration={heat.heatDurationMinutes} start={heat.startTime}/>
            </Typography>}
        </FullHeatLeaderboardHeader>
    );
};
