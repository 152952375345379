import { ReactComponent as SuccessIcon } from "./icons/SuccesIcon.svg";
import { ReactComponent as ErrorIcon } from "./icons/ErrorIcon.svg";
import { ReactComponent as WarningIcon } from "./icons/WarningIcon.svg";
import ready from "./icons/ready.gif";
import { makeStyles } from "components/providers/makeStyles";
import { SvgIcon } from "@mui/material";

const useStyles = makeStyles(theme => ({
    icon: {
        "&&": {
            width: theme.spacing(6),
            height: theme.spacing(6)
        }
    }
}));

const icons = {
    success: { component: SuccessIcon, title: "Tag read successfully" },
    error: { component: ErrorIcon, title: "Error" },
    warning: { component: WarningIcon, title: "Warning" },
};

export const ScanBoxIcons = ({ message }) => {
    const classes = useStyles();
    const icon = icons[message?.type];

    return icon
        ? <SvgIcon className={classes.icon} component={icon.component} titleAccess={icon.title} inheritViewBox/>
        : <img className={classes.icon} alt="reading tag" title="Ready to read tag" src={ready}/>;
};
