import classnames from "classnames";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";
import { useBaseInputStyles } from "./fields/baseInputStyles";
import { T } from "../../util/t";
import { useDialogState } from "../../../hooks/useDialogState";
import { SearchForm } from "../../search/search";

export const useTakeoverDialogStyles = makeStyles(theme => ({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    dialogContent: {
        paddingTop: 0,
        paddingBottom: 0,
    }
}));

const useStyles = makeStyles(theme => ({
    fakeInput: {
        display: "flex",
        marginBottom: theme.spacing(3)
    },
    icon: {
        marginRight: theme.spacing(0.5)
    }
}));
export const SearchTakeoverDialog = ({ DialogComponent, dialogProps, inputPlaceholder, dialogTitleId, className, showSearchIcon = true }) => {
    const [open, openDialog, closeDialog] = useDialogState();
    const inputClasses = useBaseInputStyles();
    const classes = useStyles();

    return (
        <>
            <div className={classnames(inputClasses.baseInput, classes.fakeInput, className)} onClick={openDialog}>
                {showSearchIcon && <Search className={classes.icon}/>}
                <T>{inputPlaceholder}</T>
            </div>
            <Dialog fullScreen open={open} onClose={closeDialog} aria-labelledby={dialogTitleId}>
                <DialogComponent {...dialogProps} closeDialog={closeDialog}/>
            </Dialog>
        </>
    );
};

export const SearchTakeoverDialogContent = ({ searchRef, dialogContentRef, placeholder, search, onSearch, loading, dialogTitleId, children, closeDialog }) => {
    const classes = useTakeoverDialogStyles();

    return (
        <>
            <DialogTitle id={dialogTitleId}>
                <Typography variant="h2" component="div" className={classes.title}>
                    <T>Search</T>
                    <IconButton color="inherit" size="small" onClick={closeDialog}><Close/></IconButton>
                </Typography>
                <Typography component="div">
                    <SearchForm
                        inputRef={searchRef}
                        placeholder={placeholder}
                        search={search}
                        onChange={onSearch}
                        loading={loading}
                        autoFocus
                    />
                </Typography>
            </DialogTitle>
            <DialogContent ref={dialogContentRef} className={classes.dialogContent}>
                {children}
            </DialogContent>
        </>
    );
};
