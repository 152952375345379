import { makeStyles } from "../../../providers/makeStyles";
import { SeriesSelector } from "../../../organisations/series/seriesSelector";

const useSeriesSelectorStyles = makeStyles({
    selector: {
        "&&": {
            padding: 0,
            margin: 0,
            width: "auto"
        }
    }
});
export const SeriesSelectorWithStyles = props => {
    const classes = useSeriesSelectorStyles();

    return <SeriesSelector className={classes.selector} {...props} />;
};
