import Immutable from "immutable";
import dayjs from "dayjs";

import createStore from "./createStore";

const findResultIndex = (heat, athlete_id) =>
    heat.get("result", Immutable.List()).findIndex(r => r.get("athlete_id") === parseInt(athlete_id));

const updateSchedule = (state, eventId, schedule) => {
    const podiums = schedule.get("podiums") || Immutable.Map(),
        scheduleSize = podiums.map(podium => podium.size).max() || 0,
        heats = podiums.valueSeq();

    Immutable.List().setSize(scheduleSize).reduce((startTime, x, i) => {
        if (i > 0) {
            startTime = dayjs(startTime)
                .add(heats.map(podiumHeats => podiumHeats.getIn([i - 1, "heat_duration_minutes"])).filter(d => d).max(), "minute")
                .add(schedule.get("heats_interval_seconds"), "second")
                .format();
        }
        const breakHere = schedule.get("breaks").filter(b => b.get("position") === i).last();
        startTime = breakHere ? breakHere.get("date") : startTime;
        startTime = heats.map(podiumHeats => podiumHeats.getIn([i, "start_time"])).filter(t => t).max() || startTime;

        heats.map(podiumHeats => podiumHeats.get(i)).filter(h => h).forEach(heat =>
            state = state.updateIn(["heats", eventId, heat.get("id")], (oldHeat = Immutable.Map()) => oldHeat.merge(heat.set("scheduled_time", dayjs(startTime).format())))
        );

        return startTime;
    }, schedule.getIn(["breaks", 0, "date"]));

    return state;
};

const removeEmpties = (fromPosition, toPosition) => podiums => {
    for (let i = fromPosition; i < Immutable.List([(toPosition != null ? toPosition : podiums.map(p => p.size).max()), podiums.map(p => p.size).max()]).min();) {
        podiums.some(ids => ids.get(i)) ?
            i++ :
            podiums = podiums.map(p => p.remove(i));
    }
    return podiums;
};

export const reducer = createStore({
    SET_EVENT_SCHEDULE: (state, action) => {
        if (state.get("stateBeforeDrag")) return state;

        const schedule = Immutable.fromJS(action.data),
            podiums = (schedule.get("podiums") || Immutable.Map()).map(heats => heats.map(heat => heat && heat.get("id"))),
            heatIds = podiums.valueSeq().flatten(1),
            currentScheduleIndex = schedule.get("current_schedule_index");

        if (!podiums.isEmpty()) {
            state = state
                .setIn(["schedules", action.event_id], schedule.set("podiums", podiums))
                .updateIn(["heats", action.event_id], (heats = Immutable.Map()) => heats.filter((h, id) => heatIds.contains(id)))
                .setIn(["current_heats", action.event_id, "heats"], Immutable.fromJS([
                    podiums.getIn(["Main bank", currentScheduleIndex]), podiums.getIn(["Secondary bank", currentScheduleIndex])
                ]).filter(id => id));
        }

        return updateSchedule(state, action.event_id, schedule);
    },

    GET_EVENTS: (state, action) =>
        state.set("list", Immutable.fromJS(action.res.data)),

    SET_EVENT: (state, action) =>
        state.setIn(["mini", action.data.id], Immutable.fromJS(action.data)),

    SET_EVENT_STATUS: (state, action) =>
        state.setIn(["mini", parseInt(action.event_id), "status"], action.status),

    SET_HEAT: (state, action) =>
        state.setIn(["heats", action.event_id, action.heat_id], Immutable.fromJS(action.data).set("scheduled_time", state.getIn(["heats", action.event_id, action.heat_id, "scheduled_time"]))),

    SET_CURRENT_HEATS: (state, action) => {
        const currentHeats = Immutable.fromJS(action.data),
            heatsMap = state.getIn(["heats", action.event_id], Immutable.Map()),
            schedule = state.getIn(["schedules", action.event_id], Immutable.Map());

        state = state
            .setIn(["schedules", action.event_id, "current_schedule_index"], currentHeats.get("current_schedule_index"))
            .setIn(["current_heats", action.event_id], currentHeats.set("heats", currentHeats.get("heats").map(heat => heat.get("id"))))
            .setIn(["heats", action.event_id], heatsMap.merge(Immutable.Map(currentHeats.get("heats").map(heat => [heat.get("id"), heat]))));

        return updateSchedule(state, action.event_id, schedule.set("podiums", (schedule.get("podiums") || Immutable.Map()).map(ids => ids.map(id => id && state.getIn(["heats", action.event_id]).get(id)))));
    },

    BEGIN_DRAG: state => state.set("stateBeforeDrag", state),

    RESTORE_PRE_DRAG_STATE: state => state.get("stateBeforeDrag") || state,

    DRAG_ENDED: state => state.delete("stateBeforeDrag"),

    SCHEDULE_SHUFFLE: (state, { event_id, source, target }) => {
        const stateBeforeDrag = state.get("stateBeforeDrag");

        if (!stateBeforeDrag) {
            return state;
        }

        if (target.position === source.position && target.podium === source.podium && source.selection.size < 2) {
            return stateBeforeDrag.set("stateBeforeDrag", stateBeforeDrag);
        }

        const podiumsPath = ["schedules", event_id, "podiums"],
            sourceHeat = stateBeforeDrag.getIn([...podiumsPath, source.podium, source.position]),
            targetPath = [...podiumsPath, target.podium],
            slice = stateBeforeDrag.getIn([...targetPath, target.position]) && source.podium === target.podium,
            mainPodium = stateBeforeDrag.getIn(podiumsPath).keySeq().first(),
            selection = source.selection.isEmpty() ? Immutable.List([source]) : source.selection,
            sliceSelection = selection.filter((s, i) => (i === 0 && slice) || (i !== 0 && s.podium === mainPodium)),
            nullSelection = selection.filter((s, i) => (i === 0 && !slice) || (i !== 0 && s.podium !== mainPodium));

        return stateBeforeDrag.withMutations(newState => {

            return newState
                .updateIn(podiumsPath, podiums => {
                    nullSelection.forEach(s => podiums = podiums.setIn([s.podium, s.position], null));
                    return podiums;
                })
                .updateIn(podiumsPath, podiums => podiums.mapEntries(([podium, ids]) =>
                    [podium, ids.filter((id, i) => !sliceSelection.some(s => s.podium === podium && s.position === i))]
                ))
                .updateIn(podiumsPath, removeEmpties(0, target.position))
                .updateIn(targetPath, targetPodium =>
                    (slice || targetPodium.get(target.position)) && target.position < targetPodium.size ?
                        targetPodium.insert(target.position, sourceHeat) :
                        targetPodium.set(target.position, sourceHeat)
                )
                .updateIn(podiumsPath, removeEmpties(target.position + 1))
                .set("stateBeforeDrag", stateBeforeDrag);
        });
    },

    MOVE_BREAK: (state, { event_id, sourceIndex, targetPosition }) => {
        const stateBeforeDrag = state.get("stateBeforeDrag");

        if (!stateBeforeDrag) {
            return state;
        }

        const currentPosition = state.getIn(["schedules", event_id, "breaks", sourceIndex, "position"]),
            newState = stateBeforeDrag.setIn(["schedules", event_id, "breaks", sourceIndex, "position"],
                currentPosition === targetPosition ? targetPosition + 1 : targetPosition);

        return newState.set("stateBeforeDrag", stateBeforeDrag);
    },

    END_SCHEDULE_SHUFFLE: (state, { event_id, source, target }) => {
        if (source.selection.size < 2)
            return state;

        const targetPath = ["schedules", event_id, "podiums", target.podium];
        let newState = state;
        source.selection.forEach((s, i) =>
            newState = i !== 0 && newState.getIn([...targetPath, target.position + i]) ?
                newState.setIn(targetPath, newState.getIn(targetPath).insert(target.position + i, state.getIn(["stateBeforeDrag", "schedules", event_id, "podiums", s.podium, s.position]))) :
                newState.setIn([...targetPath, target.position + i], state.getIn(["stateBeforeDrag", "schedules", event_id, "podiums", s.podium, s.position]))
        );

        return newState;
    },

    HEAT_SWAP_ATHLETES: (state, { eventId, heatId, source, target }) => {
        let stateBeforeDrag = state.get("stateBeforeDrag");

        if (!stateBeforeDrag) {
            return state;
        }

        if (target.position === source.position) {
            return stateBeforeDrag.set("stateBeforeDrag", stateBeforeDrag);
        }

        let sourceAthlete, targetAthlete;

        return stateBeforeDrag
            .updateIn(["heats", eventId, heatId], heat => heat
                .update("athletes", athletes =>
                    athletes.map(athlete => {
                        const position = athlete.get("position");
                        let newPosition = position;
                        if (position === source.position) {
                            sourceAthlete = athlete.update("athletes", athletes => athletes || Immutable.fromJS([{ id: athlete.get("id") }]));
                            newPosition = target.position;
                        } else if (position === target.position) {
                            targetAthlete = athlete.update("athletes", athletes => athletes || Immutable.fromJS([{ id: athlete.get("id") }]));
                            newPosition = source.position;
                        }
                        return athlete.set("position", newPosition);

                    }))
                .set("result", heat.get("result")
                    .map(result => {
                        const athleteId = result.get("athlete_id");
                        if (athleteId === sourceAthlete.get("id") && !targetAthlete) {
                            return null;
                        }
                        if (athleteId === sourceAthlete.get("id") && targetAthlete) {
                            return result
                                .set("athlete_id", targetAthlete.get("id"))
                                .update("rides", rides =>
                                    targetAthlete.get("athletes").reduce((rides, targetAthlete, i) =>
                                        rides.mapKeys(key => key === "" + sourceAthlete.getIn(["athletes", i, "id"]) ? "" + targetAthlete.get("id") : key),
                                    rides));

                        }
                        if (targetAthlete && athleteId === targetAthlete.get("id")) {
                            return result
                                .set("athlete_id", sourceAthlete.get("id"))
                                .update("rides", rides =>
                                    sourceAthlete.get("athletes").reduce((rides, sourceAthlete, i) =>
                                        rides.mapKeys(key => key === "" + targetAthlete.getIn(["athletes", i, "id"]) ? "" + sourceAthlete.get("id") : key),
                                    rides));
                        }
                        return result;
                    })
                    .push(targetAthlete ? null :
                        Immutable.fromJS({ athlete_id: sourceAthlete.get("id") }).set("rides", Immutable.Map(sourceAthlete.get("athletes").map(athlete => ["" + athlete.get("id"), Immutable.List()]))))
                    .filter(r => r)
                ))
            .set("stateBeforeDrag", stateBeforeDrag);
    },

    SET_HEAT_SCORE: (state, action) => {
        const heat = state.getIn(["heats", action.event_id, action.heat_id]);
        if (!heat) return state;

        const athleteResultIndex = findResultIndex(heat, action.parent_athlete_id);

        const newState = athleteResultIndex < 0
            ? state :
            state.updateIn([
                "heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                "" + action.score.athlete_id,
                action.ride_index,
                "scores"
            ], scores => {
                const newScores = scores || Immutable.Map(),
                    scorePath = ["" + action.judge_id];
                if (!action.score.score)
                    return newScores.deleteIn(scorePath);

                action.score.category && scorePath.push(action.score.category);
                return newScores.setIn(scorePath, action.score.score);
            });

        return newState;
    },

    ADD_HEAT_PLACE_MODIFIER: (state, action) => {
        const heat = state.getIn(["heats", action.event_id, action.heat_id]);
        const athleteResultIndex = findResultIndex(heat, action.parent_athlete_id);

        return athleteResultIndex < 0 ? state : state.setIn([
            "heats",
            action.event_id,
            action.heat_id,
            "result",
            athleteResultIndex,
            "rides",
            "" + action.athlete_id,
            action.ride_index,
            "modifier"
        ], Immutable.Map({ type: action.modifierType }));
    },

    REMOVE_HEAT_PLACE_MODIFIER: (state, action) => {
        const heat = state.getIn(["heats", action.event_id, action.heat_id]);
        const athleteResultIndex = findResultIndex(heat, action.parent_athlete_id);

        return athleteResultIndex < 0 ? state : state.setIn([
            "heats",
            action.event_id,
            action.heat_id,
            "result",
            athleteResultIndex,
            "rides",
            "" + action.athlete_id,
            action.ride_index,
            "modifier"
        ], null);
    }
});
