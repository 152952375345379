import { createContext, useContext, useState } from "react";

const AdminPageContext = createContext({});
AdminPageContext.displayName = "Admin";

export const REMOTE_HEIGHT = 82;

export const useAdminPageContext = () => useContext(AdminPageContext);

export const AdminPageProvider = ({ children }) => {
    const [isRemoteVisible, setIsRemoteVisible] = useState();

    return (
        <AdminPageContext.Provider value={{ isAdminPage: true, isRemoteVisible, setIsRemoteVisible }}>
            {children}
        </AdminPageContext.Provider>
    );
};
