import { Box } from "@mui/material";
import { Breadcrumbs } from "components/layout/navigation/Breadcrumbs";
import { HeadingNav } from "components/layout/navigation/HeadingNav";
import { Heading } from "components/layout/page/Heading";
import { MaxWidthPage } from "components/layout/page/MaxWidthPageWithHeading";
import { Fake } from "components/layout/skeleton/Fake";
import { useRouteMatch } from "react-router-dom";
import { AthleteActivityPageSkeleton } from "./AthleteActivityPageSkeleton";
import { EditAthletePageSkeleton } from "./edit/EditAthletePageSkeleton";
import { AthleteMembershipsPageSkeleton } from "./AthleteMembershipsPageSkeleton";

const skeletons = {
    "/athletes": AthleteActivityPageSkeleton,
    "/athletes/:athleteId": AthleteActivityPageSkeleton,
    "/athletes/:athleteId/edit": EditAthletePageSkeleton,
    "/athletes/:athleteId/memberships": AthleteMembershipsPageSkeleton,
};

export const AthleteDashboardPageSkeleton = () => {
    const Skeleton = skeletons[Object.keys(skeletons).find(path => useRouteMatch(path)?.isExact)]; // eslint-disable-line react-hooks/rules-of-hooks

    return (
        <div role="status" aria-label="loading" id="athlete-dashboard">
            <MaxWidthPage className="skeleton">
                <Breadcrumbs forceMobileView noBackground />
                <Heading title={<Fake size={40} />} />
                <HeadingNav links={[{ label: <Fake size={12} />, to: "" }]}/>
                <Box mt={2}>
                    {Skeleton && <Skeleton />}
                </Box>
            </MaxWidthPage>
        </div>
    );
};
