import { MaxWidthPageWithHeading } from "../../../layout/page/MaxWidthPageWithHeading";
import { FormSection } from "../../../layout/forms/FormSection";
import { FakeFormField } from "../../../layout/forms/FakeFormField";
import { Fake } from "../../../layout/skeleton/Fake";

import { ListItem, ListItemContainer } from "components/layout/lists/ListItem";
import { JerseyAvatar } from "components/layout/heat/JerseyAvatar";

export const PersonalSettingsPageSkeleton = () =>
    <MaxWidthPageWithHeading className="skeleton" title="Personal settings">
        <FormSection title="Account info">
            <FakeFormField label={20} field={80}/>
            <FakeFormField label={23} field={80}/>
            <FakeFormField label={30} field={80}/>
        </FormSection>
        <FormSection title="Athletes">
            <ListItemContainer width={{ md: "60%" }}>
                <ListItem icon={<JerseyAvatar />} title={<Fake size={27}/>} subtitle={<Fake size={55}/>}/>
            </ListItemContainer>
        </FormSection>
    </MaxWidthPageWithHeading>;
