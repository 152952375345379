import { useCallback, useRef } from "react";
import debounce from "lodash/debounce";

export const useReSubmittingFunction = (fieldName, initialValue, submit, postSubmit, TIMEOUT, setError, onEvent) => {
    const lastSavedValue = useRef(initialValue);
    const lastSubmittedTime = useRef(new Date().getTime());
    const onSubmit = useCallback((values, e, submittedTime) => {
        onEvent?.(e);
        const value = values[fieldName];
        if (value === lastSavedValue.current) {
            return postSubmit(e);
        }

        if (!submittedTime) {
            submittedTime = new Date().getTime();
            lastSubmittedTime.current = submittedTime;
        }
        lastSavedValue.current = null;

        return submit(values)
            .then(
                () => {
                    lastSavedValue.current = value;
                    postSubmit(e);
                },
                (error) => {
                    if (lastSubmittedTime.current === submittedTime) {
                        setError(fieldName, { type: "submit" });
                        debouncedSubmit(values, e, lastSubmittedTime.current);
                    }
                }
            );
    }, [submit]);
    const debouncedSubmit = useCallback(debounce(onSubmit, TIMEOUT), [onSubmit]);
    const directSubmit = useCallback((values, e) => {
        const result = debouncedSubmit(values, e);
        debouncedSubmit.flush();

        return result;
    }, [debouncedSubmit]);

    return { onSubmit: directSubmit, debouncedSubmit };
};
