import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license";
import App from "./App";

const LICENSE_KEY = "5fc0ab04c87a7ea64d3a31d2b46195d6Tz0xMDE4NTgsRT0xNzYzMjU0MjY2MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=";

LicenseInfo.setLicenseKey(LICENSE_KEY);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);
