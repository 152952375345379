import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form/immutable";

import * as EventDirectorActions from "../../../actions/eventDirector";
import { checkboxInput } from "../../forms";

import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogActions } from "@mui/material";
import { LoadingButton } from "../../actions/loadingButton";
import { Button } from "@mui/material";
import { ResponsiveDialog } from "../../modal";
import { T } from "../../util/t";


export const DidNotCompeteDialog = (props) =>
    <ResponsiveDialog className="did-not-compete-dialog"
                      open={props.open}
                      aria-labelledby="did-not-compete-dialog-title"
                      onClose={props.handleClose}>
        <EditRideForm {...props}/>
    </ResponsiveDialog>;

const selector = formValueSelector("didNotCompete");
@connect(state => ({
    initialValues: Immutable.fromJS({ did_not_compete: [] }),
    didNotCompete: (selector(state, "did_not_compete") || Immutable.List()).filter(id => id)
}))
@reduxForm({ enableReinitialize: true, touchOnChange: true, form: "didNotCompete" })
class EditRideForm extends PureComponent {
    static propTypes = {
        eventId: PropTypes.string.isRequired,
        rideLessAthletes: PropTypes.instanceOf(Immutable.Map).isRequired
    };

    submit = () => {
        const { dispatch, eventId, didNotCompete, handleClose, loadHeats } = this.props;
        return dispatch(EventDirectorActions.endHeats(eventId, didNotCompete, loadHeats))
            .then(handleClose);
    };

    render = () => {
        const { rideLessAthletes, didNotCompete, handleClose, handleSubmit, type } = this.props;

        return <>
            <form onSubmit={handleSubmit(this.submit)}>
                {rideLessAthletes.entrySeq().map(([calculator, athletes]) =>
                    <Fragment key={calculator}>
                        <DialogTitle id="did-not-compete-dialog-title" className="sticky-dialog-title">
                            <T>{calculator === "placing" ? "Remove athletes without places?" : "Remove athletes without any scores?"}</T>
                        </DialogTitle>

                        <DialogContent className="sticky-dialog-content">
                            {athletes.map((athlete, i) =>
                                <Field key={athlete.get("id")} name={`did_not_compete[${athlete.get("id")}]`}
                                       className={athlete.get("jersey")}
                                       component={checkboxInput}
                                       label={athlete.get("name")}
                                       parse={value => value ? athlete.get("id") : null}/>
                            )}
                        </DialogContent>
                    </Fragment>
                )}
            </form>

            <DialogActions className="sticky-dialog-actions">
                <Button variant="outlined" onClick={handleClose}>
                    <T>Cancel</T>
                </Button>
                <LoadingButton variant="contained" action={handleSubmit(this.submit)} color="primary" autoFocus>
                    <T>{didNotCompete.isEmpty() ? `End ${type}s` : `Remove and end ${type}s`}</T>
                </LoadingButton>
            </DialogActions>
        </>;
    };
}
