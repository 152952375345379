import classnames from "classnames";
import { Typography } from "@mui/material";
import { AthleteInfo } from "./AthleteInfo";
import { T } from "../../util/t";
import { useHeatHeaderStyles, useHeatRunsStyles } from "./heatStyles";
import { useIsMobile } from "hooks/useIsMobile";
import { RankCell } from "./RankCell";
import { Result } from "models/Result";

export const ResultRow = ({ result: r, heat, competitor, runBased }) => {
    const result = Result(r);
    const classes = useHeatHeaderStyles();
    const isMobile = useIsMobile();
    const runsClasses = useHeatRunsStyles({ canScroll: !isMobile, result: true });
    const { athlete, bib, teamName, position, priority } = competitor;
    const jersey = (heat.config.jerseyOrder || [])[position];
    const isLive = !heat.endTime && !!heat.startTime;
    const { place, type } = result.placeToDisplay();

    return (
        <>
            <RankCell type={type} runBased={runBased}>{place}</RankCell>
            <AthleteInfo {...{ athlete, bib, teamName, jersey, runBased, }} priority={isLive && priority} />

            <section className={classnames(classes.result, { [runsClasses.result]: runBased })}>
                <Typography variant="h7" className={classes.total}>
                    {result.formattedTotal(heat.config)}
                </Typography>

                {!heat.config.hideNeeds &&
                (result.winBy != null
                    ? <Typography variant="body2" color="textSecondary" className={classes.winByOrNeeds}><T winBy={result.winBy} heatFinished={!!heat.endTime}>TotalCell_winBy</T></Typography>
                    : result.needs != null
                        ? <Typography variant="body2" color="textSecondary" className={classes.winByOrNeeds}><T needs={result.needs} heatFinished={!!heat.endTime} maxRideScore={heat.config.maxRideScore}>TotalCell_needs</T></Typography>
                        : null
                )}
            </section>
        </>
    );
};
