import Immutable from "immutable";
import { DialogTitle, IconButton, MenuItem } from "@mui/material";
import { T } from "components/util/t";
import { useDialogState } from "hooks/useDialogState";
import { useCallback } from "react";
import { uuid } from "utils/uuid";
import { DialogFormKit } from "../../forms/FormKit";
import { string } from "yup";
import { HelpTooltip } from "components/elements/helpTooltip";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import * as EventDivisionGroupActions from "actions/eventDivisionGroup";
import * as EventActions from "actions/event";
import { GroupDivisionsDialogSelector } from "./GroupDivisionsDialogSelector";
import { GroupDivisionsUngroupButton } from "./GroupDivisionsUngroupButton";
import { ResponsiveDialog } from "components/modal";

export const CreateGroupDivision = ({ closeMenu, ...rest }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    const onClose = useCallback(() => {
        closeMenu();
        closeDialog();
    }, []);

    return (
        <>
            <MenuItem onClick={openDialog}>
                <T>Create groups</T>
                <HelpTooltip>
                    <T>create_group_tooltip</T>
                </HelpTooltip>
            </MenuItem>
            <GroupDivisionsDialog dialogOpen={dialogOpen} closeDialog={onClose} {...rest}/>
        </>
    );
};

export const EditGroupDivision = (props) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    const onEdit = useCallback((e) => {
        e.preventDefault(); // parent element is <a>
        openDialog();
    }, []);

    const stopPropagation = useCallback((e) => e.stopPropagation(), []);

    return (
        <>
            <IconButton onClick={onEdit}><Edit /></IconButton>
            <div onClick={stopPropagation}>
                <GroupDivisionsDialog dialogOpen={dialogOpen} closeDialog={closeDialog} {...props}/>
            </div>
        </>
    );
};

export const GroupDivisionsDialog = ({ dialogOpen, closeDialog, ...rest }) =>
    <ResponsiveDialog open={dialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth className="lh-dialog" onKeyDown={e => e.stopPropagation()}>
        <GroupDivisionsDialogContent {...rest} closeDialog={closeDialog}/>
    </ResponsiveDialog>;

export const GroupDivisionsDialogContent = ({ division, divisions, eventId, closeDialog }) => {
    const dispatch = useDispatch();

    const handleSubmit = useCallback(values =>
        dispatch(division
            ? EventDivisionGroupActions.updateDivisionGroup(division.get("id"), eventId, values.divisions)
            : EventDivisionGroupActions.createDivisionGroup(eventId,
                values.divisions.push(Immutable.fromJS({
                    name: values.groupName,
                    uuid: uuid(),
                    is_group: true
                }))
            )).then(() => dispatch(EventActions.get(eventId)).then(() => closeDialog())),
    [eventId, division]);

    const fields = [
        {
            label: "Group name:",
            name: "groupName",
            validation: string().required("The group needs a name"),
            inputProps: { disabled: !!division }
        },
        {
            name: "divisions",
            component: GroupDivisionsDialogSelector,
            divisions,
            division
        }
    ];

    return (
        <>
            <DialogTitle><T>{division ? "Edit grouped division" : "Create grouped division"}</T></DialogTitle>
            <DialogFormKit
                initialValues={{
                    groupName: division?.get("name"),
                    divisions: division?.get("event_divisions") || Immutable.List()
                }}
                fields={fields}
                onSubmit={handleSubmit}
                onCancel={closeDialog}
                buttonLabel={!division ? <T>Create</T> : <T>Update</T>}
                secondaryAction={division && <GroupDivisionsUngroupButton id={division.get("id")} eventId={eventId} closeDialog={closeDialog} />}
            />
        </>
    );
};
