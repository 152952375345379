import { gql, useMutation, useQuery } from "@apollo/client";
import { Close, Settings } from "@mui/icons-material";
import { Button, DialogTitle, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { ResponsiveDialog } from "components/modal";
import { T } from "components/util/t";
import { useDialogState } from "hooks/useDialogState";
import { useError, useSuccess } from "hooks/useNotifications";
import { DialogFormKit } from "components/forms/FormKit";
import { number, string } from "yup";
import { PointsPerPlaceField } from "../forms/fields/PointsPerPlaceField";
import { EventDivisionFragment } from "../../../graphql/eventDivision";

const ContestConfigFragment = gql`
  fragment ContestConfig on ContestConfig {
    shape {
      numberOfChildren
      heatDurationMinutes
      contestantsPerChild
      progression {
        max
      }
    }
    result {
      config {
        pointsPerPlace
      }
    }
    produces {
      shape {
        contestantsPerChild
        heatDurationMinutes
        progression {
          max
        }
      }
    }
  }`;


const GET_CONTEST = gql`
  query getContest($id: ID!) {
    contest(id: $id) {
      id
      type
      name
      parent {
        id
        config {
          ...ContestConfig
        }
      }
      savedConfig {
        ...ContestConfig
      }
    }
  }
  ${ContestConfigFragment}
`;
export const EditContest = ({ dialogTitle, contestId, buttonText, onClose, ActionComponent = EditContestMenuItem }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const contest = useQuery(GET_CONTEST, { variables: { id: contestId }, fetchPolicy: "cache-and-network", }).data?.contest;

    const onCloseDialog = (e, reason) => {
        closeDialog(e, reason);
        onClose?.();
    };

    return (
        <>
            <ActionComponent buttonText={buttonText} openDialog={openDialog}/>

            <ResponsiveDialog open={dialogOpen && !!contest} onClose={onCloseDialog} maxWidth="sm" fullWidth className="lh-dialog" aria-labelledby="edit-contest-dialog-title">
                <EditContestDialog dialogTitle={dialogTitle} closeDialog={onCloseDialog} contest={contest} />
            </ResponsiveDialog>
        </>
    );
};

export const EditContestButton = ({ openDialog, buttonText }) =>
    <Button onClick={openDialog} variant="outlined" startIcon={<Settings/>}>
        <T>{buttonText}</T>
    </Button>;

export const EditContestMenuItem = ({ openDialog, buttonText }) =>
    <MenuItem onClick={openDialog}>
        <Stack spacing={1} direction="row" alignItems="center">
            <Settings/>
            <span><T>{buttonText}</T></span>
        </Stack>
    </MenuItem>;

export const eventDivisionLevelFields = () => [
    {
        name: "config.produces.shape.contestantsPerChild",
        label: "Default heat size:",
        type: "number",
        validation: number().required("Required").min(2),
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.produces.shape.heatDurationMinutes",
        label: "Default heat duration:",
        type: "number",
        parse: v => v ? parseInt(v) : null,
        validation: number().required("Required"),
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.produces.shape.progression.0.max",
        label: "Default progression between rounds:",
        type: "number",
        placeholder: "50%",
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    }
];

export const roundRobinLevelFields = defaultConfig => [
    {
        name: "name",
        type: "text",
        label: "Round name:",
        validation: string().required("The round needs a name.")
    },
    {
        name: "config.shape.heatDurationMinutes",
        label: "Default heat duration:",
        type: "number",
        placeholder: `Default: ${defaultConfig?.shape.heatDurationMinutes} minutes`,
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.shape.numberOfChildren",
        label: "Number of rounds:",
        type: "number",
        validation: number().required("Required").min(2),
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.produces.shape.contestantsPerChild",
        label: "Heat size:",
        type: "number",
        validation: number().required("Required").min(2),
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.shape.progression.0.max",
        label: "Progression to the next round:",
        type: "number",
        placeholder: "50%",
        parse: v => v ? parseInt(v) : null,
        validation: number().required("Required").min(0),
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.result.config.pointsPerPlace",
        label: "The points set in this table will automatically be awarded to each competitor based on their final position in the round.",
        component: PointsPerPlaceField
    }
];

export const normalRoundFields = defaultConfig => [
    {
        name: "name",
        type: "text",
        label: "Round name:",
        validation: string().required("The round needs a name.")
    },
    {
        name: "config.shape.heatDurationMinutes",
        label: "Default heat duration:",
        type: "number",
        placeholder: `Default: ${defaultConfig?.shape.heatDurationMinutes} minutes`,
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.shape.contestantsPerChild",
        label: "Heat size:",
        type: "number",
        parse: v => v ? parseInt(v) : null,
        placeholder: `Default: ${defaultConfig?.shape.contestantsPerChild}`,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    },
    {
        name: "config.shape.progression.0.max",
        label: "Progression to the next round:",
        type: "number",
        placeholder: `Default: ${defaultConfig?.shape.progression?.[0]?.max || "50%"}`,
        parse: v => v ? parseInt(v) : null,
        inputProps: {
            pattern: "[0-9]*",
            inputMode: "numeric"
        }
    }
];

const UPDATE_CONTEST = gql`
  mutation updateContest($contest: ContestInput!) {
    updateContest(input: {contest: $contest}) {
      eventDivision {
        ...EventDivision
      }
      contest {
        id
        name
        savedConfig {
          ...ContestConfig
        }
        parent {
          id
          config {
            ...ContestConfig
          }
        }
      }
    }
  }
  ${EventDivisionFragment}
  ${ContestConfigFragment}
`;

const inputForEventDivision = contest => ({
    id: contest.id,
    config: {
        produces: {
            shape: {
                contestantsPerChild: contest.savedConfig.produces.shape.contestantsPerChild,
                heatDurationMinutes: contest.savedConfig.produces.shape.heatDurationMinutes,
                progression: contest.savedConfig.produces.shape.progression
            }
        }
    }
});

const inputForRoundRobin = contest => ({
    id: contest.id,
    name: contest.name,
    config: {
        shape: {
            numberOfChildren: contest.savedConfig.shape.numberOfChildren,
            heatDurationMinutes: contest.savedConfig.shape.heatDurationMinutes,
            progression: contest.savedConfig.shape.progression
        },
        produces: {
            shape: {
                contestantsPerChild: contest.savedConfig.produces.shape.contestantsPerChild,
            }
        },
        result: {
            config: {
                pointsPerPlace: contest.savedConfig.result.config.pointsPerPlace
            }
        }
    }
});

const inputForRound = contest => ({
    id: contest.id,
    name: contest.name,
    config: {
        shape: {
            contestantsPerChild: contest.savedConfig.shape?.contestantsPerChild,
            heatDurationMinutes: contest.savedConfig.shape?.heatDurationMinutes,
            progression: contest.savedConfig.shape?.progression
        }
    }
});

const EditContestDialog = ({ dialogTitle, closeDialog, contest }) => {
    const [updateContest] = useMutation(UPDATE_CONTEST);
    const { notifySuccess } = useSuccess();
    const { notifyError } = useError();

    const onSubmit = contest =>
        updateContest({
            variables: { contest },
            onCompleted: () => {
                closeDialog();
                notifySuccess("Success! Round settings have been saved.");
            },
            onError: () => notifyError("Oops, something went wrong!")
        });

    const fields = {
        "Contests::EliminationContest": eventDivisionLevelFields,
        "Contests::RepetitionContest": roundRobinLevelFields,
        "Contests::SplitContest": normalRoundFields
    }[contest.type](contest.parent?.config.produces);

    const initialValues = {
        "Contests::EliminationContest": inputForEventDivision,
        "Contests::RepetitionContest": inputForRoundRobin,
        "Contests::SplitContest": inputForRound
    }[contest.type](stripTypename(contest));

    return (
        <>
            <DialogTitle display="flex" alignItems="center" justifyContent="space-between" id="edit-contest-dialog-title">
                <Typography variant="h6" component="p">
                    {dialogTitle
                        ? <T>{dialogTitle}</T>
                        : <T name={contest.name}>contest_settings</T>
                    }
                </Typography>
                <IconButton onClick={closeDialog} color="inherit">
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogFormKit
                fields={fields}
                onSubmit={onSubmit}
                initialValues={initialValues}
                buttonLabel="Save"
                fullWidthSubmitButton
            />
        </>
    );
};

function stripTypename(response) {
    if (Array.isArray(response)) {
        return response.map(stripTypename);
    }
    if (typeof response === "object" && response !== null) {
        return Object.fromEntries(
            Object.entries(response)
                .filter(([key]) => key !== "__typename")
                .map(([key, value]) => [key, stripTypename(value)]),
        );
    }
    return response;
}
