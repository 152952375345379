import classNames from "classnames";
import { SkeletonTable } from "components/layout/skeleton/SkeletonTable";
import { MaxWidthPageWithHeading } from "components/layout/page/MaxWidthPageWithHeading";
import { Fake } from "components/layout/skeleton/Fake";
import { Typography } from "@mui/material";
import { useAthletesTeamsPageStyles } from "components/layout/organisation/athletes/useAthletesTeamsPageStyles";
import { makeStyles } from "components/providers/makeStyles";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
    page: {
        table: {
            paddingTop: theme.spacing(3)
        }
    },
    search: {
        height: theme.spacing(5)
    }
}));

export const AthletesTeamsPageSkeleton = () => {
    const classes = useAthletesTeamsPageStyles();
    const overrideClasses = useStyles();

    return (
        <div role="status" aria-label="loading">
            <MaxWidthPageWithHeading className={`skeleton ${classes.page} ${overrideClasses.page}`} title="Athletes & teams">
                <Typography variant="h7"><Fake size={30}/></Typography>
                <Box display="flex" marginTop={2}>
                    <Fake className={classNames(overrideClasses.search, classes.search)} size={30}/>
                    <Fake size={30}/>
                </Box>
                <SkeletonTable />
            </MaxWidthPageWithHeading>
        </div>
    );
};
