import { Heading } from "../../layout/page/Heading";
import { SkeletonTable } from "components/layout/skeleton/SkeletonTable";
import { PaddedPage } from "components/layout/page/PaddedPage";

export const PaymentsPageSkeleton = () => {
    return (
        <div className="skeleton">
            <Heading title="Payments"/>
            <PaddedPage>
                <SkeletonTable />
            </PaddedPage>
        </div>
    );
};
