import Immutable from "immutable";
import { useSelector } from "react-redux";

export const getAllEntries = (entries, event_division_id) => {
    const entriesGroup = entries.get(event_division_id);
    const isGroup = entriesGroup?.get("group")?.size > 0;
    const allEntries = entriesGroup && (isGroup ? entriesGroup.get("group") : Immutable.fromJS([event_division_id])).map(id => entries.get(id));
    return { allEntries, isGroup };
};

export const useAllEntries = eventDivisionId => useSelector(({ entries }) => getAllEntries(entries, eventDivisionId.toString()));
