import { makeStyles } from "components/providers/makeStyles";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { EventStatusPill } from "./EventStatusPill";
import { useIsMobile } from "../../../hooks/useIsMobile";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(2, 0),
        borderTop: `1px solid ${theme.palette.border.light}`,
    },
    name: {
        marginRight: theme.spacing(2),
    },
    namePill: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    pill: {
        display: "inline-block",
    },
    mobilePill: {
        "&&": {
            display: "inline-block",
            marginTop: theme.spacing(1)
        }
    }
}));
export const EventLinkRow = ({ link, name, date, status }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
        <Link to={link} className={classes.wrapper}>
            <div>
                <div className={classes.namePill}>
                    <Typography variant="button" className={classes.name}>
                        {name}
                    </Typography>
                    {!isMobile && <EventStatusPill className={classes.pill} status={status}/>}
                </div>
                <Typography color="textSecondary">{date}</Typography>
                {isMobile && <EventStatusPill className={classes.mobilePill} status={status}/>}
            </div>
            <ChevronRight/>
        </Link>
    );
};
