import { useCallback, useRef, useState } from "react";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: theme.spacing(2),
    }
}));

const noop = () => null;

export const SettingsOption = ({ change, untouch, field, value, defaultEditMode, onEdit = noop, onView = noop, onBoth }) => {
    const [isEditMode, setIsEditMode] = useState(defaultEditMode);
    const initialValue = useRef(value);
    const classes = useStyles();

    const startEditing = useCallback(() => setIsEditMode(true), []);

    const deleteField = useCallback(() => {
        exitEditMode();
        change(field, null);
        initialValue.current = null;
    }, []);

    const revertChanges = useCallback(() => {
        change(field, initialValue.current);
        untouch(field);
    }, []);

    const exitEditMode = useCallback(() => setIsEditMode(false), []);

    const props = { isEditMode, exitEditMode, startEditing, deleteField, revertChanges };

    return (
        <div className={classes.container}>
            {isEditMode ? onEdit(props) : onView(props)}
            {onBoth && onBoth(props)}
        </div>
    );
};
