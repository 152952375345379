import { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import classnames from "classnames";
import { Field } from "redux-form/immutable";
import { Button, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { Add, Check, Delete } from "@mui/icons-material";
import { uuid } from "../../utils/uuid";
import { Link } from "react-router-dom";

import { T } from "../util/t";
import {
    creatableSelect,
    FormField,
    isValidRequired,
    mustBeDecimal,
    oneItemRequired,
    plainInput,
    radioInput,
    required,
    selectInput
} from "./index";
import { validateNumberOption } from "./options";
import { valueToInt } from "./parsers";
import { priceFormatter, priceParser } from "../organisations/payments/fields/utils";
import { EmptyState } from "components/layout/EmptyState";
import { Payments } from "styleguide/icons/icons";
import { makeStyles } from "components/providers/makeStyles";
import { ViewPaymentsButton } from "components/payments/ViewPaymentsButton";
import { useCurrentUser } from "components/providers/CurrentUserProvider";

export const CheckboxWithChildren = ({ id, children, label, defaultChecked = false, onChange, wider, className }) => {
    const [checked, setChecked] = useState(defaultChecked);
    const toggleCheckbox = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    return <>
        <FormField className={className} type="checkbox">
            <div className="checkbox-input">
                <input id={id} type="checkbox" checked={checked} onChange={toggleCheckbox} />
                <label className="checkbox" htmlFor={id}><T>{label}</T></label>
                {checked && <Check />}
            </div>
            {checked && <div className={classnames("checkbox-children", { wider })}>{children}</div>}
        </FormField>
    </>;
};

CheckboxWithChildren.propTypes = {
    children: PropTypes.any.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    wider: PropTypes.bool,
    className: PropTypes.string
};

const RuleHeading = ({ heading, shouldRenderDelete, onDelete }) => (
    <div className="rule-heading-container">
        <Typography variant="label1" className="rule-heading">{heading}</Typography>
        {shouldRenderDelete && (
            <IconButton className="delete-row" size="small" onClick={onDelete}>
                <Delete/>
            </IconButton>
        )}
    </div>
);

RuleHeading.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    shouldRenderDelete: PropTypes.bool,
    onDelete: PropTypes.func
};

const ExtraItemRow = ({ array, extra, index, fieldPath, heading, currency, validate }) => {
    const options = useRef([]);
    const fieldName = `${fieldPath}[${index}]`;
    const parsePrice = priceParser(currency);
    const formatPrice = priceFormatter(currency);

    useEffect(() => {
        options.current = Immutable.List(extra.get("sizes")).map(value => ({ value, label: value })).toJS() || [];
    }, [fieldPath]);

    return (
        <>
            <RuleHeading
                heading={<T x={index + 1}>{heading}</T>}
                shouldRenderDelete={index > 0}
                onDelete={() => array.remove(fieldPath, index)}/>

            <div className="rule field-group-extended">
                <Field
                    name={`${fieldName}.name`}
                    component={plainInput}
                    validate={required}
                    label={<T>Description (e.g. T-shirt):</T>}/>
                <Field
                    name={`${fieldName}.sizes`}
                    label={<T>Options (e.g. S, M, L):</T>}
                    validate={validate}
                    component={creatableSelect}
                    selectOptions={{
                        isMulti: true,
                        options: options.current,
                        formatCreateLabel: value => <T value={value}>Add_new_option</T>,
                        noOptionsMessage: () => <T>No options</T>,
                        isValidNewOption: isValidRequired,
                    }}/>
                {currency && (
                    <Field
                        name={`${fieldName}.price`}
                        component={plainInput}
                        type="number" pattern="[0-9]*" step="any"
                        validate={mustBeDecimal}
                        label={<T>Price:</T>}
                        format={formatPrice}
                        parse={parsePrice}
                        startAdornment={currency}
                        normalize={valueToInt}
                    />
                )}
            </div>
        </>
    );
};

ExtraItemRow.propTypes = {
    array: PropTypes.object.isRequired,
    extra: PropTypes.instanceOf(Immutable.Map).isRequired,
    index: PropTypes.number.isRequired,
    fieldPath: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    validate: PropTypes.func,
    currency: PropTypes.string
};

const ExtrasSetup = ({ field, label, heading, array, currentExtras, currency }) => {
    const fieldPath = `payment_options.${field}`,
        isPaidExtras = field === "extras";

    return (
        <CheckboxWithChildren
            id={field}
            label={label}
            onChange={checked => checked && !currentExtras.size
                ? array.push(fieldPath, Immutable.Map({ uuid: isPaidExtras ? uuid() : undefined }))
                : array.removeAll(fieldPath)}
            defaultChecked={currentExtras.size > 0}
            wider={isPaidExtras}
        >
            {currentExtras.map((extra, index) => (
                <ExtraItemRow
                    key={index}
                    array={array}
                    extra={extra}
                    index={index}
                    fieldPath={fieldPath}
                    heading={heading}
                    currency={currency}
                    validate={isPaidExtras ? undefined : oneItemRequired}
                />
            ))}
            <Button onClick={() => array.push(fieldPath, Immutable.Map({ uuid: isPaidExtras ? uuid() : undefined }))} startIcon={<Add/>}>
                <T>Add another</T>
            </Button>
        </CheckboxWithChildren>
    );
};

ExtrasSetup.propTypes = {
    array: PropTypes.object.isRequired,
    currentExtras: PropTypes.instanceOf(Immutable.List).isRequired,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    currency: PropTypes.string
};

const MultiAthleteDiscounts = ({ familyTotal, array, change, currency }) => {
    const fieldName = "family_total";
    const field = `payment_options.${fieldName}`;
    const parsePrice = priceParser(currency);
    const formatPrice = priceFormatter(currency);

    const getLabel = (value, index) => {
        const nextAthletes = familyTotal.get(index + 1, Immutable.Map()).get("athletes") || 0;
        return index === familyTotal.size - 1
            ? `${value}+`
            : nextAthletes - value > 1
                ? `${value}-${nextAthletes - 1}`
                : value;
    };

    const getOptionFromIndex = index => {
        const prevAthletes = familyTotal.get(index - 1).get("athletes");
        return index === 0 ? 2 : prevAthletes + 1;
    };

    const getOptionData = (v, index) => {
        const value = parseInt(v);
        return ({
            label: getLabel(value, index),
            value
        });
    };

    const recalculateNextAthletes = index => (e, value) => {
        let prev = value;
        change(field, familyTotal.map((value, i) => {
            if (i > index && prev >= value.get("athletes")) return value.set("athletes", prev = prev + 1);
            return value;
        }));
    };

    return (
        <CheckboxWithChildren
            id={fieldName}
            label="Set discounted total price for multiple athletes (family discount)"
            onChange={checked => checked && !familyTotal.size
                ? array.push(field, Immutable.Map({ athletes: 2 }))
                : array.removeAll(field)}
            defaultChecked={familyTotal.size > 0}>
            {familyTotal.map((f, index) => {
                const optionFromIndex = getOptionFromIndex(index);
                return (
                    <Fragment key={index}>
                        <RuleHeading
                            heading={<T x={index + 1}>family_discount_heading</T>}
                            shouldRenderDelete={index > 0}
                            onDelete={() => array.remove(field, index)}/>

                        <div className="rule field-group-extended">
                            <Field
                                name={`${field}[${index}].athletes`}
                                component={creatableSelect}
                                label={<T>Number of athletes:</T>}
                                normalize={valueToInt}
                                validate={required}
                                onChange={recalculateNextAthletes(index)}
                                selectOptions={{
                                    isClearable: false,
                                    isValidNewOption: validateNumberOption,
                                    getNewOptionData: value => ({ ...getOptionData(value, index), __isNew__: true }),
                                    formatInitialLabel: value => getLabel(value, index),
                                    options: Immutable.Range(optionFromIndex, Math.max(optionFromIndex, 6) + 1).map(value => getOptionData(value, index)).toJS()
                                }} />
                            <Field
                                name={`${field}[${index}].price`}
                                component={plainInput}
                                type="number" pattern="[0-9]*" step="any"
                                validate={mustBeDecimal}
                                label={<T>Total price:</T>}
                                format={formatPrice}
                                parse={parsePrice}
                                startAdornment={currency}
                                normalize={valueToInt}
                            />
                        </div>
                    </Fragment>
                );
            })}

            <Button onClick={() => array.push(field, Immutable.Map({ athletes: familyTotal.map(f => f.get("athletes")).max() + 1 }))} startIcon={<Add/>}>
                <T>Add another</T>
            </Button>
        </CheckboxWithChildren>
    );
};

MultiAthleteDiscounts.propTypes = {
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    familyTotal: PropTypes.instanceOf(Immutable.List).isRequired,
    currency: PropTypes.string.isRequired
};

const CustomDivisionsPricing = ({ field, array, customDivisions, possibleDivisions, currency }) => {
    const parsePrice = priceParser(currency);
    const formatPrice = priceFormatter(currency);

    const getPossibleDivisionOptions = (index) => {
        const setDivisions = customDivisions
            .delete(index)
            .map(d => d.get("division_id"));

        return possibleDivisions
            .filter(ed => ed.get("division_id") && !setDivisions.contains(ed.get("division_id")))
            .map(ed => ({
                value: ed.get("division_id"),
                label: ed.get("division_name")
            }));
    };

    const validDivisions = customDivisions.filter(d => possibleDivisions.map(pd => pd.get("division_id")).contains(d.get("division_id")));

    return (
        <div className="custom-pricing formField">
            {validDivisions.map((d, index) => {
                const fieldIndex = customDivisions.findIndex(cd => cd.get("division_id") === d.get("division_id"));

                return (
                    <div key={index}>
                        <RuleHeading heading={<T x={index + 1}>price_x</T>}/>

                        <div className="rule field-group-extended">
                            <Field
                                name={`${field}[${fieldIndex}].division_id`}
                                label={<T>Division:</T>}
                                component={selectInput}
                                parse={valueToInt}
                                selectOptions={getPossibleDivisionOptions(fieldIndex)}
                            />

                            <Field
                                name={`${field}[${fieldIndex}].price`}
                                label={<T>Price:</T>}
                                component={plainInput}
                                type="number" pattern="[0-9]*" step="any"
                                validate={mustBeDecimal}
                                format={formatPrice}
                                parse={parsePrice}
                                startAdornment={currency}
                                normalize={valueToInt}
                            />

                            <IconButton size="small" className="delete-row" onClick={() => array.remove(field, index)}>
                                <Delete/>
                            </IconButton>
                        </div>
                    </div>
                );
            })}

            {validDivisions.size < possibleDivisions.filter(pd => pd.get("division_id")).size &&
            <Button className={classnames({ "first-add-price-rule": validDivisions.size === 0 })} onClick={() => array.push(field, Immutable.Map({ division_id: getPossibleDivisionOptions(customDivisions.size).first().value }))} startIcon={<Add/>}>
                <T>{validDivisions.size === 0 ? "Set custom pricing by division" : "Add division price"}</T>
            </Button>}
        </div>
    );
};

const usePaymentStyles = makeStyles(theme => ({
    viewPaymentsButtonContainer: {
        position: "relative",
        zIndex: 1,
    },
    viewPaymentsButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            margin: 0,
        }
    },
    defaultDivisionPriceContainer: {
        marginBottom: theme.spacing(4),
    },
}));

CustomDivisionsPricing.propTypes = {
    array: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    customDivisions: PropTypes.instanceOf(Immutable.List).isRequired,
    possibleDivisions: PropTypes.instanceOf(Immutable.List).isRequired,
    currency: PropTypes.string.isRequired
};

export const PaymentSetupForm = ({ organisation, divisions, formState, change, array, event }) => {
    const paymentOptions = formState.get("payment_options"),
        currency = organisation.get("currency"),
        customDivisionsPricing = (paymentOptions || Immutable.Map()).get("divisions") || Immutable.List();

    const parsePrice = priceParser(currency);
    const formatPrice = priceFormatter(currency);
    const waitlisted = formState.getIn(["registration_options", "waitlisted"]);

    const addPayment = () => change("payment_options", Immutable.Map({ currency }));
    const removePayment = () => change("payment_options", null);

    const classes = usePaymentStyles();
    const { currentUser } = useCurrentUser();

    const PaymentToggle = ({ onChange }) =>
        (
            <Stack flexDirection={{ md: "row" }} justifyContent={"space-between"}>
                <Stack flexDirection="column" position="relative">
                    <Field
                        name={"payments_enabled"}
                        label={"Registration fees apply"}
                        component={radioInput}
                        yesNo
                        onChange={onChange}
                        parse={value => value === "true"}
                        format={_ => paymentOptions ? "true" : "false"}
                        radioOptions={[
                            { label: <T>Yes</T>, value: "true" },
                            { label: <T>No</T>, value: "false" }
                        ]}
                    />
                    {!paymentOptions && <Typography variant="body1" position="absolute" top="80px" width="max-content" color="textSecondary"><T>Set prices, discounts, and paid extras.</T></Typography>}
                </Stack>
                {paymentOptions && currentUser.organisation?.stripe_account_type === "standard" && <div className={classes.viewPaymentsButtonContainer}>
                    <ViewPaymentsButton eventId={event.get("id")} className={classes.viewPaymentsButton} />
                </div>}
            </Stack>
        );

    const allDivisionsCustomPrice = customDivisionsPricing.size === divisions?.size;

    return <>
        {waitlisted &&
            <>
                <Typography>
                    <T>waitlist_payments_notification</T>
                </Typography>
                <br/>
            </>
        }

        {organisation.get("payments_enabled") &&
            <PaymentToggle onChange={paymentOptions ? removePayment : addPayment} />
        }

        {!paymentOptions ?
            (!organisation.get("payments_enabled") ?
                <EmptyState
                        icon={<SvgIcon component={Payments}/>}
                        title="Want to accept payments?"
                        text="Connect Stripe to activate online payments."
                        action={
                            <Button component={Link} to="/director/powerups/payments" variant="contained" size="small">
                                <T>Go to payments settings</T>
                            </Button>
                        }/> :
                null)
            :
            <>
                <br/>
                <div className={classes.defaultDivisionPriceContainer} >
                    <Field name="payment_options.default_division_price"
                           component={plainInput}
                           type="number" pattern="[0-9]*" step="any"
                           validate={allDivisionsCustomPrice ? undefined : mustBeDecimal}
                           format={formatPrice}
                           parse={parsePrice}
                           label={<T>Price per division:</T>}
                           startAdornment={currency}
                           normalize={valueToInt}
                           disabled={allDivisionsCustomPrice}/>
                    {allDivisionsCustomPrice &&
                    <Typography variant="caption" marginTop={-4} position={"absolute"}>
                        <T>Not applied as all divisions have a custom price.</T>
                    </Typography>}
                </div>
                <CustomDivisionsPricing
                    field="payment_options.divisions"
                    array={array}
                    customDivisions={customDivisionsPricing}
                    possibleDivisions={divisions.filter(ed => ed)}
                    currency={currency}
                />

                <CheckboxWithChildren
                    id="discounted-price-additional-discount"
                    label="Set discounted price for additional divisions"
                    onChange={checked => !checked && change("payment_options.extra_division_price", null)}
                    defaultChecked={formState.getIn(["payment_options", "extra_division_price"]) != null}>
                    <Field
                        name="payment_options.extra_division_price"
                        component={plainInput}
                        type="number" pattern="[0-9]*" step="any"
                        validate={mustBeDecimal}
                        format={formatPrice}
                        parse={parsePrice}
                        label={<T>Price per additional division:</T>}
                        startAdornment={currency}
                        normalize={valueToInt}/>
                </CheckboxWithChildren>

                <MultiAthleteDiscounts
                    familyTotal={(paymentOptions || Immutable.Map()).get("family_total", Immutable.List())}
                    array={array}
                    change={change}
                    currency={currency}/>

                <ExtrasSetup
                    label="Add paid extras to registration"
                    field="extras"
                    array={array}
                    currentExtras={(paymentOptions || Immutable.Map()).get("extras", Immutable.List())}
                    heading="paid_extra_count"
                    currency={currency}/>
            </>
        }
    </>;
};
