import { useState } from "react";
import { useTranslate } from "../../../providers/LocalizationProvider";
import { SpreadsheetImporter } from "../../../importer/SpreadsheetImporter";
import { getEventImportFields } from "../../../importer/fields/eventImportFields";
import { CloudUpload } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { uploadResults } from "../../../../actions/eventDirector";
import { useDispatch } from "react-redux";
import { useDialogState } from "../../../../hooks/useDialogState";
import { useEventObject } from "../../../routes/events/id/EventProvider";
import { T } from "../../../util/t";
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { eventResultDivisionsAndEntriesFromData, stripName } from "./eventResultDivisionsAndEntriesFromData";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../../graphql/organisation";

const useRowHook = ( eventDivisions ) => {
    const nameMessage = useTranslate("Athlete names are required");
    const eventDivisionMessage = useTranslate("Divisions do not exist in the event");

    return (data, addError) => {
        if (!data.name && !data.firstName && !data.lastName) {
            addError("name", { nameMessage, level: "error" });
        }

        const matchedDivision = eventDivisions.find(eventDivision => eventDivision.division.name.trim().toLowerCase() === data.division.trim().toLowerCase());
        if (!matchedDivision) {
            addError("division", { eventDivisionMessage, level: "error" });
        }

        return { ...data,
            name: stripName(data.name) || [stripName(data.firstName), stripName(data.lastName)].filter(w => w).join(" "),
            eventDivisionId: matchedDivision?.id
        };
    };
};

export const ImportResultsButton = ({ className, eventId }) => {
    const dispatch = useDispatch();
    const event = useEventObject();
    const organisationId = event?.organisation?.id;
    const rowHook = useRowHook(event?.eventDivisions);
    const [isOpen, setIsOpen] = useState(false);
    const [entries, setEntries] = useState([]);
    const [eventDivisions, setEventDivisions] = useState([]);
    const [invalidEventDivisions, setInvalidEventDivisions] = useState([]);
    const [dialogOpen, openDialog, closeDialog] = useDialogState({ disableBackdropClick: true });

    const { data } = useQuery(GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, { variables: { id: organisationId } }),
        organisation = data?.organisation;

    const onSetData = data => {
        const { eventDivisions, entries, invalidEventDivisions } = eventResultDivisionsAndEntriesFromData(data, organisation);
        setEventDivisions(eventDivisions);
        setEntries(entries);
        setInvalidEventDivisions(invalidEventDivisions);
        openDialog();
    };

    const onUpload = () =>
        dispatch(uploadResults(eventId, entries))
            .then(closeDialog)
            .catch(closeDialog);

    if (!organisation) return null;

    return (
        <>
            <MenuItem onClick={() => setIsOpen(true)}>
                <T>Upload results</T>
                &nbsp;&nbsp;
                <CloudUpload fontSize="small"/>
            </MenuItem>

            <SpreadsheetImporter
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={onSetData}
                fields={getEventImportFields(organisation, true)}
                rowHook={rowHook}
                isNavigationEnabled
            />

            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeDialog}
                action={onUpload}
                title="Upload event results?"
                content={<T divisions={eventDivisions?.length} invalidEventDivisions={invalidEventDivisions?.length}>ready_to_upload_result</T>}
                buttonTitle="Upload results"
            />
        </>
    );
};

