import Immutable from "immutable";
import createStore from "./createStore";

export const reducer = createStore({
    SET_ORGANISATIONS: (state, action) =>
        state.set("list", Immutable.fromJS(action.data)),

    SET_CURRENT_USER_ORGANISATION: (state, action) =>
        state.set("current", Immutable.fromJS(action.data)),

    SET_ORGANISATION_EVENTS: (state, action) =>
        state.setIn(["events", action.organisation_id], Immutable.fromJS(action.data)),

    SET_ORGANISATION_SERIES: (state, action) =>
        state.setIn(["series", action.organisation_id], Immutable.fromJS(action.data)),

    SET_ORGANISATION_PARENT_SERIES: (state, action) =>
        state.setIn(["parent_series", action.organisation_id, action.event_division_id], Immutable.fromJS(action.data)),

    SET_ORGANISATION_ACCOUNT: (state, action) =>
        state.setIn(["accounts", action.organisation_id], Immutable.fromJS(action.data)),
});
