import { Field } from "redux-form/immutable";
import { Add } from "@mui/icons-material";

import { T } from "../../../util/t";
import { valueToInt } from "../../../forms/parsers";
import { mustBeGreaterThanZero, plainInput } from "../../../forms";
import { SettingsOption } from "components/layout/registrationSettings/SettingsOption";
import { SettingsActions } from "components/layout/registrationSettings/SettingsActions";
import { SettingsItem } from "components/layout/registrationSettings/SettingsItem";
import { SettingsButton } from "components/layout/registrationSettings/SettingsButton";

export const MaxDivisionsPerAthlete = ({ prefix, options, change, untouch, disabled }) => {
    const name = "division_limit";
    const value = options.get(name);
    const field = `${prefix}.${name}`;

    return (
        <SettingsOption
            change={change}
            field={field}
            value={value}
            untouch={untouch}
            onEdit={({ revertChanges, exitEditMode }) => <>
                <Field
                    name={field}
                    component={plainInput}
                    type="number" pattern="[0-9]*" step="any"
                    validate={mustBeGreaterThanZero}
                    parse={valueToInt}
                    label={<T>Maximum divisions per athlete</T>}
                />
                <SettingsActions revertChanges={revertChanges} exitEditMode={exitEditMode}/>
            </>}
            onView={({ startEditing, deleteField }) => (
                <SettingsItem disabled={disabled} onEdit={value && startEditing} onDelete={value && deleteField}>
                    {value
                        ? <><T>Maximum divisions per athlete</T> : {value}</>
                        : <SettingsButton disabled={disabled} variant="outlined" onClick={startEditing} startIcon={<Add/>}><T>Limit number of divisions per athlete</T></SettingsButton>}
                </SettingsItem>
            )}
        />
    );
};
