import Immutable from "immutable";
import { T } from "components/util/t";
import { Field } from "redux-form/lib/immutable";
import { checkboxInput, mustBeDecimal, plainInput } from "components/forms";

export const RankingsLeaderboardsForm = ({ formState, event }) => {
    const config = formState.get("config") || Immutable.Map();

    const setDefaultPointsPerPlace = value => value
        ? config.setIn(["team_leaderboard", "points_per_place"], event.getIn(["organisation", "sport_type"]) === "sls" ? [6, 5, 4, 3, 2, 1] : [3, 2, 1])
        : config.delete("team_leaderboard");

    const getDefaultPointsPerPlace = value => !!value?.getIn(["team_leaderboard", "points_per_place"]);

    return (
        <>
            <Field
                name="points_multiplier"
                type="number"
                component={plainInput}
                label={<T>Ranking points multiplier:</T>}
                validate={mustBeDecimal}
            />
            <Field
                name="config"
                component={checkboxInput}
                helpText="club_leaderboard_help_text"
                label={<T>Event uses a club leaderboard</T>}
                parse={setDefaultPointsPerPlace}
                format={getDefaultPointsPerPlace}
            />
        </>
    );
};
