import { SectionWrapper } from "../../../layout/page/PageSection";
import { Fake } from "../../../layout/skeleton/Fake";
import { MaxWidthPageWithHeading } from "../../../layout/page/MaxWidthPageWithHeading";
import { Box, Typography } from "@mui/material";

export const PaymentsPageSkeleton = () =>
    <div className="skeleton">
        <MaxWidthPageWithHeading className="skeleton" title="Payments">
            <Typography variant="subtitle1"><Fake size={45} /></Typography>
            <p><Fake size={140} /></p>
            <SectionWrapper title={<Fake size={57}/>}>
                <FakeIntegrationRowDetail size={137} />
                <FakeIntegrationRowDetail size={128} />
                <FakeIntegrationRowDetail size={110} />
            </SectionWrapper>
            <SectionWrapper title={<Fake size={70}/>}>
                <FakeIntegrationRowDetail size={112} />
                <FakeIntegrationRowDetail size={169} />
                <FakeIntegrationRowDetail size={124} />
            </SectionWrapper>
            <SectionWrapper title={<Fake size={57}/>}>
                <Typography variant="subtitle3"><Fake size={35} /></Typography>
                <FakeIntegrationRowDetail size={112} />
                <FakeIntegrationRowDetail size={149} />
                <Typography variant="subtitle3"><Fake size={53} /></Typography>
                <FakeIntegrationRowDetail size={151} />
                <FakeIntegrationRowDetail size={163} />
            </SectionWrapper>

        </MaxWidthPageWithHeading>
    </div>;

const FakeIntegrationRowDetail = ({ size }) =>
    <Box ml={2}><Fake size={5} />&nbsp;<Fake size={size} /></Box>;
