import { useIsEmbedded } from "./useIsEmbedded";

export const useEnableThirdPartyCookies = (onAccessGranted, onAccessDenied) => {
    const isEmbedded = useIsEmbedded();

    const enableThirdPartyCookies = !isEmbedded || !document.requestStorageAccess
        ? onAccessGranted
        : props => document.requestStorageAccess().then(() => onAccessGranted(props)).catch(() => onAccessDenied(props));

    return enableThirdPartyCookies;
};
