import { makeStyles } from "components/providers/makeStyles";

export const useHeatRowStyles = makeStyles(theme => ({
    row: {
        cursor: "pointer",
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.border.main}`,
        [theme.breakpoints.down("sm")]: {
            alignItems: "flex-start"
        }
    },
    heat: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        rowGap: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        minWidth: 0,
        "&.isFinished": {
            textDecoration: "line-through"
        }
    },
    division: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    time: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(6),
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1)
        }
    },
    duration: {
        whiteSpace: "nowrap",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            marginLeft: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1)
        }
    },
    livePill: {
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0.25),
        },
    }
}));
