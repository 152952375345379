import { Typography, Box } from "@mui/material";
import { T } from "components/util/t";
import { HeadingNav } from "../../../../../layout/navigation/HeadingNav";
import { Link, useParams } from "react-router-dom";
import { useEmailAthletes } from "../EmailAthletesProvider";
import { Notification } from "components/layout/notifications/Notification";
import { ChevronRight, Lock } from "@mui/icons-material";

const EmailAthletesHeader = () => {
    const { id } = useParams();
    const { isOnTrial, sentEmails } = useEmailAthletes();

    const sentNavLabel = sentEmails ? <T value={sentEmails.length}>email_athletes_sent_heading</T> : "Sent";

    const headingNavLinks = [
        { label: "New message", to: `/events/${id}/email-athletes/new` },
        { label: sentNavLabel, to: `/events/${id}/email-athletes/sent`, disabled: !sentEmails?.length }
    ];

    return (
        <div>
            <Typography variant="h3" component="h1" mt={1} mb={4}>
                <T>Email athletes</T>
            </Typography>

            {isOnTrial &&
            <Box marginBottom={4}>
                <Notification type="warning" text="email_athletes_on_trial_notification"
                              buttonProps={{
                                  label: "Get event credits",
                                  to: "/director/purchase",
                                  component: Link,
                                  target: "_blank",
                                  endIcon: <ChevronRight />
                              }}
                              Icon={Lock}
                />
            </Box>}

            <HeadingNav links={headingNavLinks}/>
        </div>
    );
};

export default EmailAthletesHeader;
