import { T } from "../../util/t";
import { EventDetailSectionWrapper } from "./EventCard";

export const EventSeries = ({ series }) => {
    if (!series || series.length === 0) return null;

    return (
        <EventDetailSectionWrapper>
            {series.length === 1 ?
                <T series={series[0].name}>one_series</T> :
                <T series={series[0].name} count={series.length - 1}>more_series</T>
            }
        </EventDetailSectionWrapper>
    );
};
