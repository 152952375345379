import Immutable from "immutable";
import dayjs from "dayjs";

export const mapFamilyTotalToForm = family_total =>
    family_total && family_total.entrySeq().reduce((previousValue, [athletes, price]) => {
        return previousValue.push(Immutable.Map({ athletes: parseInt(athletes), price }));
    }, Immutable.List());

const mapFamilyTotalFromForm = family_total => {
    if (family_total && family_total.size < 1) return null;
    return family_total && family_total.reduce((previousValue, currentValue) => {
        return previousValue.set(currentValue.get("athletes"), currentValue.get("price"));
    }, Immutable.Map());
};

export const mapDivisionsToForm = divisions => {
    return divisions && divisions
        .reduce((previousValue, currentValue) => {
            if (currentValue.get("min_by") === currentValue.get("max_by")) {
                return previousValue.push(currentValue.set("by", currentValue.get("min_by")).delete("min_by").delete("max_by"));
            }
            if (currentValue.get("min_by") && currentValue.get("max_by")) {
                return previousValue
                    .push(currentValue.set("by", currentValue.get("min_by")).delete("min_by").delete("max_age").delete("max_by"))
                    .push(currentValue.set("by", currentValue.get("max_by")).delete("max_by").delete("min_age").delete("min_by"));
            }
            if (currentValue.get("min_by")) return previousValue.push(currentValue.set("by", currentValue.get("min_by")).delete("min_by"));
            if (currentValue.get("max_by")) return previousValue.push(currentValue.set("by", currentValue.get("max_by")).delete("max_by"));
            return undefined;
        }, Immutable.List());
};

const mapDivisionsFromForm = divisions => {
    return divisions && divisions.reduce((acc, division) => {
        if (division.get("min_age") == null && division.get("max_age") == null) return acc;
        if (division.get("min_age")) division = division.set("min_by", division.get("by"));
        if (division.get("max_age")) division = division.set("max_by", division.get("by"));
        division = division.delete("by");
        const index = acc.findIndex(d => d && d.get("division_id") === division.get("division_id"));
        if (index > -1) return acc.update(index, v => v.mergeWith((prev, next) => {
            if (!next) return prev;
            return next;
        }, division));
        return acc.push(division);
    }, Immutable.List());
};

export const eventPropertiesMapper = (values, originalDate) =>
    values.merge(
        Immutable.fromJS({
            date: dayjs(values.get("start_date") + (originalDate ? "T" + dayjs(originalDate).format("HH:mm:ssZ") : " 08:00:00")).toJSON(),
            days_window: dayjs(values.get("end_date")).diff(dayjs(values.get("start_date")), "day") + 1
        }))
        .delete("start_date")
        .delete("end_date")
        .update("payment_options", paymentOptions =>
            paymentOptions && paymentOptions.update("family_total", mapFamilyTotalFromForm))
        .update("registration_options", registrationOptions =>
            registrationOptions && registrationOptions.update("divisions", mapDivisionsFromForm))
        .toJS();
