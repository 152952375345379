import { useDialogState } from "../../../hooks/useDialogState";
import { Button, DialogTitle } from "@mui/material";
import { Restore } from "@mui/icons-material";
import { T } from "../../util/t";
import { ResponsiveDialog } from "../../modal";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { REFUND_PAYMENT } from "../../../graphql/payment";
import { DialogFormKit } from "../../forms/FormKit";
import { netRefunds, priceFormatter, priceParser } from "../../organisations/payments/fields/utils";
import { useTranslate } from "../../providers/LocalizationProvider";
import { useError, useSuccess } from "../../../hooks/useNotifications";

export const RefundDialog = ({ payment }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState({ disableBackdropClick: true });
    const net = netRefunds(payment);

    return(
        <>
            <Button variant="outlined"
                    onClick={openDialog}
                    startIcon={<Restore/>}
                    disabled={net === 0}>
                <T>Refund</T>
            </Button>

            <ResponsiveDialog className="lh-dialog"
                              disableEscapeKeyDown
                              fullWidth={true}
                              maxWidth="sm"
                              open={dialogOpen}
                              aria-labelledby="refund-payment-dialog-title"
                              onClose={closeDialog}>
                <RefundDialogContent payment={payment} closeDialog={closeDialog} />
            </ResponsiveDialog>
        </>
    );
};

export const RefundDialogContent = ({ payment, closeDialog }) => {
    const parse = priceParser(payment.currency);
    const format = priceFormatter(payment.currency);
    const net = netRefunds(payment);
    const { notifySuccess } = useSuccess();
    const { notifyError } = useError();
    const validationMessage = useTranslate("refund_cannot_exceed", { netAmount: net, currency: payment.currency });

    const [refundPayment] = useMutation(REFUND_PAYMENT);

    const refund = useCallback(value => {
        return refundPayment({ variables: { id: payment.id , amount: value.amount } })
            .then((refundItem) => {
                closeDialog();
                refundItem.data.refundPayment.errors ?
                    notifyError("Ouch. We were unable to process the refund, please try again") :
                    notifySuccess("All done! The payment has been successfully refunded");
            }).catch(() => {
                closeDialog();
                notifyError("Ouch. We were unable to process the refund, please try again");
            });
    }, []);

    const validateRefundAmount = value => /\d/.test(value) && (value <= net)
        ? Promise.resolve()
        : Promise.reject({ message: validationMessage });

    return (
        <>
            <DialogTitle id="refund-payment-dialog-title">
                <T>Refund payment to</T> {payment.user.name}
            </DialogTitle>
            <DialogFormKit
                fields={[{
                    name: "amount",
                    label: "Amount",
                    validation: { validate: validateRefundAmount },
                    startAdornment: payment.currency,
                    inputProps: { pattern: "[0-9]*", type: "number", step: "any", autoFocus: true, inputMode: "decimal" },
                    parse: parse,
                    format: format
                }]}
                onSubmit={refund}
                onCancel={closeDialog}
                formCaption="Refunds exclude any transaction fees and take 5-10 days to appear on a customer's statement."
                buttonLabel="Refund"/>
        </>);
};
