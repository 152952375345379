import { makeStyles } from "components/providers/makeStyles";

export const useHeatLeaderboardHeaderStyles = makeStyles(theme => ({
    header: {
        zIndex: 2,
        position: "relative",
        padding: theme.spacing(0, 2)
    },
    heading: {
        margin: theme.spacing(1, 0),
    },
    title: {
        marginRight: theme.spacing(1)
    }
}));
