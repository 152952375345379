import { useCallback, useState } from "react";
import { HeatLeaderboardBreakdown } from "../heat/HeatLeaderboardBreakdown";
import { CompactLeaderboardHeader } from "./CompactLeaderboardHeader";
import { FullLeaderboardHeader } from "./FullLeaderboardHeader";
import { StartlistRunsOrResultSelector } from "../heat/StartlistRunsOrResultSelector";
import { HeatSummary } from "../heat/HeatSummary";
import { RidesTable } from "../heat/RidesTable";
import { HeatSummaryWithRides } from "../heat/HeatSummaryWithRides";

export const LeaderboardBreakdown = ({ leaderboard, closeModal }) => {
    const { hasStartlist, hideScores, runBased } = leaderboard.config;
    const showResults = !!leaderboard.startTime && !hideScores;
    const [selected, setSelected] = useState(hasStartlist ? showResults ? "result" : "startlist" : "result");
    const renderRidesTab = showResults && leaderboard.result.length > 8;

    const handleSetSelected = useCallback(selected => {
        setSelected(selected);
    }, [setSelected]);

    return (
        <HeatLeaderboardBreakdown
            heat={leaderboard}
            compactHeader={<CompactLeaderboardHeader leaderboard={leaderboard} sticky />}
            fullHeader={<FullLeaderboardHeader leaderboard={leaderboard} closeModal={closeModal}/>}
            closeModal={closeModal}
            noMarginRight={selected === "runsOrRides" || selected === "result"}
            selector={((hasStartlist && showResults) || renderRidesTab) && <StartlistRunsOrResultSelector hasStartlist={hasStartlist} selected={selected} setSelected={handleSetSelected} renderRidesTab={renderRidesTab} runBased={runBased}/>}
        >
            {selected === "startlist" && <HeatSummary heat={{ ...leaderboard, startTime: null }}/>}
            {selected === "result" && <HeatSummaryWithRides heat={leaderboard} renderRides={!renderRidesTab}/>}
            {selected === "runsOrRides" && <RidesTable heat={leaderboard} renderAthleteHeader/>}
        </HeatLeaderboardBreakdown>
    );
};
