import { T } from "../../util/t";
import { CompactHeatLeaderboardHeader } from "../heat/CompactHeatLeaderboardHeader";

export const CompactLeaderboardHeader = ({ sticky, leaderboard }) => (
    <CompactHeatLeaderboardHeader sticky={sticky}>
        {leaderboard.eventDivision.division.name}
        &nbsp;:&nbsp;
        <T>{leaderboard.round}</T>
    </CompactHeatLeaderboardHeader>
);
