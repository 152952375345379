import { makeStyles } from "../providers/makeStyles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import classNames from "classnames";

const useDivisionStyles = makeStyles((theme, { fullWidth, disabled }) => ({
    division: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.input.border}`,
        borderRadius: theme.spacing(1),
        width: fullWidth ? "100%" : "60%",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        "& .MuiIconButton-root": {
            color: theme.palette.text.primary
        },
        opacity: disabled && 0.5
    },
    divisionName: {
        padding: theme.spacing(1),
    }
}));
export const DivisionBox = ({ name, onRemove, fullWidth, disabled, className }) => {
    const classes = useDivisionStyles({ fullWidth, disabled });

    return (
        <div className={classNames(classes.division, className)}>
            <div className={classes.divisionName}>
                {name}
            </div>
            {onRemove &&
                <IconButton size="small" onClick={onRemove} disabled={disabled} aria-label={`Remove ${name}`}>
                    <Close/>
                </IconButton>}
        </div>
    );
};
