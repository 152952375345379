export const EventDivision = eventDivisionData => ({
    ...eventDivisionData,
    hasRankingsEnabled(event) {
        const rankingsSeries = event.rankingsSeries();
        const ownSeries = rankingsSeries.filter(series => series.organisationId === event.organisationId);
        const parentRankings = rankingsSeries.filter(series => series.organisationId !== event.organisationId);
        return ownSeries.length > 0 ||
            parentRankings.some(series => eventDivisionData.divisionToSeasons.find(divisionToSeason => divisionToSeason.seasonId === series.id));
    },
    isGroup() {
        return eventDivisionData.eventDivisions.length > 0;
    },
    isTeams() {
        return eventDivisionData.formatDefinition?.isTeams;
    }
});
