import { makeStyles } from "components/providers/makeStyles";
import { Button } from "@mui/material";
import classnames from "classnames";

const useButtonStyles = makeStyles((theme) => ({
    text: {
        alignItems: "flex-start",
        justifyContent: "end",
        "&, &:hover": {
            marginTop: theme.spacing(1),
            padding: 0,
            background: "none",
            color: theme.palette.text.link,
            textDecoration: "underline",
            minHeight: 0,
            marginBottom: theme.spacing(2),
        }
    }
}));

export const SettingsButton = ({ text, Component = Button, className, ...rest }) => {
    const classes = useButtonStyles();
    return (
        <Component
            className={classnames([
                classes.base,
                {
                    [classes.text]: !!text,
                },
                className,
            ])}
            {...rest}
        />
    );
};
