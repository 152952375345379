import { HeatLeaderboardStatus } from "./HeatLeaderboardStatus";

export const getHeatStatus = (heat) => {
    const isFinished = !!heat.endTime,
        isLive = !isFinished && !!heat.startTime,
        isUpcoming = !isFinished && !isLive;
    return { isFinished, isLive, isUpcoming };
};

export const HeatStatus = ({ heat, hideTimer }) => (
    <HeatLeaderboardStatus
        heat={heat}
        hideTimer={hideTimer}
        {...getHeatStatus(heat)}
    />
);
