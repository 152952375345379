import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import * as reducers from "../../reducers";

const store = createStore(
    combineReducers(reducers),
    applyMiddleware(thunk)
);

export const ReduxProvider = ({ children }) =>
    <Provider store={store}>
        {children}
    </Provider>;
