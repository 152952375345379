import { useParams } from "react-router-dom";
import { RedrawDialog } from "../../../../../../layout/eventDivision/RedrawDialog";
import { useEventDivision } from "../EventDivisionProvider";
import { PreviewDrawDialog } from "../../../../../../layout/eventDivision/previewDraw/PreviewDrawDialog";

export const EntriesHeaderActions = () => {
    const { id, event_division_id } = useParams();
    const eventDivision = useEventDivision();
    const showPreviewDraw = eventDivision.get("status") !== "drawn" &&
        !eventDivision.get("contest_id") &&
        !eventDivision.getIn(["format_definition", "run_based"]) &&
        !eventDivision.getIn(["format_definition", "round_based"]) &&
        !eventDivision.getIn(["format_definition", "seeds"])?.keySeq().size &&
        !eventDivision.getIn(["format_definition", "progression"])?.valueSeq().some(progression => progression?.size > 1);

    return showPreviewDraw
        ? <PreviewDrawDialog/>
        : <RedrawDialog eventId={id} eventDivisionId={event_division_id} drawn={eventDivision.get("status") === "drawn"}/>;
};
