import { useState } from "react";
import classnames from "classnames";
import { Close, Search } from "@mui/icons-material";
import { CircularProgress, IconButton, Link, Stack, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { useTranslate } from "components/providers/LocalizationProvider";

import { Input } from "../layout/forms/fields/Input";

const useStyles = makeStyles((theme, { inHeading }) => ({
    inputArea: {
        "& > div": {
            height: "100%",
            "& > label": {
                background: "none",
                padding: theme.spacing(1)
            },
            "& > input": {
                paddingLeft: 0,
            }
        },
        margin: inHeading && theme.spacing(2, "auto"),
        maxWidth: inHeading && theme.shape.pageMaxWidth,
    },
    closeIcon: {
        cursor: "pointer"
    }
}));

export const SearchForm = ({ inputRef, searchFormRef, name, search, onChange, loading, inHeading, className, defaultValue, placeholder, ...rest }) => {
    const [renderClearButton, setRenderClearButton] = useState(!!(search || defaultValue));

    const onInputChange = e => {
        onChange(e.target.value, e);
        setRenderClearButton(!!e.target.value);
    };

    const clearInput = e => {
        onInputChange({ ...e, target: { value: "" } });
        if (inputRef?.current) {
            inputRef.current.value = "";
            inputRef.current.focus();
        }
    };

    const classes = useStyles({ inHeading });

    return (
        <div className={classnames(classes.inputArea, className)} ref={searchFormRef}>
            <Input field={{
                name,
                startAdornment: <Search />,
                endAdornment: loading
                    ? <Stack padding={1}><CircularProgress size={20}/></Stack>
                    : renderClearButton ? <IconButton size="small" onClick={clearInput}><Close className={classes.closeIcon}/></IconButton> : null,
                inputProps: {
                    ref: inputRef,
                    value: search,
                    type: "text",
                    onChange: onInputChange,
                    defaultValue,
                    placeholder: useTranslate(placeholder),
                    ...rest
                }
            }} />
        </div>
    );
};

const useNoResultsStyles = makeStyles((theme, { restrictMaxWidth }) => ({
    wrapper: {
        margin: theme.spacing(2, "auto"),
        maxWidth: restrictMaxWidth ? theme.shape.pageMaxWidth : "inherit",
    },
    link: {
        marginTop: theme.spacing(1)
    }
}));
export const NoSearchResults = ({ className, restrictMaxWidth = false, noResultsText, clearResultsText, onClear }) => {
    const classes = useNoResultsStyles({ restrictMaxWidth });

    return (
        <div className={classnames(classes.wrapper, className)}>
            <Typography variant="body1" component="p">{noResultsText}</Typography>
            <Typography variant="button2" component="p" className={classes.link}>
                <Link underline="always" onClick={onClear} role="button">{clearResultsText}</Link>
            </Typography>
        </div>
    );
};
