import { Box, Stack, Typography } from "@mui/material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useListItemStyles } from "./listItemStyles";

export const ListItemContainer = ({ children, ...rest }) => {
    return (
        <Stack spacing={1} marginTop={1} direction="column" {...rest}>
            {children}
        </Stack>);
};

export const ListItem = ({ title, subtitle, description, content, aside, icon, isSelected }) => {
    const classes = useListItemStyles({ isSelected });

    return (
        <Box tabIndex={0} className={classes.box}>
            <ListItemContent title={title} subtitle={subtitle} description={description} content={content} aside={aside} icon={icon} classes={classes}/>
        </Box>
    );
};

export const ListItemContent = ({ title, subtitle, description, content, aside, icon, classes, }) => {
    const isMobile = useIsMobile();
    const ListItemContent = isMobile ? MobileListItemContent : DesktopListItemContent;
    return <ListItemContent title={title} subtitle={subtitle} description={description} content={content} aside={aside} icon={icon} classes={classes}/>;
};

const IconSlot = ({ children }) => <Box marginRight={2}>{children}</Box>;

const DesktopListItemContent = ({ title, subtitle, description, content, aside, icon, classes }) =>
    <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center" className={classes.listItem} >
        <main className={classes.main}>
            {icon && <IconSlot>{icon}</IconSlot>}
            <div className={classes.content}>
                <Typography component="div" color="text.primary" variant="label1">{title}</Typography>
                {subtitle && <Typography component="div" color="text.secondary" marginTop={.5}>{subtitle}</Typography>}
                {description && <Typography component="div" color="text.secondary" marginTop={.5}>{description}</Typography>}
            </div>
        </main>
        {(content || aside) && <Box marginLeft="auto" component="aside" display="flex" alignItems="center">
            {content && <Box marginRight={2}>{content}</Box>}
            {aside}
        </Box>}
    </Stack>;

const MobileListItemContent = ({ title, subtitle, description, content, aside, icon, classes }) =>
    <Box className={classes.listItem}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <main className={classes.main}>
                {icon && <IconSlot>{icon}</IconSlot>}
                <div className={classes.content}>
                    <Typography component="div" color="text.primary" variant="label1">{title}</Typography>
                    {subtitle && <Typography component="div" color="text.secondary" marginTop={.5}>{subtitle}</Typography>}
                    {description && <Typography component="div" color="text.secondary" marginTop={.5}>{description}</Typography>}
                </div>
            </main>
            {aside && <Box display="flex" component="aside" ml={2}>{aside}</Box>}
        </Box>
        {content && <Box marginTop={2}>{content}</Box>}
    </Box>;
