import { Field } from "redux-form/immutable";
import { makeStyles } from "components/providers/makeStyles";

import { T } from "components/util/t";
import { radioInput } from "components/forms";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: theme.spacing(2),
    }
}));

export const Waitlisting = ({ prefix, options, change, untouch }) => {
    const name = "waitlisted";
    const field = `${prefix}.${name}`;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Field
                name={field}
                component={radioInput}
                yesNo
                label={<T>Waitlist entries</T>}
                parse={value => value === "true"}
                format={value => value ? "true" : "false"}
                radioOptions={[
                    { label: <T>Yes</T>, value: "true" },
                    { label: <T>No</T>, value: "false" }
                ]}/>
        </div>
    );
};

