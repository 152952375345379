import classnames from "classnames";
import Immutable from "immutable";
import { useLocation, useParams } from "react-router-dom";

import { EventDivisionSettingsDialog } from "../../../../../../eventDivisions/eventDivisionForm";
import { HidePrinting, ShowPrinting } from "../../../../../../util/printing";
import { Heading } from "../../../../../../layout/page/Heading";
import { Fake } from "../../../../../../layout/skeleton/Fake";
import { HeadingNav } from "../../../../../../layout/navigation/HeadingNav";
import { isDivisionTeamType } from "../../../../../../eventDivisions/helpers";
import { useEventDivision } from "../EventDivisionProvider";
import { useEvent } from "../../../EventProvider";
import { eventDate } from "../../../../../../events/eventDate";
import { EntriesHeaderActions } from "./EntriesHeaderActions";
import { DrawHeaderActions } from "./DrawHeaderActions";
import { T } from "../../../../../../util/t";
import { PrintButton } from "../../../../../../layout/print/PrintButton";
import { HeaderLogo } from "../../../../../../layout/print/HeaderLogo";
import { EventDivisionTemplateDialog } from "./EventDivisionTemplateDialog";
import { useConvertToImmutable } from "hooks/useConvertToImmutable";
import {
    EditContest,
    EditContestButton,
} from "../../../../../../layout/eventDivision/EditContest";

const pageHeadings = {
    draw: { ActionsComponent: DrawHeaderActions, printTitle: "Draw" },
    entries: { ActionsComponent: EntriesHeaderActions, printTitle: "Entries" },
    leaderboard: { ActionsComponent: PrintButton, printTitle: "Round Leaderboard" },
    "team-leaderboard": { ActionsComponent: PrintButton, printTitle: "Leaderboard" },
    result: { ActionsComponent: PrintButton, printTitle: "Result" },
};

export const EventDivisionDirectorHeader = ({ className }) => {
    const params = useParams();
    const { id } = params;
    const eventDivisionId = params.event_division_id || params.eventDivisionId;
    const event = useConvertToImmutable(useEvent()) || Immutable.Map();
    const eventDivision = useConvertToImmutable(useEventDivision(), ["properties"]);
    const eventDivisionPage = useLocation().pathname.match(/(draw|leaderboard|entries|team-leaderboard|result)/)?.[0];

    if (event.isEmpty() || !eventDivision) return (
        <Heading className={classnames("skeleton", className)} title={<Fake size={20}/>} returnTo={`/events/${id}/dashboard`} borderless>
            <HeadingNav links={[
                { label: "Draw", to: `/events/${id}/divisions/${eventDivisionId}/draw` },
                { label: "Athletes", to: `/events/${id}/division/${eventDivisionId}/entries` }
            ]}/>
        </Heading>
    );

    const { ActionsComponent, printTitle } = pageHeadings[eventDivisionPage];

    const isTeams = isDivisionTeamType(eventDivision);
    const hasTeamLeaderboard = eventDivision.getIn(["format_definition", "team_leaderboard"]);
    const isRoundBased = eventDivision.getIn(["format_definition", "round_based"]);
    const isGroup = eventDivision.get("event_divisions")?.size > 0;
    const eventDivisionName = eventDivision.getIn(["division", "name"]);

    return (
        <>
            <ShowPrinting>
                <HeaderLogo/>
                {event.getIn(["organisation", "name"])} / {event.get("name")} / {eventDate({ date: event.get("date"), window: event.get("days_window") })}
                <Heading className={className} title={<><T>{printTitle}</T>&nbsp;/&nbsp;{eventDivisionName}</>}/>
            </ShowPrinting>

            <HidePrinting>
                <Heading
                    className={className}
                    title={
                        <>
                            {eventDivisionName}
                            <EventDivisionTemplateDialog/>
                        </>
                    }
                    subtitle={isGroup && eventDivision.get("event_divisions").map(ed => ed.getIn(["division", "name"])).join("; ")}
                    returnTo={`/events/${id}/dashboard`}
                    borderless
                    actions={
                        <>
                            {eventDivision.get("contest_id") ?
                                <EditContest contestId={eventDivision.get("contest_id")} dialogTitle="Division settings" buttonText="Division settings" ActionComponent={EditContestButton}/> :
                                <EventDivisionSettingsDialog initialValues={eventDivision} event={event} /> }
                            <ActionsComponent/>
                        </>
                    }>

                    <HeadingNav links={[
                        { label: "Draw", to: `/events/${id}/divisions/${eventDivisionId}/draw` },
                        { label: isTeams ? "Teams" : "Athletes", to: `/events/${id}/division/${eventDivisionId}/entries` },
                        isRoundBased && { label: "Round leaderboard", to: `/events/${id}/division/${eventDivisionId}/leaderboard` },
                        hasTeamLeaderboard && { label: "Team leaderboard", to: `/events/${id}/division/${eventDivisionId}/team-leaderboard` },
                        { label: "Result", to: `/events/${id}/division/${eventDivisionId}/result` }
                    ]}/>
                </Heading>
            </HidePrinting>
        </>
    );
};
