import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useWarning } from "hooks/useNotifications";

export const NotifiedRedirect = ({ message, to }) => {
    const { notifyWarning } = useWarning();

    useEffect(() => {
        notifyWarning(message);
    }, []);

    return <Redirect to={to}/>;
};
