import classnames from "classnames";
import { Segment } from "../../../styleguide/patterns/segment";
import { BoxList } from "../../../styleguide/patterns/boxList";
import { T } from "../../util/t";
import { StatusPill } from "../../layout/StatusPill";
import { makeStyles } from "../../providers/makeStyles";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { EmptyState } from "../../layout/EmptyState";
import { Wifi, WifiOff } from "@mui/icons-material";
import { useRealtimePresence } from "../../providers/Realtime/useRealtimePresence";

const useStyles = makeStyles(theme => ({
    pill: {
        minWidth: 60,
        justifyContent: "center",
        opacity: 0.6,
        "&.online":{
            opacity: 1,
        }
    },
}));

const calloutMessages = {
    unavailable: "It looks like there are connection issues, maybe there's a problem with the internet? Retrying...",
    connecting: "We're connecting you to the judges, sit tight!",
    connected: "You're connected, but all judges are currently offline. Judge accounts will appear here when they're online."
};
const connectionStatusDisplayName = {
    unavailable: "Unavailable",
    connecting: "Connecting your device...",
    connected: "Connected",
};
export const JudgingConnection = ({ eventId }) => {
    const classes = useStyles();
    const { connection, members: judges } = useRealtimePresence(`event-presence:${eventId}`);

    return (
        <Segment
            title="Judge status"
            className={classnames("judging-connection", { "online": connection === "connected" })}
            action={<Button component={Link} to="/organisation/edit/users" variant="outlined"><T>Judge logins</T></Button>}
            subtitle={connectionStatusDisplayName[connection]}>
            <div>
                {judges.length === 0 ?
                    <EmptyState
                        className="small"
                        icon={connection === "connected" ? <Wifi fontSize="large" color=""/> : <WifiOff fontSize="large" color=""/>}
                        text={calloutMessages[connection]}
                    />
                    :
                    <BoxList
                        borderless
                        list={judges.map(judge => ({
                            titleLeft: judge.name,
                            titleRight:
                                <StatusPill className={classnames(classes.pill, { online: judge.status === "online" })} status={judge.status}>
                                    <T>{judge.status}</T>
                                </StatusPill>
                        }))}/>}
            </div>
        </Segment>
    );
};
