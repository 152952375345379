import { makeStyles } from "../../../providers/makeStyles";

export const useTeamRoleStyles = makeStyles((theme, { roleSelected }) => ({
    assign: {
        textTransform: "uppercase",
        border: `1px solid ${roleSelected ? theme.palette.background.low : theme.palette.border.main}`,
        borderRadius: theme.spacing(2.5),
        backgroundColor: roleSelected ? theme.palette.background.low : theme.palette.background.paper,
        padding: theme.spacing(.25, 1),
        width: "fit-content",
    }
}));
