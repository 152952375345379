import { Box, Typography } from "@mui/material";
import { T } from "../../../../util/t";
import { Check } from "@mui/icons-material";

export const Option = (props, option) => {
    const Component = option.icon || Check;
    const colour = option.icon ? "primary" : "success.dark";
    const styles = option.disabled ? { minWidth: "50%", maxWidth: "50%" } : {};

    // use value only when can be used as key (i.e. don't use when it's an object)
    const key = ["string", "number"].includes(typeof option.value) ? option.value : props.key;

    return <li {...props} key={key}>
        <Box display="flex" justifyContent="space-between" width="100%" sx={{ wordBreak: "break-word" }} >
            <Box sx={styles}>
                {option.createLabel
                    ? option.createLabel(option.label)
                    : option.label
                }
            </Box>
            {option.disabled && option.message &&
                <Typography display="flex" marginLeft={2} variant="body2" whiteSpace="wrap" justifyContent="space-between" textAlign="right" >
                    <T>{option.message}</T>
                    <Component sx={{ ml: 0.5, fontSize: "inherit", color: colour }}  />
                </Typography>}
        </Box>
    </li>;
};
