import { useCallback } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";

import { T } from "../../util/t";

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(3),
    }
}));

export const SettingsActions = ({ exitEditMode, revertChanges, marginTop }) => {
    const classes = useStyles();

    const handleCancel = useCallback(() => {
        revertChanges();
        exitEditMode();
    }, []);

    return (
        <div className={classnames({ [classes.marginTop]: !!marginTop }, classes.marginBottom)}>
            <Button variant="outlined" onClick={handleCancel}><T>Cancel</T></Button>
        </div>
    );
};
