import classnames from "classnames";
import { useIsMobile } from "hooks/useIsMobile";
import { useHeatRunsStyles } from "../heat/heatStyles";
import { AvatarWithBibAndPriority } from "./AvatarWithBibAndPriority";
import { useAvatarStyles } from "./useAvatarStyles";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    }
});

export const AvatarWithSideContent = ({ athlete, bib, jersey, priority, title, subtitle, runBased, avatarClassName, avatarProps }) => {
    const classes = useStyles();
    const avatarClasses = useAvatarStyles();
    const isMobile = useIsMobile();
    const runsClasses = useHeatRunsStyles({ canScroll: !isMobile });

    return (
        <section className={classnames(avatarClasses.athlete, { [runsClasses.athlete]: runBased })}>
            <AvatarWithBibAndPriority
                athlete={athlete}
                bib={bib}
                jersey={jersey}
                priority={priority}
                avatarClassName={avatarClassName}
                avatarProps={avatarProps}
            />
            <div className={classes.container}>
                {title}
                {subtitle}
            </div>
        </section>
    );
};
