import { SkeletonTable } from "../../../../layout/skeleton/SkeletonTable";
import { useEventHeaderStyles } from "../../../../layout/event/useEventHeaderStyles";
import { Breadcrumbs } from "../../../../layout/navigation/Breadcrumbs";
import { Container, Typography } from "@mui/material";
import { T } from "../../../../util/t";

export const ClubLeaderboardPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();
    return (
        <Container className="skeleton">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <Typography variant="h3" component="h1" className={headerClasses.name}><T>Club leaderboard</T></Typography>
            </div>
            <SkeletonTable/>
        </Container>
    );
};
