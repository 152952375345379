import { useMemo } from "react";
import { sortByAthleteName } from "utils/sort";

export const useEntriesState = (competitors, hideSeeds = false) => useMemo(() =>
    (hideSeeds ? [...competitors].sort(sortByAthleteName) : competitors).reduce(({ hasBibs, registered, alternates, confirmed }, competitor) => {
        if (competitor.bib) hasBibs = true;
        if (competitor.status === "denied") alternates.push(competitor);
        else {
            registered.push(competitor);
            if (competitor.status === "confirmed") confirmed++;
        }
        return { hasBibs, registered, alternates, confirmed };
    }, { hasBibs: false, registered: [], alternates: [], confirmed: 0 })
, [competitors]);
