import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { ScanBoxIcons } from "./ScanBoxIcons";
import { ScanBoxMessage } from "./ScanBoxMessage";

export const useStyles = makeStyles(theme => ({
    scanBox: {
        textAlign: "center",
        margin: theme.spacing(3, 0)
    },
    actions: {
        margin: theme.spacing(3),
        button: {
            width: "100%",
            "&:not(:last-of-type)": {
                marginBottom: theme.spacing(1)
            }
        }
    }
}));

export const ScanBox = ({ message, scanningActions, className, messageClassName, children }) => {
    const classes = useStyles();

    return (
        <section className={classnames(classes.scanBox, className)}>
            <ScanBoxIcons message={message} />
            <ScanBoxMessage message={message} className={messageClassName} />
            {children}
            {scanningActions && <div className={classes.actions}>{scanningActions}</div>}
        </section>
    );
};
