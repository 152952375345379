import { PureComponent } from "react";
import PropTypes from "prop-types";

export class AsyncAction extends PureComponent {
    static propTypes = {
        action: PropTypes.func.isRequired,
    };

    state = {
        disabled: false
    };

    componentDidMount = () =>
        this.enable = () =>  this.setState({ disabled: false });

    componentWillUnmount = () =>
        this.enable = () => {};

    onClick = e => {
        this.setState({ disabled: true });
        const result = this.props.action(e);
        ((result && result.then) ? result : Promise.resolve(result))
            .then(
                () => this.enable(),
                (e) => {
                    this.enable();
                    throw e;
                });
    };

    render = () => null;
}
