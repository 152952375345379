import { useCallback, useState } from "react";
import { makeStyles } from "components/providers/makeStyles";

import { useOnScroll } from "../../../hooks/useOnScroll";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export const useHeatBreakdownStyles = makeStyles((theme, { noMarginRight }) => ({
    container: {
        WebkitOverflowScrolling: "touch",
        overflowY: "auto",
        paddingBottom: theme.spacing(2)
    },
    header: {
        margin: theme.spacing(2, 3, 0, 3)
    },
    mainContent: {
        margin: theme.spacing(2, 1),
        marginRight: noMarginRight && 0
    }
}));

const useButtonStyles = makeStyles(theme => ({
    closeButton: {
        zIndex: 11,
        padding: 0,
        marginLeft: "auto",
        "&:hover": {
            backgroundColor: "transparent",
        },
        position: "absolute",
        top: theme.spacing(1.5),
        right: theme.spacing(2),
        borderRadius: 0,
    }
}));

export const CloseButton = ({ close }) => {
    const classes = useButtonStyles();

    return (
        <IconButton color="inherit" className={classes.closeButton} onClick={close} aria-label="close">
            <Close/>
        </IconButton>
    );
};

export const HeatLeaderboardBreakdown = ({ heat, compactHeader, fullHeader, closeModal, selector, children, noMarginRight }) => {
    const classes = useHeatBreakdownStyles({ noMarginRight });
    const [renderCompactHeader, setRenderCompactHeader] = useState(false);

    const onScroll = useCallback(e => {
        if (e.target.scrollTop > 10 && !renderCompactHeader) setRenderCompactHeader(true);
        if (e.target.scrollTop <= 10 && renderCompactHeader) setRenderCompactHeader(false);
    }, [renderCompactHeader]);

    const { scrollerRef } = useOnScroll(onScroll);

    return (
        <div ref={scrollerRef} className={classes.container}>
            <section className={classes.header}>
                {renderCompactHeader && compactHeader}
                <CloseButton close={closeModal} />
                {fullHeader}
                {selector}
            </section>

            <section className={classes.mainContent}>
                {children}
            </section>
        </div>
    );
};
