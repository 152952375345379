import { Button } from "@mui/material";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { LinkOff } from "@mui/icons-material";
import { T } from "components/util/t";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as EventDivisionGroupActions from "actions/eventDivisionGroup";

export const GroupDivisionsUngroupButton = ({ id, eventId, closeDialog }) => {
    const dispatch = useDispatch();
    const [ungroupRequested, setUngroupRequested] = useState(false);

    const onUngroup = useCallback(() => setUngroupRequested(true), []);
    const onUngroupClose = useCallback(() => setUngroupRequested(false), []);

    const unGroup = useCallback(() =>
        dispatch(EventDivisionGroupActions.deleteDivisionGroup(id, eventId)).then(() => closeDialog()),
    [eventId, id]);

    return (
        <>
            <Button onClick={onUngroup} className="left" startIcon={<LinkOff/>}>
                <T>Ungroup</T>
            </Button>

            <ConfirmationDialog
                open={ungroupRequested}
                onClose={onUngroupClose}
                action={unGroup}
                title="Ungroup divisions?"
                content="Ungrouping a division will destroy any draws and results for all divisions in the group, and cannot be undone."
                buttonTitle="Ungroup"
            />
        </>
    );
};
