import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { approveRides } from "../../../actions/heat";
import { AutoProgressButton } from "../../layout/forms/fields/AutoProgressButton";
import { Chip } from "../../layout/notifications/Chip";
import { T } from "../../util/t";

export const ApproveAllRidesButton = ({ eventId, heatId, pendingRides }) => {
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        return dispatch(approveRides({ event_id: eventId, heat_id: heatId }));
    }, [heatId]);

    return (
        <AutoProgressButton variant="outlined" onClick={onClick}>
            <T>Approve scores</T>
            <Chip>{pendingRides}</Chip>
        </AutoProgressButton>
    );
};
