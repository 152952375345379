import { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { ModifierMenu } from "./ModifierMenu";

export const ModifierButton = ({ dispatchParams, competitor, modifier, heatConfig }) => {
    const [anchor, setAnchor] = useState();
    const openMenu = useCallback(e => setAnchor(e.currentTarget), []);
    const closeMenu = useCallback(() => setAnchor(null), []);

    return (
        <>
            <IconButton size="small" onClick={openMenu}><MoreHoriz fontSize="small"/></IconButton>
            <ModifierMenu anchor={anchor} closeMenu={closeMenu} dispatchParams={dispatchParams}
                          competitor={competitor} modifier={modifier} heatConfig={heatConfig}/>
        </>
    );
};
