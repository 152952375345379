import { forwardRef } from "react";
import { IconButton, Menu, MenuItem, SvgIcon, Typography } from "@mui/material";
import { T } from "../../util/t";
import { makeStyles } from "../../providers/makeStyles";
import { Email, HelpOutline, HelpOutlined } from "@mui/icons-material";
import { useMenuState } from "../../../hooks/useMenuState";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_PURCHASES } from "../../../graphql/organisation";
import { ReactComponent as WhatsAppIcon } from "./icons/icon_whatsapp.svg";
import { useLauncherFreeHelpCenterWidget } from "../../../hooks/useLauncherFreeHelpCenterWidget";

export const HelpMenuWrapper = ({ currentUserLoaded, currentUser }) => {
    if ((!currentUserLoaded || !currentUser || currentUser?.role === "user")) return null;

    return <HelpMenu currentUser={currentUser}/>;
};

const useHelpMenuStyles = makeStyles(theme => ({
    helpMenu: {
        left: `-${theme.spacing(6.25)}`
    }
}));
export const HelpMenu = ({ currentUser }) => {
    const { data: purchaseData } = useQuery(GET_ORGANISATION_PURCHASES, { variables: { id: currentUser.organisation_id }, fetchPolicy: "cache-and-network", skip: currentUser.role !== "director" }),
        activePurchases = purchaseData?.organisation.activePurchases;
    const classes = useHelpMenuStyles();
    const [anchorEl, openMenu, closeMenu] = useMenuState();
    const hasWhatsAppSupport = activePurchases?.accountAddOns?.whatsAppSupport;

    useLauncherFreeHelpCenterWidget(currentUser?.role);

    return (
        <>
            <HelpMenuButton  openMenu={openMenu}/>

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenu} className={classes.helpMenu}>
                <HelpMenuContent closeMenu={closeMenu} hasWhatsAppSupport={hasWhatsAppSupport} shortName={purchaseData?.organisation.shortName} />
            </Menu>
        </>
    );
};

const useHelpMenuButtonStyles = makeStyles(theme => ({
    helpMenuIcon: {
        color: theme.palette.text.secondary,
    }
}));
export const HelpMenuButton = ({ openMenu }) => {
    const classes = useHelpMenuButtonStyles();

    return (
        <IconButton className={classes.helpMenuIcon} size="small" onClick={openMenu}>
            <HelpOutline fontSize="large" />
        </IconButton>);
};

const useHelpMenuContentStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1),
    }
}));
export const HelpMenuContent = forwardRef(({ hasWhatsAppSupport, shortName, closeMenu }, ref) => {
    const openHelpCenter =() => {
        if (window.zE === undefined) {
            window.open("https://support.liveheats.com", "_blank", "noreferrer");
        } else {
            window.zE("webWidget", "show");
            window.zE("webWidget", "open");
        }
        closeMenu();
    };
    const helpMenuContentClasses = useHelpMenuContentStyles();

    return (
        <>
            <MenuItem onClick={openHelpCenter} >
                <Typography display="flex" alignItems="center" >
                    <HelpOutlined color="inherit" className={helpMenuContentClasses.icon} />
                    <T>Help docs and support</T>
                </Typography>
            </MenuItem>
            <MenuItem onClick={closeMenu}>
                <Typography display="flex" alignItems="center" component="a" href="mailto:community@liveheats.com" >
                    <Email color="inherit" className={helpMenuContentClasses.icon} />
                    <T>community@liveheats.com</T>
                </Typography>
            </MenuItem>
            {hasWhatsAppSupport && <MenuItem onClick={closeMenu}>
                <Typography display="flex" alignItems="center" component="a" href={`https://wa.me/18086467455?text=Help%20required,%20my%20account%20is%20liveheats.com/${shortName}`} target="_blank" rel="noreferrer">
                    <SvgIcon aria-label="WhatsAppIcon" component={WhatsAppIcon} fontSize="small" className={helpMenuContentClasses.icon} />
                    <T>WhatsApp +1808 646 7455</T>
                </Typography>
            </MenuItem>}
        </>
    );
});
