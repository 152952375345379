import { Field } from "redux-form/immutable";
import { makeStyles } from "components/providers/makeStyles";
import { Add } from "@mui/icons-material";
import { Linkify } from "components/util/linkify";

import { plainInput } from "../../../forms";
import { T } from "../../../util/t";
import { SettingsOption } from "components/layout/registrationSettings/SettingsOption";
import { SettingsActions } from "components/layout/registrationSettings/SettingsActions";
import { SettingsItem } from "components/layout/registrationSettings/SettingsItem";
import { SettingsButton } from "components/layout/registrationSettings/SettingsButton";

export const useStyles = makeStyles((theme) => ({
    notes: {
        whiteSpace: "pre-line",
        wordWrap: "break-word",
        [theme.breakpoints.up("sm")]: {
            maxWidth: "60%"
        },
        "& > div": {
            display: "inline-block",
        }
    }
}));

export const Notes = ({ prefix, options, change, untouch, disabled }) => {
    const name = "notes";
    const value = options.get(name);
    const field = `${prefix}.${name}`;
    const classes = useStyles();

    return (
        <SettingsOption
            change={change}
            field={field}
            value={value}
            untouch={untouch}
            onEdit={({ revertChanges, exitEditMode }) =>
                <>
                    <Field
                        name={field}
                        component={plainInput}
                        type="textarea"
                        label={<T>Additional registration notes:</T>}/>
                    <SettingsActions revertChanges={revertChanges} exitEditMode={exitEditMode}/>
                </>
            }
            onView={({ startEditing, deleteField }) =>
                value
                    ? <>
                        <p><strong><T>Additional registration notes:</T></strong></p>
                        <p className={classes.notes}>
                            <Linkify>{value}</Linkify>
                        </p>
                        <SettingsItem disabled={disabled} onEdit={startEditing} onDelete={deleteField} />
                    </>
                    : <SettingsButton disabled={disabled} variant="outlined" onClick={startEditing} startIcon={<Add/>}><T>Add registration notes</T></SettingsButton>
            }
        />
    );
};
