import { makeStyles } from "../../providers/makeStyles";
import classnames from "classnames";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    content: {
        position: "relative",
        top: -1,
    },
    box: {
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        marginRight: theme.spacing(1),
        "&.division-0": { backgroundColor: "#5E7CE2" },
        "&.division-1": { backgroundColor: "#F9C80E" },
        "&.division-2": { backgroundColor: "#4FB286" },
        "&.division-3": { backgroundColor: "#CACFD6" },
        "&.division-4": { backgroundColor: "#E07BE0" },
        "&.division-5": { backgroundColor: "#92B4F4" },
        "&.division-6": { backgroundColor: "#90DDF0" },
        "&.division-7": { backgroundColor: "#7FB685" },
        "&.division-8": { backgroundColor: "#B0C592" },
        "&.division-9": { backgroundColor: "#EFDD8D" },
        "&.division-10": { backgroundColor: "#EBD270" },
        "&.division-11": { backgroundColor: "#CFDEE7" },
        "&.division-12": { backgroundColor: "#BBE6E4" },
        "&.division-13": { backgroundColor: "#75D1A0" },
        "&.division-14": { backgroundColor: "#9FD8CB" },
        "&.division-15": { backgroundColor: "#8499B1" },
        "&.division-16": { backgroundColor: "#F0A8A8" },
        "&.division-17": { backgroundColor: "#D6E5E3" },
        "&.division-18": { backgroundColor: "#ACCBE1" },
        "&.division-19": { backgroundColor: "#B1AAAD" },
        "&.division-20": { backgroundColor: "#F2C57C" },
        "&.division-21": { backgroundColor: "#E0AFA0" },
        "&.division-22": { backgroundColor: "#A5C4D4" },
        "&.division-23": { backgroundColor: "#D487B6" },
        "&.division-24": { backgroundColor: "#BCB8B1" },
        "&.division-25": { backgroundColor: "#DCCCFF" },
        "&.division-26": { backgroundColor: "#E5D4C0" },
        "&.division-27": { backgroundColor: "#A1E8CC" },
        "&.division-28": { backgroundColor: "#EEEFA8" },
        "&.division-29": { backgroundColor: "#EF6F6C" },
        "&.division-30": { backgroundColor: "#D585C8" },
        "&.division-31": { backgroundColor: "#11BBB2" },
        "&.division-32": { backgroundColor: "#2F9CB1" },
        "&.division-33": { backgroundColor: "#D7C9AA" },
        "&.division-34": { backgroundColor: "#DDAE7E" },
        "&.division-35": { backgroundColor: "#BBC2E2" },
        "&.division-36": { backgroundColor: "#FFD6EB" },
        "&.division-37": { backgroundColor: "#E09F7D" },
        "&.division-38": { backgroundColor: "#839D9A" },
        "&.division-39": { backgroundColor: "#7BB2D9" },
        "&.division-40": { backgroundColor: "#BFD2BF" },
        "&.division-41": { backgroundColor: "#FAC9B8" },
        "&.division-42": { backgroundColor: "#C5DECD" },
        "&.division-43": { backgroundColor: "#A9CBB7" },
        "&.division-44": { backgroundColor: "#E39774" },
        "&.division-45": { backgroundColor: "#5C9EAD" },
        "&.division-46": { backgroundColor: "#C0CAAD" },
        "&.division-47": { backgroundColor: "#9DA9A0" },
        "&.division-48": { backgroundColor: "#E191A1" },
        "&.division-49": { backgroundColor: "#ACC3BD" },
        "&.division-50": { backgroundColor: "#CEC075" },
        "&.division-51": { backgroundColor: "#F4B860" },
        "&.division-52": { backgroundColor: "#B098A4" },
        "&.division-53": { backgroundColor: "#DBD9DB" },
        "&.division-54": { backgroundColor: "#D6F9DD" },
        "&.division-55": { backgroundColor: "#C5D6D8" },
        "&.division-56": { backgroundColor: "#F2E94E" },
        "&.division-57": { backgroundColor: "#A3D9FF" },
        "&.division-58": { backgroundColor: "#F4D6CC" },
        "&.division-59": { backgroundColor: "#FAEAB3" },
        "&.division-60": { backgroundColor: "#E49AB0" },
        "&.division-61": { backgroundColor: "#ECB8A5" },
        "&.division-62": { backgroundColor: "#ECCFC3" },
        "&.division-63": { backgroundColor: "#CECCCC" },
        "&.division-64": { backgroundColor: "#9AE5E6" },
        "&.division-65": { backgroundColor: "#81A094" },
        "&.division-66": { backgroundColor: "#7EBDC3" },
        "&.division-67": { backgroundColor: "#FFE66D" },
        "&.division-68": { backgroundColor: "#93A3B1" },
        "&.division-69": { backgroundColor: "#7F95D1" },
        "&.division-70": { backgroundColor: "#FF82A9" },
        "&.division-71": { backgroundColor: "#FFC0BE" },
        "&.division-72": { backgroundColor: "#FFEBE7" },
        "&.division-73": { backgroundColor: "#CED097" },
        "&.division-74": { backgroundColor: "#BFAFA6" },
        "&.division-75": { backgroundColor: "#E9FA9E" },
        "&.division-76": { backgroundColor: "#6CA6C1" },
        "&.division-77": { backgroundColor: "#BDC696" },
        "&.division-78": { backgroundColor: "#D1D3C4" },
        "&.division-79": { backgroundColor: "#B0DAF1" },
        "&.division-80": { backgroundColor: "#8BBEB2" },
        "&.division-81": { backgroundColor: "#E6F9AF" },
        "&.division-82": { backgroundColor: "#25CED1" },
        "&.division-83": { backgroundColor: "#FCEADE" },
        "&.division-84": { backgroundColor: "#FF8A5B" },
        "&.division-85": { backgroundColor: "#EA526F" },
        "&.division-86": { backgroundColor: "#FAFF81" },
        "&.division-87": { backgroundColor: "#FFECD1" },
        "&.division-88": { backgroundColor: "#E5D9F2" },
        "&.division-89": { backgroundColor: "#D499B9" },
        "&.division-90": { backgroundColor: "#E8C1C5" },
        "&.division-91": { backgroundColor: "#F19C79" },
        "&.division-92": { backgroundColor: "#B9D2B1" },
        "&.division-93": { backgroundColor: "#DAC6B5" },
        "&.division-94": { backgroundColor: "#F1D6B8" },
        "&.division-95": { backgroundColor: "#FBACBE" },
        "&.division-96": { backgroundColor: "#B2DDF7" },
        "&.division-97": { backgroundColor: "#81D6E3" },
        "&.division-98": { backgroundColor: "#4CB5AE" },
        "&.division-99": { backgroundColor: "#edcd8d" },
        "&.division-100": { backgroundColor: "#a5b598" },
        "&.division-101": { backgroundColor: "#f49a76" },
        "&.division-102": { backgroundColor: "#8ecbc9" },
        "&.division-103": { backgroundColor: "#c1ebf9" },
        "&.division-104": { backgroundColor: "#c4bcdc" },
        "&.division-105": { backgroundColor: "#e1b55c" },
        "&.division-106": { backgroundColor: "#c2d4b3" },
        "&.division-107": { backgroundColor: "#f8b89e" },
        "&.division-108": { backgroundColor: "#7ec8d8" },
        "&.division-109": { backgroundColor: "#e58c9c" },
        "&.division-110": { backgroundColor: "#e5e395" },
        "&.division-111": { backgroundColor: "#c2e2d4" },
        "&.division-112": { backgroundColor: "#b5c0e5" },
        "&.division-113": { backgroundColor: "#87bdaf" },
        "&.division-114": { backgroundColor: "#c5c672" },
        "&.division-115": { backgroundColor: "#e5b9cc" },
        "&.division-116": { backgroundColor: "#ff8485" },
        "&.division-117": { backgroundColor: "#b6e2d0" },
        "&.division-118": { backgroundColor: "#bebdd8" },
        "&.division-119": { backgroundColor: "#ede085" },
        "&.division-120": { backgroundColor: "#93e3e2" },
        "&.division-121": { backgroundColor: "#f7a1c4" },
        "&.division-122": { backgroundColor: "#e6b0e9" },
        "&.division-123": { backgroundColor: "#8ecbc9" },
        "&.division-124": { backgroundColor: "#cfe273" },
        "&.division-125": { backgroundColor: "#929adc" },
        "&.division-126": { backgroundColor: "#f8a685" },
        "&.division-127": { backgroundColor: "#95e7b7" },
        "&.division-128": { backgroundColor: "#6ccdff" },
    }
}));
export const DivisionPill = ({ children, className, indicator }) => {
    const classes = useStyles();

    return (
        <div className={classnames(className, classes.box, `division-${indicator}`)}>
            <Typography variant="label2" className={classes.content}>{children}</Typography>
        </div>
    );
};
