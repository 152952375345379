import { useCallback, useRef } from "react";
import { Button } from "@mui/material";

export const FileButton = ({ accept, onChange, children, Component = Button, ...rest }) => {
    const uploadInput = useRef();
    const chooseFile = useCallback(() => uploadInput.current.click(), [uploadInput.current]);

    const handleChange = useCallback((e) => {
        onChange(e);
        uploadInput.current.value = "";
    }, [onChange]);

    return (
        <>
            <input type="file" accept={accept} ref={uploadInput} style={{ display: "none" }} onChange={handleChange} data-testid="file-input"/>
            <Component onClick={chooseFile} {...rest}>
                {children}
            </Component>
        </>
    );
};
