import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form/immutable";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Add, Delete, Settings } from "@mui/icons-material";

import { creatableSelect, FormField, mustBeNumber, plainInput, radioInput, required } from "../forms/index";
import {
    athleteRidesLimitSelectOptions,
    athletesPerTeamSelectOptions,
    countingRidesSelectOptions,
    divisionRoundBasedProgressionSelectOptions,
    heatDurationSelectOptions,
    heatSizeSelectOptions,
    roundProgressionSelectOptions,
    roundSizeSelectOptions,
    runProgressionSelectOptions,
} from "../forms/options";
import * as EventDivisionActions from "../../actions/eventDivision";
import * as OrganisationActions from "../../actions/organisation";

import { T } from "../util/t";
import { isTeamFormat, translateText } from "../../utils/utils";
import { ResponsiveDialog } from "../modal";
import { LoadingButton } from "../actions/loadingButton";
import { Accordion } from "../../styleguide/patterns/expandable";
import { valueToInt } from "../forms/parsers";
import { useDialogState } from "../../hooks/useDialogState";
import { CustomEventDivisionProperties } from "./CustomEventDivisionProperties";
import {
    EventDivisionSeriesPointAllocationsSelectors
} from "../layout/eventDivision/EventDivisionSeriesPointAllocationsSelectors";

export function eventDivisionPropertiesMapper(values) {
    let mapped = values.set("format_definition", values.get("format_definition").merge(Immutable.fromJS({
        seeds: Immutable.Map(values.get("seeded_rounds").map(seededRound =>
            [parseInt(seededRound.get("round")) - 1, parseInt(seededRound.get("seeds"))]))
    }))).delete("seeded_rounds");

    return mapped.toJS();
}

export const EventDivisionSettingsDialog = (props) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState({ disableBackdropClick: true });

    return (
        <>
            <Button onClick={openDialog} variant="outlined" startIcon={<Settings/>}>
                <T>Division settings</T>
            </Button>

            <ResponsiveDialog className="lh-dialog"
                              disableEscapeKeyDown
                              fullWidth={true}
                              maxWidth="sm"
                              open={dialogOpen}
                              aria-labelledby="event-division-form-dialog-title"
                              onClose={closeDialog}>
                <EventDivisionForm {...props} closeDialog={closeDialog}/>
            </ResponsiveDialog>
        </>
    );
};

const form = "eventDivision";
@connect((state, props) => {
    const selector = formValueSelector(form),
        organisationId = state.users.getIn(["current", "organisation_id"]),
        parentDivisions = state.organisations
            .getIn(["parent_series", organisationId, props.initialValues.get("id")], Immutable.List())
            .filter(series => series.get("rankings_divisions").size > 0)
            .map(series => {
                const existingDts = props.initialValues.get("division_to_seasons", Immutable.List())
                    .find(dts => parseInt(dts.get("season_id")) === parseInt(series.get("series_id")), {}, Immutable.Map());
                return series.merge(Immutable.Map({
                    id: existingDts.get("id"),
                    division_id: existingDts.get("division_id"),
                    point_allocation_id: existingDts.getIn(["point_allocation", "id"]),
                    point_allocation_name: existingDts.getIn(["point_allocation", "name"]),
                    _destroy: !existingDts.get("division_id")
                }));
            }),
        federationSeries = props.event.get("series", Immutable.List()),
        eventDivisionPointAllocations = federationSeries
            .filter(series => parseInt(series.get("organisation_id")) === parseInt(organisationId))
            .map(series => {
                const existingEdpa = props.initialValues.get("event_division_point_allocations", Immutable.List())
                    .find(edpa => parseInt(edpa.get("season_id")) === parseInt(series.get("id")), {}, Immutable.Map());
                return series.merge(Immutable.Map({
                    id: existingEdpa.get("id"),
                    event_division_id: props.initialValues.get("id"),
                    season_id: series.get("id"),
                    point_allocation_id: existingEdpa.getIn(["point_allocation", "id"]),
                    point_allocation_name: existingEdpa.getIn(["point_allocation", "name"]),
                    _destroy: !existingEdpa.getIn(["point_allocation", "id"])
                }));
            });

    return {
        organisationId,
        parentDivisions,
        eventDivisionPointAllocations,
        federationSeries,
        formatDefinition: selector(state, "format_definition"),
        seededRounds: selector(state, "seeded_rounds"),
        initialValues: props.initialValues
            .setIn(["format_definition", "heat_sizes"],
                props.initialValues.getIn(["format_definition", "heat_sizes"]) || Immutable.Map())
            .setIn(["format_definition", "progression"],
                props.initialValues.getIn(["format_definition", "progression"]) || Immutable.Map())
            .set("entry_limit_attributes", props.initialValues.get("entry_limit") || Immutable.Map({ limit: "", _destroy: true }))
            .delete("entry_limit")
            .set("division_to_seasons_attributes", parentDivisions)
            .set("event_division_point_allocations_attributes", eventDivisionPointAllocations),
        localization: state.localization
    };
})
@reduxForm({ form, enableReinitialize: true, touchOnChange: true })
export class EventDivisionForm extends Component {
    static propTypes = {
        initialValues: PropTypes.instanceOf(Immutable.Map).isRequired,
        localization: PropTypes.instanceOf(Immutable.Map).isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    componentDidMount = () => {
        const { dispatch, organisationId, initialValues } = this.props;
        dispatch(OrganisationActions.getParentSeries(organisationId, initialValues.get("id")));
    };

    constructor(props) {
        super(props);

        const { initialValues, localization } = props;

        this.heatDurationSelectOptions = heatDurationSelectOptions({ defaultDuration: initialValues.get("default_event_duration_minutes"), type: "event", localization });
        this.heatSizeSelectOptions = heatSizeSelectOptions({ localization });
        this.runProgressionSelectOptions = runProgressionSelectOptions({ localization });
        this.roundProgressionSelectOptions = roundProgressionSelectOptions({ localization });
        this.divisionRoundBasedProgressionSelectOptions = divisionRoundBasedProgressionSelectOptions({ localization });
        this.countingRidesSelectOptions = countingRidesSelectOptions(localization);
        this.athleteRidesLimitSelectOptions = athleteRidesLimitSelectOptions(localization);
    }

    update = (values) => {
        const { dispatch, initialValues, closeDialog } = this.props,
            eventDivisionId = initialValues.get("id");
        return dispatch(EventDivisionActions.update(eventDivisionId, eventDivisionPropertiesMapper(values))).then(closeDialog);
    };

    translate = (text, props) => translateText({ text, localization: this.props.localization, props });

    validateRoundForSeeding = (value, allValues) => {
        // eslint-disable-next-line
        let round1Error = value == 1 ? "Round 1 will be automatically seeded" : undefined;
        // eslint-disable-next-line
        let repeatedRoundError = allValues.get("seeded_rounds").filter(seededRound => seededRound.get("round") == value).count() > 1 ?
            this.translate("EventDivisionForm_seedsAdded", { value }) : undefined;
        return mustBeNumber(value) || round1Error || repeatedRoundError;
    };

    validateHeatSize = (value, allValues) =>
        !allValues.getIn(["format_definition", "run_based"]) && !allValues.getIn(["format_definition", "round_based"]) && value < (allValues.getIn(["format_definition", "progression", "default", 0, "max"]) * 2) ?
            "Must be equal to or greater than twice the default progression"  : undefined;

    validateProgression = (value, allValues) =>
        !allValues.getIn(["format_definition", "run_based"]) && !allValues.getIn(["format_definition", "round_based"]) && (allValues.getIn(["format_definition", "heat_sizes", "default"]) || 4) < (allValues.getIn(["format_definition", "progression", "default", 0, "max"]) * 2)  ?
            "Must be equal to or smaller than half the default heat size" : undefined;

    renderRoundSeeding = ({ fields }) => {
        const { seededRounds } = this.props;
        const onAdd = () => {
            const newRound = (seededRounds.last() ? parseInt(seededRounds.last().get("round")) : 1) + 1;
            fields.push(Immutable.Map({ round: newRound }));
        };

        return (
            <FormField type="seeding" showError={false} error="">
                <label><T>Add seeds to later rounds:</T></label>
                <br />

                {fields.map((field, index) =>
                    <Fragment key={field}>
                        <div className="rule field-group-extended">
                            <Field
                                name={`${field}.seeds`}
                                component={plainInput}
                                validate={mustBeNumber}
                                type="text"
                                label={<T>Seeds:</T>}/>
                            <Field
                                name={`${field}.round`}
                                component={plainInput}
                                validate={this.validateRoundForSeeding}
                                type="text"
                                label={<T>Round:</T>}/>
                            <IconButton className="delete-row" size="small" onClick={() => fields.remove(index)}>
                                <Delete />
                            </IconButton>
                        </div>
                    </Fragment>
                )}

                <Button className="add" onClick={onAdd} startIcon={<Add/>}>
                    <T>Add seeds to another round</T>
                </Button>
            </FormField>
        );
    };

    formatEntryLimit = value => value && value.get("limit");

    parseEntryLimit = value => Immutable.fromJS({
        id: this.props.initialValues.getIn(["entry_limit_attributes", "id"]),
        limit: valueToInt(value),
        _destroy: valueToInt(value) === ""
    });

    render = () => {
        const { handleSubmit, pristine, change, formatDefinition, initialValues, closeDialog, organisationId, parentDivisions, federationSeries, eventDivisionPointAllocations } = this.props,
            runBased = initialValues.getIn(["format_definition", "run_based"]),
            roundBased = formatDefinition && formatDefinition.get("round_based");

        return (
            <form className="new-form eventDivisionForm" onSubmit={handleSubmit(this.update)}>
                <div className="scrollable-area">
                    <DialogTitle id="event-division-form-dialog-title" className="sticky-dialog-title">
                        <T>Division settings</T>
                    </DialogTitle>

                    <DialogContent className="sticky-dialog-content">
                        {(runBased || (!runBased && roundBased)) &&
                            <Field name="format_definition.number_of_rounds" component={creatableSelect}
                                   label={<T>Number of rounds:</T>}
                                   format={value => value || 1}
                                   selectOptions={roundSizeSelectOptions}/>}

                        {!runBased && <Field name="format_definition.heat_sizes.default" component={creatableSelect}
                                             label={<T>Default heat size:</T>}
                                             validate={this.validateHeatSize}
                                             format={value => value || 4}
                                             selectOptions={this.heatSizeSelectOptions}/>}

                        {runBased &&
                        <Field name="format_definition.run_progression.default" component={creatableSelect}
                               label={<T>Default progression between runs in a round:</T>}
                               selectOptions={this.runProgressionSelectOptions}/>}

                        <Field name="format_definition.progression.default" component={creatableSelect}
                               label={<T>Default progression between rounds:</T>}
                               helpText={roundBased && "Since you're using Round results for progression, you must use the Round settings to set the progression between rounds. You can access the Round settings once you've drawn heats."}
                               validate={this.validateProgression}
                               format={value => value || Immutable.fromJS([{}])}
                               selectOptions={roundBased ? this.divisionRoundBasedProgressionSelectOptions : this.roundProgressionSelectOptions}/>

                        <Field name="heat_duration_minutes" component={creatableSelect}
                               label={<T>{runBased ? "Default run duration:" : "Default heat duration:"}</T>}
                               selectOptions={this.heatDurationSelectOptions}/>

                        <Accordion label="Advanced">
                            {isTeamFormat(formatDefinition) &&
                            <Field name="format_definition.team_config.athletes_per_team" component={creatableSelect}
                                   label={<T>Athletes per team:</T>}
                                   validate={required}
                                   selectOptions={athletesPerTeamSelectOptions}/>}

                            <Field name="heat_config.total_counting_rides" component={creatableSelect}
                                   label={<T>{`Counting rides${isTeamFormat(formatDefinition) ? " per athlete" : ""}:`}</T>}
                                   selectOptions={this.countingRidesSelectOptions}/>

                            <Field name="heat_config.athlete_rides_limit" component={creatableSelect}
                                   label={<T>{`Max rides${isTeamFormat(formatDefinition) ? " per athlete" : ""}:`}</T>}
                                   selectOptions={this.athleteRidesLimitSelectOptions}/>

                            <Field name="entry_limit_attributes" component={plainInput}
                                   label={<T>Available spots:</T>}
                                   type="number" pattern="[0-9]*"
                                   format={this.formatEntryLimit}
                                   parse={this.parseEntryLimit}/>

                            <FieldArray name="seeded_rounds" component={this.renderRoundSeeding}/>

                            {!runBased &&
                            <Field name="format_definition.round_based" component={radioInput}
                                   label={<T>Progression determined by athlete's place in:</T>}
                                   onChange={(event, newValue) => {
                                       if (newValue)
                                           change("format_definition.progression.default", Immutable.fromJS([{}]));
                                   }}
                                   parse={value => !!value}
                                   radioOptions={[
                                       { label:  <T>Heat results</T>, value: "" },
                                       { label:  <T>Round results</T>, value: true },
                                   ]}/>}


                        </Accordion>
                        <EventDivisionSeriesPointAllocationsSelectors parentDivisions={parentDivisions} federationSeries={federationSeries} eventDivisionPointAllocations={eventDivisionPointAllocations} organisationId={organisationId} />
                        <CustomEventDivisionProperties organisationId={organisationId}/>
                    </DialogContent>
                </div>

                <DialogActions className="sticky-dialog-actions">
                    <Button variant="outlined" onClick={closeDialog}>
                        <T>Cancel</T>
                    </Button>
                    <LoadingButton variant="contained" color="primary" action={handleSubmit(this.update)} type="submit"
                                   disabled={pristine} autoFocus>
                        <T>Save</T>
                    </LoadingButton>
                </DialogActions>
            </form>
        );
    };
}
