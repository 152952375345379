export const judgeScoreNormalize = (maxInput = 10, maxDecimals = 1) => {
    const normalise = v => v.replace(/\.+/g, ".");
    const formatRe = new RegExp(`(\\d+\\.?${[...Array(maxDecimals)].map(() => "\\d?").join("")})\\d*`);

    const format = v => {
        if (v === "") {
            return "";
        }

        if (parseInt(v) === maxInput) {
            v = "" + parseInt(v);
        }
        if (v === "00" && maxDecimals === 2) {
            v = "0.0";
        }
        else if (!(parseFloat(v) <= maxInput && (v[0] !== "0" || v.match(/^0\./)))) {
            const orderOverMax =  Math.pow(10, Math.floor(Math.log(v / maxInput) / Math.LN10 + 0.000000001));
            v = "" + (parseInt(v) / (10 * (orderOverMax < 1 ? 1 : orderOverMax)));
        }

        return v.match(formatRe)[1];
    };

    const valid = value => {
        if (!value) {
            return true;
        }

        if (value.match(/^\d+\.?\d*$/)) {
            return parseFloat(value) <= (maxInput * 10 ** maxDecimals) - (maxDecimals ? 1 : 0);
        }

        return false;
    };

    return (newValue, previousValue) => {
        newValue = normalise(newValue);
        if (valid(newValue)) {
            return format(newValue);
        }
        return previousValue;
    };
};