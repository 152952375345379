import { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../../actions/user";
import { OrganisationProvider } from "./OrganisationProvider";
import { convertToJS } from "../eventDivisions/helpers";
import { useEnableThirdPartyCookies } from "hooks/useEnableThirdPartyCookies";

const UserContext = createContext({});
UserContext.displayName = "CurrentUser";

export const useCurrentUser = () => useContext(UserContext);

export const CurrentUserProvider = ({ children }) => {
    const dispatch = useDispatch();
    const getCurrentUser = () => dispatch(UserActions.getCurrentUser());

    const enableThirdPartyCookies = useEnableThirdPartyCookies(getCurrentUser, getCurrentUser);

    useEffect(() => {
        enableThirdPartyCookies();
    }, []);

    const loaded = useSelector(({ users }) => users.get("currentUserLoaded"));
    const currentUser = useSelector(({ users }) => users.get("current"));
    const user = currentUser && currentUser.toJS();

    return (
        <UserContext.Provider value={{ loaded, currentUser: user }}>
            <OrganisationProvider value={{ organisation: convertToJS(currentUser?.get("organisation")) }}>
                {children}
            </OrganisationProvider>
        </UserContext.Provider>
    );
};
