import { useCallback, useEffect, useRef, useState } from "react";

export const useNFCEvents = () => {
    const [event, setStateEvent] = useState();
    const clearEvent = useCallback(() => setEvent(), []);
    const eventTimeout = useRef();
    const setEvent = useCallback((event) => {
        clearTimeout(eventTimeout.current);
        setStateEvent(event);
    }, []);
    const setDisappearingEvent = useCallback(event => {
        setEvent(event);
        eventTimeout.current = setTimeout(() => setEvent(), 3000);
    }, []);
    useEffect(() => () => clearTimeout(eventTimeout.current), []);

    return [event, setEvent, setDisappearingEvent, clearEvent];
};
