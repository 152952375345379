import { gql } from "@apollo/client";

export const LeaderboardFragment = gql`
  fragment Leaderboard on Leaderboard {
    id
    round
    roundPosition
    startTime
    endTime
    config {
      runBased
      hideTimer
      hideNeeds
      hideScores
      maxRideScore
      categories
      isTeams
      hasStartlist
      calculator
    }
    result {
      athleteId
      total
      winBy
      needs
      rides
      place
    }
    competitors {
      athleteId
      heatId
      position
      teamName
      bib
      athlete {
        id
        name
        image
      }
    }
    eventDivision {
        id
        division {
            id
            name
        }
    }
    heats {
          id
          config {
            hideScheduledTime
            hideTimer
          }
          endTime
          heatDurationMinutes
          position
          roundPosition
          scheduledTime
          startTime
      }
  }
`;
