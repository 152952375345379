import { Box, Container, Stack, Typography } from "@mui/material";
import { Breadcrumbs } from "components/layout/navigation/Breadcrumbs";
import { Fake } from "components/layout/skeleton/Fake";
import { makeStyles } from "components/providers/makeStyles";
import { Card } from "components/layout/cards/Card";
import { CardHeader } from "components/layout/cards/CardHeader";
import { CardCaption } from "components/layout/cards/CardCaption";

const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(5),
    }
}));

export const UserEventAthletePageSkeleton = () => {
    const classes = useStyles();

    return (
        <Box component={Container} paddingTop={2} paddingBottom={3} className="skeleton" role="status" aria-label="loading">
            <Breadcrumbs className="no-background" forceMobileView crumbs={[{}]}/>
            <Typography variant="h3" component="h1" marginBottom={3}><Fake size={25}/></Typography>
            <Typography variant="subtitle2" marginBottom={3}><Fake size={40}/></Typography>

            <Stack spacing={3}>
                {[...Array(3)].map((_,i) => (
                    <Card key={i} backgroundColor="background.mid" footer={<Typography variant="h3"><Fake size={25}/></Typography>}>
                        <CardHeader title={<Fake size={25}/>} subtitle={<Fake size={25}/>} />
                        <Typography variant="h3" component="h1" marginBottom={3}><Fake size={25}/></Typography>
                        <CardCaption><Fake size={40}/></CardCaption>
                        <Fake size={10} className={classes.button}/>
                    </Card>
                ))}
            </Stack>
        </Box>
    );
};
