import { useState } from "react";

export const useMenuState = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = e => setAnchorEl(e.currentTarget);
    const closeMenu = e => setAnchorEl(null);

    return [anchorEl, openMenu, closeMenu];
};
