import { T } from "../../components/util/t";
import { makeStyles } from "../../components/providers/makeStyles";
import classnames from "classnames";
import { Typography } from "@mui/material";

const useSegmentStyles = makeStyles( theme => ({
    segment: {
        margin: theme.spacing(4, 0, 5),
        header: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
            flexWrap: "wrap",
            rowGap: theme.spacing(1),
        }
    },
    segmentContent: {
        position: "relative",
    }
}));

export const Segment = ({ title, subtitle, action, children, className = "" }) => {
    const classes = useSegmentStyles();

    return (
        <section className={classnames(classes.segment, className)}>
            {title &&
            <header>
                <div>
                    <Typography variant="button3" component="p"><T>{title}</T></Typography>
                    <Typography><T>{subtitle}</T></Typography>
                </div>
                {action}
            </header>}
            <div className={classes.segmentContent}>
                {children}
            </div>
        </section>
    );
};
