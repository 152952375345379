import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { Fake } from "./Fake";
import { useRowStyles, useTableStyles } from "../commonStyles/tableStyles";
import { AthleteAvatarSkeleton } from "./AthleteAvatarSkeleton";

const useStyles = makeStyles(theme => ({
    athlete: {
        paddingLeft: theme.spacing(2),
        textAlign: "left"
    },
    head: {
        "& th": {
            whiteSpace: "nowrap"
        }
    }
}));
export const SkeletonTable = ({ withoutAvatar, rows = 3 }) => {
    const classes = { ...useTableStyles(), ...useStyles() };

    return (
        <table className={`skeleton ${classes.table}`}>
            <thead className={classes.head}>
                <tr>
                    <th><Fake size={12}/></th>
                    <th className={classes.athlete}><Fake size={30}/></th>
                </tr>
            </thead>
            <tbody>
                <FakeRow size={47} withoutAvatar={withoutAvatar}/>
                {rows > 1 && <FakeRow size={63} withoutAvatar={withoutAvatar}/>}
                {rows > 2 && <FakeRow size={58} withoutAvatar={withoutAvatar}/>}
            </tbody>
        </table>
    );
};

const FakeRow = ({ size, withoutAvatar }) => {
    const classes = useRowStyles();

    return (
        <tr>
            <td className={classes.rank}><Typography variant="h4"><Fake size={3}/></Typography></td>
            <td className={classes.athlete}>
                <AthleteAvatarSkeleton withoutAvatar={withoutAvatar} size={size} />
            </td>
        </tr>
    );
};
