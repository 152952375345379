import { currentHeatsSelector, schedulePositionSelector, heatsLoadedSelector, eventSelector } from "./selectors";

export const getHeatsAndSchedulePosition = (state, props) => ({
    heats: currentHeatsSelector(state, props),
    schedulePosition: schedulePositionSelector(state, props)
});

export const getHeatsAndEvent = (state, props) => ({
    heats: currentHeatsSelector(state, props),
    heatsLoaded: heatsLoadedSelector(state, props),
    event: eventSelector(state, props)
});
