import { useCallback } from "react";
import { IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles((theme, { index }) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: index > 0 && theme.spacing(2),
    },
    button: {
        alignSelf: "center",
        flex: 0
    },
    heading: {
        marginBottom: theme.spacing(2)
    }
}));

export const SettingsOptionContainer = ({ heading, array, field, index, children, renderHeader }) => {
    const classes = useStyles({ index });
    const onDelete = useCallback(() => array.remove(field, index), [field, index]);

    const button = (
        <IconButton color="inherit" size="small" onClick={onDelete} className={classes.button}>
            <Delete/>
        </IconButton>
    );

    return (
        <>
            {renderHeader && (
                <header className={classes.header}>
                    <Typography className={classes.heading} variant="label1">{heading}</Typography>
                    {button}
                </header>
            )}
            {children}
            {!renderHeader && button}
        </>
    );
};
