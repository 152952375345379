import { Container, Stack, Typography } from "@mui/material";
import { useEventHeaderStyles } from "components/layout/event/useEventHeaderStyles";
import { EventHeaderDetailsSkeleton } from "components/layout/event/EventHeaderDetailsSkeleton";
import { Breadcrumbs } from "components/layout/navigation/Breadcrumbs";
import { Fake } from "components/layout/skeleton/Fake";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(5),
    },
    message: {
        display: "block",
        height: theme.spacing(5)
    }
}));

export const UserEventPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();
    const classes = useStyles();

    return (
        <Container className="skeleton" role="status" aria-label="loading">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <EventHeaderDetailsSkeleton />

                <Stack spacing={2} direction="row" marginTop={4}>
                    <Fake size={35} className={classes.button}/>
                    <Fake size={35} className={classes.button}/>
                </Stack>
            </div>

            <Typography variant="h5" marginBottom={2}><Fake size={25}/></Typography>

            <Fake size={80} className={classes.message}/>
        </Container>
    );
};
