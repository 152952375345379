import { api } from "./api";
import * as NotificationActions from "./notification";
import { notifyError } from "./error";

const getHeatAbortControllers = {};
const abortGetHeat = heatId => getHeatAbortControllers[heatId] && getHeatAbortControllers[heatId].abort();

export const get = (event_id, heat_id, canAbort = true) =>
    dispatch => {
        abortGetHeat(heat_id);
        let signal;
        if (canAbort) {
            const controller = typeof AbortController !== "undefined" && new AbortController();
            signal = controller.signal;
            getHeatAbortControllers[heat_id] = controller;
        }
        return api.get(`heats/${heat_id}`, { signal })
            .then(res => {
                getHeatAbortControllers[heat_id] = null;
                return dispatch({
                    type: "SET_HEAT",
                    event_id,
                    heat_id,
                    data: res.data
                });
            }).catch(e => {
                getHeatAbortControllers[heat_id] = null;
                if (e.name !== "AbortError") throw e;
            });
    };

export const removeAthlete = (event_id, heat_id, athlete_id, isDivisionTeamType) =>
    dispatch =>
        api.delete(`heats/${heat_id}/athletes/${athlete_id}`)
            .then(() => dispatch(get(event_id, heat_id, false)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success(isDivisionTeamType ? "Sad face. Team removed from the heat." : "Sad face. Athlete removed from the heat.")));

export const addAthlete = (event_id, heat_id, athlete_heat, isDivisionTeamType, event_division_id) =>
    dispatch =>
        api.post(`heats/${heat_id}/add_athlete`, { athlete_heat, event_division_id })
            .then(() => dispatch(get(event_id, heat_id, false)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success(isDivisionTeamType ? "Aren't you nice! Team added to the heat." : "Aren't you nice! Athlete added to the heat.")));

export const deleteRide = ({ event_id, heat_id, athlete_id, ride }, fetchHeatId) =>
    dispatch =>
        api.delete(`heats/${heat_id}/athletes/${athlete_id}/rides/${ride}`)
            .then(() => dispatch(get(event_id, fetchHeatId, false)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Ride successfully removed!")));

export const updateRide = ({ event_id, heat_id, athlete_heat_id, team_position, athlete_id, ride_number, ride }, fetchHeatId) =>
    dispatch =>
        api.put(`heats/${heat_id}/athletes/${athlete_id}/rides/${ride_number}`, { athlete_heat_id, team_position, ride })
            .then(() => dispatch(get(event_id, fetchHeatId, false)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success(ride.get("pending") ? "Ride approved." : "Ride updated.")));

export const addPlaceModifier = ({ event_id, heat_id, dispatch_heat_id, ride_index, dispatch_ride_index, parent_athlete_id, athlete_id, type, notes }) =>
    dispatch => {
        abortGetHeat(heat_id);
        const modifier = { athlete_id, ride: dispatch_ride_index, type: `Modifiers::${type}`, notes };
        dispatch({
            type: "ADD_HEAT_PLACE_MODIFIER",
            event_id,
            heat_id,
            ride_index,
            parent_athlete_id,
            athlete_id,
            modifierType: `Modifiers::${type}`
        });
        return api.post(`heats/${dispatch_heat_id}/modify`, { modifier });
    };

export const removePlaceModifier = ({ event_id, heat_id, dispatch_heat_id, ride_index, parent_athlete_id, athlete_id, modifier_id }) =>
    dispatch => {
        abortGetHeat(heat_id);
        dispatch({
            type: "REMOVE_HEAT_PLACE_MODIFIER",
            event_id,
            heat_id,
            ride_index,
            parent_athlete_id,
            athlete_id
        });
        return api.delete(`heats/${dispatch_heat_id}/modifier/${modifier_id}`);
    };

export const approveRides = ({ event_id, heat_id }) =>
    dispatch =>
        api.put(`heats/${heat_id}/approve_rides`)
            .then(() => dispatch(get(event_id, heat_id, false)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Sweet! Scores published ✔")));
