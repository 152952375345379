import { SimpleList } from "components/layout/lists/SimpleList";
import { Fake } from "components/layout/skeleton/Fake";

export const AthleteMembershipsPageSkeleton = () => (
    <div role="status" aria-label="loading" className="skeleton">
        <SimpleList hideLastBorder>
            {[
                { title: <Fake size={35} />, description: <Fake size={30} /> },
                { title: <Fake size={35} />, description: <Fake size={30} /> },
                { title: <Fake size={35} />, description: <Fake size={30} /> }
            ]}
        </SimpleList>
    </div>
);
