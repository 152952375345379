import { useHeatHeaderStyles } from "./heatStyles";
import { ResultRowsHeaderContent } from "./ResultRowsHeader";

export const TimingRowsHeader = ({ heat }) => {
    const classes = useHeatHeaderStyles();

    return (
        <li className={classes.headerRow}>
            <ResultRowsHeaderContent heat={heat} totalLabel="Time"/>
        </li>
    );
};
