import { useCurrentUser } from "components/providers/CurrentUserProvider";
import { useApolloClient } from "@apollo/client";
import { GET_FEDERATION_TEAMS } from "graphql/organisation";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "hooks/usePrevious";

export const useGetTeams = (query = GET_FEDERATION_TEAMS) => {
    const { currentUser } = useCurrentUser();
    const client = useApolloClient();

    const observable = useRef();

    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [resultsPerPage, setResultsPerPage] = useState();

    const previousData = usePrevious(data);

    useEffect(() => {
        return () => observable.current?.unsubscribe();
    }, []);

    return {
        loading,
        items: data?.federationTeams.teams || previousData?.federationTeams.teams || [],
        totalCount: data?.federationTeams.totalCount,
        resultsPerPage,
        search: (value, page = 1, per = 100) => {
            setLoading(true);
            setResultsPerPage(per);
            observable.current?.unsubscribe();
            observable.current = client.watchQuery({
                query,
                variables: { id: currentUser.organisation_id, search: value, page, per },
                fetchPolicy: "cache-and-network"
            }).subscribe({
                next: ({ data }) => {
                    setData(data);
                    setLoading(false);
                }
            });
        }
    };
};
