import { Check } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

import { useBaseInputStyles } from "./baseInputStyles";
import classnames from "classnames";

export const useCheckboxStyles = makeStyles(theme => ({
    wrapper: {
        position: "relative",
        display: "inline-flex",
        cursor: "pointer"
    },
    input: {
        "&&": {
            width: 24,
            height: 24,
            borderRadius: 4
        },
        cursor: "pointer",
        margin: 3, // override user-agent style 3px 3px 3px 4px
        "&:disabled": {
            opacity: .6,
            cursor: "not-allowed"
        },
        "&:checked": {
            backgroundColor: theme.palette.input.hover,
            border: "none"
        },
        "&:focus-within": {
            outlineOffset: "2px",
            "&&": {
                outlineWidth: "3px",
            }
        }
    },
    check: {
        color: "#fff",
        position: "absolute",
        top: 5,
        left: 5,
        "&&": {
            width: 20,
            height: 20,
            pointerEvents: "none"
        }
    }
}));
export const Checkbox = ({ field, input, hasError, Icon = Check }) => {
    const baseClasses = useBaseInputStyles();
    const classes = useCheckboxStyles();

    return (
        <div className={classes.wrapper}>
            <input
                className={classnames(baseClasses.baseInput, classes.input, { error: hasError })}
                type="checkbox"
                checked={!!input.value}
                {...input}
                {...field?.inputProps}
            />
            {input.value && <Icon className={classes.check}/>}
        </div>
    );
};
