import { Box, Button, Typography } from "@mui/material";
import { T } from "../../util/t";
import { Section } from "./HomePage";

export const OrganiserPromoCard = () => (
    <Section paddingX={2}>
        <Box backgroundColor="background.low" borderRadius={1} padding={2}>
            <Typography variant="h3" pb={2}><T>org_promo_title</T></Typography>
            <Typography variant="subtitle2" pb={1.5}><T>Power up your action sports club, organisation or federation with online registrations, heat draws, live scoring and rankings.</T></Typography>
            <Button component="a" href="https://organiser.liveheats.com" target="_blank" variant="contained" color="primary" >Learn more</Button>
        </Box>
    </Section>
);
