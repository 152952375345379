import Immutable from "immutable";

import createStore from "../createStore";
import { hostToLanguage } from "./languageUtil";
import translations from "./translations";

const lang = hostToLanguage();

export const reducer = createStore({
    SET_LANGUAGE: (state, action) => {
        return state.set("language", action.language);
    },
}, Immutable.Map({
    language: lang,
    translations: Immutable.Map(translations),
}));
