import { NavLink as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles((theme) => ({
    nav: {
        alignItems: "center",
        color: theme.palette.text.primary,
        opacity: 0.8,

        "&:visited": {
            color: theme.palette.text.primary
        },

        "&:hover": {
            opacity: 1
        },

        "&.active": {
            opacity: 1,
            fontWeight: 700
        },

        ".MuiSvgIcon-root": {
            marginLeft: theme.spacing(0.5)
        }
    }
}));

export const NavLink = (props) => {
    const classes = useStyles();

    return (
        <Link className={classes.nav} variant="subtitle2" underline="none" component={RouterLink} exact {...props} />
    );
};
