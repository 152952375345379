import { MaxWidthPageWithHeading } from "../../../layout/page/MaxWidthPageWithHeading";
import { Box, Typography } from "@mui/material";
import { T } from "../../../util/t";
import { Fake } from "../../../layout/skeleton/Fake";

export const PlanPageSkeleton = () =>
    <MaxWidthPageWithHeading title="Plan and billing">
        <Box className="skeleton" role="status" aria-label="loading">
            <Box borderTop="1px solid" borderColor="border.light" py={3}>
                <Box marginBottom={6}>
                    <Typography variant="h5" component="h2" marginBottom={1}><T>My credits</T></Typography>
                    <Box py={3} px={2} sx={{ border: "2px solid", borderRadius: 1, borderColor: "border.light" }}>
                        <Typography variant="h7"><Fake size={27}/></Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </MaxWidthPageWithHeading>;
