import { judgeTablePagesPath } from "components/application";
import { makeStyles } from "components/providers/makeStyles";
import { useEvent } from "components/routes/events/id/EventProvider";
import { useRouteMatch } from "react-router";
import { EventRemoteControl } from "../event/RemoteControl";
import { SideNav } from "components/layout/page/SideNav";
import { RemoteControlSpacer } from "../event/RemoteControlSpacer";
import { AdminPageProvider } from "./AdminPageProvider";
import { convertToJS } from "components/eventDivisions/helpers";

const useStyles = makeStyles((theme, { restrictWidth }) => ({
    container: {
        display: "flex",
        maxWidth: restrictWidth && "100vw",
        flex: 1,
    },
    main: {
        flexGrow: 1,
        minWidth: 0
    }
}));

export const AdminPageContainer = ({ children }) => {
    const isPageWithJudgeTable = useRouteMatch(judgeTablePagesPath);
    const isSchedulePage = useRouteMatch("/events/:id/schedule/edit");
    const isPriorityJudgePage = useRouteMatch("/events/:id/priority_judge");
    const classes = useStyles({ restrictWidth: !(isPageWithJudgeTable || isSchedulePage) });
    const event = convertToJS(useEvent());
    const isPriorityJudgePageEnabled = isPriorityJudgePage && event?.priorityEnabled;
    const hideRemote = !event || !Object.keys(event).length || event.config?.disableSchedule || isPriorityJudgePageEnabled;

    return (
        <AdminPageProvider>
            <div className={classes.container}>
                {!isPriorityJudgePageEnabled && <SideNav />}

                <main className={classes.main}>
                    {children}
                    {!hideRemote && <RemoteControlSpacer />}
                </main>

                {!hideRemote && <EventRemoteControl />}
            </div>
        </AdminPageProvider>
    );
};
