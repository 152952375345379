import { PaymentDetails } from "../../../../layout/payments/PaymentDetails";
import { PurchaseDetails } from "../../../../layout/payments/PurchaseDetails";
import { makeStyles } from "../../../../providers/makeStyles";
import { Heading } from "../../../../layout/page/Heading";
import { T } from "../../../../util/t";
import { useQuery } from "@apollo/client";
import { GET_PAYMENT_DETAILS } from "../../../../../graphql/payment";
import { useParams } from "react-router-dom";
import { PaymentStatusPill } from "../../../../layout/payments/PaymentStatusPill";
import { RefundDialog } from "../../../../layout/payments/RefundDialog";
import { PaymentDetailsPageSkeleton } from "./PaymentDetailsPageSkeleton";
import { useGoBack } from "../../../../../hooks/useGoBack";

export const useDetailsSectionStyles = makeStyles(theme => ({
    header: {
        aside: {
            alignItems: "center",
        }
    },
    detailsSection: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
        "& > *" : {
            padding: theme.spacing(1),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "50%"
            },
            "& h5": {
                marginBottom: theme.spacing(4),
            }
        }
    }
}));

export const PaymentDetailsPage = () => {
    const goBack = useGoBack("/director/payments");

    const detailsClasses = useDetailsSectionStyles();
    const { id } = useParams();
    const { data } = useQuery(GET_PAYMENT_DETAILS, { variables: { id: id }, fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }),
        payment = data?.payment;

    if (!payment) return <PaymentDetailsPageSkeleton />;

    return (
        <>
            <Heading
                className={detailsClasses.header}
                title={
                    <>
                        <T>Payment details</T>: {payment.user.name}
                        <PaymentStatusPill payment={payment} />
                    </>
                }
                returnTo={goBack}
                actions={<RefundDialog payment={payment}/>}
                borderless
            />
            <section className={detailsClasses.detailsSection}>
                <PaymentDetails payment={payment} />
                <PurchaseDetails />
            </section>
        </>
    );
};
export default PaymentDetailsPage;
