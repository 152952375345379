import { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useRefocusEffect } from "../../../hooks/useRefocusEffect";
import { GET_EVENT_DIVISION_LEADERBOARD } from "../../../graphql/eventDivision";
import { HeatLeaderboardBreakdown } from "./HeatLeaderboardBreakdown";
import { CompactHeatHeader } from "./CompactHeatHeader";
import { FullHeatHeader } from "./FullHeatHeader";
import { StartlistRidesResultOrLeaderboardSelector } from "./StartlistRidesResultOrLeaderboardSelector";
import { HeatSummary } from "./HeatSummary";
import { Leaderboard } from "../leaderboard/Leaderboard";
import { LeaderboardSkeleton } from "../leaderboard/LeaderboardSkeleton";
import { RidesTable } from "./RidesTable";
import { HeatSummaryWithRides } from "./HeatSummaryWithRides";

export const HeatBreakdown = ({ heat, heatsInRound, closeModal }) => {
    const judgingContest = heat.config.calculator !== "placing" && heat.config.calculator !== "timing",
        showForTeams = !!heat.config.isTeams && heat.config.teamConfig.appraisalLevel === "individual" && judgingContest;
    const showResults = !!heat.startTime && !heat.config.hideScores;
    const { hasStartlist, hasLeaderboard, usesLanes } = heat.config;
    const [selected, setSelected] = useState(hasStartlist ? showResults ? "result" : "startlist" : "result");
    const showRides = showResults && heat.config.calculator !== "placing" && (heat.config.calculator !== "timing" || heat.config.totalCountingRides !== 1);
    const renderRidesTab = showRides && heat.result.length > 8;

    const [getLeaderboard, { data, refetch }] = useLazyQuery(GET_EVENT_DIVISION_LEADERBOARD, { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" });
    const leaderboard = data?.eventDivision.leaderboards?.[0];

    useRefocusEffect(refetch, selected === "leaderboard");

    const handleSetSelected = useCallback(selected => {
        setSelected(selected);
        if (selected === "leaderboard") {
            getLeaderboard({ variables: { id: heat.eventDivision.id, round: heat.roundPosition } });
        }
    }, [setSelected, getLeaderboard, heat]);

    return (
        <HeatLeaderboardBreakdown
            heat={heat}
            compactHeader={<CompactHeatHeader heat={heat} displayNumber={heatsInRound > 1} sticky />}
            fullHeader={<FullHeatHeader heat={heat} heatsInRound={heatsInRound} closeModal={closeModal}/>}
            closeModal={closeModal}
            noMarginRight={selected === "rides" || selected === "result"}
            selector={((hasStartlist && showResults) || hasLeaderboard || renderRidesTab) && <StartlistRidesResultOrLeaderboardSelector selected={selected} setSelected={handleSetSelected} showResults={showResults} renderRidesTab={renderRidesTab} hasLeaderboard={hasLeaderboard} hasStartlist={hasStartlist} usesLanes={usesLanes}/>}
        >
            {selected === "startlist" && <HeatSummary heat={{ ...heat, startTime: null }} inDialog/>}
            {selected === "rides" && <RidesTable heat={heat} renderAthleteHeader/>}
            {selected === "result" && <HeatSummaryWithRides heat={heat} renderRides={(showRides && !renderRidesTab) || showForTeams} inDialog/>}
            {selected === "leaderboard" && (leaderboard ? <Leaderboard leaderboard={leaderboard}/> : <LeaderboardSkeleton/>)}
        </HeatLeaderboardBreakdown>
    );
};
