import { useEffect, useState } from "react";

export const useVisualViewportHeight = () => {
    const [visualViewportHeight, setVisualViewportHeight] = useState(window.visualViewport?.height);

    useEffect(() => {
        const onResize = e => setVisualViewportHeight(e.target.height);
        window.visualViewport?.addEventListener("resize", onResize);

        return () => window.visualViewport?.removeEventListener("resize", onResize);
    }, []);

    return visualViewportHeight;
};
