import { makeStyles } from "../../providers/makeStyles";

export const useNFCTagViewStyles = makeStyles(theme => ({
    empty: {
        textAlign: "center",
        margin: theme.spacing(2,0,5,0),
    },
    athlete: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(3, 0, 5, 0),
    },
    avatar: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(2),
        textAlign: "center",
    },
}));
