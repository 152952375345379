import { keyframes } from "@mui/system";
import { makeStyles } from "components/providers/makeStyles";

const sizes = {
    sm: 1,
    md: 2
};

const useStyles = makeStyles((theme, { animated, size }) => ({
    root: {
        display: "block",
        width: theme.spacing(sizes[size]),
        height: theme.spacing(sizes[size]),
        margin: theme.spacing(0.125),
        backgroundColor: theme.palette.status.livePulse,
        borderRadius: "50%",
        animation: animated && `${keyframes`
        0% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 ${theme.palette.status.livePulseShadow}80;
        }
        70% {
            transform: scale(1);
            box-shadow: 0 0 0 ${theme.spacing(0.5)} ${theme.palette.status.livePulseShadow}00;
        }
        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 ${theme.palette.status.livePulseShadow}00;
        }
    `} 1.5s infinite`
    },
}));

export const LiveIndicator = ({ animated = true, size = "sm" }) => {
    const classes = useStyles({ animated, size });
    return <span className={classes.root} data-testid="LiveIndicator" />;
};
