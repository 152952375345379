import { FakeEvent } from "../OrganisationRootSkeleton";
import { Typography } from "@mui/material";
import { Fake } from "../../../layout/skeleton/Fake";
import { useOrganisationStyles } from "../../../layout/organisation/useOrganisationStyles";

export const EventsCalendarPageSkeleton = () => {
    const classes = useOrganisationStyles();

    return <div className="skeleton">
        <section className={classes.section}>
            <Typography variant="h5" className={classes.title}><Fake size={43}/></Typography>
            <div className={classes.events}>
                <FakeEvent size={56}/>
                <FakeEvent size={32}/>
            </div>
        </section>
        <section className={classes.section}>
            <Typography variant="h5" className={classes.title}><Fake size={63}/></Typography>
            <div className={classes.events}>
                <FakeEvent size={43}/>
                <FakeEvent size={29}/>
                <FakeEvent size={32}/>
            </div>
        </section>
    </div>;
};

