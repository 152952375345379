import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as EventDivisionActions from "../../actions/eventDivision";
import * as NotificationActions from "../../actions/notification";

import { useEventChannel } from "../routes/events/id/EventProvider";
import { EventDivisionProvider } from "../routes/events/id/divisions/eventDivisionId/EventDivisionProvider";
import { EventDivisionDirectorHeader } from "../routes/events/id/divisions/eventDivisionId/header/EventDivisionDirectorHeader";
import { makeStyles } from "../providers/makeStyles";
import { PaddedPage } from "../layout/page/PaddedPage";
import { EventDivision } from "../../models/EventDivision";
import { convertToJS } from "./helpers";
import { useBindToChannelEvent } from "../providers/Realtime/RealtimeProvider";

const useEventDivisionStyles = makeStyles(theme => ({
    container: {
        paddingBottom: theme.spacing(5)
    }
}));
export const EventDivisionRoot = ({ history, match: { params: { event_division_id } }, children }) => {
    const dispatch = useDispatch();
    const classes = useEventDivisionStyles();

    useEffect(() => {
        dispatch(EventDivisionActions.get(event_division_id))
            .catch(e => {
                if (e.response && e.response.status === 404) {
                    dispatch(NotificationActions.warn("It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?"));
                    return history.replace("/organisations");
                }
                throw e;
            });
    }, []);

    useBindToChannelEvent(useEventChannel(), "event-division-updated", ({ id: messageId }) => {
        if (event_division_id === `${messageId}`) dispatch(EventDivisionActions.get(event_division_id));
    });

    const eventDivision = useSelector(({ eventDivisions }) => eventDivisions.get(parseInt(event_division_id)));
    const eventDivisionObject = eventDivision ? EventDivision(convertToJS(eventDivision)) : eventDivision;

    return (
        <EventDivisionProvider value={{ eventDivision, eventDivisionObject }}>
            <EventDivisionDirectorHeader/>
            <PaddedPage className={classes.container} >
                {children}
            </PaddedPage>
        </EventDivisionProvider>
    );
};
