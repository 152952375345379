import { Typography } from "@mui/material";
import { AvatarWithSideContent } from "../avatar/AvatarWithSideContent";

export const AthleteInfo = ({ athlete, bib, teamName, jersey, runBased, priority }) => (
    <AvatarWithSideContent
        athlete={athlete}
        bib={bib}
        jersey={jersey}
        priority={priority}
        runBased={runBased}
        title={<Typography variant="subtitle3">{athlete.name}</Typography>}
        subtitle={teamName && <Typography variant="body2" color="textSecondary">{teamName}</Typography>}
    />
);
