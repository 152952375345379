import { makeStyles } from "../../../providers/makeStyles";

export const usePriorityTableStyles = makeStyles(theme => ({
    table: {
        borderSpacing: 0,
        width: "100%",
        "& thead th": {
            textAlign: "left",
            paddingBottom: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.border.main}`
        },
        "& tbody td": {
            padding: 0,
            borderBottom: `1px solid ${theme.palette.border.main}`,
        }
    },
    row: {
        borderBottom: `1px solid ${theme.palette.border.main}`
    },
    heatTitle: {
        marginBottom: theme.spacing(4)
    },
    priority: {
        "&&": {
            width: theme.spacing(10),
            textAlign: "center",
        }
    },
    center: {
        textAlign: "center"
    }
}));
