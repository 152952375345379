import { useEvent } from "../EventProvider";
import { useCallback, useMemo } from "react";
import { useRecordNextPlace } from "../../../../layout/heat/edit/useRecordNextPlace";
import { GroupEventDivisionSelector } from "../../../../layout/eventDivision/GroupEventDivisionSelector";
import { AutoProgressButton } from "../../../../layout/forms/fields/AutoProgressButton";
import { T } from "../../../../util/t";
import { useAddAthleteAndRecord } from "./useAddAthleteAndRecord";

export const AddToHeatAndRecordButton = ({ heat, nextPlace, resetCompetitors, athlete, tagId, setDisappearingEvent, addToDivision, setAddToDivision }) => {
    const event = useEvent();
    const eventDivisions = useMemo(() =>
        event.get("event_divisions").find(ed => ed.get("id") === heat.get("event_division_id")).get("event_divisions"),
    [event, heat]);
    const heatId = heat.get("id");
    const recordNextPlace = useRecordNextPlace({ heatId, nextPlace });
    const position = useMemo(() => (heat.get("athletes").map(a => a.get("position")).max() + 1) || 0, [heat]);
    const addAthleteAndRecord = useAddAthleteAndRecord({ heatId, position, setDisappearingEvent, recordNextPlace, resetCompetitors });

    const handleAddAthleteAndRecord = useCallback((eventDivisionId) =>
        addAthleteAndRecord(athlete.id, tagId, eventDivisionId ).then(() => setAddToDivision(false)),
    [recordNextPlace, heatId, athlete, tagId, position]);

    const onAddAnyway = useCallback(() =>
        eventDivisions.size > 0 ? setAddToDivision(true) : handleAddAthleteAndRecord(),
    [useAddAthleteAndRecord, eventDivisions.size]);

    if (addToDivision) return <GroupEventDivisionSelector onSubmit={handleAddAthleteAndRecord} eventDivisions={eventDivisions}/>;
    return <AutoProgressButton variant="contained" onClick={onAddAnyway}><T>Add anyway</T></AutoProgressButton>;
};
