import { makeStyles } from "components/providers/makeStyles";

export const usePriorityStyles = makeStyles((theme, { totalPositions }) => ({
    container: {
        height: "100vh",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "stretch"
    },
    position: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&&": {
            fontSize: [`${100 / totalPositions / 1.25}vw`, `min(${100 / totalPositions / 1.25}vw, 75vh)`],
        },
        transition: theme.transitions.create(["color", "background"], { duration: theme.transitions.duration.standard }),
        borderRight: `1vw solid ${theme.palette.text.primary}`,
        borderLeft: `1vw solid ${theme.palette.text.primary}`,
        "&:first-of-type": {
            borderLeft: 0,
        },
        "&:last-of-type": {
            borderRight: 0,
        }
    }
}));
