import { makeStyles } from "components/providers/makeStyles";
import { T } from "../util/t";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "fixed",
        top: 0,
        left: "50%",
        width: 0,
        margin: theme.spacing(1, 0, 0),
        zIndex: 10000,
        "@media print": {
            display: "none"
        },
    },
    notification: {
        position: "relative",
        top: `-${theme.spacing(7)}`,
        opacity: 0,
        width: "600px",
        padding: theme.spacing(0),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.success.light,
        textAlign: "center",
        borderRadius: theme.spacing(1),
        transform: "translateX(-50%)",
        transition: "top 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
        },
        [theme.breakpoints.down("md")]: {
            width: "90vw"
        },
        "&.warn": {
            backgroundColor: theme.palette.warn.light
        },
        "&.error": {
            backgroundColor: theme.palette.error.light
        },
        "&.info": {
            backgroundColor: theme.palette.info.light
        },
        "&.open": {
            top: 0,
            opacity: 1,
            transition: "top 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        },
        "a": {
            textDecoration: "underline"
        },
        ".content": {
            padding: theme.spacing(1, 4.5, 1, 2)
        }
    },
    close: {
        position: "absolute",
        right: theme.spacing(0.75),
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }
}));

export const ActionNotification = ({ type, text, open, handleClose }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={`${classes.notification} ${type} ${open ? "open" : ""}`}>
                <div className="content">
                    <T>{text}</T>
                </div>
                <div className={classes.close}>
                    <IconButton size="small" onClick={handleClose}><Close fontSize="small"/></IconButton>
                </div>
            </div>
        </div>
    );
};
