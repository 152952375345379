export const palette = {
    brand: {
        primary: "#FA462E",
        secondary: "#DBFA20",
        tertiary: "#020303"
    },
    primary: {
        light: "#E8EAED",
        main: "#17191C",
        dark: "#020303",
        contrastText: "#FCFCFD",
    },
    secondary: {
        lighter: "#F8FFD1",
        light: "#EFFF94",
        main: "#DBFA20",
        dark: "#8FA800",
        contrastText: "#1A1F00",
    },
    tertiary: {
        lighter: "#FFEDEB",
        light: "#FB6956",
        main: "#FA462E",
        dark: "#E52006",
        contrastText: "#E9FF6B",
    },
    text: {
        primary: "#17191C",
        secondary: "#3D444D",
        light: "#636F7D",
        link: "#17191C",
        lighter: "#909BA7",
        highlight: "#0C61FF",
        contrastText: "#FCFCFD",
    },
    neutral: {
        main: "#E8EAED"
    },
    info: {
        light: "#B3EBFF",
        main: "#0096CC"
    },
    success: {
        lighter: "#DAF6DF",
        light: "#B1ECBB",
        main: "#57D66C",
        dark: "#249437",
        contrastText: "#0C3112",
    },
    warn: {
        light: "#FFD29E",
        main: "#FFBA6B",
    },
    error: {
        lightest: "#FFF5F5",
        lighter: "#FFE5E5",
        light: "#FFCCCC",
        main: "#D60000",
        dark: "#850000",
    },
    border: {
        light: "#DADEE2",
        main: "#909BA7",
        selected: "#3D444D",
        focus: "#496588",
    },
    background: {
        default: "#FCFCFD",
        dark: "#0F1010",
        paper: "#FFFFFF",
        mid: "#E8EAED",
        low: "#F4F5F6",
        lowest: "#FCFCFD",
        high: "#DADEE2"
    },
    input: {
        selected: "#020303",
        border: "#909BA7",
        hover: "#17191C",
        focus: "#636F7D",
        error: "#D60000",
        success: "#249437",
    },
    random: {
        toolTip: "#242934",
        fb: "#3b5998"
    },
    state: {
        action: "#909BA726",
        tapLight: "#E8EAED4D",
        tapDark: "#0F10101A"
    },
    status: {
        on: "#E2FF43",
        onText: "#303800",
        onBorder: "#DBFA20",
        on_hold: "#FDB4AA",
        on_holdText: "#1E0401",
        upcoming: "#B3EBFF",
        upcomingText: "#003EB3",
        finished: "#E8EAED",
        finishedText: "#3D444D",
        cancelled: "#F4F5F6",
        cancelledText: "#636F7D",
        success: "#90E49E",
        successText: "#0C3112",
        open: "#D4BAF2",
        openText: "#311056",
        livePulse: "#BEE000",
        livePulseShadow: "#000000",
        draft: "#FCFCFD",
        draftBorder: "#DADEE2"
    },
    jerseys: {
        red: {
            main: "#FF0000",
            dark: "#AD0000",
            priorityBackground: "#FF3333",
            light: "#FFCCCC",
            contrast: "#000000"
        },
        blue: {
            main: "#004FE0",
            dark: "#003EB3",
            priorityBackground: "#3D81FF",
            light: "#CCDEFF",
            contrast: "#000000"
        },
        yellow: {
            main: "#FAE90F",
            dark: "#827103",
            priorityBackground: "#FAE90F",
            light: "#FEFBE6",
            contrast: "#000000"
        },
        white: {
            main: "#E6E6E6",
            dark: "#000000",
            priorityBackground: "#FFFFFF",
            light: "#FFFFFF",
            contrast: "#000000"
        },
        green: {
            main: "#57D66C",
            dark: "#1C732A",
            priorityBackground: "#57D66C",
            light: "#DAF6DF",
            contrast: "#000000"
        },
        pink: {
            main: "#FF42B8",
            dark: "#FF0AA1",
            priorityBackground: "#FF42B8",
            light: "#FFEBF7",
            contrast: "#000000"
        },
        black: {
            main: "#000000",
            dark: "#000000",
            priorityBackground: "#000000",
            light: "#FCFCFD",
            contrast: "#FFFFFF"
        },
        orange: {
            main: "#FF8A07",
            dark: "#B35F00",
            priorityBackground: "#FF8A07",
            light: "#FFE7CC",
            contrast: "#000000"
        },
        purple: {
            main: "#9352DE",
            dark: "#491881",
            priorityBackground: "#AA76E5",
            light: "#F4EEFC",
            contrast: "#000000"
        }
    },
    highlights: {
        entry_open: "#E5F8FF",
        membership_open: "#F4EEFC",
        event_live: "#F8FFD1",
        results_published: "#F4F5F6"
    }
};
