export const variants = [
    "hero",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "h7",
    "subtitle1",
    "subtitle2",
    "subtitle3",
    "body1",
    "bodyMono",
    "bodyMono2",
    "body2",
    "caption",
    "button",
    "button2",
    "button3",
    "button4",
    "label1",
    "label2",
    "brandBody1",
    "brandBody2",
    "brandLabel1",
    "brandSubheadingH1",
    "brandSubheadingH1Light",
    "brandSubheadingH3Light",
    "brandSubheadingH4Light",
    "brandSubtitle1",
    "highlightXl"
];

export const typography = {
    hero: {
        fontFamily: "'Inter', sans-serif",
        fontWeight: 800,
        fontSize: 64,
        lineHeight: 80 / 64
    },
    h1: {
        fontFamily: "'Archivo', sans-serif",
        fontWeight: 600,
        fontSize: 48,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    h2: {
        fontFamily: "'Archivo', sans-serif",
        fontWeight: 600,
        fontSize: 40,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    h3: {
        fontFamily: "'Archivo', sans-serif",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    h4: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: 28,
        fontWeight: 600,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    h5: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: 24,
        fontWeight: 600,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },

    // this variant is used in the Mui Dialog titles, so we can't change it without affecting all the existing dialogs
    h6: {
        fontFamily: "'Archivo', sans-serif",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    // this is our h6
    h7: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: 21,
        fontWeight: 600,
        lineHeight: 1.05,
        letterSpacing: "-0.01rem",
    },
    subtitle1: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 21,
        fontWeight: 500,
        lineHeight: 32 / 21,
    },
    subtitle2: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 24 / 18,
        letterSpacing: 0,
    },
    button3: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 18,
        lineHeight: 24 / 18,
        fontWeight: 600
    },
    label1: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 16,
        lineHeight: 24 / 16,
        fontWeight: 600
    },
    subtitle3: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 16,
        lineHeight: 21 / 16,
        fontWeight: 500
    },
    body1: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 21 / 16,
    },
    bodyMono: {
        fontFamily: "'Roboto Mono', monospace",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 21 / 16,
    },
    bodyMono2: {
        fontFamily: "'Roboto Mono', monospace",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 18 / 14,
    },
    body2: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 21 / 14
    },
    label2: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 13,
        lineHeight: 18 / 13,
        fontWeight: 600
    },
    caption: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 13,
        lineHeight: 16 / 13,
    },
    button: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 16,
        lineHeight: 21 / 16,
        fontWeight: 600,
        textTransform: "none"
    },
    button2: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 14,
        lineHeight: 1,
        fontWeight: 600
    },
    button4: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 7,
        lineHeight: 8 / 7,
        fontWeight: 600
    },
    brandBody1: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: "1rem",
        lineHeight: 1.3,
        fontWeight: 400,
        letterSpacing: "0.02rem",
    },
    brandBody2: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: "0.875rem",
        lineHeight: 1.3,
        fontWeight: 400,
        letterSpacing: "0.02rem",
    },
    brandLabel1: {
        fontFamily: "'Archivo', sans-serif",
        fontSize: "1rem",
        fontWeight: 600,
        letterSpacing: "0.02rem",
    },
    brandSubheadingH1: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: "3rem",
        lineHeight: 1,
        fontWeight: 600,
        letterSpacing: "-0.02rem",
    },
    brandSubheadingH1Light: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: "3rem",
        lineHeight: 1,
        fontWeight: 400,
        letterSpacing: "-0.02rem",
    },
    brandSubheadingH3Light: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: "2rem",
        lineHeight: 1,
        fontWeight: 400,
        letterSpacing: "-0.02rem",
    },
    brandSubheadingH4Light: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: "1.75rem",
        lineHeight: 1,
        fontWeight: 400,
        letterSpacing: "-0.02rem",
    },
    brandSubtitle1: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: "1.3125rem",
        lineHeight: 1,
        fontWeight: 400,
        letterSpacing: "-0.02rem",
    },
    highlightXl: {
        fontFamily: "'Inter', sans-serif",
        fontSize: "1.75rem",
        fontWeight: 600,
        letterSpacing: "-0.04rem",
        lineHeight: 1.3125, // default, required for responsiveFontSizes
    },
    priorityScreen: {
        fontFamily: "'Inter', sans-serif",
        fontWeight: 800,
        lineHeight: 1
    }
};
