import { NoSearchResults } from "../../../search/search";
import { SearchTakeoverDialog, SearchTakeoverDialogContent } from "../../forms/SearchTakeoverDialog";
import { useHeatCompetitorsFilter } from "../../../../hooks/useHeatCompetitorsFilter";
import { SpotPlacing } from "./SpotPlacing";
import { T } from "../../../util/t";
import { useCompetitorsToRecord } from "./useCompetitorsToRecord";

export const HeatFilterAndPlace = ({ heat, spots }) =>
    <SearchTakeoverDialog
        DialogComponent={FilterAndPlaceDialog}
        dialogProps={{ heat, spots }}
        inputPlaceholder="Filter and place"
        dialogTitleId="heat-filter-and-place-dialog-title"
    />;

export const FilterAndPlaceDialog = ({ heat, spots: immutableSpots, closeDialog }) => {
    const { competitors, nextPlace } = useCompetitorsToRecord({ immutableSpots, heat });
    const { inputRef, searchString, onSearch, clearSearch, filteredCompetitors } = useHeatCompetitorsFilter(competitors);

    return (
        <SearchTakeoverDialogContent
            dialogTitleId="heat-filter-and-place-dialog-title"
            searchRef={inputRef}
            search={searchString}
            onSearch={onSearch}
            closeDialog={closeDialog}
        >
            {!!filteredCompetitors.length ?
                filteredCompetitors.map(competitor =>
                    <SpotPlacing key={competitor.athleteId} heat={heat} competitor={competitor} nextPlace={nextPlace}/>
                ) :
                <NoSearchResults
                    noResultsText={<T>Sorry, we can't seem to find that athlete 😞</T>}
                    clearResultsText={<T>Clear your search to view all athletes</T>}
                    onClear={clearSearch}
                />
            }
        </SearchTakeoverDialogContent>
    );
};
