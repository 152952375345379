import { FormKit } from "components/forms/FormKit";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        "& form": {
            textAlign: "left",
            "& > div": {
                marginBottom: theme.spacing(-2), // hide gap caused by empty error field
            },
            "& button": {
                marginBottom: theme.spacing(3),
            }
        }
    }
}));

export const ScanDialogForm = ({ handleSubmit, fields, buttonLabel = "Confirm" }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FormKit fields={fields} onSubmit={handleSubmit} buttonLabel={buttonLabel}/>
        </div>
    );
};
