import { makeStyles } from "components/providers/makeStyles";

import { FakeFormField } from "../../../layout/forms/FakeFormField";
import { FormSection } from "../../../layout/forms/FormSection";
import { AvatarOrInitials } from "../../../layout/avatar/AvatarOrInitials";

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(2.5, 0)
    },
    avatar: {
        backgroundColor: theme.palette.background.low
    }
}));
export const EditOrganisationPageSkeleton = () => {
    const classes = useStyles();

    return (
        <div className="skeleton">
            <FormSection className="skeleton" title="Organisation info">
                <section className={classes.wrapper}>
                    <AvatarOrInitials className={classes.avatar} size="xxl" />
                </section>
                <FakeFormField label={35} field={152}/>
                <FakeFormField label={20} field={152}/>
                <FakeFormField label={39} field={152}/>
                <FakeFormField label={27} field={152}/>
                <FakeFormField label={28} field={152}/>
            </FormSection>
        </div>
    );
};

