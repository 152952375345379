import { useIsMobile } from "hooks/useIsMobile";
import { ModalContentMobile } from "./ModalContentMobile";
import { ModalContentDesktop } from "./ModalContentDesktop";

export const DesktopOrMobileModal = ({ open, closeModal, children, ...rest }) => {
    const isMobile = useIsMobile();
    const Container = isMobile ? ModalContentMobile : ModalContentDesktop;

    return (
        <Container open={open} closeModal={closeModal} {...rest}>
            {children}
        </Container>
    );
};
