import { useFormState } from "react-hook-form";
import { ProgressButton } from "./fields/ProgressButton";
import { T } from "../../util/t";

export const SubmitButton = ({ className, control, buttonLabel, endIcon, fullWidth, enableOnPristine }) => {
    const { isDirty, isSubmitting } = useFormState({ control });

    return (
        <ProgressButton
            className={className}
            fullWidth={fullWidth}
            variant="contained"
            disabled={(!isDirty && !enableOnPristine) || isSubmitting}
            type="submit"
            inProgress={isSubmitting}
            endIcon={endIcon}
        >
            <ButtonLabel label={buttonLabel} control={control} />
        </ProgressButton>
    );
};

const ButtonLabel = ({ label, control }) => {
    if (typeof label === "function") {
        const Component = label;
        return <Component control={control} />;
    };
    return <T>{label}</T>;
};
