import EventEmailAthletesContainer from "./container/EmailAthletesContainer";
import { EmailAthletesNewMessagePageSkeleton } from "./new/EmailAthletesNewMessagePageSkeleton";
import { EmailAthletesSentMessagePageSkeleton } from "./sent/EmailAthletesSentMessagePageSkeleton";
import { useLocation } from "react-router-dom";

export const EmailAthletesRootSkeleton = () => {
    const isSentPage = Boolean(useLocation().pathname.match(/sent/)?.[0]);

    return (
        <EventEmailAthletesContainer>
            { isSentPage ? <EmailAthletesSentMessagePageSkeleton /> :
                <EmailAthletesNewMessagePageSkeleton /> }
        </EventEmailAthletesContainer>  
    );
    
};
