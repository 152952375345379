import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";

const useSimpleListStyles = makeStyles((theme, { borderBottom, padding = 2, onClick, disabled, highlightOnHover }) => ({
    limitContent: {
        display: "-webkit-box",
        WebkitLineClamp: "1" ,
        lineClamp: "1",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        wordBreak: "break-all"
    },
    item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: theme.spacing(padding),
        paddingBottom: theme.spacing(padding),
        borderBottom: borderBottom && `${borderBottom}px solid`,
        borderColor: theme.palette.border.light,
        cursor: onClick && !disabled ? "pointer" : "inherit",
        opacity: disabled ? 0.5 : 1,
        "@media (hover: hover)": {
            "&:hover": {
                background: highlightOnHover && theme.palette.tertiary.lighter,
            }
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
    },
    aside: {
        flexShrink: 0,
    }
}));

export const SimpleList = ({ children, hideLastBorder, variant = "medium" }) => (
    <Stack component="ul" margin={0} padding={0}>
        {children.map((item, i) =>
            item.to ?
                <SimpleListItemLink {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} /> :
                <SimpleListItem {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} />
        )}
    </Stack>
);


export const SimpleListItemLink = ({ to, target, rel, ...rest }) => (
    <Link to={to} target={target} rel={rel}>
        <SimpleListItem {...rest} aside={rest.aside || <ChevronRight />} />
    </Link>
);

const SimpleListItemMedium = ({ title, subtitle, description, aside, borderBottom = 1, limitDescription = false, limitTitle = false }) => {
    const classes = useSimpleListStyles({ borderBottom });

    return (
        <li className={classes.item}>
            <div className={classes.content}>
                <Typography variant="button" component="div" className={classnames(limitTitle && classes.limitContent)}>{title}</Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                {description && <Typography color="textSecondary" marginTop={1} className={classnames(limitDescription && classes.limitContent)}>{description}</Typography>}
            </div>
            <Typography className={classes.aside} component="div">{aside}</Typography>
        </li>
    );
};

const SimpleListItemSmall = ({ subtitle, description, aside, onClick, borderBottom = 0, highlightOnHover }) => {
    const classes = useSimpleListStyles({ borderBottom, padding: 0.5, onClick, highlightOnHover });

    return (
        <li className={classes.item} onClick={onClick}>
            <div className={classes.content}>
                <Typography component="div">{subtitle}</Typography>
                <Typography color="textSecondary" variant="caption">{description}</Typography>
            </div>
            <div className={classes.aside}>{aside}</div>
        </li>
    );
};

const VARIANTS = {
    medium: SimpleListItemMedium,
    small: SimpleListItemSmall
};

export const SimpleListItem = ({ variant = "medium", ...rest }) => {
    const ListComponent = VARIANTS[variant];

    return <ListComponent {...rest} />;
};
