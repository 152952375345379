import { Avatar } from "@mui/material";
import { makeStyles } from "../providers/makeStyles";

const useStyles = makeStyles((theme) => ({
    avatar: {
        ".MuiSvgIcon-root": {
            fontSize: theme.spacing(4.5),
        },
    }
}));
export const ContainedIcon = ({ icon }) => {
    const classes = useStyles();

    return (<Avatar sx={{
        alignSelf: "center",
        width: 64,
        height: 64,
        marginBottom: 2,
        bgcolor: "secondary.light",
        color: "secondary.contrast"
    }} className={classes.avatar}>
        {icon}
    </Avatar>);
};
