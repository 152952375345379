import { Button, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import { useDialogState } from "hooks/useDialogState";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { T } from "../util/t";
import supportedBrowsers from "../../supportedBrowsers";
import { useLocation } from "react-router-dom";
import chrome from "./google-chrome-seeklogo.com.svg";
import firefox from "./firefox-quantum-seeklogo.com.svg";
import edge from "./microsoft-edge-new-2020-seeklogo.com-2.svg";
import opera from "./opera-mini-seeklogo.com.svg";
import safari from "./safari-seeklogo.com.svg";

export const isBrowserSupported = () => supportedBrowsers.test(navigator?.userAgent);

export const BrowserUpdateDialog = () => {
    const location = useLocation();
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    useEffect(() => {
        if (!isBrowserSupported() && !location.pathname.match(/^\/events\/\d+\/broadcast/)) openDialog();
    }, []);

    return (
        <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
            <BrowserUpdateDialogContent closeDialog={closeDialog} />
        </Dialog>
    );
};

const Content = styled(DialogContent)(({ theme }) => ({
    textAlign: "center",
    "h3": {
        marginBottom: theme.spacing(3)
    }
}));

const Cell = styled("a")(({ theme }) => ({
    margin: theme.spacing(4, 0, 2, 0),
    display: "inline-block",
    width: "20%",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
        width: "50%",
        margin: theme.spacing(1, 0),
        "&:first-of-type": {
            width: "100%",
            marginTop: theme.spacing(3),
        }
    },
    "& > img": {
        width: 84,
        height: 84,
        margin: theme.spacing(0.5, 0, 2, 0),
        [theme.breakpoints.down("sm")]: {
            width: 74,
            height: 74,
            marginBottom: theme.spacing(0)
        }
    },
    "&:nth-of-type(2) img, &:nth-of-type(3) img": {
        width: 90,
        height: 90,
        marginTop: theme.spacing(0),
        [theme.breakpoints.down("sm")]: {
            width: 80,
            height: 80,
        }
    }
}));

const Cta = styled(Button)({
    margin: "0 auto"
});

export const BrowserUpdateDialogContent = ({ closeDialog }) => (
    <>
        <Content>
            <Typography variant="h3"><T>Please update your browser</T></Typography>
            <Typography variant="subtitle2"><T>It seems you are using an unsupported browser. Please update to a recent version of one of the following to continue enjoying LiveHeats:</T></Typography>
            <Cell href="https://www.google.com/chrome">
                <img src={chrome} alt="Google Chrome" />
                <Typography variant="body1">Google Chrome</Typography>
            </Cell>
            <Cell href="https://www.firefox.com">
                <img src={firefox} alt="Mozilla Firefox" />
                <Typography variant="body1">Mozilla Firefox</Typography>
            </Cell>
            <Cell href="https://www.apple.com/safari">
                <img src={safari} alt="Safari" />
                <Typography variant="body1">Safari</Typography>
            </Cell>
            <Cell href="https://www.microsoft.com/windows/microsoft-edge">
                <img src={edge} alt="Microsoft Edge" />
                <Typography variant="body1">Microsoft Edge</Typography>
            </Cell>
            <Cell href="https://www.opera.com">
                <img src={opera} alt="Opera" />
                <Typography variant="body1">Opera</Typography>
            </Cell>
        </Content>

        {closeDialog && <>
            <Divider />

            <DialogActions>
                <Cta variant="contained" color="primary" onClick={closeDialog}>
                    <T>Continue anyway</T>
                </Cta>
            </DialogActions>
        </>}
    </>
);
