import { makeStyles } from "../../../providers/makeStyles";
import { Typography } from "@mui/material";
import { T } from "../../../util/t";
import { PreviewHeatCard } from "./PreviewHeatCard";
import { PreviewRoundSkeleton } from "./PreviewRoundSkeleton";
import { SelfSubmittingFormField } from "../../forms/selfSubmittingField/SelfSubmittingFormField";
import { useLocalizationContext } from "../../../providers/LocalizationProvider";
import { ProgressionField } from "./ProgressionField";
import { lazy, number, string } from "yup";

const heatSizeField = (placeholder, isTeams) => ({
    label: isTeams ? "Teams per heat" : "Athletes per heat",
    name: "heatSize",
    type: "number",
    width: "100%",
    validation: lazy(value => value === "" ? string() : number().min(1)),
    inputProps: { pattern: "[0-9]*", placeholder },
});
export const usePreviewRoundStyles = makeStyles((theme, { isSingleRound }) => ({
    section: {
        minWidth: 175,
        marginRight: theme.spacing(2),
        "&:last-of-type": {
            marginRight: 0
        }
    },
    header: {
        marginBottom: theme.spacing(3)
    },
    round: {
        marginTop: isSingleRound ? theme.spacing(3) : theme.spacing(6)
    },
    fakeForm: {
        height: theme.typography.pxToRem(74)
    }
}));
export const PreviewRound = ({ round, isTeams, onHeatSizeChange, onProgressionChange, loading, isSingleRound }) => {
    const classes = usePreviewRoundStyles({ isSingleRound });
    const numberOfCompetitors = round.heats.reduce((total, heat) => total + heat.numberOfCompetitors, 0);
    const onSubmitHeatSize = values => onHeatSizeChange({ round: round.roundPosition, size: values.heatSize });
    const { translate } = useLocalizationContext();

    return (
        <section className={classes.section}>
            <header className={classes.header}>
                <Typography variant="h7">{round.name}</Typography>
                <Typography variant="subtitle2" color="text.light">
                    <T x={numberOfCompetitors}>{isTeams ? "x_teams" : "x_athletes"}</T>
                </Typography>
            </header>

            <SelfSubmittingFormField
                field={heatSizeField(translate("divisionDefault", { defaultSize: round.defaultHeatSize }), isTeams)}
                onSubmit={onSubmitHeatSize}
                initialValue={round.heatSize}/>

            {onProgressionChange
                ? <ProgressionField round={round} onProgressionChange={onProgressionChange} translate={translate}/>
                : !isSingleRound && <div className={classes.fakeForm} role="none"/>
            }

            <div className={classes.round}>
                {loading ?
                    <PreviewRoundSkeleton numberOfHeats={round.heats.length}/> :
                    <>
                        <Typography variant="subtitle2" color="text.light"><T x={round.heats.length}>x_heats</T></Typography>
                        {round.heats.map((heat, i) =>
                            <PreviewHeatCard
                                key={`round-${round}-heat-${i}`}
                                competitorsText={<T x={heat.numberOfCompetitors}>{isTeams ? "x_teams" : "x_athletes"}</T>}
                                heatTitle={<T number={heat.position + 1}>heat_number</T>}
                            />)}
                    </>
                }
            </div>
        </section>
    );
};
