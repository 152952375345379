import { T } from "../../util/t";
import { RunsHeader } from "./RunsHeader";
import { LeaderboardStatus } from "../leaderboard/LeaderboardStatus";
import { FullHeatLeaderboardHeader } from "../heat/FullHeatLeaderboardHeader";

export const FullLeaderboardHeader = ({ leaderboard, closeModal }) => (
    <FullHeatLeaderboardHeader
        heat={leaderboard}
        title={<T>{leaderboard.round}</T>}
        status={<LeaderboardStatus heats={leaderboard.heats} hideTimer/>}
        closeModal={closeModal}
    >
        <RunsHeader heats={leaderboard.heats} runBased={leaderboard.config.runBased} />
    </FullHeatLeaderboardHeader>
);
