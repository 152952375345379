import { MaxWidthPageWithHeading } from "../../../../layout/page/MaxWidthPageWithHeading";
import { Fake } from "../../../../layout/skeleton/Fake";
import { Box, Typography } from "@mui/material";
import { T } from "../../../../util/t";
import { ListItem, ListItemContainer } from "components/layout/lists/ListItem";

export const TeamPageSkeleton = () =>
    <div className="skeleton" role="status" aria-label="loading">
        <MaxWidthPageWithHeading className="skeleton" title={<Fake size={12}/>}>
            <Box my={3}>
                <Typography variant="h7" component="h2"><T>Managers</T></Typography>
                <Typography variant="body2" color="text.secondary">
                    <T>People who can manage this team’s entries and rosters.</T>
                </Typography>
            </Box>
            <ListItemContainer width={{ md: "60%" }}>
                <ListItem title={<Fake size={27}/>} subtitle={<Fake size={55}/>}/>
            </ListItemContainer>
        </MaxWidthPageWithHeading>
    </div>;
