import { Lock } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

import { Tooltip } from "../../elements/tooltip";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    tooltip: {
        pointerEvents: "none !important",

        "& > .tooltip": {
            pointerEvents: "auto",
            top: theme.spacing(3.5)
        }
    },
    tooltipContent: {
        display: "flex",
        alignItems: "center",

        "& > svg": {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.25)
        }
    },
    // following styles ensure entire area is clickable
    wrapper: {
        width: "100%",
        height: "100%",
        "& > div": {
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
        }
    }
}));

export const ScoreRenderer = ({ isEditLocked, score }) => {
    const classes = useStyles();

    if (isEditLocked) return (
        <Tooltip
            className={classes.tooltip}
            title={<div className={classes.tooltipContent}><Lock fontSize="small" /><T>Score locked</T></div>}
            childrenWrapperClass={classes.wrapper}
        >
            {score}
        </Tooltip>
    );
    return score;
};
