import { T } from "../../util/t";
import { StatusPill } from "../StatusPill";

export const EVENT_STATUSES = {
    scheduled: "Scheduled",
    upcoming: "Entry open",
    drawn: "Entry closed",
    published: "Draw published",
    on: "Live",
    on_hold: "On hold",
    finished: "Complete",
    results_published: "Results published",
    cancelled: "Cancelled"
};

export const EventStatusPill = ({ status, children, className, onClick }) =>
    <StatusPill className={className} status={status} onClick={onClick}>
        <T>{EVENT_STATUSES[status]}</T>
        {children}
    </StatusPill>;
