import classnames from "classnames";
import { Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "components/providers/makeStyles";

import { HidePrinting } from "../../util/printing";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    nav: {
        borderBottom: `2px solid ${theme.palette.border.light}`,
        display: "flex",
        whiteSpace: "nowrap",
        scrollbarWidth: "none",
        overflowX: "auto",
        maxWidth: `calc(100vw - ${theme.spacing(4)})`,
        WebkitOverflowScrolling: "touch",
        "&&::-webkit-scrollbar": {
            display: "none"
        }
    },
    link: {
        fontWeight: "normal",
        marginRight: theme.spacing(4),
        minHeight: theme.spacing(6),
        display: "inline-flex",
        alignItems: "center",
        borderWidth: 0,
        borderBottomWidth: 4,
        borderStyle: "solid",
        borderColor: "transparent",
        transition: theme.transitions.create(["border-color"]),
        flexShrink: 0,

        "&:last-child": {
            marginRight: 0,
        },

        "&.active": {
            borderColor: theme.palette.brand.primary,
            fontWeight: "bold"
        }
    }
}));

export const HeadingNav = ({ className, links }) => {
    const classes = useStyles();
    const getLabel = (label) =>
        typeof label === "string"
            ? <T>{label}</T>
            : label;
    return (
        <HidePrinting>
            <nav className={classnames(classes.nav, className)}>
                {links.filter(l => l).map((link, i) =>
                    link.disabled
                        ? <Typography key={i} className={classes.link} variant="button">
                            {getLabel(link.label)}
                        </Typography>
                        : <Link key={i} className={classes.link} component={NavLink} exact to={link.to}>
                            {getLabel(link.label)}
                        </Link>
                )}
            </nav>
        </HidePrinting>
    );
};
