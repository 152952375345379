import { makeStyles } from "../../../providers/makeStyles";

export const usePriorityColoursStyles = makeStyles((theme, { heatSize }) => ({
    colour: {
        width: `${100/heatSize}%`,
        margin: theme.spacing(0.5),
        cursor: "pointer"
    },
    palette: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        padding: theme.spacing(1, 2)
    },
}));
