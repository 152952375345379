import { MoreHoriz } from "@mui/icons-material";
import { Button, Menu } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { useState } from "react";
import { DrawButton } from "./DrawButton";
import { ImportAthletes } from "./ImportAthletes";
import { CreateGroupDivision } from "components/layout/eventDivision/GroupDivisionsDialog";
import { convertToJS } from "../../../eventDivisions/helpers";
import { useEvent } from "../../../routes/events/id/EventProvider";
import { RandomizeButton } from "./RandomizeButton";
import { ImportResultsButton } from "./ImportResultsButton";

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: theme.spacing(6),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

export const DashboardMenu = ({ eventId, eventDivisions, hasSchedule, loaded }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const event = convertToJS(useEvent());
    const eventNotDrawn = ["upcoming", "scheduled"].indexOf(event?.status) > -1 || (!hasSchedule && event?.status === "drawn");
    const eventCompleted = ["finished", "results_published", "cancelled"].indexOf(event?.status) >= 0;
    const randomizeEntries = event?.organisation?.sportType === "sls";

    const classes = useStyles();

    return (
        <aside>
            {eventNotDrawn &&
            (randomizeEntries
                ? <RandomizeButton eventId={eventId}/>
                : <DrawButton eventId={eventId} hasSchedule={hasSchedule}/>
            )}

            <Button className={classes.button} variant="outlined" onClick={openMenu}>
                <MoreHoriz />
            </Button>

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {!eventCompleted && <CreateGroupDivision divisions={eventDivisions} eventId={eventId} closeMenu={handleClose} />}
                {!eventCompleted && <ImportAthletes closeMenu={handleClose} eventId={eventId} eventDivisions={eventDivisions} loaded={loaded} />}
                {eventCompleted && <ImportResultsButton className={classes.button} eventId={eventId}/>}
            </Menu>
        </aside>
    );
};
