import { useCallback } from "react";
import classnames from "classnames";
import { Button, ButtonGroup } from "@mui/material";

import { useButtonGroupStyles } from "../commonStyles/buttonStyles";
import { T } from "../../util/t";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    selector: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));
export const StartlistRunsOrResultSelector = ({ selected, setSelected, renderRidesTab, hasStartlist, runBased }) => {
    const classes = useButtonGroupStyles();
    const selectorClasses = useStyles();
    const selectStartlist = useCallback(() => setSelected("startlist"), []);
    const selectRuns = useCallback(() => setSelected("runsOrRides"), []);
    const selectResult = useCallback(() => setSelected("result"), []);

    return (
        <ButtonGroup className={`${classes.group} ${selectorClasses.selector}`} aria-label="Startlist, runs or result selector">
            {hasStartlist && <Button className={classnames(classes.button, { selected: selected === "startlist" })} onClick={selectStartlist}>
                <T>Start list</T>
            </Button>}

            {renderRidesTab &&
            <Button className={classnames(classes.button, { selected: selected === "runsOrRides" })} onClick={selectRuns}>
                {runBased ? <T>Runs</T> : <T>Rides</T>}
            </Button>}

            <Button className={classnames(classes.button, { selected: selected === "result" })} onClick={selectResult}>
                <T>Result</T>
            </Button>
        </ButtonGroup>
    );
};
