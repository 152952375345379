import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { T } from "../util/t";
import { makeStyles } from "../providers/makeStyles";
import { BrowserUpdateDialogContent, isBrowserSupported } from "components/BrowserUpdateDialog/BrowserUpdateDialog";

const reload = () => window.location.reload();

export const ChunkErrorPage = () => <ErrorPage title="😩" mainMessage="Oh no, it looks like you might be having issues with your connection." action={{ label: "Try again", action: reload }}/>;
export const GenericErrorPage = () => <ErrorPage title="😥" mainMessage="Oh no, we're sorry, something went wrong." action={{ label: "Try again", action: reload }}>{!isBrowserSupported() && <BrowserUpdateDialogContent />}</ErrorPage>;
export const NFCNotSupported = ({ closeDialog }) => <ErrorPage title="😞" mainMessage="scanning_not_supported" action={{ label: "Go back", action: closeDialog }}/>;

const useStyles = makeStyles(theme => ({
    page: {
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        margin: theme.spacing(8, "auto"),
    },
    title: {
        fontSize: 60
    },
    subtitle: {
        margin: theme.spacing(2, 0, 4, 0),
    },
    container: {
        margin: theme.spacing(6, "auto", 0, "auto")
    },
    divider: {
        marginBottom: theme.spacing(3)
    }
}));

const ErrorPage = ({ title, mainMessage, action, children }) => {
    const classes = useStyles();

    return (
        <Box className={classes.page}>
            <Typography variant="h1" className={classes.title}>{title}</Typography>
            <Typography variant="h3" className={classes.subtitle}><T>{mainMessage}</T></Typography>
            <Button variant="contained" onClick={action.action}><T>{action.label}</T></Button>

            {children && <Container maxWidth="sm" className={classes.container}>
                <Divider className={classes.divider} />
                {children}
            </Container>}
        </Box>
    );
};
