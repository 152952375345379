import classNames from "classnames";
import { Modal } from "@mui/material";
import { T } from "components/util/t";
import { Icon as LHIcon } from "styleguide/icons/Icon";
import { makeStyles } from "components/providers/makeStyles";
import { MENU_WIDTH } from "../navigation/useSideNavStyles";

const useStyles = makeStyles({
    modalBehindSidebar: {
        zIndex: "2 !important",
        paddingLeft: MENU_WIDTH / 2
    }
});

export const NotStartedHeatMessage = ({ startTime, runBased, revealSideNav }) => {
    const classes = useStyles();

    return (
        <Modal className={classNames("lh-modal judge-sheet-overlay", revealSideNav && classes.modalBehindSidebar)} open={!startTime}>
            <table><tbody><tr><td>
                <h1><T>{`Waiting for director to start ${runBased ? "run" : "heat"}`}</T></h1>
                <LHIcon icon="Megaphone" title="Waiting"/>
            </td></tr></tbody></table>
        </Modal>
    );
};
