import dayjs from "dayjs";
import { SHORT_DATE } from "../../utils/dates";

export const eventDate = ({ date, window }) => {
    const startDate = dayjs(date);
    const endDate = dayjs(date).add(window - 1, "day");

    let text = startDate.format("D");
    startDate.unix() === endDate.unix() ?
        text += ` ${startDate.format("MMMM YYYY")}` :
        startDate.month() === endDate.month() ?
            text += ` - ${endDate.format("D")} ${startDate.format("MMMM YYYY")}` :
            text += ` ${startDate.format("MMM")} - ${endDate.format(SHORT_DATE)}`;

    return text;
};

export const EventDate = ({ date, window }) => <div className="event-date">{eventDate({ date, window })}</div>;
