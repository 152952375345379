import { useIsMobile } from "../../../hooks/useIsMobile";
import { Box, Button, Stack, Typography } from "@mui/material";
import { T } from "../../util/t";
import { ContainedIcon } from "../ContainedIcon";

export const BigBoxList = ({ items }) => {
    const isMobile = useIsMobile();

    return (
        <Stack spacing={1} direction={isMobile ? "column" : "row"}>
            {items.map((item, i) => <BigBox key={i} {...item} isMobile={isMobile}/>)}
        </Stack>
    );
};

export const BigBox = ({ icon, text, subtext, isMobile, onClick }) =>
    <Button variant="outlined" sx={{ flex: 1 }} onClick={onClick}>
        <Box display="flex" flexDirection="column" py={isMobile ? 4 : 6} px={4}>
            <ContainedIcon icon={icon}/>
            <Typography variant="button" marginBottom={2}><T>{text}</T></Typography>
            <Typography color="text.secondary"><T>{subtext}</T></Typography>
        </Box>
    </Button>;
