import { useDialogState } from "../../../../hooks/useDialogState";
import { TextAndIconMenuItem } from "../ModifierMenu";
import { DSQReasonDialog } from "./DSQReasonDialog";
import { Edit } from "@mui/icons-material";

export const DSQEditMenuItem = ({ addModifier, notes }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <>
            <TextAndIconMenuItem name="Add/edit reason" Icon={Edit} onClick={openDialog}/>
            <DSQReasonDialog open={dialogOpen} onClose={closeDialog} addModifier={addModifier} notes={notes}/>
        </>
    );
};
