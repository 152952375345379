import { makeStyles } from "components/providers/makeStyles";

export const useMiniButtonStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        minHeight: 0,
        padding: theme.spacing(0, 1),
        borderRadius: theme.spacing(0.5)
    },
}));

export const useButtonGroupStyles = makeStyles(theme => ({
    group: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            "&& button": {
                minWidth: "auto",
            }
        }
    },
    button: {
        width: "100%",
        "&&": {
            minWidth: 180,
        }
    }
}));
