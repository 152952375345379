import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { T } from "../../../util/t";
import { convertToJS } from "../../../eventDivisions/helpers";
import { useEventDivision } from "../../../routes/events/id/divisions/eventDivisionId/EventDivisionProvider";
import { PreviewDrawSkeleton } from "./PreviewDrawSkeleton";
import { PreviewDrawWidget } from "./PreviewDrawWidget";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { PREVIEW_DRAW } from "../../../../graphql/eventDivision";
import { ResponsiveDialog } from "../../../modal";
import { useDialogState } from "../../../../hooks/useDialogState";
import { Storage } from "@mui/icons-material";
import { Icon as LHIcon } from "../../../../styleguide/icons/Icon";
import { EmptyState } from "../../EmptyState";
import { DrawButton } from "./DrawButton";
import { StartingAthletes } from "./StartingAthletes";

export const PreviewDrawDialog = () => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    return (
        <>
            <Button variant="outlined" onClick={openDialog} startIcon={<Storage/>}>
                <T>Draw heats</T>
            </Button>

            <ResponsiveDialog
                aria-labelledby="preview-draw-dialog-title"
                className="lh-dialog"
                maxWidth="md"
                open={dialogOpen}
                onClose={closeDialog}
                fullWidth
            >
                <PreviewDrawDialogContent closeDialog={closeDialog}/>
            </ResponsiveDialog>
        </>
    );
};

export const PreviewDrawDialogContent = ({ closeDialog }) => {
    const eventDivision = convertToJS(useEventDivision());
    const formatDefinition = useRef({
        isTeams: eventDivision.formatDefinition?.isTeams,
        heatSizes: eventDivision.formatDefinition?.heatSizes,
        progression: eventDivision.formatDefinition?.progression
    });
    const entryLimit = useRef(eventDivision.entryLimit);
    const { data, refetch, loading } = useQuery(PREVIEW_DRAW, {
            variables: { id: eventDivision.id, formatDefinition: formatDefinition.current, entryLimit: entryLimit.current },
            fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true
        }),
        previewDrawData = data?.eventDivision?.previewDraw;
    const [previewDraw, setPreviewDraw] = useState(previewDrawData);
    useEffect(() => {
        if (previewDrawData) setPreviewDraw(previewDrawData);
    }, [previewDrawData]);

    return (
        <>
            <DialogTitle id="preview-draw-dialog-title" className="sticky-dialog-title">
                <Typography variant="h3" component="div"><T>Preview draw</T></Typography>
                <Typography variant="subtitle2" color="text.light" component="div">{eventDivision.division.name}</Typography>
            </DialogTitle>

            <DialogContent>
                <Box paddingTop={2} sx={{ width: { md: "40%" }, left: 0, position: "sticky" }}>
                    <StartingAthletes
                        entryLimit={entryLimit}
                        initialEntryLimit={eventDivision.entryLimit?.limit}
                        refetch={refetch}
                        eventDivisionId={eventDivision.id}
                        isTeams={formatDefinition.current.isTeams}
                    />
                </Box>
                <Divider />
                <Box display="flex" paddingTop={2}>
                    {!previewDraw
                        ? <PreviewDrawSkeleton/> :
                        previewDraw.rounds.length
                            ? <PreviewDrawWidget formatDefinition={formatDefinition} previewDraw={previewDraw} refetch={refetch} loading={loading}/>
                            : <EmptyState icon={<LHIcon icon="AthleteProfile"/>} text="Nothing to see here - there's no competitors in this draw. You can build a skeleton draw by adding a number to Available spots, or build an empty draw."/>
                    }
                </Box>
            </DialogContent>

            <DialogActions className="sticky-dialog-actions">
                <Button variant="outlined" onClick={closeDialog}><T>Cancel</T></Button>
                <DrawButton formatDefinition={formatDefinition} entryLimit={entryLimit} closeDialog={closeDialog}/>
            </DialogActions>
        </>
    );
};
