import { Paper, Typography } from "@mui/material";

import { heatLeaderboardCardStyles } from "../leaderboard/heatLeaderboardCardStyles";
import { Fake } from "../skeleton/Fake";
import { JerseyAvatar } from "./JerseyAvatar";
import { useHeatSummaryStyles } from "./heatStyles";
import { useHeatLeaderboardHeaderStyles } from "./useHeatLeaderboardHeaderStyles";
import { useAvatarStyles } from "../avatar/useAvatarStyles";

export const HeatCardSkeleton = () => {
    const classes = heatLeaderboardCardStyles();

    return (
        <Paper variant="outlined" className={classes.card}>
            <HeatCardSkeletonContent/>
        </Paper>
    );
};

export const HeatCardSkeletonContent = () => {
    const classes = useHeatLeaderboardHeaderStyles();

    return (
        <>
            <header className={classes.header}>
                <Typography variant="h7" component="h3">
                    <Fake size={23}/>
                </Typography>
            </header>

            <FakeAthleteList names={[44, 57, 33, 48]} />
        </>
    );
};

export const FakeAthleteList = ({ names }) => {
    const classes = { ...useAvatarStyles(), ...useHeatSummaryStyles() };

    return (
        <ul className={classes.resultList}>
            <li className={classes.row}>
                <Typography variant="subtitle2"><Fake size={19}/></Typography>
            </li>
            {names.map((name, i) => <li key={i} className={classes.row}>
                <section className={classes.athlete}>
                    <div className={classes.avatar}><JerseyAvatar/></div>

                    <div>
                        <Typography variant="body1"><Fake size={name}/></Typography>
                    </div>
                </section>
            </li>)}
        </ul>
    );
};
