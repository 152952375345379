import { ActionNotification } from "components/notifications/ActionNotification";
import { ActionNotificationSticky } from "components/notifications/ActionNotificationSticky";
import { PureComponent } from "react";
import { connect } from "react-redux";

import * as NotificationActions from "../../actions/notification";

@connect(state => ({
    notification: state.notifications.get("notification"),
    sticky: state.notifications.get("sticky")
}))
export class Notifications extends PureComponent {
    closeNotification = () => this.props.dispatch(NotificationActions.hide("notification"));

    render = () => {
        const { notification, sticky } = this.props;

        return <>
            {sticky &&
            <ActionNotificationSticky type={sticky.get("type", "")} text={sticky.get("text")} />
            }

            {notification && <Notification notification={notification} closeNotification={this.closeNotification} />}
        </>;
    };
}

const Notification = ({ notification, closeNotification }) => {
    return (
        <ActionNotification type={notification.get("type")} open={notification.get("open", "")} text={notification.get("text")} handleClose={closeNotification} />
    );
};
