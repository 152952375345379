import { memo } from "react";

import { useHeatSummaryStyles } from "./heatStyles";
import { HeatSummaryResults } from "./HeatSummaryResults";
import { HeatSummaryStartlist } from "./HeatSummaryStartlist";

export const HeatSummary = memo(({ heat, forceResults, HeatSummaryStartlistComponent = HeatSummaryStartlist, HeatSummaryResultsComponent = HeatSummaryResults, ...rest }) => {
    const showResults = !!heat.startTime && (forceResults || !heat.config.hideScores);
    const classes = useHeatSummaryStyles();
    const Component = showResults ? HeatSummaryResultsComponent : HeatSummaryStartlistComponent;

    return (
        <ul className={classes.resultList}>
            <Component heat={heat} {...rest} />
        </ul>
    );
});
