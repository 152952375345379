import Immutable from "immutable";

import createStore from "./createStore";

const findHeat = (state, event_id, heat_id) => state.getIn(["current_heats", event_id, heat_id]);

const findResultIndex = (state, event_id, heat_id, athlete_id) =>
    state.getIn(["current_heats", event_id, heat_id, "result"], Immutable.List())
        .findIndex(result => "" + result.get("athlete_id") === athlete_id);

export const reducer = createStore({
    SET_CURRENT_JUDGE_HEATS: (state, action) => {
        const heats = Immutable.fromJS(action.data.heats);
        return state
            .setIn(["current_heat_ids", action.event_id], heats.map(h => h.get("id")))
            .setIn(["current_heats", action.event_id], Immutable.Map(heats.map(h => [h.get("id"), h])));
    },

    SET_CURRENT_JUDGE_HEAT: (state, action) =>
        state.setIn(["current_heats", action.event_id, action.heat_id], Immutable.fromJS(action.data)),

    SET_HEAT_SCORE: (state, action) => {
        const heat = findHeat(state, action.event_id, action.heat_id);
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        return athleteResultIndex < 0 ? state :
            state.updateIn([
                "current_heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                action.score.athlete_id || action.score.team_position
            ], rides => (rides || Immutable.List()).updateIn([
                action.ride_index
            ], ride => (ride || Immutable.Map({ pending: !!heat.getIn(["config", "requires_approval"]) })).updateIn([
                "scores"
            ], scores => {
                const newScores = scores || Immutable.Map(),
                    scorePath = ["" + action.judge_id];
                if (!action.score.score)
                    return newScores.deleteIn(scorePath);

                action.score.category && scorePath.push(action.score.category);
                return newScores.setIn(scorePath, action.score.score);
            })));
    },

    SET_HEAT_CATEGORY_SCORE: (state, action) => {
        const heat = findHeat(state, action.event_id, action.heat_id);
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        return athleteResultIndex < 0 ? state :
            state.updateIn([
                "current_heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                action.score.athlete_id || action.score.team_position
            ], rides => (rides || Immutable.List()).updateIn([
                action.ride_index
            ], ride => {
                const newRide = (ride || Immutable.Map({ pending: !!heat.getIn(["config", "requires_approval"]) })),
                    newScores = newRide.get("scores") || Immutable.Map(),
                    scorePath = ["" + action.judge_id];

                if (!action.score.score)
                    return newRide
                        .set("scores", newScores.deleteIn(scorePath))
                        .delete("category");

                action.score.category && scorePath.push(action.score.category);
                return newRide
                    .set("scores", newScores.setIn(scorePath, action.score.score))
                    .set("category", action.score.ride_category);
            }));
    },

    SET_HEAT_SCORES: (state, action) => {
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        return athleteResultIndex < 0 ? state :
            state.updateIn([
                "current_heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                action.athlete_id || action.score.team_position
            ], rides => (rides || Immutable.List()).updateIn([
                action.ride_index,
            ], ride => (ride || Immutable.Map({ pending: !!action.heat_config.get("requires_approval") })).updateIn([
                "scores"
            ], scores => {
                const newScores = scores || Immutable.Map(),
                    scorePath = "" + action.judge_id,
                    actionScores = Immutable.fromJS(action.scores);

                if (!actionScores.some(s => s.get("score")))
                    return newScores.delete(scorePath);

                const total = actionScores.reduce((result, score) =>
                    result + (parseFloat(score.get("score") || 0) * (action.heat_config.getIn(["categories", score.get("category"), "weight"], 1)))
                , 0).toFixed(2);

                return newScores.set(scorePath, Immutable.Map(actionScores.map(s => [s.get("category"), s.get("score")]).push(["total", total])));
            })));
    },

    DELETE_HEAT_SCORES: (state, action) => {
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        return athleteResultIndex < 0 ? state :
            state.deleteIn([
                "current_heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                action.athlete_id,
                action.ride_index,
                "scores",
                "" + action.judge_id
            ]);
    },

    ADD_MODIFIER: (state, action) => {
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        const modifier = { type: "Modifiers::" + action.modifier_type };
        if (action.modifier_id) modifier.id = action.modifier_id;
        return athleteResultIndex < 0 ? state :
            state.updateIn([
                "current_heats",
                action.event_id,
                action.heat_id,
                "result",
                athleteResultIndex,
                "rides",
                action.athlete_id || action.team_position
            ], rides => (rides || Immutable.List()).setIn([
                action.ride_index,
                "modifier"
            ], Immutable.fromJS(modifier)));
    },

    DELETE_MODIFIER: (state, action) => {
        const athleteResultIndex = findResultIndex(state, action.event_id, action.heat_id, action.parent_athlete_id);
        return athleteResultIndex < 0 ? state : state.setIn([
            "current_heats",
            action.event_id,
            action.heat_id,
            "result",
            athleteResultIndex,
            "rides",
            action.athlete_id,
            action.ride_index,
            "modifier"
        ], null);
    }
});
