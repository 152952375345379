import { Component, lazy, Suspense }  from "react";
const LinkifyLib = lazy(() => import("linkify-react"));

export class Linkify extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
    }

    render() {
        const { children, ...rest } = this.props;
        return this.state.hasError ?
            children :
            <Suspense fallback={children}>
                <LinkifyLib options={{ target: "_blank" }} {...rest}>
                    {children}
                </LinkifyLib>
            </Suspense>;
    }
}
