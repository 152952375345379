import { Map } from "immutable";
import { isTeamFormat, recursiveSnakeToCamel } from "../../utils/utils";

export const isDivisionTeamType = eventDivision => !!(eventDivision && (Map.isMap(eventDivision)
    ? isTeamFormat(eventDivision.get("format_definition"))
    : isTeamFormat(eventDivision.formatDefinition)
));

export const convertToJS = object => Map.isMap(object)
    ? recursiveSnakeToCamel(object.toJS())
    : object;
