import { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import dayjs from "dayjs";

import { Timer } from "../../timer";
import { T } from "../../util/t";
import { Check, Timer as TimerIcon } from "@mui/icons-material";

export class HeatHeader extends Component {
    static propTypes = {
        heat: PropTypes.instanceOf(Immutable.Map).isRequired,
        isNext: PropTypes.bool,
        forceShowScheduled: PropTypes.bool,
        displayFormat: PropTypes.string,
        bank: PropTypes.string,
        displayTimerIcon: PropTypes.bool
    };

    renderUpcoming = () => {
        const { displayFormat = "hh:mm a", heat, forceShowScheduled } = this.props,
            scheduledTime = (forceShowScheduled || !heat.getIn(["config", "hide_scheduled_time"])) && heat.get("scheduled_time");

        return scheduledTime &&
            <span className="heat-time">
                {dayjs(scheduledTime).format(displayFormat)}
            </span>;
    };

    renderStarted = () =>
        this.props.heat.getIn(["config", "hide_timer"]) ?
            <span className="heat-time flag">
                <span><T>LIVE</T></span>
            </span> :
            <span className="heat-time">
                {this.props.displayTimerIcon && <TimerIcon />}

                <Timer start={this.props.heat.get("start_time")} duration={this.props.heat.get("heat_duration_minutes")}/>
            </span>;

    renderFinished = () =>
        <span className="heat-time">
            <Check className="done" />
        </span>;

    renderNext = () =>
        <span className="heat-time flag">
            <span><T>NEXT</T></span>
        </span>;


    renderTime() {
        const { isNext, heat } = this.props,
            heatFinished = heat.get("end_time"),
            heatStarted = heat.get("start_time");
        return isNext ? this.renderNext() :
            heatFinished ? this.renderFinished() :
                heatStarted ? this.renderStarted() : this.renderUpcoming();
    }

    render() {
        const { heat, actions, className = "" } = this.props;

        return (
            <header className={`heat-header ${className}`}>
                {actions && <div className="heat-actions">{actions}</div>}
                <T heat={heat}>HeatHeader_title</T>
                {this.renderTime()}
            </header>
        );
    }
}
