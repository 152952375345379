import { HeatSummary } from "../heat/HeatSummary";
import { EmptyState } from "../EmptyState";
import { IconEmptyLeaderboard } from "../../../styleguide/icons/icons";

export const Leaderboard = ({ leaderboard, forceResults }) => (
    leaderboard.startTime
        ? <HeatSummary heat={leaderboard} forceResults={forceResults} />
        : <EmptyState
            className="small"
            icon={<IconEmptyLeaderboard/>}
            text="You can view the leaderboard here once the round is underway."
        />
);
