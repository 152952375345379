import { useDispatch } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { logout } from "../actions/user";

export const useLogout = (callback) => {
    const dispatch = useDispatch();
    const client = useApolloClient();

    return () =>
        logout()
            .then(async () => {
                client.stop();
                await client.clearStore();
                callback?.();
                setTimeout(() => dispatch({ type: "LOGOUT_USER" })); // this sucks but is required to prevent a weird notification because user gets cleared while still on a private route
            });
};
