import { gql } from "@apollo/client";
import { DivisionFragment } from "./division";

const CompetitorFragment = gql`
  fragment Competitor on Competitor {
      id
      athleteId
      position
      priority
      teamName
      bib
      athlete {
        id
        name
        image
      }
      teamMembers {
        id
        order
        athlete {
          id
          name
          image
        }
        teamRoleName
      }
    }
`;

const ProgressionFragment = gql`
  fragment Progression on HeatProgression {
    round
    roundPosition
    heat
    position
    roundOnly
    run
    obscure
  }
`;

export const HeatFragment = gql`
  fragment Heat on Heat {
    id
    contestId
    eventDivisionId
    round
    roundPosition
    position
    startTime
    endTime
    heatDurationMinutes
    config {
      jerseyOrder
      runBased
      hideTimer
      hideNeeds
      hideScheduledTime
      hideScores
      maxRideScore
      categories
      isTeams
      hasStartlist
      usesLanes
      numberOfLanes
      hasLeaderboard
      hasPriority
      calculator
      heatSize
      totalCountingRides
      teamConfig {
        athletesPerTeam
        appraisalLevel
      }
    }
    result {
      athleteId
      total
      winBy
      needs
      rides
      place
    }
    competitors {
      ...Competitor
    }
    eventDivision {
      id
      division {
        ...Division
      }
    }
  }
  ${DivisionFragment}
  ${CompetitorFragment}
`;

export const ScheduledHeatFragment = gql`
  fragment ScheduledHeat on Heat {
    id
    round
    roundPosition
    position
    podium
    scheduledTime
    startTime
    endTime
    heatDurationMinutes
    config {
      runBased
      hideTimer
      hideScheduledTime
    }
    eventDivision {
      id
      division {
        ...Division
      }
    }
  }
  ${DivisionFragment}
`;

export const CompleteHeatFragment = gql`
  fragment CompleteHeat on Heat {
    ...Heat
    scheduledTime
    group {
      name
      roundName
      roundContestId
      groupContestId
    }
    eventDivision {
      id
      division {
        ...Division
      }
    }
    progressions {
      ...Progression
    }
  }
  ${HeatFragment}
  ${DivisionFragment}
  ${ProgressionFragment}
`;

export const GET_HEAT = gql`
  query getHeat($id: ID!) {
    heat(id: $id) {
      ...Heat
    }
  }
  ${HeatFragment}
`;

export const ADD_HEAT_TO_ROUND = gql`
  mutation addHeatToRound($eventDivisionId: ID!, $roundPosition: Int!) {
    addHeatToRound(input: {eventDivisionId: $eventDivisionId, roundPosition: $roundPosition}) {
      heat {
        ...CompleteHeat
      }
    }
  }
  ${CompleteHeatFragment}
`;

export const MOVE_HEAT_ITEMS = gql`
  mutation moveHeatItems($eventDivisionId: ID!, $source: MoveHeatItemInput!, $target: MoveHeatItemInput!) {
    moveHeatItems(input: {eventDivisionId: $eventDivisionId, source: $source, target: $target}) {
      heats {
        id
        competitors {
          ...Competitor
        }
        progressions {
          ...Progression
        }
      }
    }
  }
  ${CompetitorFragment}
  ${ProgressionFragment}
`;

export const ADD_ATHLETE_TO_HEAT = gql`
  mutation addAthleteToHeat($heatId: ID!, $athleteHeat: AthleteHeatInput!, $eventDivisionId: ID) {
    addAthleteToHeat(input: {heatId: $heatId, athleteHeat: $athleteHeat, eventDivisionId: $eventDivisionId}) {
      heat {
        id
        competitors {
          ...Competitor
        }
      }
    }
  }
  ${CompetitorFragment}
`;

export const REMOVE_ATHLETE_FROM_HEAT = gql`
  mutation removeAthleteFromHeat($heatId: ID!, $athleteId: ID!) {
    removeAthleteFromHeat(input: {heatId: $heatId, athleteId: $athleteId}) {
      heat {
        id
        competitors {
          ...Competitor
        }
      }
    }
  }
  ${CompetitorFragment}
`;

export const REMOVE_PRIORITY = gql`
  mutation removePriority($competitorId: ID!) {
    removePriority(input: {competitorId: $competitorId}) {
      heat {
        id
        competitors {
          id
          priority
        }
      }
    }
  }
`;

export const ADD_PRIORITY = gql`
  mutation addPriority($competitorId: ID!, $priority: Int!) {
    addPriority(input: {competitorId: $competitorId, priority: $priority}) {
      heat {
        id
        competitors {
          id
          priority
        }
      }
    }
  }
`;
