import { useDispatch } from "react-redux";
import { error, success, warn } from "../actions/notification";

export const useError = () => {
    const dispatch = useDispatch();
    const notifyError = message => dispatch(error(message));
    const notifyResponseError = e => {
        dispatch(error(e.response?.data?.error || e.response?.message || "Oops, something went wrong!"));
    };

    return { notifyError, notifyResponseError };
};

export const useWarning = () => {
    const dispatch = useDispatch();
    const notifyWarning = message => dispatch(warn(message));

    return { notifyWarning };
};

export const useSuccess = () => {
    const dispatch = useDispatch();
    const notifySuccess = message => dispatch(success(message));

    return { notifySuccess };
};

