import { Link, Stack, Typography } from "@mui/material";
import { T } from "./util/t";
import { useRouteMatch } from "react-router-dom";

export const TheHeatSheet = () => {
    const isHome = useRouteMatch("/");

    return isHome?.isExact && (
        <Stack bgcolor="brand.secondary" justifyContent="center" direction="row" padding={1} spacing={0.5} flexWrap="wrap" textAlign="center">
            <Typography>
                <Typography textTransform="uppercase" variant="brandLabel1">
                    <T>The 2024 heat sheet</T>
                </Typography>
                {" "}
                <Typography variant="brandBody1"><T>12 months of action sports' data in 1 place.</T></Typography>
            </Typography>
            <Typography>
                <Link variant="brandBody1" href="https://theheatsheet.liveheats.com" target="_blank" underline="none" sx={{ textDecoration: "underline", textUnderlineOffset: "3px", textDecorationThickness: "1px" }}>
                    <T>Get your personalised Heat Sheet</T>
                </Link>
                {" "}
                <Typography variant="brandBody1" display="inline-block" sx={{ transform: "scale(1.75) translate(1px,-1.5px)" }}>⇗</Typography>
            </Typography>
        </Stack>
    );
};
