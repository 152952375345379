import { useState } from "react";

import { T } from "../../components/util/t";
import { ExpandMore } from "@mui/icons-material";

export const Accordion = ({ label, children }) => {
    const [isOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!isOpen);

    return (
        <div className={`expandable ${isOpen ? "open" : "closed"}`}>
            <div className="expandable-label" onClick={toggle}>
                <ExpandMore />
                <T>{label}</T>
            </div>

            {isOpen &&
            <div className="expandable-content">
                {children}
            </div>}
        </div>
    );
};
