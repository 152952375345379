const getScrollTopPosition = () => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

export const syncScrollTop = (listRef, outerRef) => {
    listRef.current && listRef.current.scrollTo(Math.max(0, getScrollTopPosition() - Math.round(outerRef.current.getBoundingClientRect().top + getScrollTopPosition())));
};

export const loadMoreItems = (onLoadMore, loading, setLoading, hasMoreItems) => {
    const scrollTop = getScrollTopPosition();
    const offsetHeight = document.documentElement.offsetHeight || document.body.offsetHeight || 0;
    const threshold = window.innerHeight;

    if (window.innerHeight + scrollTop + threshold >= offsetHeight) {
        if (!hasMoreItems || loading) return;
        setLoading(true);
        onLoadMore().then(() => setLoading(false));
    }
};

export const setSize = (ref, data, index) => {
    const safeRef = ref.current; // The ref value 'ref.current' will likely have changed by the time this function runs.

    if (!safeRef) return;
    data.setItemsSize(itemsSize => {
        const size = Math.round(safeRef.getBoundingClientRect().height);
        return itemsSize[index] === size ? itemsSize : ({ ...itemsSize, [index]: size });
    });
    data.listRef.current.resetAfterIndex(index);
};
