import { makeStyles } from "../../providers/makeStyles";
import { Button, IconButton, Typography } from "@mui/material";
import { T } from "../../util/t";
import { Close } from "@mui/icons-material";
import { ScanBox } from "./ScanBox";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(9),
        h2: {
            marginTop: theme.spacing(1)
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    heading: {
        marginBottom: theme.spacing(2)
    },
    floatingButtonWrapper: {
        position: "fixed",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        right: theme.spacing(2),
    }
}));
export const ScanDialogContent = ({ title, subtitle, message, scanningActions, heading, closeDialog, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <header className={classes.header}>
                <aside>
                    <Typography variant="h3" component="h1"><T>{title}</T></Typography>
                    <Typography variant="h7" component="h2"><T>{subtitle}</T></Typography>
                </aside>
                <IconButton color="inherit" size="small" onClick={closeDialog}><Close/></IconButton>
            </header>

            <ScanBox message={message} scanningActions={scanningActions}/>

            <Typography className={classes.heading} variant="h5">
                {heading}
            </Typography>

            {children}

            <section className={classes.floatingButtonWrapper}>
                <Button className={classes.floatingButton} variant="contained" onClick={closeDialog} fullWidth>
                    <T>Done scanning</T>
                </Button>
            </section>
        </div>
    );
};
