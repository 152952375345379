import { Stack } from "@mui/material";
import { StatusPill } from "components/layout/StatusPill";
import { Card } from "components/layout/cards/Card";
import { EventCard } from "components/layout/cards/EventCard";
import { Fake } from "components/layout/skeleton/Fake";
import { nSpaces } from "utils/utils";

export const AthleteActivityPageSkeleton = () =>
    <div role="status" aria-label="loading" className="skeleton">
        <Stack spacing={2}>
            {Array.from({ length: 3 }).map((_, i) => (
                <EventCard
                    key={i}
                    title={<Fake size={50} />}
                    subtitle={<Fake size={30} />}
                    caption={<Fake size={40} />}
                    aside={<StatusPill status={"on"}>{nSpaces(10)}</StatusPill>}
                    footer={<Card><div><Fake size={30} /></div></Card>}
                />
            ))}
        </Stack>
    </div>;
