import { useForm, useController } from "react-hook-form";
import { noop } from "../../../forms";
import { useReSubmittingFunction } from "./useReSubmittingFunction";
import { TimeUnitField } from "./TimeUnitField";

const TIMEOUT = 1000;
const FIELD_NAME = "score";

export const SelfSubmittingTimingField = ({ initialValue, onSubmit: submit, postSubmit = noop, field }) => {
    const { control,
        handleSubmit,
        setError } = useForm({ defaultValues: { [FIELD_NAME]: initialValue }, shouldFocusError: false });
    const { onSubmit, debouncedSubmit } = useReSubmittingFunction(FIELD_NAME, initialValue, submit, postSubmit, TIMEOUT, setError);
    const { field: input } = useController({
        control,
        name: FIELD_NAME
    });

    const onChange= (e, value) => {
        input.onChange(value);
        handleSubmit(debouncedSubmit)(e);
    };

    const onBlur = e => {
        input.onBlur();
        handleSubmit(onSubmit)(e);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TimeUnitField value={input.value} onChange={onChange} onBlur={onBlur} field={field}/>
        </form>
    );
};
