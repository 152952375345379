import { gql } from "@apollo/client";

export const EventDivisionTeamLeaderboardFragment = gql`
  fragment EventDivisionTeamLeaderboard on EventDivisionTeamLeaderboard {
    id
    result {
      place
      teamName
      total
      memberResults {
        athleteId
        competitor {
          id
          athleteId
          teamName
          bib
          athlete {
            id
            name
            image
          }
        }
        place
        total
        rides
        countingMember
      }
    }
  }
`;
