import { gql } from "@apollo/client";
import { DivisionFragment } from "./division";

const AthleteFragment = gql`
  fragment Athlete on Athlete {
    id
    name
    dob
    image
  }
`;

export const AthleteWithTagsFragment = gql`
  fragment AthleteWithTags on Athlete {
    ...Athlete
    nfcTags {
        id
        athleteId
    }
  }
  ${AthleteFragment}
`;

export const AthleteWithUsersFragment = gql`
  fragment AthleteWithUsers on Athlete {
    ...Athlete
    users {
      id
      name
      email
      phone
    }
    properties
  }
  ${AthleteFragment}
`;

export const GET_ATHLETE = gql`
  query getAthlete($id: ID!) {
    athlete(id: $id) {
      ...AthleteWithUsers
    }
  }
  ${AthleteWithUsersFragment}
`;

export const UPDATE_ATHLETE = gql`
  mutation updateAthlete($id: ID!, $athlete: AthleteInput!) {
    updateAthlete(input: {id: $id, athlete: $athlete}) {
      athlete {
        ...AthleteWithUsers
      }
    }
  }
  ${AthleteWithUsersFragment}
`;

export const GET_ATHLETE_EVENT_DIVISIONS = gql`
  query getAthleteEvents($id: ID!, $limit: Int) {
    athlete(id: $id) {
      id
      eventDivisions(limit: $limit) {
        id
        division {
          ...Division
        }
        event {
          id
          organisation {
            id
            name
          }
        }
      }
    }
  }
  ${DivisionFragment}
`;

export const MERGE_ATHLETES = gql`
  mutation mergeAthletes($primary: ID!, $others: [ID!]!) {
    mergeAthletes(input: {primary: $primary, others: $others}) {
      athlete {
        ...AthleteWithUsers
      }
    }
  }
  ${AthleteWithUsersFragment}
`;
