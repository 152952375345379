import { useCallback, useEffect, useState } from "react";
import { useOnScroll } from "./useOnScroll";

export const useHasMoreToScroll = (direction = "horizontal") => {
    const [hasBackwardScroll, setHasBackwardScroll] = useState(false);
    const [hasForwardScroll, setHasForwardScroll] = useState(true);

    const onScroll = useCallback(e => {
        const backwardScroll = direction === "horizontal" ? e.target.scrollLeft : e.target.scrollTop;
        const scrollSize = direction === "horizontal" ? e.target.scrollWidth : e.target.scrollHeight;
        const clientSize = direction === "horizontal" ? e.target.clientWidth : e.target.clientHeight;
        const remainingScrollForward = scrollSize - backwardScroll - clientSize;

        if (backwardScroll > 0) setHasBackwardScroll(true);
        else setHasBackwardScroll(false);

        if (parseInt(remainingScrollForward) > 0) setHasForwardScroll(true);
        else setHasForwardScroll(false);
    }, []);

    const { scrollerRef, scrollerNode } = useOnScroll(onScroll);

    useEffect(() => {
        if (scrollerNode) {
            const listener = onScroll.bind(null, { target: scrollerNode });
            listener();
            window.addEventListener("resize", listener);

            return () => window.removeEventListener("resize", listener);
        }
    }, [scrollerNode]);

    return {
        scrollerRef,
        scrollerNode,
        ...direction === "horizontal" ? {
            hasLeftScroll: hasBackwardScroll,
            hasRightScroll: hasForwardScroll
        } : {
            hasTopScroll: hasBackwardScroll,
            hasBottomScroll: hasForwardScroll
        }
    };
};
