import { useCallback } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";

import { LoadingButton } from "../actions/loadingButton";
import { T } from "./t";

export const UploadDialog = ({ title, description, open, importFile, closeImportDialog, doFileUpload }) =>
    <Dialog open={open}
            onClose={closeImportDialog}
            aria-labelledby="import-dialog-title"
            aria-describedby="import-dialog-description">

        <UploadDialogContent {...{ title, description, importFile, closeImportDialog, doFileUpload }}/>
    </Dialog>;

const UploadDialogContent = ({ title, description, importFile, closeImportDialog, doFileUpload }) => {
    const uploadOverwrite = useCallback(() => doFileUpload(importFile, true), []);
    const uploadAdd = useCallback(() => doFileUpload(importFile, false), []);

    return (
        <>
            <DialogTitle id="import-dialog-title"><T>{title}</T></DialogTitle>
            <DialogContent id="import-dialog-description">
                <T>{description}</T>
                <p>
                    <T>Selected file:</T>
                    &nbsp;{importFile.name}
                </p>
            </DialogContent>

            <DialogActions className="multi-buttons">
                <Button className="left" onClick={closeImportDialog} startIcon={<Close/>}>
                    <T>Cancel</T>
                </Button>

                <LoadingButton variant="outlined" action={uploadOverwrite}>
                    <T>No, overwrite existing</T>
                </LoadingButton>

                <LoadingButton variant="contained" color="primary" action={uploadAdd}>
                    <T>Yes, add to existing</T>
                </LoadingButton>
            </DialogActions>
        </>
    );
};
