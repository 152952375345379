import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "components/util/t";
import classnames from "classnames";

export const useStyles = makeStyles(theme => ({
    message: {
        margin: theme.spacing(0, 6)
    }
}));

export const ScanBoxMessage = ({ className, message }) => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.message, className)}>
            <Typography variant="subtitle2" component="p"><T>{message?.value || "Hold device near tag"}</T></Typography>
        </div>
    );
};
