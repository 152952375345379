import { ResponsiveDialog } from "../../../modal";
import { DialogTitle, IconButton } from "@mui/material";
import { T } from "../../../util/t";
import { DialogFormKit } from "../../../forms/FormKit";
import { Close } from "@mui/icons-material";

export const DSQReasonDialog = ({ open, onClose, addModifier, notes }) =>
    <ResponsiveDialog open={open} onClose={onClose} maxWidth="sm" className="lh-dialog" aria-labelledby="dsq-reason-dialog-title" fullWidth>
        <DSQReasonDialogContent addModifier={addModifier} onClose={onClose} notes={notes}/>
    </ResponsiveDialog>;

const DSQReasonDialogContent = ({ addModifier, onClose, notes }) => {
    const onSubmit = values => addModifier("DSQ", values.notes).then(() => onClose());

    const fields = [{
        name: "notes",
        label: "Reason for disqualification (optional)",
        placeholder: "DSQ code and/or short description",
        type: "textarea",
        inputProps: { autoFocus: true }
    }];

    return (
        <>
            <DialogTitle id="dsq-reason-dialog-title" className="sticky-dialog-title" display="flex" justifyContent="space-between">
                <T>DSQ reason</T>
                <IconButton color="inherit" size="small" onClick={onClose} aria-label="close"><Close/></IconButton>
            </DialogTitle>
            <DialogFormKit
                fields={fields}
                initialValues={{ notes }}
                onSubmit={onSubmit}
                buttonLabel="Save"
                fullWidthSubmitButton
                enableOnPristine/>
        </>
    );
};
