import { useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Link } from "@mui/material";

import { useDialogState } from "../../../hooks/useDialogState";
import { GET_HEAT } from "../../../graphql/heat";
import { useRefocusEffect } from "../../../hooks/useRefocusEffect";
import { HeatBreakdownSkeleton } from "./HeatBreakdownSkeleton";
import { HeatBreakdown } from "./HeatBreakdown";
import { LoadingLeaderboardModal } from "../leaderboard/LeaderboardModal";
import { DesktopOrMobileModal } from "../modal/DesktopOrMobileModal";

export const HeatModalWithTrigger = ({ heat, heatsInRound, children }) => {
    const [open, openModal, closeModal] = useDialogState();

    return (
        <>
            <Link underline="none" color="inherit" variant="inherit" onClick={openModal}>{children}</Link>
            <HeatModal open={open} heat={heat} heatsInRound={heatsInRound} closeModal={closeModal}/>
        </>
    );
};

export const LoadingModalWithTrigger = ({ heat, linkVariant = "inherit", isLeaderboard, onClose, children }) => {
    const [open, openModal, closeModal] = useDialogState();
    const ModalComponent = (heat.config?.runBased || isLeaderboard) ? LoadingLeaderboardModal : LoadingHeatModal;

    const handleClose = useCallback((...args) => {
        onClose && onClose();
        closeModal(...args);
    }, []);

    return (
        <>
            <Link underline="none" color="inherit" variant={linkVariant} onClick={openModal}>{children}</Link>
            <ModalComponent open={open} heat={heat} closeModal={handleClose}/>
        </>
    );
};

export const LoadingHeatModal = ({ heat, open, closeModal }) => {
    const [getHeat, { data, refetch }] = useLazyQuery(GET_HEAT, { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" });
    const heatId = heat?.id;

    useRefocusEffect(refetch, open);

    useEffect(() => {
        if (heatId && open) {
            getHeat({ variables: { id: heatId } });
        }
    }, [open, heatId, getHeat]);

    return <HeatModal open={open} heat={data?.heat} heatsInRound={2} closeModal={closeModal}/>;
};

export const HeatModal = ({ open, closeModal, heat, heatsInRound }) =>
    <DesktopOrMobileModal open={open} closeModal={closeModal}>
        {heat
            ? <HeatBreakdown heat={heat} heatsInRound={heatsInRound} closeModal={closeModal}/>
            : <HeatBreakdownSkeleton/>}
    </DesktopOrMobileModal>;
