import Immutable from "immutable";
import { Button, Typography } from "@mui/material";
import { SelectedDivision } from "components/divisions/divisionsSelector";
import { T } from "components/util/t";
import { useCallback, useMemo, useState } from "react";
import { uuid } from "utils/uuid";
import { useLocalizationContext } from "../../providers/LocalizationProvider";
import { makeStyles } from "components/providers/makeStyles";
import { Notification } from "components/layout/notifications/Notification";
import { Warning } from "@mui/icons-material";
import { SearchSelect } from "../forms/fields/Autocomplete/SearchSelect";
import { FieldMessage } from "../forms/FormField";
import { useController } from "react-hook-form";

export const validateDivisions = value =>
    value.filter(v => !v.get("ungroup")).size > 0 ? undefined : "Pick some divisions";

const useStyles = makeStyles(theme => ({
    label: {
        display: "block",
        marginBottom: theme.spacing(1)
    },
    notification: {
        marginTop: theme.spacing(2)
    }
}));

export const GroupDivisionsDialogSelector = ({ field, control }) => {
    const { field: input, fieldState } = useController({
        control,
        name: field.name,
        rules: { validate: validateDivisions }
    });
    const hasError = !!fieldState.error;
    const [addingDivision, setAddingDivision] = useState(false);
    const [warning, setWarning] = useState();
    const addDivision = useCallback(() => setAddingDivision(true), []);
    const classes = useStyles();

    const selectDivision = useCallback(({ value }) => {
        if (value == null) return;
        const division = field.divisions.find(division => division.get("id") === value);
        const alreadyAddedIndex = input.value.findIndex(division => division.get("id") === value);
        setWarning(null);

        if (division?.get("status") === "drawn") setWarning([division, "division_group_add_division"]);

        input.onChange(alreadyAddedIndex < 0 ?
            input.value.push(Immutable.Map({
                name: division?.get("name") || value,
                id: division?.get("id"),
                uuid: division?.get("id") ? undefined : uuid(),
                new: true
            })) :
            input.value.setIn([alreadyAddedIndex, "ungroup"], false));

        setAddingDivision(false);
    }, [input.value, field.divisions]);

    const deleteDivision = useCallback(index => {
        setWarning(null);

        if (!input.value.getIn([index, "new"]) && field.division?.get("status") === "drawn") {
            setWarning([input.value.get(index), "division_group_remove_division"]);
        }

        input.onChange(field.division?.get("id")
            ? input.value.setIn([index, "ungroup"], true)
            : input.value.delete(index));
    }, [input.value, field.division]);

    const availableDivisions = useMemo(() => {
        const alreadySelected = input.value.filter(v => !v.get("ungroup")).map(v => v.get("id"));
        return field.divisions
            .concat(field.division?.get("event_divisions") || Immutable.List())
            .filter(v => !alreadySelected.includes(v.get("id")) && !v.get("event_divisions")?.size);
    }, [field.division, field.divisions, input.value]);

    const { translate } = useLocalizationContext();

    return (
        <>
            <Typography variant="label1" component="label" className={classes.label}>
                <T>Select divisions:</T>
            </Typography>

            {input.value.map((division, index) =>
                division.get("ungroup")
                    ? null
                    : <SelectedDivision
                        key={division.get("id") || division.get("uuid")}
                        name={division.get("name")}
                        index={index}
                        deleteDivision={deleteDivision}
                        fullWidth
                    />
            )}

            {addingDivision
                ? <SearchSelect
                    hasError={hasError}
                    input={{ onChange: selectDivision }}
                    field={{
                        placeholder: translate("Select a division") + "...",
                        inputProps: { autoFocus: true },
                        options: availableDivisions.map(d => ({ value: d.get("id"), label: d.get("name") })).toJS()
                    }} />
                : availableDivisions.size > 0 ? <Button fullWidth variant="outlined" onClick={addDivision}><T>Add division</T></Button> : null
            }
            {hasError && <FieldMessage errorMessage={fieldState.error?.message}/>}

            {warning && <div className={classes.notification}>
                <Notification
                    Icon={Warning}
                    type="warning"
                    text={translate(warning[1], { divisionName: warning[0].get("name") })}/>
            </div>}
        </>
    );
};
