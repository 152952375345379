import classnames from "classnames";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(() => ({
    progress: {
        position: "absolute"
    }
}));
export const ProgressButton = ({ children, inProgress, className, startIcon, endIcon, ...rest }) => {
    const classes = useStyles();
    const inProgressWithoutStartEndIcons = inProgress && (!startIcon && !endIcon);

    return (
        <Button
            disabled={inProgress}
            className={classnames(className, { hideText: inProgressWithoutStartEndIcons })}
            startIcon={inProgress && startIcon ? <CircularProgress color="inherit" size={20}/> : startIcon}
            endIcon={inProgress && endIcon ? <CircularProgress color="inherit" size={20}/> : endIcon}
            {...rest}
        >
            {children}
            {inProgressWithoutStartEndIcons && <CircularProgress color="secondary" className={classes.progress} size={24}/>}
        </Button>
    );
};
