import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import { T } from "../../../../util/t";
import { ArrowForward, Close, WorkspacePremium } from "@mui/icons-material";
import { makeStyles } from "../../../../providers/makeStyles";
import { StatusPill } from "../../../StatusPill";

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.secondary.lighter,
        padding: theme.spacing(1.5, 2)
    },
    content: {
        backgroundColor: theme.palette.secondary.lighter,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(2)
    },
    list: {
        margin: 0,
        padding: theme.spacing(0, 2.5)
    }
}));
export const UpsellDocumentSigning = ({ closeDialog }) => {
    const classes = useStyles();

    return (
        <>
            <DialogTitle id="documents-dialog-title" display="flex" alignItems="center" justifyContent="space-between" className="sticky-dialog-title">
                <Typography variant="h6" component="p" display="flex">
                    <WorkspacePremium fontSize="inherit"/>
                    <T>Premium add-on required</T>
                </Typography>
                <IconButton color="inherit" size="small" onClick={closeDialog}><Close/></IconButton>
            </DialogTitle>
            <DialogContent className="sticky-dialog-content">
                <Box borderRadius={1} overflow="hidden" boxShadow={8}>
                    <Typography variant="label1" className={classes.title} component="div">
                        <T>To add documents for signing, please activate the required add-on</T>
                    </Typography>
                    <Box className={classes.content}>
                        <Box display="flex" alignItems="flexStart" justifyContent="space-between">
                            <div>
                                <Typography variant="h5"><T>e-Signatures</T></Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Powered by <Link href="https://www.docuseal.co" underline="always" variant="inherit" target="_blank">DocuSeal</Link>
                                </Typography>
                            </div>
                            <div>
                                <StatusPill status="on">
                                    <WorkspacePremium/>
                                    <T>PREMIUM ADD ON</T>
                                </StatusPill>
                            </div>
                        </Box>
                        <Box mt={1.5}>
                            <T>Ensure a smooth signing experience with secure e-signatures.</T>
                            <br/><br/>
                            <T>By activating this add-on, you'll be able to:</T>
                            <ul className={classes.list}>
                                <li><T>Collect signed waivers and forms in seconds.</T></li>
                                <li><T>Save time and keep documents organised.</T></li>
                                <li><T>Protect your liability for a hassle-free experience.</T></li>
                            </ul>
                            <br/><br/>
                            <T>Note: This integration requires a DocuSeal <Link href="https://www.docuseal.co/pricing" underline="always" variant="inherit" target="_blank">Pro plan</Link>.</T>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions className="sticky-dialog-actions">
                <Button onClick={closeDialog} sx={{ marginRight: "auto" }}><T>Close</T></Button>
                <Button variant="contained" endIcon={<ArrowForward/>} component="a" href="https://forms.clickup.com/26411634/f/t60kj-10776/W2KK7EWY82GW1S0908" target="_blank" rel="noopener noreferrer">
                    <T>Enquire about e-Signatures</T>
                </Button>
            </DialogActions>
        </>
    );
};
