import { useCallback } from "react";
import classnames from "classnames";
import { Button, ButtonGroup } from "@mui/material";

import { useButtonGroupStyles } from "../commonStyles/buttonStyles";
import { T } from "../../util/t";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    selector: {
        marginTop: theme.spacing(2),
        width: "100%"
    },
    button: {
        "&&": {
            minWidth: "auto"
        }
    }
}));
export const StartlistRidesResultOrLeaderboardSelector = ({ selected, setSelected, hasStartlist, usesLanes, showResults, hasLeaderboard, renderRidesTab }) => {
    const classes = useButtonGroupStyles();
    const selectorClasses = useStyles();
    const selectStartlist = useCallback(() => setSelected("startlist"), []);
    const selectRides = useCallback(() => setSelected("rides"), []);
    const selectResult = useCallback(() => setSelected("result"), []);
    const selectLeaderboard = useCallback(() => setSelected("leaderboard"), []);

    const heatOrResultButton = !(hasStartlist && hasLeaderboard && !showResults);
    const extraButtonClass = { [selectorClasses.button]: hasStartlist && heatOrResultButton && renderRidesTab && hasLeaderboard };

    return (
        <ButtonGroup className={`${classes.group} ${selectorClasses.selector}`} aria-label="Startlist, result or leaderboard selector">
            {hasStartlist &&
            <Button className={classnames(classes.button, extraButtonClass, { selected: selected === "startlist" })} onClick={selectStartlist}>
                <T>Start list</T>
            </Button>}

            {heatOrResultButton &&
            <Button className={classnames(classes.button, extraButtonClass, { selected: selected === "result" })} onClick={selectResult}>
                {hasLeaderboard ? <T>Heat</T> : <T>Result</T>}
            </Button>}

            {renderRidesTab &&
            <Button className={classnames(classes.button, extraButtonClass, { selected: selected === "rides" })} onClick={selectRides}>
                {usesLanes ? <T>Laps</T> : <T>Rides</T>}
            </Button>}

            {hasLeaderboard &&
            <Button className={classnames(classes.button, extraButtonClass, { selected: selected === "leaderboard" })} onClick={selectLeaderboard}>
                <T>Leaderboard</T>
            </Button>}
        </ButtonGroup>
    );
};
