import { api } from "./api";

let getHeatAbortController;

export const getCurrentHeats = (event_id) =>
    dispatch =>
        api.get(`events/${event_id}/current_heats`)
            .then(res => dispatch({
                type: "SET_CURRENT_JUDGE_HEATS",
                event_id,
                data: res.data
            }));

export const getHeat = (event_id, heat_id) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        getHeatAbortController = typeof AbortController !== "undefined" && new AbortController();
        return api.get(`heats/${heat_id}`, { signal: getHeatAbortController && getHeatAbortController.signal })
            .then(res => {
                getHeatAbortController = null;
                return dispatch({
                    type: "SET_CURRENT_JUDGE_HEAT",
                    event_id,
                    heat_id,
                    data: res.data
                });
            }).catch(e => {
                getHeatAbortController = null;
                if (e.name !== "AbortError") throw e;
            });
    };

export const addAthlete = (event_id, heat_id, athlete_heat, isDivisionTeamType, event_division_id) =>
    () => api.post(`heats/${heat_id}/add_athlete`, { athlete_heat, event_division_id });

export const updateAthleteHeat = (event_id, heat_id, athlete_heat_id, athlete_heat) =>
    () => api.put(`heats/${heat_id}/athlete_heats/${athlete_heat_id}`, { athlete_heat });

export const addScore = (event_id, heat_id, dispatchHeatId, ride_index, judge_id, parent_athlete_id, score) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "SET_HEAT_SCORE",
            event_id,
            heat_id,
            ride_index,
            judge_id,
            parent_athlete_id,
            score
        });
        return api.put(`heats/${dispatchHeatId}/add_score`, { score }, 1).catch(e => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
            else throw e;
        });
    };

export const addCategoryScore = (event_id, heat_id, dispatchHeatId, ride_index, judge_id, parent_athlete_id, score) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "SET_HEAT_CATEGORY_SCORE",
            event_id,
            heat_id,
            ride_index,
            judge_id,
            parent_athlete_id,
            score
        });
        return api.put(`heats/${dispatchHeatId}/add_category_score`, { score }).catch(e => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
            else throw e;
        });
    };

export const addModifier = (event_id, heat_id, dispatchHeatId, athlete_heat_id, team_position, parent_athlete_id, athlete_id, ride_index, dispatchRideIndex, type) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "ADD_MODIFIER",
            event_id,
            heat_id,
            team_position,
            parent_athlete_id,
            athlete_id,
            ride_index,
            modifier_type: type
        });
        return api.post(`heats/${dispatchHeatId}/modify`, {
            modifier: {
                athlete_heat_id,
                team_position,
                athlete_id,
                ride: dispatchRideIndex,
                type: `Modifiers::${type}`
            }
        }).catch((e) => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
        });
    };

export const removeModifier = (event_id, heat_id, dispatchHeatId, parent_athlete_id, athlete_id, ride_index, modifier_id) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "DELETE_MODIFIER",
            event_id,
            heat_id,
            parent_athlete_id,
            athlete_id,
            ride_index,
        });
        return api.delete(`heats/${dispatchHeatId}/modifier/${modifier_id}`).catch((e) => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
        });
    };

export const addScores = (event_id, heat_id, dispatchHeatId, ride_index, judge_id, parent_athlete_id, athlete_id, heat_config, scores) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "SET_HEAT_SCORES",
            event_id,
            heat_id,
            ride_index,
            judge_id,
            parent_athlete_id,
            athlete_id,
            heat_config,
            scores
        });
        return api.put(`heats/${dispatchHeatId}/upsert_scores`, { scores }).catch(e => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
            else throw e;
        });
    };

export const deleteScores = (event_id, heat_id, dispatchHeatId, ride_index, judge_id, parent_athlete_id, athlete_id, heat_config, scores) =>
    dispatch => {
        getHeatAbortController && getHeatAbortController.abort();
        dispatch({
            type: "DELETE_HEAT_SCORES",
            event_id,
            heat_id,
            ride_index,
            judge_id,
            parent_athlete_id,
            athlete_id
        });
        return api.put(`heats/${dispatchHeatId}/upsert_scores`, { scores }).catch(e => {
            if (e?.response?.status === 423) return dispatch(getHeat(event_id, heat_id));
            else throw e;
        });
    };
