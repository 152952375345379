import { useEffect, useRef } from "react";

export const useScrollIntoView = (selected, options = {}) => {
    const ref = useRef();
    useEffect(() => {
        if (selected && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "end", ...options });
        }
    }, [selected]);

    return ref;
};
