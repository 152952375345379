import { useCallback, useEffect, useRef, useState } from "react";

import success from "./success.m4a";
import error from "./error.m4a";
import alert from "./alert.m4a";

const getRecordFromRecordType = (message, recordType, matcher = /^(.*)$/) => {
    const record = message.records.find(record => record.recordType === recordType);
    const recordText = record && (new TextDecoder()).decode(record.data);

    return recordText?.match(matcher)?.[1];
};

export const useNFC = ({ onReading, onReadingError, onBadTag, onLoading }) => {
    const [nfcEnabled, setNFCEnabled] = useState("NDEFReader" in window);
    const [ndef, setNDEF] = useState([]);
    const abortController = useRef();

    const successAudio = useRef(new Audio(success));
    const errorAudio =  useRef(new Audio(error));
    const alertAudio =  useRef(new Audio(alert));

    useEffect(() => {
        if (ndef) {
            ndef.onreading = event => {
                onLoading && onLoading();

                const serialNumber = event.serialNumber;
                const humanReadableId =
                    getRecordFromRecordType(event.message, "text") ||
                    getRecordFromRecordType(event.message,"url", /\?serialNumber=(.+)$/);

                if (serialNumber) {
                    onReading({ serialNumber, humanReadableId })
                        .then(() => successAudio.current.play())
                        .catch(() => errorAudio.current.play());
                } else {
                    errorAudio.current.play();
                    onBadTag();
                }
            };

            ndef.onreadingerror = () => {
                alertAudio.current.play();
                onReadingError();
            };
        }
    }, [ndef, onReading, onReadingError, onBadTag]);

    const startScanning = useCallback(() => {
        if (nfcEnabled) {
            abortController.current = new AbortController();
            const ndef = new window.NDEFReader();

            ndef.scan({ signal: abortController.current.signal })
                .then(() => setNDEF(ndef))
                .catch(() => setNFCEnabled(false));
        }
    }, [nfcEnabled]);

    const stopScanning = useCallback(() => {
        abortController.current?.abort();
        abortController.current = null;
        setNDEF();
    }, [abortController]);

    useEffect(() => () => abortController.current?.abort(), []);

    return { nfcEnabled, startScanning, stopScanning };
};
