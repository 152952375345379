import { Typography } from "@mui/material";
import { JerseyAvatar } from "./JerseyAvatar";
import { T } from "../../util/t";
import { useHeatHeaderStyles, useHeatRankStyles } from "./heatStyles";
import { makeStyles } from "components/providers/makeStyles";
import { useAvatarStyles } from "../avatar/useAvatarStyles";

const useStyles = makeStyles(theme => ({
    text: {
        "@media print": {
            fontSize: "0.6rem",
        }
    },
    place: {
        width: theme.spacing(4.75),
        "@media print": {
            paddingLeft: theme.spacing(1.5),
            fontSize: "0.75rem",
        }
    }
}));

export const ProgressionRow = ({ heat, position, progression, jersey, withDragIcon }) => {
    const heatHeaderClasses = useHeatHeaderStyles();
    const heatRankClasses = useHeatRankStyles();

    const classes = { ...useAvatarStyles(), ...useStyles() };

    return (
        <>
            {heat.config.hasStartlist &&
            <Typography variant="body1" className={withDragIcon ? heatRankClasses.position : heatHeaderClasses.place}>
                {heat.config.usesLanes ? position : position + 1}
            </Typography>}

            <section className={classes.athlete}>
                <JerseyAvatar alt="?" jersey={jersey} className={classes.jerseyAvatar}/>

                <Typography variant="body2" color="textSecondary" className={classes.text}>
                    <T progression={progression} isTeams={heat.config.isTeams}>{progression.obscure ? "heat_progression_obscure" : "heat_progression"}</T>
                </Typography>
            </section>
        </>
    );
};
