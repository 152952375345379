import { makeStyles } from "../../providers/makeStyles";
import { nSpaces } from "../../../utils/utils";

const useFakeChipStyles = makeStyles(theme => ({
    chip: {
        borderRadius: theme.spacing(15),
        backgroundColor: theme.palette.background.low,
    }
}));
export const FakeChip = () => {
    const classes = useFakeChipStyles();
    return <span className={classes.chip}>{nSpaces(18)}</span>;
};
