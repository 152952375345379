import classnames from "classnames";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "components/util/t";
import { JerseyAvatar } from "./JerseyAvatar";
import { useTableStyles } from "components/layout/commonStyles/tableStyles";
import { toFixedOrDash } from "../eventDivision/leaderboard/TeamRow";
import { useTeamRoleStyles } from "../dialogs/team/useTeamRoleStyles";

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: 50,
        "@media print": {
            marginLeft: theme.spacing(3),
        },
        "& thead th": {
            textTransform: "uppercase",
        },
        "& thead th, & tbody td": {
            borderBottom: 0
        },
        "& th:last-of-type": {
            paddingLeft: theme.spacing(1),
            display: "block"
        }
    },
    athlete: {
        display: "flex",
        alignItems: "center",
        "&&": {
            width: "100%",
            padding: theme.spacing(0.5, 0, 0.5, 1),
        }
    },
    athleteName: {
        marginLeft: theme.spacing(1)
    },
    dropped: {
        textDecoration: "line-through"
    }
}));

export const TeamBreakdown = ({ teamConfig, teamMembers, result, hasModifier }) => {
    const tableClasses = useTableStyles();
    const teamRoleStyles = useTeamRoleStyles({ roleSelected: true });
    const classes = useStyles();

    if (!teamMembers.length) return null;

    return (
        <table className={`${tableClasses.table} ${classes.table}`}>
            <thead>
                <tr>
                    <th>
                        <Typography variant="label2" color="textSecondary">
                            {result
                                ? <T>Place</T>
                                : <T>Start</T>}
                        </Typography>
                    </th>
                    <th>
                        <Typography variant="label2" color="textSecondary"><T>Athlete</T></Typography>
                    </th>
                </tr>
            </thead>
            <tbody>
                {teamMembers.map(member => {
                    if (member.order >= teamConfig.athletesPerTeam) return null;

                    const modifierClass = result?.rides?.[member.athlete.id]?.[0]?.modifier?.type?.split("::")?.[1];
                    const resultOrOrder = modifierClass ? modifierClass : result
                        ? toFixedOrDash(result.rides?.[member.athlete.id]?.[0]?.modified_total, 0)
                        : member.order + 1;
                    return (
                        <tr key={member.order}>
                            <td className={classnames({ [classes.dropped]: hasModifier && !modifierClass })}>
                                {resultOrOrder}
                            </td>
                            <td className={classes.athlete}>
                                <JerseyAvatar alt={member.athlete.name} src={member.athlete.image} />
                                <Stack className={classes.athleteName} spacing={.5}>
                                    <span>{member.athlete.name}</span>
                                    {member.teamRoleName && <div className={teamRoleStyles.assign}>
                                        <Typography variant="bodyMono2">{member.teamRoleName}</Typography>
                                    </div>}
                                </Stack>

                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
