import classnames from "classnames";

import { Fake } from "../../../layout/skeleton/Fake";
import { JerseyAvatar } from "../../../layout/heat/JerseyAvatar";
import { useNFCTagPageStyles } from "./useNFCTagPageStyles";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "../../../providers/makeStyles";
import { useNFCTagViewStyles } from "../../../layout/nfc/useNFCTagViewStyles";

const useStyles = makeStyles(theme => ({
    skeletonButton: {
        backgroundImage: "none",
        "&.Mui-disabled": {
            opacity: 1,
            backgroundColor: theme.palette.background.low,
        },
    }
}));

export const NFCTagPageSkeleton = () => {
    const classes = useStyles();
    const NFCTagPageclasses = useNFCTagPageStyles();
    const NFCTagViewClasses = useNFCTagViewStyles();

    return (
        <main className={classnames(NFCTagPageclasses.page, "skeleton")}>
            <Typography variant="button" component="p"><Fake size={40}/></Typography>
            <div className={NFCTagViewClasses.athlete}>
                <JerseyAvatar className={NFCTagViewClasses.avatar} avatarProps={{ size: "xxl" }} />
                <Typography variant="h2"><Fake size={45}/></Typography>
                <Typography variant="subtitle1" className={NFCTagViewClasses.subtitle}><Fake size={22}/></Typography>
            </div>
            <Button disabled={true} variant="contained" className={classes.skeletonButton} component={"span"}><Fake size={35}/></Button>
        </main>
    );
};
