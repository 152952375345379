import { createContext, useContext } from "react";

const EventContext = createContext({});
EventContext.displayName = "Event";

export const useEvent = () => useContext(EventContext).event;
export const useEventObject = () => useContext(EventContext).eventObject;
export const useEventChannel = () => useContext(EventContext).eventChannel;

export const EventProvider = EventContext.Provider;
