import { ScrollWithShadows } from "components/layout/utils/ScrollWithShadows";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LiveIndicator } from "components/layout/organisation/LiveIndicator";
import { T } from "components/util/t";
import { Section } from "./HomePage";
import { Fake } from "../../layout/skeleton/Fake";
import { useFeaturedEventsStyles } from "./useFeaturedEventsStyles";

export const FeaturedEventsSkeleton = () => {

    return (
        <Section className="skeleton" marginTop={4}>
            <Stack direction="row" spacing={1} marginBottom={2} paddingX={2}>
                <Box color="status.livePulse" paddingTop={2}>
                    <LiveIndicator animated={false} size="md" />
                </Box>
                <Stack direction="column" color="text.primary">
                    <T>live_now_heading</T>
                </Stack>
            </Stack>
            <ScrollWithShadows shadowsColor="background.default">
                <Stack paddingX={2} spacing={2} direction="row">
                    <FakeEventPair />
                    <FakeEventPair />
                    <FakeEventPair />
                    <FakeEventPair />
                </Stack>
            </ScrollWithShadows>
        </Section>
    );
};

const FakeEventPair = () =>
    <Stack direction="column" spacing={2}>
        <FakeEventCard/>
        <FakeEventCard/>
    </Stack>;

const FakeEventCard = () => {
    const classes = useFeaturedEventsStyles();

    return (
        <Paper variant="outlined" className={classes.card}>
            <Stack spacing={1.25} direction="column" padding={2} className={classes.mainSection}>
                <Typography variant="label2"><Fake size={28} /></Typography>
                <Typography variant="h7"><Fake size={50} /></Typography>
                <Typography variant="label2"><Fake size={18} /></Typography>
                <Typography variant="body2"><Fake size={20} /></Typography>
            </Stack>
            <div className={classes.detailSection}>
                <Fake size={5} />
            </div>
        </Paper>
    );
};
