import { useError } from "hooks/useNotifications";
import { createContext, useContext, useRef } from "react";

const RequestsQueueContext = createContext({});
RequestsQueueContext.displayName = "RequestsQueue";

export const useRequestsQueue = (queueId) => {
    if (!queueId) throw new Error("queueId is required");
    const { enqueue } = useContext(RequestsQueueContext);

    return { enqueue: props => enqueue(queueId, props) };
};

export const RequestsQueueProvider = ({ children }) => {
    const callbacks = {};
    const queues = useRef({});
    const inProgress = useRef({});
    const { notifyError } = useError();

    const enqueue = (queueId, { request, onAllCompleted, onError }) => {
        queues.current[queueId] = queues.current[queueId] ? [...queues.current[queueId], request] : [request];
        callbacks.current = { onAllCompleted, onError };

        if (!inProgress.current[queueId]) return processQueue(queueId);
    };

    const processQueue = queueId => {
        inProgress.current[queueId] = true;
        const { onAllCompleted, onError } = callbacks.current;

        return queues.current[queueId].shift()().then(() => {
            if (queues.current[queueId].length) {
                processQueue(queueId);
            } else {
                onAllCompleted && onAllCompleted();
                inProgress.current[queueId] = false;
            }
        }).catch(() => {
            queues.current[queueId] = [];
            inProgress.current[queueId] = false;
            notifyError("Oops, something went wrong!");
            onError && onError();
        });
    };

    return (
        <RequestsQueueContext.Provider value={{ enqueue }}>
            {children}
        </RequestsQueueContext.Provider>
    );
};
