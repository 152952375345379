import { MaxWidthPageWithHeading } from "../../layout/page/MaxWidthPageWithHeading";
import { EditOrganisationPageSkeleton } from "./edit/EditOrganisationPageSkeleton";

export const DirectorOrganisationRootSkeleton = () => {
    return (<MaxWidthPageWithHeading className="skeleton" title="Organisation settings">

        <EditOrganisationPageSkeleton />
    </MaxWidthPageWithHeading>
    );
};
