import { Typography } from "@mui/material";

import { AthleteInfo } from "./AthleteInfo";
import { useHeatHeaderStyles, useHeatRankStyles } from "./heatStyles";

export const AthleteRow = ({ heat, position, withDragIcon, ...rest }) => {
    const heatHeaderClasses = useHeatHeaderStyles();
    const heatRankClasses = useHeatRankStyles();

    return (
        <>
            {heat.config.hasStartlist &&
            <Typography variant="body1" className={withDragIcon ? heatRankClasses.position : heatHeaderClasses.place}>
                {heat.config.usesLanes ? position : position + 1}
            </Typography>}
            <AthleteInfo {...rest}/>
        </>
    );
};
