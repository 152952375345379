import { makeStyles } from "components/providers/makeStyles";
import { Tooltip } from "./tooltip";
import { Help } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const useStyles = makeStyles(theme => ({
    tooltip: {
        display: "inline-block",
        color: theme.palette.text.link,
        textAlign: "center",
    },
}));

export const HelpTooltip = ({ className = "", children }) => {
    const classes = useStyles();

    return (
        <Tooltip title={children} childrenWrapperClass={`${classes.tooltip} ${className}`}>
            <IconButton size="small">
                <Help/>
            </IconButton>
        </Tooltip>
    );
};
