import { SimpleListItem } from "components/layout/lists/SimpleList";
import { Fake } from "components/layout/skeleton/Fake";

export const EmailAthletesSentMessagePageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <ListItemSkeleton title={50} caption={65} aside={15}/>
        <ListItemSkeleton title={50} caption={65} aside={15}/>
        <ListItemSkeleton title={50} caption={65} aside={15}/>
    </div>
);


const ListItemSkeleton  = ({ title, caption, aside }) => <SimpleListItem title={<Fake size={title}/>} description={<Fake size={caption}/>} aside={<Fake size={aside}/>} />;