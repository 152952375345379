import { useIsMobile } from "hooks/useIsMobile";

import { MobileDrawSkeleton } from "../../../../../layout/eventDivision/MobileDrawSkeleton";
import { DesktopDrawSkeleton } from "../../../../../layout/eventDivision/DesktopDrawSkeleton";

export const EventDivisionPageSkeleton = () => {
    const isMobile = useIsMobile();

    return (
        <div className="skeleton">
            {isMobile ? <MobileDrawSkeleton/> : <DesktopDrawSkeleton/>}
        </div>
    );
};
