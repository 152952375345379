import { Box, Container, List, Typography } from "@mui/material";
import { T } from "../../../../util/t";
import { SimpleListItem } from "components/layout/lists/SimpleList";
import { Fake } from "../../../../layout/skeleton/Fake";
import { ChevronRight } from "@mui/icons-material";

export const ReportsPageSkeleton = () => {
    return (
        <Box className="skeleton" role="status" aria-label="loading">
            <Container sx={{ margin: 0 }}>
                <Typography variant="h3" component="h1" pt={2} pb={3}><T>Reports</T></Typography>
                <List sx={{ p: 0 }}>
                    <SimpleListItem subtitle={<Fake size={42}/>} aside={<ChevronRight />} />
                    <SimpleListItem subtitle={<Fake size={42}/>} aside={<ChevronRight />} />
                </List>
            </Container>
        </Box>
    );
};
export default ReportsPageSkeleton;
