import { useEffect } from "react";
import chunk from "lodash/chunk";

import * as HeatActions from "../../../actions/heat";
import {
    HeatCardWrapper,
    AthletePlaceAndName,
    resultsByAthletePosition,
    resultsByResultOrder,
} from "./card";
import { toFixedOrDash } from "./editHeat";

export const CompactAthleteResult = ({ place, athlete, result, displayScores, isPlaceBased, thisRun }) => {
    const jersey = athlete.get("jersey"),
        total = result.get("total", 0).toFixed(2),
        flattenedRides = result.get("rides").valueSeq().flatten(1),
        topRides = (thisRun
            ? flattenedRides.filter(r => r.get("heat_id") === thisRun && !r.get("pending"))
            : flattenedRides.filter(r => r.get("scoring_ride")))
            .map(w => toFixedOrDash(w.get("modified_total"), 2));

    if (isPlaceBased) {
        displayScores = false;
        if (!parseInt(total)) place = "";
    }

    const isScored = total > 0;

    return (
        <tr className={`compact-result ${jersey || "no-jersey"}`}>
            <AthletePlaceAndName {...{ jersey, place: displayScores ? isScored ? place : "-" : isPlaceBased ? place : "", name: athlete.get("name"), image: athlete.get("image"), bib: athlete.get("bib"), teamName: athlete.get("team_name") }}/>

            {displayScores &&
            <td className="total">
                {total > 0 ? total : "-"}
            </td>}

            {displayScores &&
            <td className="top-rides">
                {topRides.join(" + ")}
            </td>}

            {!displayScores && isPlaceBased && <td colSpan="2"/>}
        </tr>
    );
};

export const TinyHeatResults = ({ heat, hideFinal }) => {
    const displayScores = !!heat.get("start_time") && !(hideFinal && /^final$/i.test(heat.get("round"))),
        athleteSpots = !displayScores ?
            resultsByAthletePosition(heat) :
            resultsByResultOrder(heat);
    const isPlaceBased = heat.getIn(["config", "calculator"]) === "placing";

    return (
        <HeatCardWrapper heat={heat} className="tiny-heat-card tiny-heat-results">
            {athleteSpots.map((spot, i) =>
                spot && <CompactAthleteResult key={i}
                                              place={spot.get("place")}
                                              athlete={spot.get("athlete")}
                                              result={spot.get("result")}
                                              isPlaceBased={isPlaceBased}
                                              displayScores={!isPlaceBased && displayScores}/>
            )}
        </HeatCardWrapper>
    );
};

export const TinyHeatDetails = ({ heat, isNext }) => {
    const athletes = chunk(heat.get("athletes").valueSeq().sortBy(a => a.get("position")).toArray(), 3);

    return (
        <HeatCardWrapper heat={heat} isNext={isNext} className="tiny-heat-card tiny-heat-details">
            {athletes.map((chunk, i) =>
                <tr key={i}>
                    {chunk.map((athlete, i) =>
                        <td className={`athlete ${athlete.get("jersey")}`} key={i}>
                            {athlete.get("name")}
                        </td>
                    )}
                </tr>
            )}
        </HeatCardWrapper>
    );
};

export const TinyLoadingHeatCard = ({ dispatch, eventId, heat, component: Component, ...rest }) => {
    useEffect(() => {
        if (!heat.get("result")) dispatch(HeatActions.get(eventId, heat.get("id")));
    }, [heat]);

    return heat.get("result") ? <Component heat={heat} {...rest}/> : null;
};
