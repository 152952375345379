import { makeStyles } from "../../providers/makeStyles";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useDispatch } from "react-redux";
import { addScore } from "../../../actions/judge";
import { scoreParser } from "../../forms/parsers/scoreParser";
import { SelfSubmittingField } from "../../layout/forms/selfSubmittingField/SelfSubmittingField";
import { useCallback } from "react";

const useStyles = makeStyles(theme => ({
    form: {
        height: "100%",
        "div": {
            height: "100%",
        },
        "div > div": {
            width: "100%",
            maxWidth: 70,
            borderRadius: 0,
            borderWidth: 2,
            borderColor: "transparent",
            "&:hover": {
                borderColor: "transparent",
            },
            "&.error:focus-within, &.error:hover": {
                borderColor: theme.palette.input.error,
            }
        },
        input: {
            width: "100%",
            height: "100%",
            padding: 0,
            textAlign: "center"
        }
    },
}));
export const ScoreForm = ({
    eventId,
    dispatchHeatId,
    parentAthleteId,
    heatId,
    athleteHeatId,
    teamPosition,
    athleteId,
    rideIndex,
    dispatchRideIndex,
    score,
    category,
    judgingScale,
    judgingDecimals,
    postSubmit
}) => {
    const { currentUser: { id: judgeId } } = useCurrentUser();
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSubmit = useCallback(values =>
        dispatch(addScore(eventId, heatId, dispatchHeatId, rideIndex, judgeId, parentAthleteId, {
            score: values.score,
            category,
            judge_id: judgeId,
            athlete_heat_id: athleteHeatId,
            team_position: teamPosition,
            athlete_id: athleteId,
            ride: dispatchRideIndex
        }))
    , [eventId, heatId, dispatchHeatId, rideIndex, judgeId, parentAthleteId, athleteHeatId, teamPosition, athleteId, dispatchRideIndex, category]);

    const field = {
        name: "score",
        type: "number",
        inputProps: { pattern: "[0-9]*", step: "any", autoFocus: true, autoComplete: "off" },
        parse: scoreParser(judgingScale, judgingDecimals)
    };

    return (
        <div className={classes.form}>
            <SelfSubmittingField
                onSubmit={onSubmit}
                initialValue={score != null ? score.toString() : ""}
                field={field}
                postSubmit={e => e.type !== "change" && postSubmit()}
                onEvent={e => e.type === "submit" && document.activeElement?.blur()}
            />
        </div>
    );
};
