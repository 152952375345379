import { Box, Typography } from "@mui/material";
import { FakeEvent } from "../../shortName/OrganisationRootSkeleton";
import { MaxWidthPage } from "../../../layout/page/MaxWidthPageWithHeading";
import { T } from "../../../util/t";

export const TeamManagerDashboardPageSkeleton = () =>
    <MaxWidthPage className="skeleton">
        <Typography variant="h3" component="h1" marginBottom={2}><T>Team manager dashboard</T></Typography>
        <Box role="status" aria-label="loading">
            <FakeEvent size={56}/>
            <FakeEvent size={32}/>
            <FakeEvent size={32}/>
        </Box>
    </MaxWidthPage>;
