import { Container } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";

import { MobileDrawSkeleton } from "../../../../../layout/eventDivision/MobileDrawSkeleton";
import { DesktopDrawSkeleton } from "../../../../../layout/eventDivision/DesktopDrawSkeleton";
import { EventDivisionHeaderSkeleton } from "../../../../../layout/eventDivision/EventDivisionHeaderSkeleton";
import { useRouteMatch } from "react-router-dom";
import { newManageEventPagesPath } from "../../../../../application";

export const EventDivisionRootSkeleton = () => {
    const isMobile = useIsMobile();
    const newEventManagementPathMatch = useRouteMatch(newManageEventPagesPath);

    return (
        <Container maxWidth={newEventManagementPathMatch ? false : "lg"} sx={{ px: newEventManagementPathMatch && [2] }} className="skeleton">
            <EventDivisionHeaderSkeleton/>

            {isMobile ? <MobileDrawSkeleton/> : <DesktopDrawSkeleton/>}
        </Container>
    );
};
