import classnames from "classnames";
import { useMemo } from "react";
import { Typography } from "@mui/material";

import { makeStyles } from "components/providers/makeStyles";
import { toFixedOrDash, toFixedOrEmpty } from "./TeamRow";
import { RideCategory } from "../../judging/RideCategory";
import { useHeatRideCellsStyles } from "components/layout/heat/heatStyles";

const scoreFlattener = scores => Object.keys(scores || {}).reduce((flatScores, judgeId) => {
    if (typeof scores[judgeId] === "number") flatScores.push({ category: "Judge", score: scores[judgeId], judgeId });
    else Object.keys(scores[judgeId]).forEach(category => flatScores.push({
        category,
        score: scores[judgeId][category],
        judgeId
    }));
    return flatScores;
}, []);
const useStyles = makeStyles(theme => ({
    score: {
        textAlign: "center",
        padding: theme.spacing(1, 2),
        "&.scoringRide": {
            background: theme.palette.success.lighter
        }
    },
    rideTotal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    rideCategory: {
        marginLeft: theme.spacing(0.5),
    }
}));
export const RideCells = ({ ride = {}, categories, Component = "td" }) => {
    const flatScores = useMemo(() => scoreFlattener(ride.scores), [ride.scores]);

    const modifier = ride.modifier,
        modifierClass = modifier ? modifier.type.split("::")[1] : "",
        didNotModifier = modifierClass.match(/D(N|S)(S|F|Q|I)/),
        scoreText = didNotModifier ? modifierClass : toFixedOrDash(ride.modified_total, 2);

    const classes = useStyles();
    const rideCellsClasses = useHeatRideCellsStyles();

    return (
        <>
            <Component className={classnames(classes.score, { scoringRide: ride.scoring_ride }, rideCellsClasses.firstCell)}>
                <div className={classes.rideTotal}>
                    <Typography variant="label1">
                        {scoreText}
                    </Typography>
                    {ride.category && <RideCategory category={ride.category} className={classes.rideCategory}/>}
                </div>
            </Component>
            {categories.map((category, index) => {
                const categoryScores = flatScores.filter(s => s.category === category.category);
                return [...Array(category.maxScores)].map((_, i) => {
                    const score = categoryScores[i] || {};
                    return (
                        <Component
                            key={`score${category.category}${score.judgeId || `${index}-${i}`}`}
                            className={classnames(classes.score, { scoringRide: ride.scoring_ride })}>
                            {toFixedOrEmpty(score.score, category.judging_decimals || 1)}
                        </Component>
                    );
                });
            })}
        </>
    );
};
