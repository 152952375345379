import { makeStyles } from "components/providers/makeStyles";

export const heatLeaderboardCardStyles = makeStyles((theme, { clickable = true, disabled }) => ({
    card: {
        padding: theme.spacing(1, 0),
        marginBottom: theme.spacing(1),
        cursor: clickable && "pointer",
        border: `2px solid ${theme.palette.border.light}`,
        borderRadius: theme.spacing(2),
        opacity: disabled && theme.shape.disabledOpacity,
        "@media print": {
            marginRight: theme.spacing(2)
        }
    }
}));
