import { makeStyles } from "components/providers/makeStyles";
import { HidePrinting } from "components/util/printing";

const useStyles = makeStyles(theme => ({
    remoteControlSpacer: {
        fontSize: theme.typography.subtitle2.fontSize,
        height: "5em"
    }
}));

export const RemoteControlSpacer = () => {
    const classes = useStyles();

    return (
        <HidePrinting>
            <div className={classes.remoteControlSpacer}>&nbsp;</div>
        </HidePrinting>
    );
};
