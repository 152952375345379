import { Fake } from "../../skeleton/Fake";
import { PreviewHeatCard } from "./PreviewHeatCard";
import { Typography } from "@mui/material";
import { T } from "../../../util/t";

export const PreviewRoundSkeleton = ({ numberOfHeats }) =>
    <section className="skeleton" role="status" aria-label="loading">
        <Typography variant="subtitle2" color="text.light"><T x="-">x_heats</T></Typography>
        {[...Array(numberOfHeats)].map((_, i) => <PreviewHeatCardSkeleton key={i}/>)}
    </section>;

const PreviewHeatCardSkeleton = () =>
    <PreviewHeatCard heatTitle={<Fake size={20}/>} competitorsText={<Fake size={20}/>}/>;
