import { makeStyles } from "../../providers/makeStyles";
import { useRouteMatch } from "react-router-dom";

const pageContainerStyles = makeStyles((theme, { isHomeOrIsRankings }) => ({
    pageContainer: {
        display: "inline-flex",
        flexDirection: "column",
        minWidth: "100%",
        minHeight: "100vh",
        maxWidth: isHomeOrIsRankings && "calc(100vw - (100vw - 100%))",
        "@media print": {
            position: "relative !important",
            background: "none !important"
        }
    }
}));

export const PageContainer = ({ children }) => {
    const isHome = useRouteMatch("/")?.isExact;
    const isRankings = useRouteMatch("*/rankings")?.isExact;
    const classes = pageContainerStyles({ isHomeOrIsRankings: isHome || isRankings });

    return (
        <div className={classes.pageContainer}>
            {children}
        </div>
    );
};
