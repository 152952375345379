import { makeStyles } from "components/providers/makeStyles";
import { Typography } from "@mui/material";

import { T } from "../../util/t";

const useStyles = makeStyles((theme, { borderless, disabled }) => ({
    header: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            flexWrap: "nowrap",
            "& .MuiButton-root": {
                whiteSpace: "nowrap"
            }
        },
    },
    section: {
        borderTop: !borderless && `1px solid ${theme.palette.border.light}`,
        paddingTop: theme.spacing(2),
        opacity: disabled && 0.5,
        cursor: disabled && "not-allowed"
    },
    title: {
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        marginTop: theme.spacing(1)
    },
    actions: {
        marginBottom: theme.spacing(2)
    }
}));
export const FormSection = ({ title, subtitle, actions, children, borderless = false, disabled }) => {
    const classes = useStyles({ borderless, disabled });

    return (
        <section className={classes.section}>
            <header className={classes.header}>
                <div className={classes.title}>
                    <Typography variant="h5"><T>{title}</T></Typography>
                    {subtitle && <Typography className={classes.subtitle} color="textSecondary"><T>{subtitle}</T></Typography>}
                </div>
                {actions && <aside className={classes.actions}>{actions}</aside>}
            </header>
            {children}
        </section>
    );
};
