import { Typography } from "@mui/material";
import { JerseyAvatar } from "../heat/JerseyAvatar";
import { PriorityBadge } from "../heat/badges/PriorityBadge";
import { makeStyles } from "components/providers/makeStyles";
import { useAvatarStyles } from "./useAvatarStyles";
import classNames from "classnames";

const useStyles = makeStyles({
    priorityContainer: {
        position: "relative"
    }
});

export const AvatarWithBibAndPriority = ({ athlete, bib, jersey, priority, avatarClassName, avatarProps }) => {
    const classes = { ...useAvatarStyles(), ...useStyles() };

    return (
        <div className={classes.priorityContainer}>
            {priority && <PriorityBadge jersey={jersey} priority={priority} />}
            <div className={classNames(classes.avatar, avatarClassName)}>
                <JerseyAvatar alt={athlete.name} src={athlete.image} jersey={jersey} className={classes.jerseyAvatar} avatarProps={avatarProps} />
                {bib && <Typography variant="label2" className="bib">{bib}</Typography>}
            </div>
        </div>
    );
};
