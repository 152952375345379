import { Component } from "react";
import Immutable from "immutable";

import * as EventActions from "../../actions/event";
import { connect } from "react-redux";
import { TinyHeatResults, TinyHeatDetails, TinyLoadingHeatCard } from "./heats/tinyCard";
import { LoadingHeatCard } from "./heats/card";
import { T } from "../util/t";
import { currentHeatsSelector, eventSelector } from "./selectors";

@connect(({ events }, { match: { params: { id } } }) => ({
    upcomingHeats: events.getIn(["schedules", id, "podiums"], Immutable.Map()).valueSeq()
        .map(heats => heats.slice(events.getIn(["schedules", id, "current_schedule_index"]) + 1))
        .flatten(1)
        .filter(h => h)
        .map(heatId => events.getIn(["heats", id, heatId]))
        .sortBy(h => h.get("scheduled_time")),
    previousHeats: events.getIn(["schedules", id, "podiums"], Immutable.Map()).valueSeq()
        .map(heats => heats.slice(0, events.getIn(["schedules", id, "current_schedule_index"])))
        .flatten(1)
        .filter(h => h)
        .map(heatId => events.getIn(["heats", id, heatId]))
        .sortBy(h => h.get("end_time"))
        .reverse(),
    currentHeats: currentHeatsSelector({ events }, { match: { params: { id } } })
        .filter(h => h),
    event: eventSelector({ events }, { match: { params: { id } } })
}))

export class Spectate extends Component {
    state = { heatToShow: 0 };

    componentDidMount = () => {
        this.heatsChanged();

        this.interval = setInterval(() => {
            this.setState({
                heatToShow: this.props.currentHeats.size === 1 ? 0 : this.state.heatToShow ? 0 : 1
            });
        }, 10000);
    };

    componentWillUnmount = () => clearInterval(this.interval);

    heatsChanged = () => {
        let { dispatch } = this.props;
        let event_id = this.props.match.params.id;

        dispatch(EventActions.getCurrentHeats(event_id));
    };

    render = () => {
        const { event, dispatch, currentHeats, previousHeats, upcomingHeats, match: { params: { id } } } = this.props,
            { heatToShow } = this.state,
            currentHeat = this.props.currentHeats.size === 1 ?
                this.props.currentHeats.first() :
                this.props.currentHeats.get(this.state.heatToShow);

        return !event.isEmpty() &&
        <div id="spectate">
            <div className="column">
                <h4>
                    <T>Live Heat</T>
                    {currentHeats.size > 1 && ` ${heatToShow + 1} of 2`}
                </h4>

                {currentHeat &&
                <LoadingHeatCard key={currentHeat.get("id")}
                                 dispatch={dispatch}
                                 eventId={id}
                                 heat={currentHeat}
                                 hideFinal={event.get("should_hide_finals")}
                                 isWhiteboard />}

                <div className="results">
                    {previousHeats.map(heat =>
                        <TinyLoadingHeatCard key={"tiny-result-" + heat.get("id")}
                                             dispatch={dispatch}
                                             eventId={id}
                                             component={TinyHeatResults}
                                             heat={heat}
                                             hideFinal={this.props.event.get("should_hide_finals")}/>
                    )}
                </div>
            </div>

            <div className="column">
                <h4><T>Upcoming</T></h4>
                <div className="upcoming">
                    {upcomingHeats.map((heat, i) =>
                        <TinyLoadingHeatCard key={"tiny-detail-" + heat.get("id")}
                                             dispatch={dispatch}
                                             eventId={id}
                                             component={TinyHeatDetails}
                                             heat={heat}
                                             isNext={i === 0 || (i === 1 && upcomingHeats.getIn([0, "scheduled_time"]) === upcomingHeats.getIn([1, "scheduled_time"]))}/>
                    )}
                </div>
            </div>
        </div>;
    };
}
