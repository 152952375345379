import { useRef, useState } from "react";
import { SearchRegExp, SearchRegExpCombined, nonWhitespacePattern } from "../utils/utils";

export const useSearch = () => {
    const inputRef = useRef();
    const [searchString, setSearchString] = useState("");

    const clearSearch = (skipFocus = false) => {
        setSearchString("");
        if (inputRef.current?.value) inputRef.current.value = "";
        if (!skipFocus) {
            inputRef.current?.focus();
        }        
    };

    return { inputRef, searchString, onSearch: setSearchString, clearSearch };
};

export const filterList = (list, searchString, matcher, sameWordsOrder) => {
    const words = searchString.match(nonWhitespacePattern);

    if (!words) return list;
    if (sameWordsOrder) return list.filter(item => matcher(item, SearchRegExpCombined(words)));
    return words.reduce((filteredList, word) => filteredList.filter(item => matcher(item, SearchRegExp(word))), list);
};

export const useListFilter = (list, matcher, skip, sameWordsOrder) => {
    const { inputRef, searchString, onSearch, clearSearch } = useSearch();

    const filteredList = skip ? list : filterList(list, searchString, matcher, sameWordsOrder);

    return { inputRef, searchString, onSearch, clearSearch, filteredList };
};

export const getFilteredList = (list, getLabel, searchString) => {
    return list.filter(item =>
        prepareForComparison(getLabel(item)).indexOf(prepareForComparison(searchString)) > -1
    );
};

const prepareForComparison = v => v.trim().toLowerCase();

export const useSimpleListFilter = (list, getLabel) => {
    const { inputRef, searchString, onSearch, clearSearch } = useSearch();
    const filteredList = getFilteredList(list, getLabel, searchString);

    return { inputRef, searchString, onSearch, clearSearch, filteredList };
};
