import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

import { useIsMobile } from "hooks/useIsMobile";
import { Fake } from "../skeleton/Fake";
import { T } from "components/util/t";

const useStyles = makeStyles(theme => ({
    list: {
        listStyle: "none",
        color: "white",
        background: theme.palette.brand.primary,
        position: "sticky",
        margin: 0,
        left: 0,
        width: "calc(100vw - (100vw - 100%))",
        display: "flex",
        padding: theme.spacing(1, 2),
        textOverflow: "ellipsis",
        "& a, a:visited": {
            color: "inherit"
        },
        "&.no-background": {
            width: "auto",
            background: "none",
            color: "inherit",
            padding: 0,
            marginBottom: theme.spacing(2),
        }
    }
}));
export const Breadcrumbs = ({ className, forceMobileView, noBackground, ...props }) => {
    const isMobile = useIsMobile();
    const classes = useStyles();

    return (
        <ol className={classnames(classes.list, className, { "no-background": noBackground })}>
            {isMobile || forceMobileView ? <MobileBreadcrumbs {...props}/> : <DesktopBreadcrumbs {...props}/>}
        </ol>
    );
};

const useMobileStyles = makeStyles(theme => ({
    link: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));
export const MobileBreadcrumbs = ({ crumbs = [{ link: "", text: "" }], tryToUseGoBack } ) => {
    const classes = useMobileStyles();
    const history = useHistory();
    const shouldUseGoBack = tryToUseGoBack && history.action !== "POP";

    return (
        <li>
            <Link className={classes.link} to={crumbs[crumbs.length - 1].link || "#"} onClick={shouldUseGoBack ? (e) => {
                e.preventDefault();
                history.goBack();
            } : null}>
                <ArrowBack className={classes.icon}/>
                <Typography variant="caption">{translateText(crumbs[crumbs.length - 1].text) || <Fake size={10}/>}</Typography>
            </Link>
        </li>
    );
};

const useDesktopStyles = makeStyles(theme => ({
    separator: {
        margin: theme.spacing(0, 1)
    }
}));
export const DesktopBreadcrumbs = ({ crumbs = [{ link: "", text: "" }], current = "" }) => {
    const classes = useDesktopStyles();

    return (
        <>
            {crumbs.map((crumb, i) =>
                <li key={i}>
                    <Typography variant="caption" component={Link} to={crumb.link || "#"}>{translateText(crumb.text) || <Fake size={10}/>}</Typography>
                    {i !== crumbs.length - 1 && <span className={classes.separator}>/</span>}
                </li>
            )}

            {current &&
            <li>
                <span className={classes.separator}>/</span>
                <Typography variant="caption">{current}</Typography>
            </li>}
        </>
    );
};

const translateText = text => text && typeof text === "string" ? <T>{text}</T> : text;
