import { Divider, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    divider: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(3),
    }
}));

export const PageSection = () => {
    const classes = useStyles();
    return (
        <Divider className={classes.divider} />
    );
};

const useSectionStyles = makeStyles(theme => ({
    section: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.low,
        borderRadius: theme.spacing(1.5),
        marginBottom: theme.spacing(2)
    }
}));
export const SectionWrapper = ({ title, children }) => {
    const classes = useSectionStyles();
    return (
        <div className={classes.section}>
            <Typography variant="subtitle2"><T>{title}</T></Typography>
            {children}
        </div>
    );
};
