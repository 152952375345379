import dayjs from "dayjs";
import { Badge } from "@mui/material";
import { useDragLayer } from "react-dnd";
import { LHIconButton } from "../actions/LHIconButton";
import { LONG_DATE_AND_TIME } from "utils/dates";
import { DivisionPill } from "../layout/division/DivisionPill";
import { T } from "components/util/t";
import { DateRange, DragIndicator } from "@mui/icons-material";

const dragLayers = {
    "athlete": ({ item }) =>
        <div className="name">
            <DragIndicator className="drag" />
            <span key="name">{item.name}</span>
        </div>,

    "heat": ({ item }) => {
        const heat = (
            <div className="heat">
                <div className="drag-handle"><DragIndicator fontSize="inherit" /></div>

                <span className="text">
                    <DivisionPill indicator={item.divisionIndicator}>{item.heatDivision}</DivisionPill>
                    <T>{item.heatRound}</T>
                    <div>
                        <T number={item.heatNumber}>
                            {item.heatType === "run" ? "run_short_title" : "heat_short_title"}
                        </T>
                    </div>
                </span>
            </div>
        );

        return item.selectedHeats.size < 2 ? heat :
            <Badge className="heat-badge" badgeContent={item.selectedHeats.size} color="primary">
                {heat}
            </Badge>;
    },

    "break": ({ item }) =>
        <div className="break">
            <div className="drag-handle"><DragIndicator className="drag-icon" fontSize="inherit"/></div>
            <span className="text">{dayjs(item.blockDate).format(LONG_DATE_AND_TIME)}</span>
            <span className="edit-icon">
                <LHIconButton className="tiny" title="Edit break">
                    <DateRange />
                </LHIconButton>
            </span>
        </div>
};

const NullItem = () => null;

export const CustomDragLayer = () => {
    const { item, itemType, currentOffset, isDragging } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }));

    const Component = dragLayers[itemType] || NullItem;

    const rotate = Math.min(1100/item?.width, 3).toFixed(0);
    const x = itemType === "athlete" ? currentOffset?.x  : currentOffset?.x - 5;
    const transform = `translate(${x}px, ${currentOffset?.y}px) rotate(${rotate}deg)`;

    const itemStyles = !currentOffset
        ? { display: "none" }
        : {
            width: `${item?.width}px`,
            height: `${item?.height}px`,
            transform: transform,
            WebkitTransform: transform
        };

    if (isDragging) return (
        <div className="drag-layer-wrapper">
            <div className="drag-item" style={itemStyles}>
                <Component item={item}/>
            </div>
        </div>
    );
};
