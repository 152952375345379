import { PreviewRound } from "./PreviewRound";

export const PreviewDrawWidget = ({ formatDefinition, previewDraw, refetch, loading }) => {
    const onHeatSizeChange = ({ round, size }) => {
        formatDefinition.current = {
            ...formatDefinition.current,
            heatSizes: {
                ...formatDefinition.current.heatSizes,
                [round]: size ? parseInt(size) : null
            }
        };
        return refetch({ formatDefinition: formatDefinition.current });
    };
    const onProgressionChange = ({ round, progression }) => {
        formatDefinition.current = {
            ...formatDefinition.current,
            progression: {
                ...formatDefinition.current.progression,
                [round]: progression
            }
        };
        return refetch({ formatDefinition: formatDefinition.current });
    };

    return (
        previewDraw.rounds.map((round, i) =>
            <PreviewRound
                key={round.roundPosition}
                round={{
                    ...round,
                    heatSize: formatDefinition.current.heatSizes?.[round.roundPosition] || "",
                    defaultHeatSize:  formatDefinition.current.heatSizes?.default || 4,
                    progression: formatDefinition.current.progression?.[round.roundPosition]?.[0],
                    defaultProgression: formatDefinition.current.progression?.default?.[0]
                }}
                isSingleRound={previewDraw.rounds.length === 1}
                onHeatSizeChange={onHeatSizeChange}
                onProgressionChange={i < previewDraw.rounds.length - 1 && onProgressionChange}
                isTeams={formatDefinition.current.isTeams}
                loading={loading}
            />)
    );
};
