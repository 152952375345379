import { SimpleList } from "../lists/SimpleList";
import { Fake } from "../skeleton/Fake";

export const SearchEventAndOrganisationResultsSkeleton = () => (
    <div role="status" aria-label="loading" className="skeleton">
        <SimpleList>
            {[...new Array(3)].map((_, key) => ({ key, title: <Fake size={50} />, description: <Fake size={30} /> }))}
        </SimpleList>
    </div>
);
