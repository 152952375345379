import { Container, Typography } from "@mui/material";

import { useEventHeaderStyles } from "../../../../layout/event/useEventHeaderStyles";
import { Breadcrumbs } from "../../../../layout/navigation/Breadcrumbs";
import { T } from "../../../../util/t";
import { RegistrationWizardSkeleton } from "../../../../layout/registration/RegistrationWizardSkeleton";

export const EventRegistrationPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();

    return (
        <Container className="skeleton" role="status" aria-label="loading">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <Typography variant="h3" component="h1" className={headerClasses.name}><T>Event registration</T></Typography>
            </div>
            <RegistrationWizardSkeleton/>
        </Container>
    );
};
