import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider, TouchTransition, PointerTransition } from "react-dnd-multi-backend";
import { useEnableScroll } from "./useEnableScroll";

export const DELAY_TOUCH_START = 300;

const HTML5toTouch = {
    backends: [
        {
            id: "html5",
            backend: HTML5Backend,
            preview: true,
            transition: PointerTransition
        },
        {
            id: "touch",
            backend: TouchBackend,
            options: { delayTouchStart: DELAY_TOUCH_START },
            preview: true,
            transition: TouchTransition
        },
    ],
};

export const MultiDnD = ({ children }) =>
    <DndProvider options={HTML5toTouch}>
        <AutoScroll/>
        {children}
    </DndProvider>;

const AutoScroll = () => {
    useEnableScroll();

    return null;
};
