import { useCallback } from "react";
import { Print } from "@mui/icons-material";
import { Button } from "@mui/material";
import { T } from "../../util/t";

export const PrintButton = () => {
    const print = useCallback(() => window.print(), []);

    return <Button variant="outlined" onClick={print} startIcon={<Print/>}><T>Print</T></Button>;
};
