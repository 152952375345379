import classnames from "classnames";
import { NavLink } from "./NavLink";
import { T } from "../../util/t";
import { RunEventMenu } from "../menus/RunEventMenu";

export const NavMenuLinks = ({ user = {}, className, anchorEl, openMenu, closeMenu, clickLink, isMobile }) => (
    <div className={classnames(className)}>
        {(!user.role) && <RunEventMenu isMobile={isMobile} clickLink={clickLink} anchorEl={anchorEl} openMenu={openMenu} closeMenu={closeMenu} />}

        {user.role === "judge" &&
        <NavLink to="/dashboard/judge" onClick={clickLink}><T>Events</T></NavLink>}

        {user.role === "director" &&
        <>
            <NavLink to="/director" onClick={clickLink}><T>Events</T></NavLink>
            <NavLink to="/director/rankings" onClick={clickLink}><T>Series</T></NavLink>
            <NavLink to="/director/athletes-teams" onClick={clickLink}><T>Athletes & teams</T></NavLink>

            {user.organisation?.stripe_account_type === "express" && <NavLink to="/director/payments" onClick={clickLink}><T>Payments (legacy)</T></NavLink>}
        </>}
    </div>
);
