import { useCallback, useState } from "react";
import { Button } from "@mui/material";
import { T } from "../../../../util/t";
import { ScanDialogContent } from "../../../../layout/nfc/ScanDialogContent";
import { SpotPlacing } from "../../../../layout/heat/edit/SpotPlacing";
import { AddToHeatAndRecordButton } from "./AddToHeatAndRecordButton";
import { AddToTeamAndRecordButton } from "./AddToTeamAndRecordButton";

export const ScanResultsDialog = ({ heat, competitors, nextPlace, resetCompetitors, event, clearEvent, setDisappearingEvent, closeDialog }) => {
    const isTeams = heat.getIn(["config", "is_teams"]);
    const teamAppraisal = heat.getIn(["config", "team_config", "appraisal_level"]) === "team";
    const reverse = heat.getIn(["config", "calculator_options", "reverse"]);
    const recorded = competitors
        .filter(competitor => competitor.modifier || competitor.place)
        .sort((a, b) => reverse ? (a.place || 0) - (b.place || 0) : (b.place || 0) - (a.place || 0));

    const [addToDivision, setAddToDivision] = useState();

    const onCancel = useCallback(() => {
        clearEvent();
        setAddToDivision(false);
    }, []);

    let message, actions;
    if (event?.type === "success") {
        message = { type: "success", value: teamAppraisal ? "Team recorded" : "Athlete recorded" };
    } else if (event?.type === "scanning") {
        message = { type: "scanning", value: <T>Connecting</T> };
    } else if (event?.type === "warning") {
        message = { type: "error", value: "Scanning failed, try again" };
    } else if (event?.type === "error") {
        if (!event.tagId) {
            message = { type: "error", value: "Faulty tag. Please try another" };
            actions = (
                <>
                    <Button variant="contained" onClick={clearEvent}><T>Scan another tag</T></Button>
                    <Button variant="outlined" onClick={closeDialog}><T>Record manually</T></Button>
                </>
            );
        } else if (event.athlete) {
            message = { type: "warning", value: <T name={event.athlete.name}>{isTeams ? "scanned_athlete_not_in_team_in_heat" : "scanned_athlete_not_in_heat"}</T> };
            actions = (
                <>
                    {isTeams
                        ? <AddToTeamAndRecordButton
                            athlete={event.athlete}
                            tagId={event.tagId}
                            heat={heat}
                            competitors={competitors}
                            nextPlace={nextPlace}
                            resetCompetitors={resetCompetitors}
                            setDisappearingEvent={setDisappearingEvent}
                        />
                        : <AddToHeatAndRecordButton
                            athlete={event.athlete}
                            tagId={event.tagId}
                            heat={heat}
                            nextPlace={nextPlace}
                            resetCompetitors={resetCompetitors}
                            setDisappearingEvent={setDisappearingEvent}
                            addToDivision={addToDivision}
                            setAddToDivision={setAddToDivision}
                        />}
                    <Button variant="outlined" onClick={onCancel}><T>Cancel</T></Button>
                </>
            );
        } else if (!event.humanReadableId) {
            message = { type: "error", value: "no_athlete_linked_to_tag_or_bad_scan" };
            actions = (
                <>
                    <Button variant="contained" onClick={clearEvent}><T>Scan again</T></Button>
                    <Button variant="outlined" onClick={closeDialog}><T>Record manually</T></Button>
                </>
            );
        } else {
            message = { type: "error", value: "No athlete linked to this tag" };
            actions = (
                <>
                    <Button variant="contained" onClick={clearEvent}><T>Scan another tag</T></Button>
                    <Button variant="outlined" onClick={closeDialog}><T>Record manually</T></Button>
                </>
            );
        }
    }

    return (
        <ScanDialogContent
            title="Scan to record"
            subtitle={<T heat={heat}>HeatHeader_title</T>}
            message={message}
            closeDialog={closeDialog}
            scanningActions={actions}
            heading={<T recorded={recorded.length} total={competitors.length}>{teamAppraisal ? "x_recorded_of_total_teams" : "x_recorded_of_total_athletes"}</T>}
        >
            {recorded.map(competitor => <SpotPlacing key={competitor.athleteId} heat={heat} competitor={competitor} nextPlace={nextPlace}/>)}
        </ScanDialogContent>
    );
};
