import Immutable from "immutable";

const hostToLang = Immutable.fromJS({
    com: "en",
    au: "en",
    jp: "ja",
    br: "pt",
    pt: "pt",
    es: "es",
    fr: "fr",
});

export const hostToLanguage = (win) => {
    if (typeof window !== "undefined" && !win) win = window;
    const lastHostPart = Immutable.List(win?.location.hostname.split(".")).last();

    return hostToLang.get(lastHostPart, "en");
};
