import { Avatar, Box, Typography } from "@mui/material";

import { useRowStyles, useTableStyles } from "../../../../../../layout/commonStyles/tableStyles";
import { Fake } from "../../../../../../layout/skeleton/Fake";
import { SecondaryHeading } from "../../../../../../layout/page/SecondaryHeading";

export const EntriesPageSkeleton = () => {
    const tableClasses = useTableStyles();

    return (
        <div className="skeleton">
            <SecondaryHeading heading={<Typography variant="h5"><Fake size={30}/></Typography>}/>

            <table className={tableClasses.table}>
                <thead>
                    <tr>
                        <th><Fake size={37}/></th>
                    </tr>
                </thead>
                <tbody>
                    <FakeRow size={47}/>
                    <FakeRow size={63}/>
                    <FakeRow size={58}/>
                </tbody>
            </table>
        </div>
    );
};

const FakeRow = ({ size }) => {
    const classes = useRowStyles();

    return (
        <tr className={classes.row}>
            <td className={classes.athlete}>
                <Box display="flex" alignItems="center">
                    <div className={classes.padded}><Avatar/></div>
                    <Fake size={size}/>
                </Box>
            </td>
        </tr>
    );
};
