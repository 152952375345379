import { makeStyles } from "components/providers/makeStyles";
import classNames from "classnames";
import logo from "assets/logos/LogomarkBrandBlack.svg";

const useStyles = makeStyles((theme) => ({
    logo: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),

        "& .icon svg .fill": {
            fill: theme.palette.text.primary
        }
    }
}));

export const HeaderLogo = ({ className }) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.logo, className)}>
            <img src={logo} alt="Liveheats" height={52}/>
        </div>
    );
};
