import { makeStyles } from "components/providers/makeStyles";

import { FormSection } from "../../../../layout/forms/FormSection";
import { JerseyAvatar } from "../../../../layout/heat/JerseyAvatar";
import { FakeFormField } from "../../../../layout/forms/FakeFormField";
import { Fake } from "../../../../layout/skeleton/Fake";

import { ListItem, ListItemContainer } from "components/layout/lists/ListItem";

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(2.5, 0)
    }
}));
export const EditAthletePageSkeleton = () => {
    const classes = useStyles();

    return (
        <div role="status" aria-label="loading">
            <div className="skeleton">
                <section className={classes.wrapper}>
                    <JerseyAvatar avatarProps={{ size: "xxl" }} />
                </section>

                <section>
                    <FakeFormField label={20} field={80}/>
                    <FakeFormField label={23} field={60}/>
                    <FakeFormField label={30} field={80}/>
                </section>
                <FormSection title="Linked users">
                    <FakeLinkedUser name={27} email={55}/>
                </FormSection>
            </div>
        </div>
    );
};


const FakeLinkedUser = ({ name, email }) => <ListItemContainer width={{ md: "60%" }}><ListItem title={<Fake size={name}/>} subtitle={<Fake size={email}/>}/></ListItemContainer>;
