import { useCallback } from "react";
import { ScanDialogForm } from "../nfc/ScanDialogForm";

export const TeamSelector = ({ onSubmit, teams }) => {
    const handleSubmit = useCallback(values => onSubmit(values.teamId), [onSubmit]);

    const fields = [{
        name: "teamId",
        label: "Teams",
        type: "select",
        options: [
            { label: "Select a team", value: "", disabled: true },
            ...teams.map(team => ({ label: team.athlete.name, value: team.id }))
        ]
    }];

    return <ScanDialogForm handleSubmit={handleSubmit} fields={fields} buttonLabel="Add to team"/>;
};
