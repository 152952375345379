import { Box, Container, Typography } from "@mui/material";
import { Breadcrumbs } from "components/layout/navigation/Breadcrumbs";
import { useEventHeaderStyles } from "components/layout/event/useEventHeaderStyles";
import { Fake } from "components/layout/skeleton/Fake";
import { makeStyles } from "components/providers/makeStyles";
import { HeatCardSkeleton } from "components/layout/heat/HeatCardSkeleton";

const useStyles = makeStyles(theme => ({
    button: {
        height: theme.spacing(5),
        display: "inline-block"
    }
}));

export const UserEventDivisionPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();
    const classes = useStyles();

    return (
        <Box component={Container} paddingBottom={2} className="skeleton" role="status" aria-label="loading">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" forceMobileView crumbs={[{}]}/>
                <Typography variant="h3" marginBottom={1}><Fake size={25}/></Typography>
                <Fake size={50} className={classes.button}/>
            </div>

            {[...Array(3)].map((_,i) => <HeatCardSkeleton key={i}/>)}
        </Box>
    );
};
