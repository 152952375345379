import { Stack, Typography } from "@mui/material";
import {
    DirectionsBike,
    DirectionsWalk,
    DownhillSkiing, ElectricScooter,
    Kitesurfing,
    PedalBike,
    Pool, Rowing,
    Skateboarding,
    Surfing, Terrain,
    Waves
} from "@mui/icons-material";
import { T } from "../../util/t";

const SPORT_TYPE_ICONS = {
    surf: <Surfing fontSize="sm"/>,
    skate: <Skateboarding fontSize="sm"/>,
    snow: <DownhillSkiing fontSize="sm"/>,
    sls: <Pool fontSize="sm"/>,
    wake: <Kitesurfing fontSize="sm"/>,
    skim: <Surfing fontSize="sm"/>,
    bodyboard: <Waves fontSize="sm"/>,
    bmx: <PedalBike fontSize="sm"/>,
    scooter: <ElectricScooter fontSize="sm"/>,
    paddle: <Rowing fontSize="sm"/>,
    freeride: <Terrain fontSize="sm"/>,
    fmb: <DirectionsBike fontSize="sm"/>,
};
export const EventDivisions = ({ sportType, divisions }) => {
    if (!divisions || divisions.length === 0) return null;

    return (
        <Stack direction="row" spacing={.5} alignItems="flex-start">
            <Typography variant="body2" color="text.primary" display="flex" alignItems="center" height={({ typography: { body2 } }) => body2.fontSize * body2.lineHeight}>
                {SPORT_TYPE_ICONS[sportType] || <DirectionsWalk fontSize="sm"/>}
            </Typography>

            {divisions.length === 1 ?
                <Typography variant="body2" color="text.primary">{divisions[0].name}</Typography>  :
                <T division={divisions[0].name} count={divisions.length - 1}>more_divisions</T>
            }
        </Stack>
    );
};
