import classnames from "classnames";
import { Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useHeatRankStyles } from "./heatStyles";

export const RankCell = ({ children, type, runBased, className }) => {
    const isMobile = useIsMobile();
    const heatRankClasses = useHeatRankStyles({ canScroll: !isMobile, result: true });

    return (
        <div className={className || classnames(heatRankClasses.position, { [heatRankClasses.place]: runBased })}>
            <Typography variant={type === "modifier" ? "label1" : "h4"}>{children}</Typography>
        </div>
    );
};
