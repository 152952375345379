import { gql } from "@apollo/client";

export const GET_PURCHASE_DETAILS = gql`
  query getPurchaseDetails($id: ID!) {
    payment(id: $id) {
        id
        amount
        currency
        purchasedOptions {
            athletes {
                name
                registrationsAttributes {
                    id
                    optionId
                }
                extras {
                    uuid
                    name
                    size
                }
            }
        }
    }
  }
`;

export const GET_PAYMENT_DETAILS = gql`
  query getPaymentDetails($id: ID!) {
    payment(id: $id) {
        id
        amount
        payable {
                ...on Event {
                    name
                }
                ...on Series {
                    name
                    membershipDivisions {
                        id
                    }
                }
        }
        fee
        intentId
        createdAt
        currency
        user {
            name
        }
        refunds {
            id
            amount
            status
        }
    }
  }
`;

export const GET_PAYMENT_STATUS = gql`
  query getPaymentStatus($id: ID!) {
    payment(id: $id) {
        id
        status
        registrationError
    }
  }
`;

export const REFUND_PAYMENT = gql`
  mutation refundPayment($id: ID!, $amount: Int!) {
    refundPayment(input: {id: $id, amount: $amount}) {
      payment {
        id
        user {
            name
        }
        payable {
            ...on Event {
                name
            }
            ...on Series {
                name
                divisions {
                    id
                }
            }
        }
        refunds {
            id
            amount
            status
        }
      }
      errors
    }
  }
`;
