import { useListFilter } from "./useListFilter";

export const useHeatCompetitorsFilter = competitors => {
    const {
        inputRef,
        searchString,
        onSearch,
        clearSearch,
        filteredList
    } = useListFilter(competitors, (competitor, searchRegex) =>
        competitor.athlete.name.match(searchRegex) || (competitor.athlete.teamName || "").match(searchRegex)
    );

    return { inputRef, searchString, onSearch, clearSearch, filteredCompetitors: filteredList };
};
