import { Typography } from "@mui/material";
import { FakeFormField, FakeInput } from "components/layout/forms/FakeFormField";
import { Fake } from "components/layout/skeleton/Fake";

export const EmailAthletesNewMessagePageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <Typography mt={4}>
            <Fake size={30}/>
        </Typography>

        <Typography>
            <Fake size={50}/>
        </Typography>

        <Typography mb={5}>
            <Fake size={30}/>
        </Typography>

        <FakeFormField label={20} field={150}/>
        <FakeFormField label={20} field={150} height={260}/>

        <Typography mt={2}>
            <FakeInput input={42} height={40} />
        </Typography>
    </div>
);
