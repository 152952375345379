import { SkeletonTable } from "components/layout/skeleton/SkeletonTable";
import { Fake } from "components/layout/skeleton/Fake";

export const NewRankingsPageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <div>
            <Fake size={30} />
        </div>
        <div>
            <Fake size={20} />
        </div>
        <br />
        <SkeletonTable />
    </div>
);
