import { api } from "./api";
import * as NotificationActions from "./notification";
import { notifyError } from "./error";
import * as EventDirectorActions from "actions/eventDirector";

export const createDivisionGroup = (event_id, division_group) =>
    dispatch =>
        api.post(`events/${event_id}/event_division_groups`, { division_group })
            .then(() => dispatch(EventDirectorActions.getEventDivisions(event_id)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Group successfully created. Nicely done 🙂")));

export const updateDivisionGroup = (id, event_id, division_group) =>
    dispatch =>
        api.put(`events/${event_id}/event_division_groups/${id}`, { division_group })
            .then(() => dispatch(EventDirectorActions.getEventDivisions(event_id)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Group successfully updated. Nicely done 🙂")));

export const deleteDivisionGroup = (id, event_id) =>
    dispatch =>
        api.delete(`events/${event_id}/event_division_groups/${id}`)
            .then(() => dispatch(EventDirectorActions.getEventDivisions(event_id)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Divisions successfully ungrouped. Nicely done 🙂")));
