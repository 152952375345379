import { MaxWidthPage } from "../../layout/page/MaxWidthPageWithHeading";
import { Fake } from "../../layout/skeleton/Fake";
import { useOrganisationStyles } from "../../layout/organisation/useOrganisationStyles";
import { makeStyles } from "../../providers/makeStyles";
import { Paper, Typography } from "@mui/material";
import { T } from "../../util/t";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import { useEventRowStyles } from "../../layout/organisation/useEventRowStyles";
import { FakeChip } from "../../layout/skeleton/FakeChip";
import { OrganisationAvatar } from "components/layout/avatar/OrganisationAvatar";

const useOrganisationRootSkeletonStyles = makeStyles(theme => ({
    nav: {
        borderBottom: `2px solid ${theme.palette.border.main}`,
        marginBottom: theme.spacing(4)
    },
    link: {
        fontWeight: "normal",
        marginRight: theme.spacing(4),
        display: "inline-flex",
        alignItems: "center",
        minHeight: theme.spacing(6)
    },
    chips: {
        padding: theme.spacing(0, 0, 1, 1)
    },
}));
export const OrganisationRootSkeleton = () => {
    const { shortName } = useParams();
    const classes = { ...useOrganisationStyles(), ...useOrganisationRootSkeletonStyles() };

    return(<>
        <MaxWidthPage className="skeleton">
            <Typography variant="h5" component="h1" className={classes.heading}>
                <OrganisationAvatar className={classes.avatar} size="xxl" />
                <section className={classes.name}>
                    {shortName}
                </section>
            </Typography>
            <div className={classes.chips}>
                <FakeChip />
            </div>
            <div className={classnames(classes.nav)}>
                <Typography key="1" className={classes.link} variant="button"><T>Home</T></Typography>
                <Typography key="2" className={classes.link} variant="button"><T>Events</T></Typography>
            </div>
            <section className={classes.section}>
                <Typography variant="h7" component="h2" className={classes.title}>Events</Typography>
                <FakeEvent size={43}/>
                <FakeEvent size={56}/>
                <FakeEvent size={32}/>
            </section>
        </MaxWidthPage>
    </>);
};

export const FakeEvent = ({ size }) => {
    const classes = useEventRowStyles();

    return(
        <section className={classes.event}>
            <Paper variant="rounded" className={classes.box}/>
            <Typography variant="button3" component="h1"><Fake size={size} /></Typography>
        </section>
    );
};

