import { Dialog, useTheme, useMediaQuery } from "@mui/material";

export const ResponsiveDialog = ({ children, ...dialogProps }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog fullScreen={fullScreen} {...dialogProps}>
            {children}
        </Dialog>
    );
};
