import classnames from "classnames";
import { Button, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { makeStyles } from "components/providers/makeStyles";
import { useTableStyles } from "components/layout/commonStyles/tableStyles";
import { T } from "components/util/t";
import { Add } from "@mui/icons-material";
import { Field } from "components/forms/Field";
import { useScrollIntoView } from "hooks/useScrollIntoView";
import { usePrevious } from "hooks/usePrevious";

const useStyles = makeStyles(theme => ({
    field: {
        "& > div": {
            marginBottom: 0,
            "& > div:last-of-type": {
                display: "none"
            }
        }
    },
    points: {
        "&&": {
            paddingLeft: theme.spacing(6)
        },
        width: "100%"
    },
    add: {
        marginTop: theme.spacing(2)
    },
    row: {
        height: theme.spacing(8)
    }
}));

export const PointsPerPlaceField = ({ field, control }) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const { fields, append } = useFieldArray({ control, name: field.name });
    const previousFieldsLength = usePrevious(fields.length);
    const addPlaceRef = useScrollIntoView(fields.length > previousFieldsLength ? fields.length : false, { behavior: "auto", block: "start" });

    const addPlace = () => append(null);

    return (
        <>
            <Typography variant="subtitle2" color="textSecondary" marginBottom={3}>
                <T>{field.label || "team_leaderboard_caption"}</T>
            </Typography>
            <table className={tableClasses.table}>
                <thead>
                    <tr>
                        <th>
                            <T>Place</T>
                        </th>
                        <th className={classes.points}>
                            <T>Points</T>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((f, index) => (
                        <tr key={f.id} className={classes.row}>
                            <td>
                                <Typography variant="h5" textAlign="center">{index + 1}</Typography>
                            </td>
                            <td className={classnames(classes.points, classes.field)}>
                                <Field
                                    field={{
                                        name: `${field.name}.${index}`,
                                        type: "number",
                                        parse: v => v ? parseInt(v) : null,
                                        inputProps: {
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }
                                    }}
                                    control={control}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Button onClick={addPlace} className={classes.add} ref={addPlaceRef}><Add /><T>Add place</T></Button>
        </>
    );
};
