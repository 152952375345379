import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/lib/immutable";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

import * as UserActions from "../../actions/user";

import { plainInput } from "../forms";
import { LoadingButton } from "../actions/loadingButton";
import { AccountFormPage } from "./common";
import { T } from "../util/t";
import { Notification } from "../layout/notifications/Notification";

@connect()
@reduxForm({ form: "forgotPassword", touchOnChange: true })
export class ForgotPasswordForm extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
    };

    state = {};

    submit = (values) => {
        const { dispatch } = this.props;
        return dispatch(UserActions.forgotPassword(values.get("email")))
            .then(() =>
                this.setState({
                    error: null,
                    message: "An email has been sent with instructions on how to reset your password."
                }))
            .catch(e =>
                this.setState({
                    error: (e.response && e.response.status === 422 && "Uh oh. That email address does not have a LiveHeats account yet.") ||
                        "Oops, something went wrong!",
                    message: null
                }));
    };

    render = () =>
        <>
            <form onSubmit={this.props.handleSubmit(this.submit)}>
                {this.state.message && <Notification type="success" className="margin-bottom" text={this.state.message}/>}
                {this.state.error && <Notification type="warning" className="margin-bottom" text={this.state.error}/>}

                <Field name="email"
                       label={<T>Email:</T>}
                       type="email"
                       component={plainInput}/>

                <div className="actions">
                    <LoadingButton variant="contained" action={this.props.handleSubmit(this.submit)}
                                   disabled={this.props.pristine}
                                   type="submit"
                                   color="primary">
                        <T>Get reset link</T>
                    </LoadingButton>
                </div>
            </form>

            <div className="actions change-page-action">
                <MuiLink to={{ pathname: "/login", state: this.props.location?.state }} component={Link}><T>Log in</T></MuiLink>
            </div>
        </>;
}

export const ForgotPasswordPage = (props) =>
    <AccountFormPage title="Password reset" actionText="To reset your password, enter the email address you use to sign in.">
        <ForgotPasswordForm {...props} />
    </AccountFormPage>;
