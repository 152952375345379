import { Typography } from "@mui/material";
import { SearchEventAndOrganisationInput } from "components/layout/home/SearchEventAndOrganisation";
import { T } from "components/util/t";
import background from "assets/graphics/Header-pattern-overlay.svg";
import { makeStyles } from "components/providers/makeStyles";
import { Search } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.brand.tertiary,
        backgroundImage: `url("${background}")`,
        backgroundPosition: "center center",
        height: parseInt(theme.spacing(26.5)) - 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    box: {
        padding: theme.spacing(2),
        width: theme.spacing(100),
        position: "relative",
        margin: `0 ${theme.spacing(2)}`,
        backdropFilter: `blur(${theme.spacing(1.5)})`,
        borderRadius: theme.spacing(1.5),
        overflow: "hidden",
    },
    content: {
        position: "relative",
        zIndex: 2,
    },
    background: {
        position: "absolute",
        backgroundColor: theme.palette.background.low,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0.83,
        zIndex: 1,
        pointerEvents: "none",
    },
    searchHeading: {
        display: "flex",
        marginBottom: theme.spacing(1),
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(0.25),
    }
}));

export const Header = () => {
    const classes = useStyles();

    return (
        <header className={classes.container}>
            <div className={classes.box}>
                <div className={classes.content}>
                    <div className={classes.searchHeading}>
                        <Search className={classes.icon}/>
                        <Typography variant="h5"><T>Find an action sports event</T></Typography>
                    </div>
                    <SearchEventAndOrganisationInput />
                </div>
                <div className={classes.background} />
            </div>
        </header>
    );
};
