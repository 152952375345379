import { PureComponent } from "react";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import { translateText } from "../../utils/utils";
import PropTypes from "prop-types";
import Immutable from "immutable";

@connect(state => ({ localization: state.localization }))
export class LHIconButton extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        funcTitleProps: PropTypes.func,
        localization: PropTypes.instanceOf(Immutable.Map).isRequired,
    };

    render() {
        const { dispatch, title, localization, funcTitleProps, children, ...rest } = this.props;
        const translatedText = translateText({ text: title, localization, props: funcTitleProps });

        return (
            <IconButton {...rest} title={translatedText}>
                {children}
            </IconButton>
        );
    }
}
