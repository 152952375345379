import { Box, Paper, Stack } from "@mui/material";

const Footer = ({ children, padding }) => children && (
    <Box padding={padding}>
        {children}
    </Box>
);

export const Card = ({ children, backgroundColor, footer, ...rest }) => (
    <Paper variant="outlined" component={Box} sx={{ borderRadius: 2, borderColor: "border.light", borderWidth: 2 }} overflow="hidden" {...rest}>
        <Stack spacing={2} backgroundColor={backgroundColor} padding={2}>
            {children}
        </Stack>
        <Footer padding={2}>
            {footer}
        </Footer>
    </Paper>
);
