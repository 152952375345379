import { eventDate } from "../../events/eventDate";
import { EventLinkRow } from "../../layout/event/EventLink";

export const EventLink = ({ event, link }) =>
    <EventLinkRow
        link={link}
        name={event.name}
        date={eventDate({ date: event.date, window: event.daysWindow })}
        status={event.status}
    />;
