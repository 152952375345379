const isNumber = value => value !== undefined && /\d/.test(value);
const parsePriceWithDecimal = value => value ? Math.round(parseFloat(value) * 100) : value;
const formatPriceWithDecimal = value => isNumber(value) ? value/100 : "";
const parsePriceZeroDecimal = value => value ? parseInt(value) : value;
const formatPriceZeroDecimal = value => isNumber(value) ? value : "";
const parsers = { JPY: parsePriceZeroDecimal };
const formatters = { JPY: formatPriceZeroDecimal };

export const priceParser = currency => parsers[currency] || parsePriceWithDecimal;
export const priceFormatter = currency => formatters[currency] || formatPriceWithDecimal;

export const isRefunded = payment =>
    payment.refunds?.length > 0;

export const totalRefunds = payment =>
    isRefunded(payment) ? payment.refunds.filter(refund => refund.status === "succeeded").reduce((total, current) => total + current.amount, 0) : 0;

export const netRefunds = payment =>
    payment.amount - totalRefunds(payment);
