import { Typography } from "@mui/material";
import { T } from "../../util/t";
import { getHeatStatus } from "../heat/HeatStatus";
import { ScheduledTimeOrUpcoming } from "../heat/badges/ScheduledTime";

export const RoundHeatStatus = ({ heat, runBased, displayRound, color = "textSecondary" }) => {
    const { isFinished, isLive } = getHeatStatus(heat);

    return (
        <Typography key={heat.position} variant="label2" color={color} textTransform="uppercase" display="block">
            {displayRound && <>
                {heat.round}
                <span>{" - "}</span>
            </>}
            <T number={heat.position + 1}>{runBased ? "run_number" : "heat_number"}</T>
            <span>{" - "}</span>
            {isLive ? <T>Live</T> : isFinished ? <T>Complete</T> : <ScheduledTimeOrUpcoming heat={heat}/>}
        </Typography>
    );
};
