import { useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import { RowRenderer } from "./RowRenderer";

export const VirtualizedList = ({
    height,
    itemData,
    estimatedItemSize,
    initialItemsSize = {},
    innerElementType,
    outerElementType
}) => {
    const listRef = useRef();
    const [itemsSize, setItemsSize] = useState(initialItemsSize);

    return (
        <VariableSizeList {...{
            ref: listRef,
            height,
            estimatedItemSize: estimatedItemSize,
            itemSize: index => itemsSize[index] || estimatedItemSize,
            itemData: { ...itemData, listRef, setItemsSize },
            itemCount: itemData.items.length,
            innerElementType,
            overscanCount: 10,
            outerElementType
        }}>
            {RowRenderer}
        </VariableSizeList>
    );
};
