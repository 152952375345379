import { lazy, Suspense } from "react";
const ReactSpreadsheetImport = lazy(() =>
    import(/* webpackChunkName: "spreadsheet-importer" */ "react-spreadsheet-import")
        .then(({ ReactSpreadsheetImport }) => ({ default: ReactSpreadsheetImport }))
);

export const SpreadsheetImporter = props =>
    <Suspense fallback={null}>
        <ReactSpreadsheetImport {...props}/>
    </Suspense>;

