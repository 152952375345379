import { createContext, useContext, useState, useCallback } from "react";
import dayjs from "dayjs";
import translations from "../../reducers/localization/translations";
import { hostToLanguage } from "../../reducers/localization/languageUtil";
import "dayjs/locale/ja";
import "dayjs/locale/pt";
import "dayjs/locale/es";
import "dayjs/locale/fr";

const language = hostToLanguage();
dayjs.locale(language);

const LocalizationContext = createContext({});
LocalizationContext.displayName = "Translation";

export const useLocalizationContext = () => useContext(LocalizationContext);

export const useTranslate = (text, props) => {
    const { translate } = useLocalizationContext();
    return translate ? translate(text, props) : text;
};

export const LocalizationProvider = ({ children }) => {
    const [selectedLanguage, setLanguage] = useState(language);

    const translateText = useCallback((text, props) => {
        const textTranslations = translations[text] || {},
            translatedText = textTranslations[selectedLanguage] || textTranslations["en"];

        return translatedText != null ? (typeof translatedText === "function" ? translatedText(props) : translatedText) : text;
    }, [selectedLanguage]);

    return (
        <LocalizationContext.Provider value={{ translate: translateText, setLanguage }}>
            {children}
        </LocalizationContext.Provider>
    );
};
