import { createContext, useContext, useRef } from "react";

const AbortableRequestsContext = createContext({});
AbortableRequestsContext.displayName = "AbortableRequests";

export const useAbortableRequests = () => useContext(AbortableRequestsContext);

export const AbortableRequestsProvider = ({ children }) => {
    const observables = useRef({});

    const setAbortableRequest = (channel, id, request) => observables.current[`${channel}-${id}`] = request;
    const abortPendingRequest = (channel, id) => observables.current[`${channel}-${id}`]?.unsubscribe();

    return (
        <AbortableRequestsContext.Provider value={{ setAbortableRequest, abortPendingRequest }}>
            {children}
        </AbortableRequestsContext.Provider>
    );
};
