import { makeStyles } from "components/providers/makeStyles";

export const useAthletesTeamsPageStyles = makeStyles((theme, { hideBorder }) => ({
    page: {
        "&&": {
            paddingBottom: theme.spacing(8)
        },
    },
    search: {
        width: "100%",
        marginRight: theme.spacing(2)
    },
    mergeButton: {
        whiteSpace: "nowrap"
    }
}));
