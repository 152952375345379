import { makeStyles } from "components/providers/makeStyles";
import classNames from "classnames";

import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    title: {
        display: "flex",
        alignItems: "center",
        paddingRight: theme.spacing(3),
    },
    stickyCompactHeatHeader: {
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 10,
        background: theme.palette.common.white,
        width: "100%",
        padding: theme.spacing(2, 2, 1, 2),
        minHeight: theme.spacing(5),
        boxShadow: theme.shadows.custom[1],
    }
}));

export const CompactHeatLeaderboardHeader = ({ sticky, children }) => {
    const classes = useStyles();

    return (
        <header className={classNames({ [classes.stickyCompactHeatHeader]: sticky })}>
            <Typography variant="label1" className={classes.title}>
                {children}
            </Typography>
        </header>
    );
};
