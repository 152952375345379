import { ArrowOutward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { T } from "components/util/t";

export const ViewPaymentsButton = ({ eventId, ...rest }) => (
    <Button
        component="a"
        variant="outlined"
        href={`https://dashboard.stripe.com/search?query=${encodeURIComponent(`liveheats_event_id:${eventId}`)}`}
        target="_blank"
        rel="noreferrer"
        {...rest}
    >
        <T>View payments in Stripe</T> <ArrowOutward />
    </Button>
);
