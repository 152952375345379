import { makeStyles } from "../../providers/makeStyles";

export const eventCardWidth = theme => theme.spacing(40);

export const useFeaturedEventsStyles = makeStyles(theme => ({
    card: {
        boxShadow: `${theme.shadows.homepageCards[1]}, ${theme.shadows.homepageCards[2]}`,
        backgroundColor: theme.palette.background.low,
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.border.light,
        borderWidth: theme.spacing(.125),
        width: eventCardWidth(theme),
        overflow: "hidden",
        padding: theme.spacing(0.5),
    },
    eventName: {
        whiteSpace: "normal",
        display: "-webkit-box",
        WebkitLineClamp: 5,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    mainSection: {
        backgroundColor: theme.palette.background.lowest,
        borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    },
    detailSection: {
        backgroundColor: `${theme.palette.background.lowest}66`,
        padding: theme.spacing(2),
        alignItems: "center",
        boxShadow: theme.shadows.homepageCards[0],
        "&:last-of-type": {
            borderRadius: theme.spacing(0, 0, 0.5, 0.5),
        }
    }
}));
