import classnames from "classnames";
import { AthleteOrTeamHeader } from "./AthleteOrTeamHeader";
import { Typography } from "@mui/material";
import { useHeatHeaderStyles, useHeatRankStyles, useHeatRunsStyles } from "./heatStyles";
import { T } from "../../util/t";
import { useIsMobile } from "hooks/useIsMobile";

export const ResultRowsHeader = ({ heat }) => {
    const classes = useHeatHeaderStyles();

    return (
        <li className={classes.headerRow}>
            <ResultRowsHeaderContent heat={heat} />
        </li>
    );
};

export const ResultRowsHeaderContent = ({ heat, runBased, totalLabel = "Total", displayTotal = true }) => {
    const isMobile = useIsMobile();
    const classes = useHeatHeaderStyles();
    const runsClasses = useHeatRunsStyles({ canScroll: !isMobile });
    const heatRankClasses = useHeatRankStyles({ canScroll: !isMobile });

    return (
        <>
            <Typography variant="label2" color="textSecondary" className={classnames(classes.place, classes.header, { [heatRankClasses.place]: runBased })}>
                <T>Rank</T>
            </Typography>

            <AthleteOrTeamHeader className={classnames(classes.header, { [runsClasses.athlete]: runBased })} config={heat.config}/>

            {displayTotal &&
            <Typography variant="label2" color="textSecondary" className={classnames(classes.header, classes.result, { [runsClasses.result]: runBased })}>
                <T>{totalLabel}</T>
            </Typography>}
        </>
    );
};
