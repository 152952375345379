import { Typography } from "@mui/material";
import { AthleteOrTeamHeader } from "./AthleteOrTeamHeader";
import { T } from "../../util/t";
import { useHeatHeaderStyles } from "./heatStyles";

export const AthleteRowsHeader = ({ heat }) => {
    const classes = useHeatHeaderStyles();

    return (
        <li className={classes.headerRow}>
            {heat.config.hasStartlist &&
            <Typography variant="label2" color="textSecondary" className={`${classes.place} ${classes.header}`}>
                {heat.config.usesLanes ?
                    <T>Lane</T> :
                    <T>start_order_header</T>
                }
            </Typography>}
            <AthleteOrTeamHeader className={classes.header} config={heat.config}/>
        </li>
    );
};
