import { useHeatHeaderStyles } from "./heatStyles";
import { ResultRowsHeaderContent } from "./ResultRowsHeader";

export const PlacementRowsHeader = ({ heat }) => {
    const classes = useHeatHeaderStyles();
    const displayTotal = heat.config?.teamConfig?.appraisalLevel === "individual";

    return (
        <li className={classes.headerRow}>
            <ResultRowsHeaderContent heat={heat} displayTotal={displayTotal} totalLabel="Points"/>
        </li>
    );
};
