import { Tooltip } from "../../../elements/tooltip";
import { Typography } from "@mui/material";
import { T } from "../../../util/t";

export const Modifier = ({ className, modifier }) =>
    <Tooltip title={modifier.notes} shouldHide={!modifier.notes}>
        <Typography variant="inherit" sx={{ textDecoration: modifier.notes && "underline" }} className={className}>
            <T>{modifier.type.split("::")[1]}</T>
        </Typography>
    </Tooltip>;
