import { useEffect } from "react";
import { useForm, useController } from "react-hook-form";

import { Input } from "../fields/Input";
import { useReSubmittingFunction } from "./useReSubmittingFunction";
import { makeStyles } from "../../../providers/makeStyles";
import { createValidateField } from "../../../forms/Field";
import { FieldMessage } from "../FormField";

const TIMEOUT = 1000;

const useStyles = makeStyles((theme, { width }) => ({
    inputWrapper: {
        width
    }
}));
const identity = v => v;
export const SelfSubmittingField = ({ field, initialValue, onEvent, onSubmit: submit, postSubmit = identity, alwaysReset, showError }) => {
    const { control, handleSubmit, setError, resetField } = useForm({ defaultValues: { [field.name]: initialValue }, shouldFocusError: false });
    const { field: inputField, fieldState } = useController({
        control,
        name: field.name,
        rules: field.validation && { validate: createValidateField(field.validation) }
    });
    useEffect(() => {
        if (!fieldState.isDirty || alwaysReset) resetField(field.name, { defaultValue: initialValue });
    }, [initialValue]);
    const { onSubmit, debouncedSubmit } = useReSubmittingFunction(field.name, initialValue, submit, postSubmit, TIMEOUT, setError, onEvent);
    const classes = useStyles({ width: field.width });
    const input = {
        ref: inputField.ref,
        name: inputField.name,
        value: (field.format || identity)(inputField.value),
        onChange: e => {
            const parsedValue = (field.parse || identity)(e.target.value);
            inputField.onChange(parsedValue);
            handleSubmit(debouncedSubmit)(e);
        },
        onBlur: e => {
            inputField.onBlur();
            handleSubmit(onSubmit)(e);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.inputWrapper}>
                <Input field={field} input={input} hasError={!!fieldState.error}/>
            </div>
            {showError && <FieldMessage
                isDirty={fieldState.isDirty}
                errorMessage={fieldState.error?.message}
                checkingMessage={field.checkingMessage}
                validMessage={field.validMessage}
                validating={field.validating}
                infoMessage={field.infoMessage}
            />}
        </form>
    );
};
