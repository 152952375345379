import { gql } from "@apollo/client";

export const EventDivisionRankFragment = gql`
  fragment EventDivisionRank on EventDivisionRank {
    id
    place
    total
    rides
    excluded
    competitor {
      id
      athleteId
      teamName
      bib
      athlete {
        id
        name
        image
      }
    }
  }
`;
