import classnames from "classnames";
import { Button } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

import { T } from "../../util/t";
import { SubmitButton } from "./SubmitButton";

const useFormStyles = makeStyles(theme => ({
    buttons: {
        display: "flex"
    },
    button: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    cancelButton: {
        marginRight: theme.spacing(1)
    }
}));
export const FormWrapper = ({ control, handleSubmit, fullWidthSubmitButton, buttonLabel, buttonEndIcon, classNames = {}, onCancel, children }) => {
    const classes = useFormStyles();

    return (
        <form onSubmit={handleSubmit}>
            {children}

            <div className={classnames(classes.buttons, classNames.buttons)}>
                {onCancel &&
                <Button
                    className={classnames(classes.button, classes.cancelButton, classNames.button)}
                    variant="outlined"
                    onClick={onCancel}>
                    <T>Cancel</T>
                </Button>}

                <SubmitButton
                    control={control}
                    className={classnames(classes.button, classNames.button)}
                    buttonLabel={buttonLabel}
                    endIcon={buttonEndIcon}
                    fullWidth={fullWidthSubmitButton}
                />
            </div>
        </form>
    );
};
