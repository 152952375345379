import { ResponsiveDialog } from "../../../../modal";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { T } from "../../../../util/t";
import { timeFormat } from "../../../../../utils/dates";
import { useState } from "react";
import { dateInput } from "components/forms";
import { Delete } from "@mui/icons-material";

export const DayDialog = ({ open, onClose, dialogTitle, value, dateInputProps, onChange }) =>
    <ResponsiveDialog open={open} onClose={onClose} maxWidth="sm" aria-labelledby="day-dialog-title">
        <DayDialogContent date onClose={onClose} dialogTitle={dialogTitle} value={value} dateInputProps={dateInputProps} onChange={onChange} />
    </ResponsiveDialog>;

const DayDialogContent = ({ onClose, dialogTitle, value, dateInputProps = {}, onChange }) => {
    const { initialValue, minDate,  minTime, maxTime, timeIntervals } = dateInputProps;
    const [selected, setSelected] = useState(initialValue);

    const selectDate = destroy => e => {
        onChange(destroy ? null : selected);
        onClose(e);
    };

    const DateInput = dateInput;

    return (
        <>
            <DialogTitle id="day-dialog-title" className="sticky-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent key="dialog-content" className="sticky-dialog-content">
                <DateInput
                    meta={{}}
                    input={{ value: selected, onChange: setSelected }}
                    showTimeSelect
                    autoFocus
                    minDate={minDate}
                    minTime={minTime?.(selected)}
                    maxTime={maxTime?.(selected)}
                    timeIntervals={timeIntervals}
                    timeFormat={timeFormat()}
                    width="100%"
                    inline
                />
            </DialogContent>
            <DialogActions>
                {value && <Box onClick={selectDate(true)} startIcon={<Delete />} component={Button} marginRight="auto">
                    <T>Delete</T>
                </Box>}
                <Button variant="outlined" onClick={onClose}><T>Close</T></Button>
                <Button variant="contained" onClick={selectDate()} disabled={value?.valueOf() === selected?.valueOf()}>
                    {!value
                        ? <T>Add</T>
                        : <T>Update</T>}
                </Button>
            </DialogActions>
        </>
    );
};
