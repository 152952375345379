import { Suspense } from "react";
import Immutable from "immutable";
import { Redirect, Route } from "react-router-dom";

import { NotifiedRedirect } from "./NotifiedRedirect";
import { useCurrentUser } from "../providers/CurrentUserProvider";

const homeByRole = {
    "judge": "/dashboard/judge",
    "director": "/director"
};
export const homeForUser = (user = Immutable.Map()) => {
    const immutableUser = Immutable.Map.isMap(user) ? user : Immutable.fromJS(user);

    let home = homeByRole[immutableUser.get("role")];
    if (!home && immutableUser.get("permissions", []).some(p => p.get("record_type") === "Team" && /entries\/manage/.test(p.get("scopes"))))
        home = "/user/team-manager";

    return home ? home : immutableUser.isEmpty() ? "/" : "/user/athletes";
};

export const LazyPrivateRoute = ({ fallback: Fallback, ...props }) =>
    <Suspense fallback={<Fallback/>}>
        <PrivateRoute {...props} fallback={<Fallback/>}/>
    </Suspense>;

const roleOrder = ["user", "judge", "director", "admin"];
const userHasRequiredRole = (role, currentUser) =>
    currentUser ?
        !role || roleOrder.findIndex(r => r === role) <= roleOrder.findIndex(r => r === currentUser.role) :
        false;

export const PrivateRoute = ({ component: Component, role, fallback = null, signUp, children, ...rest }) => {
    const { loaded, currentUser } = useCurrentUser();
    const immutableUser = Immutable.fromJS(currentUser);
    const hasRequiredRole = userHasRequiredRole(role, currentUser);

    if (!loaded) return fallback;

    return (
        <Route {...rest} render={props =>
            hasRequiredRole ?
                Component ? <Component {...props} currentUser={immutableUser}/> : children
                :
                signUp
                    ? <Redirect to={{ pathname: "/sign-up", state: { from: props.location } }}/>
                    : <NotifiedRedirect
                        message={currentUser ?
                            "You don't have enough permissions to view that page!" :
                            "You need to login before continuing to that page!"}
                        to={{
                            pathname: currentUser ? homeForUser(immutableUser) : "/login",
                            state: { from: props.location }
                        }}
                    />
        }/>
    );
};
