import { makeStyles } from "components/providers/makeStyles";

const useShowPrintingStyles = makeStyles(() => ({
    wrapper: {
        "@media not print": {
            display: "none"
        },
    }
}));

export const ShowPrinting = ({ children }) => {
    const classes = useShowPrintingStyles();
    return <div className={classes.wrapper}>{children}</div>;
};

const useHidePrintingStyles = makeStyles(() => ({
    wrapper: {
        "@media print": {
            display: "none"
        },
    }
}));

export const HidePrinting = ({ children }) => {
    const classes = useHidePrintingStyles();
    return <div className={classes.wrapper}>{children}</div>;
};
