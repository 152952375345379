import { Fragment } from "react";
import { Tooltip } from "../../../elements/tooltip";
import { T } from "../../../util/t";
import { useHeatRideCellsStyles } from "components/layout/heat/heatStyles";

export const RidesHeaders = ({ maxRides, runBased, categories, Component = "th" }) => {
    const rideCellsClasses = useHeatRideCellsStyles();

    return (
        [...Array(maxRides)].map((_, rideI) =>
            <Fragment key={`ride${rideI}`}>
                <Component className={rideCellsClasses.firstCell}><T number={rideI + 1}>{runBased ? "run_number" : "ride_title"}</T></Component>
                {categories.map(category => [...Array(category.maxScores)].map((_, i) =>
                    <Component key={`${category.category}${i}`}>
                        <Tooltip title={`${category.category} ${i+1}`}>
                            {`${category.category.slice(0, 1)}${i+1}`}
                        </Tooltip>
                    </Component>
                ))}
            </Fragment>
        )
    );
};
