import { Typography } from "@mui/material";

import { useRoundStyles } from "./roundStyles";
import { Fake } from "../skeleton/Fake";
import { HeatCardSkeleton } from "../heat/HeatCardSkeleton";
import { ScrollWithShadows } from "../utils/ScrollWithShadows";

export const DesktopDrawSkeleton = () => {
    const roundClasses = useRoundStyles();

    return (
        <ScrollWithShadows shadowsColor="background.default" hideLeft>
            <FakeRound classes={roundClasses} round={20} heats={3}/>
            <FakeRound classes={roundClasses} round={27} heats={2}/>
            <FakeRound classes={roundClasses} round={16} heats={1}/>
        </ScrollWithShadows>
    );
};

const FakeRound = ({ classes, round, heats }) =>
    <div className={classes.round}>
        <Typography variant="h5" component="h2" className={classes.roundName}><Fake size={round}/></Typography>
        {[...Array(heats)].map((_,i) => <HeatCardSkeleton key={i}/>)}
    </div>;
