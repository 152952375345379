import classnames from "classnames";
import { Paper, Typography } from "@mui/material";

import { useScrollIntoView } from "../../../hooks/useScrollIntoView";
import { useBoxStyles } from "../commonStyles/boxStyles";
import { T } from "../../util/t";

export const RoundSelector = ({ name, status, selected, selectRound }) => {
    const classes = useBoxStyles();

    const ref = useScrollIntoView(selected);

    return (
        <Paper ref={ref} variant="outlined" className={classnames(classes.box, { selected })} onClick={selectRound}>
            <Typography variant="button"><T>{name}</T></Typography>
            {status && <Typography variant="body2" color="textSecondary"><T>{status}</T></Typography>}
        </Paper>
    );
};
