import { makeStyles } from "components/providers/makeStyles";

export const useDaySelectorStyles = makeStyles(theme => ({
    box: {
        padding: theme.spacing(0.25, 4),
    },
    month: {
        textTransform: "uppercase"
    }
}));
