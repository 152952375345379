import { Fake } from "components/layout/skeleton/Fake";
import { Paper, Typography } from "@mui/material";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { usePriorityColoursStyles } from "../../../../layout/event/priority_judge/usePriorityColourStyles";
import classnames from "classnames";
import { makeStyles } from "../../../../providers/makeStyles";
import { usePriorityTableStyles } from "../../../../layout/event/priority_judge/usePriorityTableStyles";
import { Heading } from "../../../../layout/page/Heading";
import { usePriorityRowStyles } from "../../../../layout/event/priority_judge/usePriorityRowStyles";

const usePriorityJudgePageSkeletonStyles = makeStyles(theme => ({
    heatHeader: {
        padding: theme.spacing(1, 0, 5, 1)
    }
}));
export const PriorityJudgePageSkeleton = () => {
    const classes = usePriorityJudgePageSkeletonStyles();

    return (
        <div className={"skeleton"} role="status" aria-label="loading">
            <Heading title="Priority judge"/>
            <Typography variant="h4" className={classes.heatHeader}><Fake size={24}/> <Fake size={16}/> <Fake size={16}/></Typography>
            <PriorityTableSkeleton size={4} />
        </div>
    );
};

const usePriorityTableSkeletonStyles = makeStyles(theme => ({
    heatHeader: {
        padding: theme.spacing(1, 0, 5, 1)
    },
    headerCenter: {
        textAlign: "center"
    }
}));
const PriorityTableSkeleton = ({ size }) => {
    const classes = {  ...usePriorityTableStyles(), ...usePriorityTableSkeletonStyles() };

    return (
        <table className={classes.table}>
            <thead>
                <tr>
                    <th><Typography className={classes.headerCenter}><Fake  size={15} /></Typography></th>
                    <th><Fake size={17} /></th>

                </tr>
            </thead>
            <tbody>
                {Array(size).fill(1).map((position, index) => <PriorityRowSkeleton key={index} size={size} />)}
            </tbody>
        </table>
    );
};

const usePriorityRowSkeletonStyles = makeStyles((theme, { numberOfPositions }) => ({
    position: {
        width: theme.spacing(12),
        textAlign: "center"
    },
    selected: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(0, 2),
        flexGrow: 1
    },
    skeletonColumn: {
        height: `${50/numberOfPositions}vh`
    },
}));
const PriorityRowSkeleton = ({ size }) => {
    const classes = { ...usePriorityRowSkeletonStyles({ numberOfPositions: size }), ...usePriorityRowStyles() } ;
    const isMobile = useIsMobile();

    return (
        <tr className={classes.row}>
            <td className={`${classes.priorityPosition} ${classes.column} ${classes.position} }`}>
                <Typography variant="h2"><Fake size={6} /></Typography>
            </td>
            <td className={`${classes.priority} ${classes.skeletonColumn}`}>
                <span className={classnames("fake", classes.selected)}/>
                {!isMobile && <PriorityPaletteSkeleton size={size}  />}
            </td>
        </tr>
    );
};

const PriorityPaletteSkeleton = ({ size }) => {
    const classes = usePriorityColoursStyles({ heatSize: size });

    return (
        <div className={classes.palette}>
            {Array(size).fill(1).map((position, index) =>
                <span className={classnames( "fake",classes.colour)} key={index}>
                    <Paper variant="rounded" />
                </span>)}
        </div>
    );
};
