import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { useHasMoreToScroll } from "../../../hooks/useHasMoreToScroll";
import { HidePrinting } from "../../util/printing";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { get } from "lodash";

const SCROLL_OFFSET = 200;
const scrollTo = (scrollerNode, left) => {
    if (scrollerNode.scroll) {
        scrollerNode.scroll({
            top: 0,
            left,
            behavior: "smooth",
        });
    } else {
        scrollerNode.scrollLeft = left; // IE10 fallback
    }
};

const useStyles = makeStyles((theme, { offsetLeft, shadowsColor, buttonsPositionAbsolute }) => ({
    wrapper: {
        position: "relative",
        overflow: "hidden"
    },
    scrollContent: {
        display: "flex",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
        "&&::-webkit-scrollbar": {
            display: "none"
        }
    },
    shadow: {
        position: "absolute",
        top: 0,
        bottom: 0,
        zIndex: 3,
        width: 45,
        cursor: "pointer"
    },
    shadowLeft: {
        left: offsetLeft,
        paddingLeft: 15,
        backgroundImage: `linear-gradient(to right, ${get(theme.palette, shadowsColor)}, transparent)`,
        cursor: "w-resize"
    },
    shadowRight: {
        right: 0,
        width: 60,
        backgroundImage: `linear-gradient(to left, ${get(theme.palette, shadowsColor)}, transparent)`,
        cursor: "e-resize"
    },
    button: {
        position: buttonsPositionAbsolute ? "absolute" : "fixed",
        top: "50%",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrast,
        opacity: 0.85,
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            }
        },
        "@media (hover: none)": {
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
            }
        }
    }
}));

export const ScrollWithShadows = ({ children, hideLeft, hideRight, className, showButtons, offsetLeft = 0, shadowsColor = "background.paper", buttonsPositionAbsolute = false, fixedScrollOffset }) => {
    const classes = useStyles({ offsetLeft, shadowsColor, buttonsPositionAbsolute });
    const { scrollerRef, scrollerNode, hasRightScroll, hasLeftScroll } = useHasMoreToScroll();

    const scrollLeft = () => {
        const { scrollLeft, clientWidth } = scrollerNode;
        const scrollOffset = fixedScrollOffset || clientWidth - SCROLL_OFFSET;
        scrollTo(scrollerNode, Math.max(scrollLeft - scrollOffset, 0));
    };

    const scrollRight = () => {
        const { scrollLeft, clientWidth, scrollWidth } = scrollerNode;
        const scrollOffset = fixedScrollOffset || clientWidth - SCROLL_OFFSET;
        scrollTo(scrollerNode, Math.min(scrollLeft + scrollOffset, scrollWidth - clientWidth));
    };

    return (
        <div className={classnames(className, classes.wrapper)}>
            {hasLeftScroll && !hideLeft && (
                <HidePrinting>
                    <div className={classnames(classes.shadow, classes.shadowLeft)} onClick={scrollLeft}>
                        {showButtons && <IconButton size="small" className={classes.button}><ChevronLeft fontSize="large" /></IconButton>}
                    </div>
                </HidePrinting>
            )}

            <section ref={scrollerRef} className={classes.scrollContent}>
                {children}
            </section>

            {hasRightScroll && !hideRight && (
                <HidePrinting>
                    <div className={classnames(classes.shadow, classes.shadowRight)} onClick={scrollRight}>
                        {showButtons && <IconButton size="small" className={classes.button}><ChevronRight fontSize="large" /></IconButton>}
                    </div>
                </HidePrinting>
            )}
        </div>
    );
};
