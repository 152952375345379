import classnames from "classnames";

import { makeStyles } from "components/providers/makeStyles";

import { AvatarOrInitials } from "../avatar/AvatarOrInitials";
import { getUniqueClassFromStringHash } from "../../../utils/utils";

const useStyles = makeStyles(theme => ({
    avatar: {
        ...Object.keys(theme.palette.jerseys).reduce((map, jersey) => {
            map[`&.${jersey}`] = {
                border: `2px solid ${theme.palette.jerseys[jersey].main}`,
                background: theme.palette.jerseys[jersey].light,
                color: theme.palette.jerseys[jersey].dark
            };
            return map;
        }, {}),
        "&&.default-1": { background: "#F8DBE9", color: "#D8438C" },
        "&&.default-2": { background: "#F6E7CA", color: "#A7740A" },
        "&&.default-3": { background: "#CEEAED", color: "#34A5B3" },
        "&&.default-4": { background: "#FDDED9", color: "#F7755E" },
        "&&.default-5": { background: "#D1F1EC", color: "#3DC4AF" },
        "&&.default-6": { background: "#E2D0E2", color: "#873B87" },
        "&&.default-7": { background: "#D0D3E2", color: "#3B4988" },
        "&&.default-8": { background: "#D8D0E2", color: "#5E3B87" },
        "&&.default-9": { background: "#D0DDE2", color: "#3B7288" },
        "&&.default-10": { background: "#D0E2DE", color: "#3B8779" },
        "&&.default-11": { background: "#D6C9D0", color: "#56203D" },
        "&&.default-12": { background: "#E9E3F5", color: "#8264C3" },
        "&&.default-13": { background: "#C1DBDD", color: "#006A71" },
        "&&.default-14": { background: "#F1CFD9", color: "#C63A65" },
        "&&.default-15": { background: "#DFE5F9", color: "#4563BB" },
        "&&.default-16": { background: "#D0E2D5", color: "#3B8750" },
        "&&.default-17": { background: "#F1DECF", color: "#C6793A" },
        "&&.default-18": { background: "#FDEBC8", color: "#C58D1C" },
        "&&.default-19": { background: "#F1CFCF", color: "#C63A3A" },
        "&&.default-20": { background: "#C9C9D1", color: "#221F41" },
        "&&.default-21": { background: "#DCDAF2", color: "#4E4A75" },
        "&&.default-22": { background: "#DDE8E5", color: "#4A9180" },
        "&&.default-23": { background: "#E2E2D0", color: "#6F6F23" },
        "&&.default-24": { background: "#E7EDF4", color: "#265795" },
        "&&.default-25": { background: "#F6DDC9", color: "#DC731D" },
        "&&.default-26": { background: "#CCD4CD", color: "#2A4B32" },
        "&&.default-27": { background: "#DAF1F6", color: "#3CB5D2" },
        "&&.default-28": { background: "#E4CDCD", color: "#902F2F" },
        "&&.default-29": { background: "#E9EFFA", color: "#6886B7" },
        "&&.default-30": { background: "#E3F6E9", color: "#47BE6A" },
        "&&.default-31": { background: "#F9F6C9", color: "#ABA209" },
        "&&.default-32": { background: "#EBF3E6", color: "#6EA349" },
        "&&.default-33": { background: "#F3E1EB", color: "#BB568E" },
        "&&.default-34": { background: "#FBE9C2", color: "#F0A202" },
        "&&.default-35": { background: "#CBCDE8", color: "#27349F" },
        "&&.default-36": { background: "#CCD5F3", color: "#2D53CF" },
        "&&.default-37": { background: "#E1C7D6", color: "#861657" },
        "&&.default-38": { background: "#D5F9ED", color: "#17C98B" },
        "&&.default-39": { background: "#D5DDC4", color: "#53710A" },
        "&&.default-40": { background: "#DFEAF2", color: "#346381" },
        "&&.default-41": { background: "#DFCCE8", color: "#7B2BA1" },
        "&&.default-42": { background: "#DDF4D3", color: "#65BA3E" },
        "&&.default-43": { background: "#F1D7ED", color: "#C45AB3" },
        "&&.default-44": { background: "#FCDACF", color: "#F16636" },
        "&&.default-45": { background: "#C7D9E5", color: "#166394" },
        "&&.default-46": { background: "#FFD4D8", color: "#FF4B5C" },
        "&&.default-47": { background: "#F2EDC6", color: "#A39210" },
        "&&.default-48": { background: "#E0E8D4", color: "#7EA04D" },
        "&&.default-49": { background: "#FFF1D0", color: "#D1771A" },
        "&&.default-50": { background: "#CDE3ED", color: "#2D8BB3" },
        "&&.default-51": { background: "#D1D6DB", color: "#3D5467" },
        "&&.default-52": { background: "#C3D1DA", color: "#033F63" },
        "&&.default-53": { background: "#FFEBC2", color: "#D99204" },
        "&&.default-54": { background: "#DADBF4", color: "#6369D1" },
        "&&.default-55": { background: "#D6C5DC", color: "#540D6E" },
        "&&.default-56": { background: "#FBEECB", color: "#AC7C00" },
        "&&.default-57": { background: "#F5D5C6", color: "#D45113" },
        "&&.default-58": { background: "#D1F5DD", color: "#52D97F" },
        "&&.default-59": { background: "#E0F7FF", color: "#3DBFEC" },
        "&&.default-60": { background: "#E9CCF5", color: "#A72BD5" },
        "&&.default-61": { background: "#FFF7CF", color: "#C3A50E" },
        "&&.default-62": { background: "#DBEBDD", color: "#6AAC75" },
        "&&.default-63": { background: "#CAE6D4", color: "#25994C" },
        "&&.default-64": { background: "#E0EBFF", color: "#447DE0" },
        "&&.default-65": { background: "#D3CAE9", color: "#4A24A3" },
        "&&.default-66": { background: "#FFD2E9", color: "#FF46A8" },
        "&&.default-67": { background: "#FFE1CB", color: "#FF8727" },
        "&&.default-68": { background: "#F0F9BE", color: "#899925" },
        "&&.default-69": { background: "#C5C1ED", color: "#1100B9" },
        "&&.default-70": { background: "#C3E8CE", color: "#05A134" },
        "&&.default-71": { background: "#C7EEF1", color: "#16BAC5" },
        "&&.default-72": { background: "#E4DFDF", color: "#8E7C7C" },
        "&&.default-73": { background: "#D6D9FD", color: "#5863F8" },
        "&&.default-74": { background: "#F3C2C8", color: "#D0021B" },
        "&&.default-75": { background: "#F9C6DC", color: "#E40063" },
        "&&.default-76": { background: "#C9E5F8", color: "#1E97E3" },
        "&&.default-77": { background: "#FFE6DD", color: "#D9724D" },
        "&&.default-78": { background: "#FBE9D0", color: "#BC873C" },
        "&&.default-79": { background: "#F1F0D9", color: "#A9A022" },
        "&&.default-80": { background: "#FCE5E4", color: "#D45753" },
    }
}));
export const JerseyAvatar = ({ className, jersey, alt, src, children, avatarProps }) => {
    const classes = useStyles();
    let extraClasses = { [className]: className };
    if (!jersey && !src) {
        extraClasses = { ...extraClasses, ...getUniqueClassFromStringHash(alt, 80, "default") };
    }

    return (
        <AvatarOrInitials className={classnames(jersey, classes.avatar, extraClasses)} alt={alt} src={src} {...avatarProps}>
            {children}
        </AvatarOrInitials>
    );
};
