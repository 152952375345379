import { Stack, Typography } from "@mui/material";

export const CardHeader = ({ title, subtitle, textColor, actions, direction, flex }) => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={direction} flex={flex}>
            <Typography variant="button3" color={textColor}>{title}</Typography>
            <Typography variant="label2" color={textColor} textTransform="uppercase">{subtitle}</Typography>
        </Stack>
        {actions}
    </Stack>
);
