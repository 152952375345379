import {
    createTheme,
    CssBaseline,
    responsiveFontSizes,
    StyledEngineProvider,
    ThemeProvider as MuiThemeProvider
} from "@mui/material";

import { typography, variants } from "./theme/typography";
import { palette } from "./theme/palette";
import { components } from "./theme/components";

const defaultTheme = createTheme();

const shadows = [...defaultTheme.shadows];
shadows.custom = ["0px 1px 3px 0px rgba(0, 0, 0, 0.2)", "0 2px 4px 0 rgba(0, 0, 0, 0.1)", "0 -2px 4px 0 rgba(0, 0, 0, 0.1)", "0 3px 12px 0 rgba(0,0,0,0.15)"];
shadows.homepageCards = ["0px 2px 4px 0px rgba(0, 0, 0, 0.02)", "0px 0px 6px 0px rgba(0, 0, 0, 0.02)", "0px 2px 4px 0px rgba(0, 0, 0, 0.08)"];

const theme = responsiveFontSizes(createTheme({
    typography,
    palette,
    shadows,
    components,
    shape: {
        borderRadius: 8,
        pageMaxWidth: 1200,
        disabledOpacity: 0.6
    },
}), { variants });

export const ThemeProvider = ({ children }) =>
    <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </MuiThemeProvider>
    </StyledEngineProvider>;
