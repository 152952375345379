import { PureComponent } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/lib/immutable";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import debounce from "lodash/debounce";
import { Add, Delete } from "@mui/icons-material";

import * as JudgeActions from "../../../actions/judge";

import { decimalOrEmpty, plainInput } from "../../forms";
import { ResponsiveDialog } from "../../modal";
import { T } from "../../util/t";
import { LoadingButton } from "../../actions/loadingButton";
import { judgeScoreNormalize } from "../../../models/judgeScoreNormalize";
import { toFixedOrDash } from "../heats/editHeat";
import { ScoreRenderer } from "./ScoreRenderer";
import { JudgingAthleteModalHeader } from "../../layout/judging/JudgingAthleteModalHeader";

@connect((state, { athleteId, rideIndex, scores }) => ({
    form: `scores-${athleteId}-${rideIndex}`,
    initialValues: { scores }
}))
@reduxForm({ touchOnChange: true })
export class MultiCategoryDialog extends PureComponent {
    constructor(props) {
        super(props);

        const SAVE_DELAY_MS = 1000;
        this.normalizes = props.heatConfig.get("categories").map(category =>
            judgeScoreNormalize(category.get("judging_scale"), category.get("judging_decimals")));
        this.debouncedDispatchAction = debounce(this.dispatchAction, SAVE_DELAY_MS);
        this.state = {};
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.open && this.props.open) this.props.initialize({ scores: this.props.scores });
    };

    handleClose = (e, reason) => {
        e && e.stopPropagation && e.stopPropagation();
        if (reason !== "backdropClick") {
            this.props.handleClose();
        }
    };

    dispatchAction = (values, action, e) => {
        const { dispatch, eventId, dispatchHeatId, heatId, rideIndex, dispatchRideIndex, judgeId, athleteHeatId, teamPosition, athleteId, parentAthleteId, heatConfig } = this.props,
            scores = heatConfig.get("categories").keySeq().map(category => ({
                athlete_heat_id: athleteHeatId,
                team_position: teamPosition,
                athlete_id: athleteId,
                judge_id: judgeId,
                ride: dispatchRideIndex,
                category: category,
                score: values.getIn(["scores", category])
            }));

        this.debouncedDispatchAction.cancel();
        this.handleClose(e);
        this.setState({ submitting: true });
        return dispatch(action(eventId, heatId, dispatchHeatId, rideIndex, judgeId, parentAthleteId, athleteId, heatConfig, scores.toJS()))
            .then(
                () => {
                    this.setState({ submitting: false, error: false });
                },
                () => {
                    this.debouncedDispatchAction(values, action);
                    this.setState({ submitting: false, error: true });
                });
    };

    delete = (e) => this.dispatchAction(Immutable.Map(), JudgeActions.deleteScores, e);

    submit = (values, e) => this.dispatchAction(values, JudgeActions.addScores, e);

    render = () => {
        const { open, athleteName, teamName, rideIndex, jersey, bib, scores, modifier, heatConfig, handleSubmit, pristine, isEditLocked } = this.props,
            categories = heatConfig.get("categories"),
            { submitting, error } = this.state;

        return <>
            {(scores || modifier) ?
                <div className={`judge-total ${classnames(submitting && "submitting")} ${classnames(error && "error")}`}>
                    <ScoreRenderer isEditLocked={isEditLocked} score={scores && toFixedOrDash(scores.get("total"), 2)} />
                </div> :
                <IconButton>
                    <Add/>
                </IconButton>
            }

            <ResponsiveDialog className="lh-dialog"
                              open={open}
                              disableEscapeKeyDown
                              fullWidth={true}
                              maxWidth="sm"
                              aria-labelledby="multi-category-dialog-title"
                              onClose={this.handleClose}>
                <div className="scrollable-area">
                    <DialogTitle id="multi-category-dialog-title" className="sticky-dialog-title">
                        <JudgingAthleteModalHeader name={athleteName} team={teamName} jersey={jersey} bib={bib} ride={rideIndex} runBased={heatConfig.get("run_based")}/>
                    </DialogTitle>

                    <DialogContent className="sticky-dialog-content">
                        <form onSubmit={handleSubmit(this.submit)}>
                            {categories.sortBy((config, category) => config.get("order", category)).keySeq().map(category =>
                                <Field key={category} name={`scores.${category}`}
                                       component={plainInput}
                                       type="number" pattern="[0-9]*" step="any"
                                       label={category} validate={decimalOrEmpty}
                                       normalize={this.normalizes.get(category)}
                                />
                            )}
                        </form>
                    </DialogContent>
                </div>

                <DialogActions className="sticky-dialog-actions">
                    <LoadingButton className="left" action={this.delete} startIcon={<Delete/>}>
                        <T>Delete</T>
                    </LoadingButton>

                    <Button variant="outlined" onClick={this.handleClose}>
                        <T>Cancel</T>
                    </Button>

                    <LoadingButton variant="contained" action={e => handleSubmit(values => this.submit(values, e))()} type="submit"
                                   disabled={pristine} color="primary">
                        <T>Save</T>
                    </LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </>;
    };
}
