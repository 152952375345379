import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { Field, FieldArray } from "redux-form/immutable";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { uuid } from "../../utils/uuid";

import { translateText } from "../../utils/utils";
import { creatableSelect, FormField, required } from "../forms";
import { T } from "../util/t";
import { DivisionBox } from "./divisionBox";
import { useRef } from "react";
import { useResizeObserver } from "hooks/useResizeObserver";

const getSelectOptions = (organisationDivisions, localization, divisions = Immutable.List(), divisionNameField, divisionIdField) => ({
    options: organisationDivisions.reduce((options, division) => {
        const value = division.getIn(divisionIdField.split("."));
        if (divisions.find(d => d.getIn(divisionIdField.split(".")) === value)) return options;
        return options.push({ label: division.getIn(divisionNameField.split(".")), value });
    }, Immutable.List()).toJS(),
    getNewOptionData: (value) => ({
        label: translateText({ text: "division_add_division", localization, props: { division: value } }),
        value,
        __isNew__: true,
    }),
    placeholder:<><T>Type or select</T>...</>,
    isValidNewOption: inputValue => !required(inputValue),
    defaultMenuIsOpen: true,
    autoFocus: true
});

const Fields = ({ divisions, fields, meta, deleteDivision, divisionsError, setDivisionsError, divisionNameField, divisionIdField, divisionUuidField, RowRenderer, disabled }) => {
    useEffect(() => {
        if (meta.submitFailed && meta.error !== divisionsError) setDivisionsError(meta.error);
    }, [meta.submitFailed, meta.error, divisionsError, setDivisionsError]);

    return (
        divisions ? fields.map((field, index) => {
            const division = divisions.get(index);
            if (!division) return null;
            return (
                <RowRenderer key={division.getIn(divisionIdField.split(".")) || division.getIn(divisionUuidField.split("."))} divisionId={division.getIn(divisionIdField.split("."))} uuid={division.getIn(divisionUuidField.split("."))} index={index}>
                    <Field name={`${field}.${divisionNameField}`} component="input" type="hidden"/>
                    <SelectedDivision name={division.getIn(divisionNameField.split("."))} index={index} deleteDivision={deleteDivision} disabled={disabled} />
                </RowRenderer>
            );
        }) : null
    );
};

const useSelectedDivisionStyles = makeStyles((theme, { fullWidth, disabled }) => ({
    division: {
        "&&": {
            width: "100%"
        }
    }
}));

export const SelectedDivision = ({ name, index, deleteDivision, fullWidth, disabled }) => {
    const classes = useSelectedDivisionStyles();
    const onRemove = useCallback(() => deleteDivision(index), [deleteDivision, index]);

    return <DivisionBox name={name} onRemove={onRemove} fullWidth={fullWidth} className={classes.division} disabled={disabled} />;
};

const requireDivision = value => required(value && value.join(""));

const useDivisionsSelectorStyles = makeStyles((theme, { disabled }) => ({
    table: {
        width: "100%",
        borderSpacing: 0,
        wordBreak: "break-word",
        opacity: disabled && 0.5,
        cursor: disabled && "not-allowed"
    }
}));

export const DivisionsSelectorDumb = ({
    organisationDivisions,
    array,
    divisions,
    localization,
    fieldName = "divisions",
    divisionNameField = "name",
    divisionIdField = "id",
    divisionUuidField = "uuid",
    RowRenderer = ({ children, index }) => <tr><td>{children}</td></tr>,
    divisionValidation = requireDivision,
    newDivisionMapper,
    headers,
    wrapHeader,
    disabled
}) => {
    const [divisionsError, setDivisionsError] = useState("");
    const classes = useDivisionsSelectorStyles({ disabled });

    return (
        <table className={classes.table} cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    {headers.map(({ label, ...rest }, i) => {
                        const wrap = wrapHeader && i === 0 ? "wrap" : "nowrap";
                        return (<Box key={i} component="td" whiteSpace={wrap} paddingBottom={0.5} {...rest}>
                            <Typography variant="label1" component="label"><T>{label}</T></Typography>
                        </Box>);
                    })}
                </tr>
            </thead>
            <tbody>
                <Divisions
                    fieldName={fieldName}
                    divisionValidation={divisionValidation}
                    divisions={divisions}
                    array={array}
                    divisionsError={divisionsError}
                    setDivisionsError={setDivisionsError}
                    divisionNameField={divisionNameField}
                    divisionIdField={divisionIdField}
                    divisionUuidField={divisionUuidField}
                    RowRenderer={RowRenderer}
                    disabled={disabled}
                />

                {!disabled && <AddDivision
                    organisationDivisions={organisationDivisions}
                    localization={localization}
                    divisions={divisions}
                    divisionNameField={divisionNameField}
                    divisionIdField={divisionIdField}
                    divisionUuidField={divisionUuidField}
                    fieldName={fieldName}
                    array={array}
                    divisionsError={divisionsError}
                    newDivisionMapper={newDivisionMapper}
                />}
            </tbody>
        </table>
    );
};

const Divisions = ({ fieldName, divisionValidation, divisions, array, divisionsError, setDivisionsError, divisionNameField, divisionIdField, divisionUuidField, RowRenderer, disabled }) => {
    const deleteDivision = useCallback((index) => {
        array.remove(fieldName, index);
    }, []);

    return (
        <FieldArray name={fieldName} validate={divisionValidation} component={({ fields, meta }) =>
            <Fields
                divisions={divisions}
                fields={fields}
                meta={meta}
                deleteDivision={deleteDivision}
                divisionsError={divisionsError}
                setDivisionsError={setDivisionsError}
                divisionNameField={divisionNameField}
                divisionIdField={divisionIdField}
                divisionUuidField={divisionUuidField}
                RowRenderer={RowRenderer}
                disabled={disabled}
            />
        }/>
    );
};

const useStyles = makeStyles((theme, { cellWidth }) => ({
    button: {
        "&.error": {
            borderColor: theme.palette.input.error
        }
    },
    select: {
        "&& .react-select__control": {
            width: "100%",
            maxWidth: cellWidth
        }
    },
    addDivision: {
        "&&": {
            width: "100%"
        }
    }
}));

const AddDivision = ({ organisationDivisions, localization, divisions, divisionNameField, divisionUuidField, divisionIdField, fieldName, array, divisionsError, newDivisionMapper = v => v }) => {
    const cellRef = useRef();
    const [cellWidth, setCellWidth] = useState(cellRef.current?.clientWidth);
    useResizeObserver(cellRef, () => setCellWidth(cellRef.current?.clientWidth));

    const classes = useStyles({ cellWidth });
    const [addingDivision, setAddingDivision] = useState(false);

    const selectOptions = useMemo(() => getSelectOptions(organisationDivisions, localization, divisions, divisionNameField, divisionIdField), [divisions]);

    const selectDivision = useCallback((value) => {
        if (!!required(value)) return;
        const division = organisationDivisions.find(division => division.getIn(divisionIdField.split(".")) === value) || Immutable.Map().setIn(divisionNameField.split("."), value).setIn(divisionUuidField.split("."), uuid());
        array.push(fieldName, newDivisionMapper(division));
        setAddingDivision(false);
    }, [newDivisionMapper]);

    const addDivision = useCallback(() => setAddingDivision(true), []);

    const Select = creatableSelect;

    return (
        <tr>
            <td ref={cellRef}>
                {addingDivision
                    ? <Select className={classes.select} selectOptions={selectOptions} input={{ onChange: selectDivision, onBlur: () => {} }} meta={{ touched: !!divisionsError, error: divisionsError }}/>
                    : <FormField showError={!!divisionsError} error={divisionsError}>
                        <Button className={classnames(classes.button, classes.addDivision, { "error": !!divisionsError })} variant="outlined" onClick={addDivision}>
                            <T>Add division</T>
                        </Button>
                    </FormField>}
            </td>
        </tr>
    );
};

const propsAreEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);
export const DivisionsSelector = memo(({ ...props }) => {
    return (
        <DivisionsSelectorDumb {...props}/>
    );
}, propsAreEqual);
