import { makeStyles } from "components/providers/makeStyles";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    chip: {
        display: "flex",
        alignItems: "center",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.warn.main,
        marginLeft: theme.spacing(1)
    }
}));
export const Chip = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.chip}><T>{children}</T></div>;
};