import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as EventDirectorActions from "actions/eventDirector";
import { Button } from "@mui/material";
import { T } from "components/util/t";
import { ConfirmationDialog } from "components/layout/dialogs/ConfirmationDialog";

export const DrawButton = ({ eventId, hasSchedule }) => {
    const dispatch = useDispatch();
    const [confirmDraw, setConfirmDraw] = useState();

    const closeDrawConfirmation = useCallback(() => setConfirmDraw(false), []);
    const openDrawConfirmation = useCallback(() => setConfirmDraw(true), []);

    const drawHeats = useCallback(() => dispatch(EventDirectorActions.drawHeats(eventId)).then(closeDrawConfirmation), [eventId]);

    return (
        <>
            <Button variant="contained" onClick={openDrawConfirmation}>
                <T>{hasSchedule ? "Redraw all divisions" : "Generate draw"}</T>
            </Button>

            <ConfirmationDialog
                open={!!confirmDraw}
                onClose={closeDrawConfirmation}
                action={drawHeats}
                title="Draw heats and close entries?"
                content="This will draw the heats and close entries for this event. It's no biggie if you do, you can always reopen entries and redraw heats later."
                buttonTitle="Draw heats"
            />
        </>
    );
};
