import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Link } from "@mui/material";

import { useDialogState } from "../../../hooks/useDialogState";
import { useRefocusEffect } from "../../../hooks/useRefocusEffect";
import { GET_EVENT_DIVISION_LEADERBOARD } from "../../../graphql/eventDivision";
import { LeaderboardBreakdown } from "./LeaderboardBreakdown";
import { HeatBreakdownSkeleton } from "../heat/HeatBreakdownSkeleton";
import { DesktopOrMobileModal } from "../modal/DesktopOrMobileModal";

export const LeaderboardModalWithTrigger = ({ leaderboard, children }) => {
    const [open, openModal, closeModal] = useDialogState();

    return (
        <>
            <Link underline="none" color="inherit" variant="inherit" onClick={openModal}>{children}</Link>
            <LeaderboardModal open={open} leaderboard={leaderboard} closeModal={closeModal}/>
        </>
    );
};

export const LoadingLeaderboardModal = ({ heat, open, closeModal }) => {
    const [getLeaderboard, { data, refetch }] = useLazyQuery(GET_EVENT_DIVISION_LEADERBOARD, { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }),
        eventDivision = data?.eventDivision;
    const eventDivisionId = heat?.eventDivision.id;

    useRefocusEffect(refetch, open);

    useEffect(() => {
        if (eventDivisionId && open) {
            getLeaderboard({ variables: { id: eventDivisionId, round: heat.roundPosition } });
        }
    }, [open, eventDivisionId, getLeaderboard]);

    const leaderboard = eventDivision && heat ? eventDivision.leaderboards?.[0] : null;

    return <LeaderboardModal open={open} leaderboard={leaderboard} closeModal={closeModal}/>;
};

export const LeaderboardModal = ({ open, closeModal, leaderboard }) =>
    <DesktopOrMobileModal open={open} closeModal={closeModal}>
        {leaderboard
            ? <LeaderboardBreakdown leaderboard={leaderboard} closeModal={closeModal}/>
            : <HeatBreakdownSkeleton/>}
    </DesktopOrMobileModal>;
