import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";
import { T } from "../util/t";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "sticky",
        // eslint-disable-next-line no-dupe-keys
        position: "-webkit-sticky",
        top: theme.spacing(0),
        left: theme.spacing(0),
        width: "calc(100vw - (100vw - 100%))",
        maxWidth: "100vw",
        zIndex: 6,
        textAlign: "center",
        background: theme.palette.background.dark,
        color: theme.palette.text.contrastText,
        padding: theme.spacing(1),
        "&.success": {
            background: theme.palette.success.main,
            color:  theme.palette.text.primary
        }

    }
}));

export const ActionNotificationSticky = ({ type, text }) => {
    const classes = useStyles();

    return (
        <div className={`sticky-notification ${classnames(classes.wrapper, type)}`}>
            <T>{text}</T>
        </div>
    );
};
