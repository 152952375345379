import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { T } from "../../util/t";
import { SubmitButton } from "./SubmitButton";

export const DialogFormWrapper = ({ control, handleSubmit, buttonLabel, onCancel, secondaryAction, formCaption, children, classNames = {}, fullWidthSubmitButton, enableOnPristine }) => (
    <form onSubmit={handleSubmit} className={classNames.form}>
        <DialogContent className={classNames.content}>
            {children}
            {formCaption &&
            <p><Typography variant="caption"><T>{formCaption}</T></Typography></p>}
        </DialogContent>

        <DialogActions className="sticky-dialog-actions">
            {secondaryAction}
            {onCancel && <Button variant="outlined" onClick={onCancel}><T>Cancel</T></Button>}

            <SubmitButton control={control} fullWidth={fullWidthSubmitButton} buttonLabel={buttonLabel} enableOnPristine={enableOnPristine}/>
        </DialogActions>
    </form>
);
