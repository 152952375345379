import { Fragment } from "react";
import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { Typography } from "@mui/material";

import { useCompetitorsById } from "./HeatSummaryResults";
import { Tooltip } from "../../elements/tooltip";
import { T } from "../../util/t";
import { RideCategory } from "../judging/RideCategory";

import { FormattedTotal } from "../../../models/FormattedTotal";

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 2),
        "&& ::-webkit-scrollbar": {
            display: "none"
        }
    },
    scrollArea: {
        overflowX: "auto",
        maxWidth: "100vw",
        WebkitOverflowScrolling: "touch",
    },
    shadowRight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        zIndex: 1,
        right: 0,
        width: theme.spacing(5),
        backgroundImage: "linear-gradient(to left, #fff 50%, rgba(255, 255, 255, 0) 100%)"
    },
    table: {
        borderSpacing: 2,
        paddingRight: theme.spacing(1),
        marginLeft: -2,
        "& td": {
            textAlign: "center",
            padding: theme.spacing(0, 1),
            zIndex: 1,
        },
        "& thead th": {
            textTransform: "uppercase"
        },
        "& tbody td": {
            height: "2.5em",
            textAlign: "right"
        },
        "& th": {
            padding: theme.spacing(0.5, 1, 0.5, 0),
            textAlign: "left",
            position: "sticky",
            left: 0,
            minWidth: 156,
            backgroundImage: "linear-gradient(to right, #fff 89%, rgba(255, 255, 255, 0) 100%)",
            zIndex: 2,
            "&.team-athlete": {
                paddingLeft: theme.spacing(5)
            }
        },
        "& .original-score": {
            textDecoration: "line-through"
        }
    },
}));
export const RidesTable = ({ heat, renderAthleteHeader }) => {
    const competitorsById = useCompetitorsById(heat.competitors);
    const isTeams = heat.config.isTeams && heat.config.teamConfig?.appraisalLevel === "individual";
    const maxRides = Math.max(0, ...heat.result.map(r => Math.max(...Object.keys(r.rides).map(key => r.rides[key].filter(r => !r.pending).length))));
    const maxRidesArray = [...Array(maxRides)];
    const classes = useStyles();

    if (!maxRides && !isTeams) return null;

    return (
        <div className={classes.container}>
            <section className={classes.scrollArea}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>{renderAthleteHeader ? <Typography variant="label2" color="textSecondary"><T>Athlete</T></Typography> : <>&nbsp;</>}</th>
                            {maxRidesArray.map((_, i) =>
                                <td key={i}>
                                    <Typography variant="label2" color="textSecondary">{i + 1}</Typography>
                                </td>)}
                        </tr>
                    </thead>
                    <tbody>
                        {heat.result.map(athleteResult => {
                            const { teamMembers, athlete: { name } } = competitorsById[athleteResult.athleteId];

                            return (
                                <Fragment key={athleteResult.athleteId}>
                                    {isTeams && <tr><th><Typography variant="label1">{name}</Typography></th><td/></tr>}
                                    {(isTeams
                                        ? teamMembers.map(member => member.athlete)
                                        : Object.keys(athleteResult.rides).map(id => ({ id, name }))
                                    ).map(({ id, name }) => {
                                        const rides = athleteResult.rides[id].filter(r => !r.pending);
                                        return (
                                            <tr key={id}>
                                                <th className={classnames(isTeams && "team-athlete")}>
                                                    <Typography variant="subtitle3">
                                                        {name}
                                                    </Typography>
                                                </th>
                                                {maxRidesArray.map((_, i) =>
                                                    <Ride key={`${id}-${i}`} ride={rides[i]} config={heat.config}/>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </section>

            <div className={classes.shadowRight}/>
        </div>
    );
};

const useRideStyles = makeStyles(theme => ({
    rideTotal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rideCategory: {
        marginLeft: theme.spacing(1),
    }
}));
export const Ride = ({ ride, config }) => {
    const classes = useRideStyles();

    if (!ride) return <td/>;

    const { categories } = config,
        hideTooltip = !ride.scores || Object.values(ride.scores).length < 2,
        modifiedTotal = ride.total !== ride.modified_total,
        modifier = ride.modifier,
        modifierClass = modifier ? modifier.type.split("::")[1] : "",
        interfere = modifierClass.match(/Interference/),
        didNotModifier = modifierClass.match(/D(N|S)(S|F|Q|I)/),
        scoreText = interfere ? <T>INT</T> : didNotModifier ? <T>{modifierClass}</T> : FormattedTotal(ride.modified_total, config);

    return (
        <td className={classnames({ ride, "scoring-ride": ride.scoring_ride, [modifierClass]: modifierClass })}>
            <Tooltip title={<ScoresTooltip categories={categories} rideCategory={ride.category} scores={ride.scores}/>} shouldHide={hideTooltip} variableWidth>
                <div className={classes.rideTotal}>
                    <Typography variant="label1">
                        {scoreText}
                    </Typography>
                    {ride.category && <RideCategory category={ride.category} className={classes.rideCategory}/>}
                </div>
                {modifiedTotal &&
                <Typography component="p" variant="label2" className="original-score">
                    {FormattedTotal(ride.total, config)}
                </Typography>}
            </Tooltip>
        </td>
    );
};

const useScoresTooltipStyles = makeStyles(theme => ({
    score: {
        textAlign: "center",
    }
}));
export const ScoresTooltip = ({ categories, scores, rideCategory }) => {
    const judges = Object.keys(scores).sort();
    const classes = useScoresTooltipStyles();

    if (!categories) return (
        <>
            {rideCategory && <div className="tooltip-heading categories">
                <div key={rideCategory} className="category">{rideCategory}</div>
            </div>}
            {judges.map(judge =>
                <div key={judge} className={classes.score} >{parseFloat(scores[judge]).toFixed(1)}</div>
            )}
        </>
    );

    const categoryNames = Object.keys(categories).sort((a, b) => categories[a].order - categories[b].order);
    return (
        <div className="categories">
            {categoryNames.map(category => (
                <div key={category} className="category">
                    <div className="tooltip-heading">{category}</div>
                    <div className="tooltip-body">
                        {judges.map(judge => scores[judge][category] &&
                            <div key={`${category}-${judge}`}>
                                {parseFloat(scores[judge][category]).toFixed(categories[category].judging_decimals || 1)}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
