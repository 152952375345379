import { Box, Typography } from "@mui/material";
import { Fake } from "../../../layout/skeleton/Fake";
import { FakeEvent } from "../../shortName/OrganisationRootSkeleton";
import { MaxWidthPage } from "../../../layout/page/MaxWidthPageWithHeading";

export const UserDashboardPageSkeleton = () =>
    <MaxWidthPage className="skeleton">
        <Typography variant="h1" marginBottom={2}><Fake size={43}/></Typography>
        <Box>
            <FakeEvent size={56}/>
            <FakeEvent size={32}/>
            <FakeEvent size={32}/>
        </Box>
    </MaxWidthPage>;
