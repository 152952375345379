export const division = {
    label: "Division",
    key: "division",
    alternateMatches: ["contest name", "contest", "event", "Contest Name"],
    fieldType: { type: "input" },
    validations: [
        { rule: "required", errorMessage: "Division is required", level: "error" }
    ],
    example: "Open Female Surf Race",
};
export const club = {
    label: "Club",
    key: "teamName",
    alternateMatches: ["club"],
    fieldType: { type: "input" },
    example: "Bondi beach"
};
export const teamName = {
    label: "Team",
    key: "teamName",
    alternateMatches: ["team"],
    fieldType: { type: "input" },
    example: "Bondi beach"
};
export const relayTeam = {
    label: "Team",
    key: "competitor",
    alternateMatches: ["team", "team name", "Team name"],
    fieldType: { type: "input" },
    example: "Team A"
};
export const tagTeamName = {
    label: "Tag team name",
    key: "competitor",
    fieldType: { type: "input" },
    example: "Bondi Boardriders A"
};
export const bib = {
    label: "Bib",
    key: "bib",
    alternateMatches: ["Bib Number"],
    fieldType: { type: "input" },
    example: "101"
};
export const seed = {
    label: "Seed",
    key: "seed",
    alternateMatches: ["ranking"],
    fieldType: { type: "input" },
    validations: [
        { rule: "regex", value: /^\d*$/, errorMessage: "Seed must be a number", level: "error" }
    ],
    example: "1"
};
export const rank = {
    label: "Rank",
    key: "rank",
    alternateMatches: ["rank", "Place", "place", "Result", "result"],
    fieldType: { type: "input" },
    validations: [
        { rule: "regex", value: /^\d*$/, errorMessage: "Rank must be a number", level: "error" }
    ],
    example: "1"
};
export const registrationProperties = properties => properties.map(property => ({
    label: property.label,
    key: `properties.registration.${property.uuid}`,
    fieldType: { type: "input" }
}));
