import { makeStyles } from "components/providers/makeStyles";

import { EventStatusPill } from "../../event/EventStatusPill";
import { Timer } from "../../../timer";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
    livePill: {
        "& .timer-wrapper": {
            marginLeft: theme.spacing(0.5)
        }
    }
}));
export const LivePill = ({ heat, hideTimer, className }) => {
    const classes = useStyles();

    return (
        <EventStatusPill status="on" className={classnames(classes.livePill, className)}>
            {!heat.config.hideTimer && !hideTimer && <Timer duration={heat.heatDurationMinutes} start={heat.startTime}/>}
        </EventStatusPill>
    );
};
