import { Box, Divider, List, Stack, Typography } from "@mui/material";
import logo from "assets/logos/StackedVerticalBrandSecondary.svg";
import { T } from "components/util/t";
import { Link } from "react-router-dom";
import instagram from "assets/socials/socialIcons - Instagram.svg";
import facebook from "assets/socials/socialIcons - Facebook.svg";
import tiktok from "assets/socials/socialIcons - Tiktok.svg";
import youtube from "assets/socials/socialIcons - Youtube.svg";
import x from "assets/socials/socialIcons - X.svg";
import linkedin from "assets/socials/socialIcons - LinkedIn.svg";
import { SearchEventAndOrganisationButton } from "components/layout/home/SearchEventAndOrganisation";
import { Section } from "components/routes/root/HomePage";

const socials = [[
    { src: instagram, url: "https://www.instagram.com/live_heats", alt: "Instagram" },
    { src: facebook, url: "https://www.facebook.com/liveheats", alt: "Facebook" },
    { src: tiktok, url: "https://www.tiktok.com/@liveheats", alt: "Tiktok" }
], [
    { src: youtube, url: "https://www.youtube.com/channel/UCAxLroTSxac5Xn7-ucDMplQ", alt: "Youtube" },
    { src: x, url: "https://x.com/LiveHeats", alt: "X (Twitter)" },
    { src: linkedin, url: "https://www.linkedin.com/company/liveheats", alt: "LinkedIn" }
]];

export const Footer = () => (
    <Section maxWidth="100%" backgroundColor="brand.tertiary" color="text.contrastText" paddingBottom={5.5}>
        <Section paddingX={4}>
            <Box component="img" src={logo} alt="Liveheats" width={theme => theme.spacing(9)} />

            <Typography variant="brandSubheadingH1Light" component="h2" textTransform="uppercase" marginY={3}>
                Where action sports history <Box component="span" whiteSpace="nowrap">is made</Box>
            </Typography>

            <Typography variant="brandBody2">
                <T>Helping organisers run sophisticated competitions with minimal effort. All-in-one platform for live scoring, heat draws, scheduling and rankings.</T>
            </Typography>

            <Stack component="nav" direction="row" marginY={4} flexWrap="wrap">
                <Stack width={{ xs: "55%", sm: "50%", lg: "25%" }} spacing={1.5}>
                    <Typography variant="brandLabel1"><T>Athletes</T></Typography>
                    <Stack component={List} spacing={1.5} disablePadding>
                        <Typography component="li" variant="brandBody2">
                            <SearchEventAndOrganisationButton>
                                <T>Search events</T>
                            </SearchEventAndOrganisationButton>
                        </Typography>
                        <Typography component="li" variant="brandBody2"><Link to="/organisations"><T>Search organisations</T></Link></Typography>
                        <Typography component="li" variant="brandBody2"><a href="https://support.liveheats.com/hc/en-us/articles/8055665617428-Contacting-the-organiser-for-issues-relating-to-event-or-membership-registration"><T>Contact event organiser</T></a></Typography>
                    </Stack>
                </Stack>

                <Stack width={{ xs: "45%", sm: "50%", lg: "25%" }} spacing={1.5}>
                    <Typography variant="brandLabel1"><T>Event organisers</T></Typography>
                    <Stack component={List} spacing={1.5} disablePadding>
                        <Typography component="li" variant="brandBody2"><a href="https://organiser.liveheats.com"><T>Features</T></a></Typography>
                        <Typography component="li" variant="brandBody2"><a href="https://organiser.liveheats.com/pricing"><T>Pricing</T></a></Typography>
                        <Typography component="li" variant="brandBody2"><a href="https://support.liveheats.com"><T>Help centre</T></a></Typography>
                    </Stack>
                </Stack>

                <Stack width={{ xs: "100%", lg: "50%" }} spacing={1.5} marginTop={{ xs: 4, lg: 0 }}>
                    <Typography variant="brandLabel1"><T>Socials</T></Typography>
                    <Stack direction="row">
                        {socials.map((socials, i) => (
                            <Stack key={i} component={List} spacing={1.5} width={{ xs: i === 0 ? "55%" : "45%", sm: "50%" }} disablePadding>
                                {socials.map(({ src, url, alt }) => (
                                    <li key={alt}>
                                        <Stack direction="row" component="a" href={url} alignItems="center" spacing={1}>
                                            <img src={src} alt={alt} />
                                            <Typography variant="brandBody2">{alt}</Typography>
                                        </Stack>
                                    </li>
                                ))}
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack>

            <Divider />

            <Stack marginTop={3} justifyContent="space-between" direction={{ xs: "column", sm: "row" }} spacing={{ xs: 2, lg: 0 }}>
                <Stack component={List} disablePadding direction="row" spacing={2}>
                    <Typography variant="brandBody2" component="li"><Link to="/privacy_policy"><T>Privacy Policy</T></Link></Typography>
                    <Typography variant="brandBody2" component="li"><Link to="/terms_and_conditions"><T>Terms of use</T></Link></Typography>
                </Stack>
                <List disablePadding>
                    <Typography variant="brandBody2" component="li">Born in Bondi Beach 🏖️</Typography>
                </List>
            </Stack>
        </Section>
    </Section>
);
