import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { EventStatusPill } from "../event/EventStatusPill";
import { LivePill } from "./badges/LivePill";
import { StatusPill } from "../StatusPill";
import { T } from "../../util/t";

const useStyles = makeStyles(theme => ({
    status: {
        display: "inline-block",
        verticalAlign: "middle",
    }
}));

export const HeatLeaderboardStatus = ({ heat, isFinished, isLive, hideTimer }) => {
    const classes = useStyles();

    return (
        <Typography variant="label2" component="span" className={classes.status}>
            {isFinished
                ? <EventStatusPill status="finished" />
                : isLive
                    ? <LivePill heat={heat} hideTimer={hideTimer} />
                    : <StatusPill status="upcoming">
                        <T>upcoming</T>
                    </StatusPill>
            }
        </Typography>
    );
};
