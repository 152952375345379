import { useQuery } from "@apollo/client";

import { GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../graphql/organisation";
import { PropertiesTableSkeleton } from "../customProperties/PropertiesTableSkeleton";
import { PropertiesTable } from "../customProperties/PropertiesTable";

export const SetupRegistrationFields = ({ organisationId, ...rest }) => {
    const { data } = useQuery(GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, { variables: { id: organisationId }, fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }),
        organisation = data?.organisation;

    if (!organisation) return <PropertiesTableSkeleton/>;

    return <PropertiesTable organisationId={organisationId} properties={organisation.federationProperties} ownPropertiesId={organisation.customProperties.map(p => p?.uuid)} {...rest}/>;
};

