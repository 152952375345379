import { makeStyles } from "components/providers/makeStyles";

import { RoundSelector } from "./RoundSelector";
import { Fake } from "../skeleton/Fake";
import { HeatCardSkeleton } from "../heat/HeatCardSkeleton";

const noop = () => {};

const useStyles = makeStyles(theme => ({
    nav: {
        display: "flex",
        marginBottom: theme.spacing(2),
    }
}));
export const MobileDrawSkeleton = () => {
    const classes = useStyles();

    return (
        <>
            <nav className={classes.nav}>
                <RoundSelector name={<Fake size={20}/>} status={<Fake size={10}/>} selectRound={noop}/>
                <RoundSelector name={<Fake size={25}/>} status={<Fake size={13}/>} selectRound={noop}/>
            </nav>

            <HeatCardSkeleton/>
        </>
    );
};
