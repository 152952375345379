import { useCallback } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { makeStyles } from "components/providers/makeStyles";

import { useCurrentUser } from "../../providers/CurrentUserProvider";
import * as JudgeActions from "../../../actions/judge";
import { SelfSubmittingField } from "../../layout/forms/selfSubmittingField/SelfSubmittingField";
import { placingField } from "../../layout/heat/edit/SpotPlacing";
import { SelfSubmittingTimingField } from "../../layout/forms/selfSubmittingField/SelfSubmittingTimingField";
import { ModifierButton } from "./modifiers/ModifierButton";
import { Modifier } from "./modifiers/Modifier";

const usePlaceStyles = makeStyles(theme => ({
    place: {
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(32),
        fontWeight: 700,
        textAlign: "right",
        "&&": {
            padding: `${theme.spacing(0.5)} !important`,
        },
        "& input": {
            fontSize: theme.typography.pxToRem(24),
            textAlign: "center",
            padding: 4,

        },
        "& button": {
            marginLeft: theme.spacing(1)
        }
    },
    box: {
        display: "flex",
        justifyContent: "space-between"
    },
    modifier: {
        marginLeft: theme.spacing(1)
    }
}));
export const PlaceEditor = ({ eventId, heat, parentAthleteId, athleteId, ride = Immutable.Map(), rideIndex }) => {
    const { currentUser: { id: judgeId } } = useCurrentUser();
    const classes = usePlaceStyles();
    const place = ride.getIn(["scores", "" + judgeId], "");
    const modifier = ride.get("modifier")?.toJS();
    const modifierType = ride.getIn(["modifier", "type"], "").split("::")[1];
    const dispatch = useDispatch();
    const heatId = heat.get("id");
    const dispatchHeatId = heat.getIn(["runs", rideIndex], heatId);
    const dispatchRideIndex = ride.get("ride_index", !heat.get("runs") ? rideIndex : 0);
    const competitor = { competitorId: parentAthleteId, athleteId: athleteId };
    const dispatchParams = {
        event_id: eventId,
        heat_id: heatId,
        dispatch_heat_id: dispatchHeatId,
        ride_index: rideIndex,
        dispatch_ride_index: dispatchRideIndex,
    };

    const onSubmit = useCallback(values =>
        dispatch(JudgeActions.addScore(eventId, heatId, dispatchHeatId, rideIndex, judgeId, parentAthleteId, {
            score: values.score,
            judge_id: judgeId,
            athlete_id: athleteId,
            ride: dispatchRideIndex
        }))
    , [eventId, heatId, dispatchHeatId, rideIndex, dispatchRideIndex, judgeId, athleteId]);

    const SelfSubmittingInputField = heat.getIn(["config", "calculator"]) === "timing" ?
        <SelfSubmittingTimingField onSubmit={onSubmit} initialValue={place?.toString()} /> :
        <SelfSubmittingField onSubmit={onSubmit} initialValue={place?.toString()} field={placingField}/>;

    return (
        <td className={classnames(classes.place, { [`ride modified ${modifierType}`]: modifierType })}>
            <div className={classes.box}>
                {modifier ?
                    <Modifier className={classes.modifier} modifier={modifier}/> :
                    SelfSubmittingInputField
                }

                <div>
                    <ModifierButton dispatchParams={dispatchParams} competitor={competitor} modifier={modifier} heatConfig={heat.get("config")}/>
                </div>
            </div>
        </td>
    );
};

