import { DesktopDrawSkeleton } from "components/layout/eventDivision/DesktopDrawSkeleton";
import { MobileDrawSkeleton } from "components/layout/eventDivision/MobileDrawSkeleton";
import { useIsMobile } from "hooks/useIsMobile";
import { Box } from "@mui/material";

export const EditDrawPageSkeleton = () => {
    const isMobile = useIsMobile();

    return (
        <div className="skeleton">
            <Box pt={3}>
                {isMobile ? <MobileDrawSkeleton/> : <DesktopDrawSkeleton/>}
            </Box>
        </div>
    );
};
