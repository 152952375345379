const formatTime = value => {
    const timeInSeconds = value?.toString() || "";
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const hundredths = timeInSeconds.match(/.*\.(\d{1,2})/)?.[1].padEnd(2, "0") || "00";

    return (
        (hours ? hours + ":" : "") +
        (minutes ? minutes.toString().padStart(2, hours ? "0" : "") + ":" : hours ? "00:" : "") +
        (seconds ? seconds.toString().padStart(2, minutes ? "0" : "") + "." : "00.") +
        hundredths
    );
};

export const FormattedTotal = (total, config) => {
    const { calculator } = config;
    if (calculator === "timing") return total ? formatTime(total) : "-";
    if (calculator === "placing") return total ? parseFloat(total).toFixed(0) : "-";
    return total ? parseFloat(total).toFixed(2) : "-";
};
