import { makeStyles } from "../../providers/makeStyles";

export const useOrganisationStyles = makeStyles(theme => ({
    heading: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(3)
    },
    avatar: {
        marginRight: theme.spacing(1),
        fontSize: "1.5rem",
    },
    name: {
        marginLeft: theme.spacing(0.75),
    },
    media: {
        "&:hover": {
            borderRadius: theme.spacing(1),
        }
    },
    section: {
        margin: theme.spacing(0,0,4)
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1)
    },
}));
