import { makeStyles } from "../../../providers/makeStyles";
import { Typography } from "@mui/material";
import { T } from "../../../util/t";
import { SelfSubmittingField } from "./SelfSubmittingField";

const useFormFieldStyles = makeStyles((theme, { width }) => ({
    field: {
        marginBottom: theme.spacing(2),
    },
    label: {
        display: "block",
        marginBottom: theme.spacing(1),
    }
}));
export const SelfSubmittingFormField = ({ field, initialValue, onSubmit, postSubmit, alwaysReset, showError }) => {
    const classes = useFormFieldStyles();

    return (
        <div className={classes.field}>
            <label>
                {field.label &&
                <Typography variant="label1" className={classes.label}>
                    <T>{field.label}</T>
                </Typography>}
                <SelfSubmittingField field={field} initialValue={initialValue} onSubmit={onSubmit} postSubmit={postSubmit} alwaysReset={alwaysReset} showError={showError}/>
            </label>
        </div>
    );
};
