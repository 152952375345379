import { useCallback, useState } from "react";
import { ProgressButton } from "./ProgressButton";

export const useAutoProgress = (propsOnClick) => {
    const [inProgress, setInProgress] = useState(false);
    const onClick = useCallback(e => {
        setInProgress(true);
        const result = propsOnClick(e);
        ((result && result.then) ? result : Promise.resolve(result))
            .then(
                () => setInProgress(false),
                (e) => {
                    setInProgress(false);
                    throw e;
                });
    }, [propsOnClick]);

    return { inProgress, onClick };
};

export const AutoProgressButton = ({ onClick: propsOnClick, children, Component = ProgressButton, ...rest }) => {
    const { inProgress, onClick } = useAutoProgress(propsOnClick);
    return <Component {...rest} disabled={inProgress} inProgress={inProgress} onClick={onClick}>{children}</Component>;
};
