import { makeStyles } from "components/providers/makeStyles";

export const useBoxStyles = makeStyles(theme => ({
    box: {
        textAlign: "center",
        cursor: "pointer",
        flexShrink: 0,
        padding: theme.spacing(1, 2),
        borderWidth: 2,
        marginRight: theme.spacing(1),
        transition: theme.transitions.create("border-color", {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
        "&.selected": {
            border: `2px solid ${theme.palette.border.selected}`,
        },
        "&:last-child": {
            marginRight: 0,
        },
    }
}));
