import { StatusPill } from "../StatusPill";
import { isRefunded, netRefunds, totalRefunds } from "../../organisations/payments/fields/utils";
import { T } from "../../util/t";

const deriveStatus = (payment) => {
    const net = netRefunds(payment);
    if (net === 0 && isRefunded(payment)) return "refunded";
    if (totalRefunds(payment) > 0 && net > 0) return "partially refunded";
    return "paid";
};

export const PaymentStatusPill = ({ payment, className }) => {
    const status = deriveStatus(payment);

    return (
        <StatusPill status={status} className={className}><T>{status}</T></StatusPill>
    );
};
