import { useCallback, useState } from "react";

export const useCallbackRef = () => {
    const [node, setNode] = useState();

    // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const ref = useCallback(node => {
        if (node !== null) setNode(node);
    }, []);

    return [ref, node];
};