import { useDispatch } from "react-redux";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import * as JudgeActions from "../../../../actions/judge";

export const useRecordNextPlace = ({ heatId, nextPlace }) => {
    const dispatch = useDispatch();
    const { currentUser: { id: judgeId } } = useCurrentUser();
    const { id: eventId } = useParams();

    return useCallback((competitorId, athleteId) => {
        return dispatch(JudgeActions.addScore(eventId, heatId, heatId, 0, judgeId, competitorId, {
            score: nextPlace,
            judge_id: judgeId,
            athlete_id: athleteId,
            ride: 0
        }));
    }, [eventId, judgeId, heatId, nextPlace]);
};
