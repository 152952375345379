import { useRef } from "react";
import dayjs from "dayjs";
import { Field } from "redux-form/immutable";

import { creatableSelect, dateInput, plainInput, required, selectInput } from "../../../forms";
import { heatDurationSelectOptions } from "../../../forms/options";
import { EventDivisionsSelector } from "./EventDivisionsSelector";
import { T } from "../../../util/t";
import { useSetSeedFromSeries } from "../new/hooks/useSetSeedFromSeries";
import { Series } from "models/Series";
import { convertToJS } from "components/eventDivisions/helpers";
import { LocationInput } from "./LocationInput";

export const EventGeneralForm = ({ organisation, startDate, change, event, divisions, organisationDivisions, array, localization, formState, setCanSubmit }) => {
    const heatDurationOptions = useRef(heatDurationSelectOptions({ localization }));
    const series = formState?.get("season_ids");

    const availableSeriesOptions = organisation.get("available_series").map(series => {
        const seriesObject = Series(convertToJS(series));
        return {
            value: seriesObject.id,
            label: seriesObject.name + (organisation.get("id") !== seriesObject.organisation.id ? ` (${seriesObject.organisation.name})` : ""),
            isRankingsSeries: seriesObject.isRankingsSeries(),
        };
    }).toJS();

    const availableRankingsSeries = availableSeriesOptions.filter(option => series?.includes(option.value) && option.isRankingsSeries);

    useSetSeedFromSeries({ series: availableRankingsSeries.map(option => option.value), name: "seed_from_series_id", value: formState?.get("seed_from_series_id"), setValue: change });

    return (
        <>
            <Field name="name" type="text" component={plainInput}
                   label={<T>Event name:</T>} validate={required}/>

            <Field name="season_ids"
                   component={creatableSelect}
                   label={<T>Which series does this event belong to?</T>}
                   selectOptions={{
                       placeholder: <T>Standalone event</T>,
                       isValidNewOption: () => false,
                       isMulti: true,
                       options: availableSeriesOptions,
                   }}/>

            {availableRankingsSeries.length > 1 && (
                <Field name="seed_from_series_id"
                       type="select"
                       component={selectInput}
                       label={<><T>Seeding comes from</T>:</>}
                       selectOptions={availableRankingsSeries}
                       parse={v => parseInt(v)}
                />
            )}

            <Field name="start_date" component={dateInput}
                   label={<T>Start date:</T>} validate={required}
                   onChange={(event, newValue) => {
                       change("end_date", newValue);
                   }}/>

            <Field name="end_date" type="date" component={dateInput}
                   minDate={dayjs(startDate).toDate()}
                   label={<T>End date:</T>} validate={required}/>

            <LocationInput
                name="location"
                formState={formState}
                change={change}
                setCanSubmit={setCanSubmit}
            />

            {event &&
            <Field name="heat_duration_minutes" component={creatableSelect}
                   label={<T>Default heat duration:</T>}
                   selectOptions={heatDurationOptions.current}/>}

            <EventDivisionsSelector
                array={array}
                divisions={divisions}
                localization={localization}
                organisationDivisions={organisationDivisions}
            />
        </>
    );
};
