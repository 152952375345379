import { makeStyles } from "../../../../providers/makeStyles";
import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { T } from "../../../../util/t";
import { Add, Edit } from "@mui/icons-material";
import { useDialogState } from "../../../../../hooks/useDialogState";
import { DayDialog } from "./DayDialog";
import dayjs from "dayjs";
import { LONG_DATE, timeFormat } from "../../../../../utils/dates";

const useStyles = makeStyles((theme, { isEntryOpen, isDateSet }) => ({
    dayBox: {
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.border.light}`,
        width: theme.spacing(20.75),
        cursor: "pointer",
        textAlign: "center",
        padding: 0

    },
    title: {
        backgroundColor: isEntryOpen ? theme.palette.status.upcoming : theme.palette.status.finished,
        color: isEntryOpen && theme.palette.status.upcomingText,
        padding: theme.spacing(.25, 1),
        width: "100%",
        justifyContent: "center",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    date: {
        color: isEntryOpen && isDateSet ? theme.palette.status.upcomingText : theme.palette.text.secondary,
        padding: theme.spacing(1, 2),
        alignItems: "center",
        justifyContent: "center",
        minHeight: theme.spacing(7),
    }
}));
export const DayBox = ({ title, value, disabled, dialogTitle, dateInputProps, onChange }) => {
    const isEntryOpen = title === "Entry opens";
    const classes = useStyles({ isEntryOpen, isDateSet: !!value });
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <Box className={classes.dayBox} onClick={openDialog} component={ButtonBase} disabled={disabled}>
            <Stack spacing={.5} className={classes.title} alignItems="flex-start" direction="row">
                <Typography variant="label2" textTransform="uppercase"><T>{title}</T></Typography>
                {value && <Edit fontSize="sm"/>}
            </Stack>

            {!value
                ? <Stack className={classes.date}>
                    <Add/>
                    <Typography variant="caption"><T>Add date</T></Typography>
                </Stack>
                : <Stack className={classes.date}>
                    <Typography variant="label2" textTransform="uppercase">{dayjs(value).format(LONG_DATE)}</Typography>
                    <Typography variant="label2" textTransform="uppercase">{dayjs(value).format(timeFormat())}</Typography>
                </Stack>
            }

            <DayDialog
                open={dialogOpen}
                onClose={closeDialog}
                value={value}
                onChange={onChange}
                dialogTitle={dialogTitle}
                dateInputProps={dateInputProps}
            />
        </Box>
    );
};
