import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useLauncherFreeHelpCenterWidget = role => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(!!window.zE);
    const theme = useTheme();
    const location = useLocation();

    const onLoad = () => {
        const hideWidget = () => window.zE?.("webWidget", "hide");

        hideWidget();

        setLoaded(true);
        setLoading(false);
        window.zE("webWidget:on", "userEvent", function (event) {
            window.dataLayer?.push({
                "event": "zendesk_event",
                "zendesk_action": event.action,
                "zendesk_article": event.properties?.name ? `${event.properties?.id} - ${event.properties?.name}` : undefined,
                "zendesk_search": event.properties?.term
            });
        });

        window.zE("webWidget:on", "close", function () {
            hideWidget();
        });
    };

    useEffect(() => {
        const hideWidget = () => window.zE?.("webWidget", "hide");

        if (!role) return hideWidget();

        if (!loading && !loaded && ["judge", "director", "admin"].find(r => r === role)) {
            setLoading(true);
            const script = document.createElement("script");
            script.id = "ze-snippet";
            script.src = "https://static.zdassets.com/ekr/snippet.js?key=2a6b322f-01a4-41c6-8d0f-fdfccbad414c";
            script.onload = onLoad;
            script.defer = true;
            script.crossOrigin = "anonymous";
            document.body.appendChild(script);
        }

        window.zESettings = {
            webWidget: {
                color: { theme: theme.palette.brand.tertiary },
                zIndex: 6
            }
        };

        window.zE?.("webWidget", "helpCenter:setSuggestions", { url: true });
    }, [role]);

    useEffect(() => {
        window.zE?.("webWidget", "helpCenter:setSuggestions", { url: true });
    }, [location]);
};
