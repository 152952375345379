import Immutable from "immutable";
import { useDialogState } from "../../../../../../../hooks/useDialogState";
import { Dialog, DialogTitle } from "@mui/material";
import { Edit, Warning } from "@mui/icons-material";
import { T } from "../../../../../../util/t";
import { useEventDivision } from "../EventDivisionProvider";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_TEMPLATES } from "../../../../../../../graphql/organisation";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { changeTemplate } from "../../../../../../../actions/eventDivision";
import { DialogFormKit } from "../../../../../../forms/FormKit";
import { useEvent } from "../../../EventProvider";
import { StatusPill } from "../../../../../../layout/StatusPill";
import { nSpaces } from "../../../../../../../utils/utils";
import { Notification } from "../../../../../../layout/notifications/Notification";
import { useConvertToImmutable } from "hooks/useConvertToImmutable";
import { sortByLabel } from "utils/sort";

export const EventDivisionTemplateDialog = () => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const eventDivision = useConvertToImmutable(useEventDivision());
    const event = useConvertToImmutable(useEvent()) || Immutable.Map();
    const { data } = useQuery(GET_ORGANISATION_WITH_TEMPLATES, { variables: { id: event.get("organisation_id") }, fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }),
        templates = data?.organisation?.federationTemplates;

    if (!templates || !eventDivision.get("template")) return null;

    return (
        <>
            <StatusPill onClick={openDialog} status="finished">
                <T>{eventDivision.getIn(["template", "name"])}</T>
                {nSpaces(2)}
                <Edit fontSize="inherit"/>
            </StatusPill>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={dialogOpen}
                aria-labelledby="change-template-dialog-title"
                onClose={closeDialog}>
                <EventDivisionTemplateDialogContent
                    eventDivisionId={eventDivision.get("id")}
                    eventId={event.get("id")}
                    template={eventDivision.get("template").toJS()}
                    templates={templates}
                    closeDialog={closeDialog}
                />
            </Dialog>
        </>
    );
};

export const EventDivisionTemplateDialogContent = ({ eventDivisionId, eventId, template, templates, closeDialog }) => {
    const dispatch = useDispatch();
    const onSubmit = useCallback(({ template_id }) =>
        dispatch(changeTemplate(eventDivisionId, eventId, template_id)).then(closeDialog)
    , []);

    const options = templates.map(template => ({ label: template.name, value: template.id })).sort(sortByLabel);
    if (!options.find(o => o.value === "" + template.id))
        options.unshift({
            label: template.name,
            value: "" + template.id
        });

    return (
        <>
            <DialogTitle id="change-template-dialog-title">
                <T>Change the template in use for this division</T>
            </DialogTitle>
            <DialogFormKit
                initialValues={{ template_id: "" + template.id }}
                fields={[{
                    name: "template_id",
                    label: "Template",
                    type: "select",
                    options
                }]}
                onSubmit={onSubmit}
                onCancel={closeDialog}
                buttonLabel="Change"
            >
                <Notification type="warning" text="change_template_warning" Icon={Warning}/>
                <br/>
            </DialogFormKit>
        </>
    );
};
