import { Modifier } from "../components/events/judging/modifiers/Modifier";
import { FormattedTotal } from "./FormattedTotal";

export const Result = result => ({
    ...result,
    getModifier() {
        return Object.values(result.rides || {})
            .map(athleteRides =>
                athleteRides
                    .filter(ride => !ride.pending && ride.modifier)
                    .sort((a, b) => ["DNF", "DSQ", "DNS"].indexOf(a.modifier.type.split("::")[1]) - ["DNF", "DSQ", "DNS"].indexOf(b.modifier.type.split("::")[1]))
                    ?.[0]
            ).filter(m => m)[0]?.modifier;
    },
    placeToDisplay() {
        const modifier = this.getModifier();
        const modifierClass = modifier?.type.split("::")?.[1];
        const didNotModifier = modifierClass?.match(/D(N|S)(S|F|Q)/);

        if (result.total > 0) return { place: result.place, type: "place" };
        if (didNotModifier) return { place: <Modifier modifier={modifier}/>, type: "modifier" };
        return { place: "-", type: "unscored" };
    },
    formattedTotal(config) {
        return FormattedTotal(result.total, config);
    }
});
