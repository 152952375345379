import { Typography } from "@mui/material";
import { Fake } from "../skeleton/Fake";
import { makeStyles } from "../../providers/makeStyles";
import { PaddedPage } from "../page/PaddedPage";

const useStyles = makeStyles(theme => ({
    total: {
        borderTop: `1px solid ${theme.palette.border.light}`,
        paddingTop: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between"
    }
}));
export const PurchaseDetailsSkeleton = () => {
    const classes = useStyles();

    return (
        <PaddedPage className="skeleton">
            <Typography variant="h5" >Purchase details</Typography>
            <p><Typography variant="label1"><Fake size={19}/> <Fake size={45}/></Typography></p>
            <p><Typography variant="label1"><Fake size={22}/> <Fake size={19}/></Typography></p>
            <p><Typography variant="label1"><Fake size={15}/> <Fake size={30}/></Typography></p>
            <section className={classes.total}>
                <Typography variant="h7"><Fake size={22}/></Typography>
                <Typography variant="h7"><Fake size={22}/></Typography>
            </section>
        </PaddedPage>);
};
