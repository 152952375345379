import { useCallback, useEffect, useRef } from "react";

export const useResizeObserver = (ref, onResize) => {
    const isSupported = typeof window.ResizeObserver !== "undefined";

    const animationFrame = useRef();
    const safeOnResize = useCallback(entries => animationFrame.current = requestAnimationFrame(() => onResize(entries)), [onResize]);
    const resizeObserver = useRef(isSupported && new ResizeObserver(safeOnResize));

    useEffect(() => {
        if (!isSupported || !ref.current) return;

        const safeRef = ref.current; // The ref value 'ref.current' will likely have changed by the time this effect cleanup function runs.
        resizeObserver.current.observe(safeRef);

        return () => {
            resizeObserver.current.unobserve(safeRef);
            cancelAnimationFrame(animationFrame.current);
        };
    }, []);

    return null;
};
