import { useOrganisationStyles } from "../../../layout/organisation/useOrganisationStyles";
import { Fake } from "../../../layout/skeleton/Fake";
import { Typography } from "@mui/material";
import { makeStyles } from "../../../providers/makeStyles";
import { SearchForm } from "../../../search/search";
import { useOrganisationRowStyles } from "../../../layout/organisation/useOrganisationRowStyles";
import { useFilteredOrganisationsStyles } from "../../../layout/organisation/useFilteredOrganisationsStyles";
import { OrganisationAvatar } from "components/layout/avatar/OrganisationAvatar";

export const FederatedOrganisationsPageSkeleton = () => {
    const classes = { ...useOrganisationStyles(), ...useOrganisationRowStyles(), ...useFilteredOrganisationsStyles() };

    return <div className="skeleton">
        <SearchForm
            inHeading
            className={classes.search}
        />
        <section className={classes.section}>
            <Typography variant="body1" className={classes.firstLetter}><Fake size={8} /></Typography>
            <FakeOrganisation nameSizes={[23,45]}/>
            <FakeOrganisation nameSizes={[13,20, 23]}/>
            <FakeOrganisation nameSizes={[54,31]}/>
            <Typography variant="body1" className={classes.firstLetter}><Fake size={8} /></Typography>
            <FakeOrganisation nameSizes={[18,22,17]}/>
            <FakeOrganisation nameSizes={[32,47]}/>
            <Typography variant="body1" className={classes.firstLetter}><Fake size={8} /></Typography>
            <FakeOrganisation nameSizes={[20,15]}/>
            <FakeOrganisation nameSizes={[41,30]}/>
        </section>
    </div>;
};

const FakeOrganisation = ({ nameSizes }) => {
    const classes = useOrganisationStyles();

    return <section className={classes.organisation}>
        <div className={classes.details}>
            <OrganisationAvatar size="xl" />
            <Typography variant="subtitle3" className={classes.title}><FakeOrganisationName sizes={nameSizes} /></Typography>
        </div>
    </section>;
};

const useFakeOrganisationNameStyles = makeStyles(theme => ({
    name: {
        display: "flex"
    },
    words: {
        margin: theme.spacing(1.5, 0.5, 0)
    }
}));
const FakeOrganisationName = ({ sizes }) => {
    const classes = useFakeOrganisationNameStyles();

    return <div className={classes.name}>
        {sizes.map((size, i) => <Fake key={i} className={classes.words} size={size} />)}
    </div>;
};
