import classnames from "classnames";

import { T } from "../util/t";
import { Typography } from "@mui/material";
import { makeStyles } from "../providers/makeStyles";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: theme.typography.pxToRem(48),
        marginBottom: theme.spacing(2),
    },
    text: {
        marginBottom: theme.spacing(2)
    }
}));
export const EmptyState = ({ className, icon, title, text, action }) => {
    const classes = useStyles();

    return (
        <div className={classnames(className, "empty-state")}>
            <div className={classes.icon}>{icon}</div>
            {title && <Typography variant="label1"><T>{title}</T></Typography>}
            <div className={classes.text}><T>{text}</T></div>
            {action && <div className="action">{action}</div>}
        </div>
    );
};
