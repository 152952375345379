import { makeStyles } from "components/providers/makeStyles";
import logoPrimary from "assets/logos/LockupHorizontalBrandRed.svg";
import logoSecondary from "assets/logos/LockupHorizontalBrandSecondary.svg";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme, { sticky, variant }) => ({
    poweredBy: {
        position: sticky && "fixed",
        width: "100%",
        left: 0,
        textAlign: "center",
        zIndex: 2,
        bottom: theme.spacing(1),
        pointerEvents: "none",
        color: variant === "secondary" ? theme.palette.brand.secondary : theme.palette.text.primary,
    },
    textLogo: {
        minHeight: theme.spacing(4),
        pointerEvents: "auto",
        display: "inline-flex",
        alignItems: "center",
        borderRadius: theme.spacing(3),
        background: variant === "secondary" ? theme.palette.brand.tertiary : theme.palette.background.paper,
        padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
        "& a": {
            color: variant === "secondary" ? theme.palette.brand.secondary : theme.palette.text.primary
        },
        boxShadow: theme.shadows[4],
    },
    text: {
        marginTop: theme.spacing(0.3125),
        marginRight: theme.spacing(0.5),
    }
}));

const variantToLogo = {
    primary: logoPrimary,
    secondary: logoSecondary,
};

export const PoweredBy = ({ sticky = true, variant = "primary" }) => {
    const classes = useStyles({ sticky, variant });

    return (
        <div className={classes.poweredBy}>
            <Typography className={classes.textLogo} variant="caption" component="a" href="http://liveheats.com/" target="_blank" rel="noreferrer">
                <span className={classes.text}>Powered by</span>
                <img src={variantToLogo[variant]} height={20} alt="Logo"/>
            </Typography>
        </div>
    );
};
