import { useEntriesState } from "components/layout/entries/headings/useEntriesState";
import { useTranslate } from "components/providers/LocalizationProvider";
import { T } from "components/util/t";
import { number } from "yup";
import { SelfSubmittingFormField } from "../../forms/selfSubmittingField/SelfSubmittingFormField";
import { useAllEntries } from "../useAllEntries";

const getMessage = (entryLimit, confirmed, initialEntryLimit, isTeams) => {
    if (initialEntryLimit > confirmed) {
        if (entryLimit != null && entryLimit > initialEntryLimit) return <T spots={initialEntryLimit}>exceeds_available_spots</T>;
        if (entryLimit != null && entryLimit > confirmed) return <T confirmed={confirmed} isTeams={isTeams}>exceeds_confirmed_athletes</T>;
        if (entryLimit == null) return <T confirmed={confirmed} isTeams={isTeams}>exceeds_confirmed_athletes</T>;
    } else {
        if (entryLimit != null && initialEntryLimit === confirmed && entryLimit > initialEntryLimit) return <T spots={initialEntryLimit}>exceeds_available_spots</T>;
        if (entryLimit != null && entryLimit > confirmed) return <T confirmed={confirmed} isTeams={isTeams}>exceeds_confirmed_athletes</T>;
        if (entryLimit != null && entryLimit > initialEntryLimit) return <T spots={initialEntryLimit}>exceeds_available_spots</T>;
        if (initialEntryLimit === confirmed) return;
        return typeof initialEntryLimit !== "undefined" && <T spots={initialEntryLimit}>exceeds_available_spots</T>;
    }
};

export const StartingAthletes = ({ entryLimit, initialEntryLimit, refetch, eventDivisionId, isTeams }) => {
    const { allEntries } = useAllEntries(eventDivisionId);
    const { confirmed } = useEntriesState(allEntries?.map(e => e.get("entries")).flatten(1).toJS());
    const placeholder = useTranslate(...!initialEntryLimit || confirmed >= initialEntryLimit ? ["x_(confirmed_athletes)", { confirmed, isTeams }] : ["x_(available_spots)", { spots: initialEntryLimit }]);
    const lowerThanConfirmedError = useTranslate("lower_than_confirmed_athletes", { confirmed, isTeams });

    const onSubmit = values => {
        entryLimit.current = values.startingAthletes ? { limit: parseInt(values.startingAthletes) } : null;
        return refetch({ entryLimit: entryLimit.current });
    };

    return (
        <SelfSubmittingFormField
            field={{
                label: isTeams ? "Starting teams" : "Starting athletes",
                name: "startingAthletes",
                type: "number",
                width: "100%",
                validation: number()
                    .transform(value => isNaN(value) ? null : parseInt(value))
                    .nullable()
                    .min(confirmed, lowerThanConfirmedError),
                inputProps: { pattern: "[0-9]*", placeholder },
                infoMessage: getMessage(entryLimit.current?.limit, confirmed, initialEntryLimit, isTeams)
            }}
            onSubmit={onSubmit}
            initialValue=""
            showError
        />
    );
};
