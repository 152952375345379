import { Box, Container, Typography } from "@mui/material";

import { useEventHeaderStyles } from "../../../layout/event/useEventHeaderStyles";
import { Fake } from "../../../layout/skeleton/Fake";
import { Breadcrumbs } from "../../../layout/navigation/Breadcrumbs";
import { EventHeaderDetailsSkeleton } from "components/layout/event/EventHeaderDetailsSkeleton";
import { SimpleListItem } from "components/layout/lists/SimpleList";

export const EventPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();

    return (
        <Container className="skeleton">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <EventHeaderDetailsSkeleton />
            </div>

            <Typography variant="h5" component="h2"><Fake size={31}/></Typography>

            <Box component="ul" margin={0} padding={0} paddingBottom={9}>
                <DivisionRowSkeleton title={40} caption={10}/>
                <DivisionRowSkeleton title={20} caption={8}/>
                <DivisionRowSkeleton title={33} caption={7}/>
                <DivisionRowSkeleton title={25} caption={9}/>
            </Box>
        </Container>
    );
};

const DivisionRowSkeleton = ({ title, caption }) => <SimpleListItem title={<Fake size={title}/>} description={<Fake size={caption}/>} />;
