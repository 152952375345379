import Immutable from "immutable";
import dayjs from "dayjs";

import createStore from "./createStore";

export const reducer = createStore({
    SET_EVENT_DIVISION_ENTRIES: (state, action) => {
        const eventDivision = action.res.data;
        let newState = state.set("" + action.event_division_id, Immutable.fromJS({
            ...eventDivision,
            group: eventDivision.event_divisions?.map(ed => "" + ed.id) || ["" + eventDivision.id]
        }));

        eventDivision.event_divisions?.forEach(ed => newState = newState.set("" + ed.id, Immutable.fromJS(ed)));

        return newState;
    },

    SET_ENTRY: (state, action) =>
        state.updateIn(["" + action.event_division_id, "entries"], entries => {
            const index = entries.findIndex(entry => entry.get("id") === action.entry_id);
            if (index < 0) return entries;

            return entries.set(index, Immutable.fromJS(action.res.data).set("updated_at", dayjs().toISOString()));
        }),

});
