import { useState } from "react";
import { IconButton } from "@mui/material";
import { ManageEventLinks } from "../navigation/ManageEventLinks";
import { ChevronLeft } from "@mui/icons-material";
import { useIsMobile } from "hooks/useIsMobile";
import { useParams } from "react-router-dom";
import { useAdminPageContext } from "./AdminPageProvider";
import { useSideNavStyles } from "components/layout/navigation/useSideNavStyles";

export const SideNav = () => {
    const { id } = useParams();
    const [isSideNavCollapsed, setSideNavCollapsed] = useState(false);
    const toggleSideNav = () => setSideNavCollapsed(!isSideNavCollapsed);
    const isMobile = useIsMobile();
    const { isRemoteVisible } = useAdminPageContext();
    const classes = useSideNavStyles({ isRemoteVisible, isSideNavCollapsed });

    if (isMobile) return null;

    return (
        <div className={classes.nav} onClick={isSideNavCollapsed ? toggleSideNav : undefined}>
            {!isSideNavCollapsed && <ManageEventLinks eventId={id}/>}
            <IconButton className={classes.toggle} onClick={toggleSideNav}>
                <ChevronLeft/>
            </IconButton>
        </div>
    );
};
