import { useCallback } from "react";
import { ScanDialogForm } from "../nfc/ScanDialogForm";

export const GroupEventDivisionSelector = ({ onSubmit, eventDivisions }) => {
    const handleSubmit = useCallback(values => onSubmit(values.division), [onSubmit]);

    const fields = [{
        name: "division",
        label: "Division",
        type: "select",
        options: [
            { label: "Select a division", value: "", disabled: true },
            ...eventDivisions.map(ed => ({ label: ed.getIn(["division", "name"]), value: ed.get("id") })).toJS()
        ]
    }];

    return <ScanDialogForm handleSubmit={handleSubmit} fields={fields}/>;
};
