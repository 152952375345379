import { DivisionsSelector } from "components/divisions/divisionsSelector";
import { required } from "components/forms";
import { useIsMobile } from "hooks/useIsMobile";

export const divisionValidation = value => required(value && value.join("")) ? "You must select at least one division" : undefined;

export const EventDivisionsSelector = ({ array, divisions, localization, organisationDivisions }) => {
    const isMobile = useIsMobile();

    return (
        <DivisionsSelector
            fieldName="event_divisions_attributes"
            divisionNameField="division_name"
            divisionIdField="division_id"
            divisionValidation={divisionValidation}
            array={array}
            divisions={divisions}
            localization={localization}
            organisationDivisions={organisationDivisions}
            headers={[{ label: "Divisions in this event:", width: isMobile ? "100%" : "60%" }, { width: "auto" }]}
        />
    );
};
