import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { Fake } from "../skeleton/Fake";

const useFormFieldStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: theme.spacing(4),
    },
    label: {
        marginBottom: theme.spacing(1),
        display: "block",
    }
}));
export const FakeFormField = ({ label, field, height }) => {
    const classes = useFormFieldStyles();

    return (
        <div className={classes.wrapper}>
            <Typography variant="label1" className={classes.label}><Fake size={label}/></Typography>
            <FakeInput input={field} height={height}/>
        </div>
    );
};

const useInputStyles = makeStyles((theme, { height }) => ({
    input: {
        display: "inline-block",
        height: height || 42,
        overflowY: "hidden", // this prevents wrapping to next line on small screens
        "&&": {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        }
    }
}));
export const FakeInput = ({ input, height }) => {
    const classes = useInputStyles({ height });

    return <Fake className={classes.input} size={input}/>;
};
