import { memo } from "react";

import { makeStyles } from "components/providers/makeStyles";
import { HeatSummary } from "./HeatSummary";
import { RidesTable } from "./RidesTable";

const useStyles = makeStyles(theme => ({
    summary: {
        marginRight: theme.spacing(1)
    },
    rides: {
        marginTop: theme.spacing(2)
    }
}));

export const HeatSummaryWithRides = memo(({ heat, renderRides, inDialog }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.summary}>
                <HeatSummary heat={heat} inDialog={inDialog} />
            </div>

            {renderRides && (
                <div className={classes.rides}>
                    <RidesTable heat={heat}/>
                </div>
            )}
        </>
    );
});
