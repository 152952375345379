import { Typography } from "@mui/material";
import { Fake } from "../skeleton/Fake";
import { PaddedPage } from "../page/PaddedPage";

export const PaymentDetailsSkeleton = () =>
    <PaddedPage className="skeleton">
        <Typography variant="h5" >Payment details</Typography>
        <p><Typography variant="label1"><Fake size={19}/> <Fake size={45}/></Typography></p>
        <p><Typography variant="label1"><Fake size={22}/> <Fake size={19}/></Typography></p>
        <p><Typography variant="label1"><Fake size={15}/> <Fake size={30}/></Typography></p>
        <p><Typography variant="label1"><Fake size={22}/> <Fake size={56}/></Typography></p>
    </PaddedPage>;
