import { Link } from "react-router-dom";
import classnames from "classnames";

const ItemWrapper = (props) => {
    const Component = props.to ? Link : "a";
    return (
        <Component {...props} className={classnames(props.className, "box-item", { "box-link": props.to })}>
            {props.children}
        </Component>
    );
};

export const boxListClassName = borderless => classnames("box-list", "horizontal", { borderless });

export const BoxListRow = ({ element }) =>
    <ItemWrapper to={element.link}>
        <span className="title-left">{element.titleLeft}</span>
        <span className="title-right">{element.titleRight}</span>
    </ItemWrapper>;

export const BoxList = ({ list, borderless }) =>
    <ul className={boxListClassName(borderless)}>
        {list.map((element, i) => <li key={i}><BoxListRow element={element} i={i} /></li>)}
    </ul>;
