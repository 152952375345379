import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { remove } from "../../../../actions/event";
import { useDialogState } from "../../../../hooks/useDialogState";
import { T } from "../../../util/t";
import { ConfirmationDialog } from "components/layout/dialogs/ConfirmationDialog";

export const DeleteEventWidget = ({ eventId }) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const dispatch = useDispatch();
    const history = useHistory();
    const action = () => dispatch(remove(eventId)).then(() => history.push("/director"));

    return (
        <>
            <Button onClick={openDialog} startIcon={<Delete/>}>
                <T>Delete event</T>
            </Button>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeDialog}
                action={action}
                title="Are you sure you want to delete this event?"
                content="All of the event data will be lost, this action cannot be undone!"
                buttonTitle="Yes, delete it"
            />
        </>
    );
};
