import classnames from "classnames";
import { useMemo } from "react";
import { Typography } from "@mui/material";

import { JerseyAvatar } from "../../heat/JerseyAvatar";
import { useIsMobile } from "hooks/useIsMobile";
import { makeStyles } from "components/providers/makeStyles";
import { RideCells } from "./RideCells";

export const toFixedOrDash = (value, decimals) => value > 0 ? parseFloat(value).toFixed(decimals) : "-";

const useTeamStyles = makeStyles(theme => ({
    teamRow: {
        "& td": {
            borderTop: `1px solid ${theme.palette.border.main}`,
        },
    },
    team: {
        padding: theme.spacing(1, 0)
    },
    athlete: {
        "&&": {
            width: "30%",
            textAlign: "left"
        }
    },
    total: {
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 2),
        },
        "@media print": {
            padding: theme.spacing(0, 2),
        },
        "&&": {
            textAlign: "right",
        }
    },
    avatar: {
        padding: theme.spacing(0, 2)
    },
    row: {
        "&.dropped": {
            opacity: 0.6,

            "& .scoringRide": {
                background: "none"
            }
        }
    }
}));
export const TeamRow = ({ result, maxRides, categories }) => {
    const scoreCells = useMemo(() =>
        maxRides * (Object.values(categories).reduce((sum, category) => sum + category.maxScores, 0) + 1)
    , [categories, maxRides]);
    const isMobile = useIsMobile();
    const classes = useTeamStyles();

    return (
        <>
            <tr className={classes.teamRow}>
                <td><Typography variant="h4">{toFixedOrDash(result.place, 0)}</Typography></td>
                <td className={classes.avatar}><JerseyAvatar alt={result.teamName}/></td>
                <td className={classnames(classes.team, classes.athlete)}><Typography variant="subtitle3">{result.teamName}</Typography></td>
                <td className={classes.total}><Typography variant="h7">{toFixedOrDash(result.total, 2)}</Typography></td>
                {!isMobile && <td colSpan={scoreCells}/>}
            </tr>
            {result.memberResults.map(memberResult =>
                <MemberRow
                    key={memberResult.athleteId}
                    classes={{ row: classes.row, athlete: classes.athlete, total: classes.total }}
                    memberResult={memberResult}
                    maxRides={maxRides}
                    categories={categories}
                />
            )}
        </>
    );
};

export const toFixedOrEmpty = (value, decimals) => value != null ? parseFloat(value).toFixed(decimals) : "";

export const MemberRow = ({ classes = {}, memberResult, maxRides, categories }) => {
    const athlete = memberResult.competitor.athlete;
    const rides = Object.keys(memberResult.rides).reduce((rides, athleteId) => rides.concat(memberResult.rides[athleteId]), []);
    const isMobile = useIsMobile();

    return (
        <tr className={classnames(classes.row, { dropped: !memberResult.countingMember })}>
            <td/>
            <td><Typography variant="label1">{toFixedOrDash(memberResult.place, 0)}</Typography></td>
            <td className={classes.athlete}><Typography variant="body2" color="textSecondary">{athlete.name}</Typography></td>
            <td className={classes.total}><Typography variant="label1">{toFixedOrDash(memberResult.total, 2)}</Typography></td>
            {!isMobile && [...Array(maxRides)].map((_, i) =>
                <RideCells key={`ride${i}`} ride={rides[i]} categories={categories}/>)
            }
        </tr>
    );
};

