import { makeStyles } from "../../../providers/makeStyles";
import { SelfSubmittingFormField } from "../../forms/selfSubmittingField/SelfSubmittingFormField";
import { Link } from "@mui/material";
import { T } from "../../../util/t";

const progressionField = placeholder => ({
    label: "Progression per heat",
    name: "progression",
    type: "number",
    width: "100%",
    inputProps: { pattern: "[0-9]*", placeholder }
});

const useStyles = makeStyles((theme, { disabled }) => ({
    field: {
        position: "relative",
    },
    link: {
        position: "absolute",
        bottom: "-1.25rem",
        color: theme.palette.text.link,
        opacity: disabled && .4,
        pointerEvents: disabled && "none"
    }
}));
const is50Percent = progression => progression && !progression.max;
export const ProgressionField = ({ round, onProgressionChange, translate }) => {
    const onSubmitProgression = values => onProgressionChange({
        round: round.roundPosition,
        progression: values.progression ? [{ max: parseInt(values.progression) }] : null
    });
    const onSet50Percent = () => onProgressionChange({ round: round.roundPosition, progression: [{}] });
    const setTo50Percent = is50Percent(round.progression);
    const defaultIs50Percent = !round.defaultProgression || is50Percent(round.defaultProgression);
    const classes = useStyles({ disabled: setTo50Percent });

    return (
        <div className={classes.field}>
            <SelfSubmittingFormField
                field={progressionField(setTo50Percent ? "50%" : translate("divisionDefault", { defaultSize: defaultIs50Percent ? "50%" : round.defaultProgression?.max }))}
                onSubmit={onSubmitProgression}
                initialValue={round.progression?.max || ""}
                alwaysReset
            />
            <Link className={classes.link} variant="button2" underline="always" onClick={onSet50Percent} role="button">
                <T>Set to 50%</T>
            </Link>
        </div>
    );
};
