import { makeStyles } from "../../../providers/makeStyles";
import { palette } from "../../../providers/theme/palette";

export const useChipStyles = makeStyles(theme => ({
    chipContainer: {
        padding: theme.spacing(0.75),
    },
    chip: {
        display: "flex",
        alignItems: "center",
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(0.5, 2),
        whiteSpace: "nowrap",
        cursor: "pointer",
        "&:disabled": {
            opacity: 0.6,
        },
        "&.selected": {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                opacity: 0.9,
            },
            "& .MuiTouchRipple-child": {
                backgroundColor: palette.state.tapLight,
            }
        },
        "&:not(.selected)": {
            border: `1px solid ${theme.palette.border.main}`,
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                border: `1px solid ${theme.palette.primary.main}`,
                opacity: 0.9,
            },
            "& .MuiTouchRipple-child": {
                backgroundColor: palette.state.tapLight,
            },
        },
        "&:focus-visible": {
            outline: `3px solid ${palette.input.focus}`,
            outlineOffset: "2px"
        },
    }
}));
