import { Typography } from "@mui/material";
import { T } from "../../util/t";
import { PaymentDetailsSection } from "./PaymentDetailsSection";
import { formatPrice } from "../../../utils/utils";
import dayjs from "dayjs";
import { SHORT_DATE } from "../../../utils/dates";
import { netRefunds, isRefunded, totalRefunds } from "../../organisations/payments/fields/utils";

export const PaymentDetails = ({ payment }) => {
    const item = (payment.payable.membershipDivisions !== undefined) ? "Membership for" : "Registration to";
    const amountRefunded = totalRefunds(payment);
    const refunded = isRefunded(payment);

    return (
        <PaymentDetailsSection title="Payment details" >
            <p><Typography variant="label1"><T>{item}</T>:</Typography> {payment.payable.name}</p>
            <p><Typography variant="label1"><T>Amount</T>:</Typography> {formatPrice(payment.amount, payment.currency)}</p>
            {refunded && <p><Typography variant="label1"><T>Refunded</T>:</Typography> {formatPrice(amountRefunded, payment.currency)}</p>}
            <p><Typography variant="label1"><T>Fee</T>:</Typography> {formatPrice(payment.fee, payment.currency)}</p>
            <p><Typography variant="label1"><T>Net</T>:</Typography> {formatPrice(netRefunds(payment) - payment.fee, payment.currency)}</p>
            <p><Typography variant="label1"><T>Reference</T>:</Typography> {payment.intentId}</p>
            <p><Typography variant="label1"><T>Date</T>:</Typography> {dayjs(payment.createdAt).format(SHORT_DATE)}</p>
        </PaymentDetailsSection>
    );
};
