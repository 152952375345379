import { Container, Typography } from "@mui/material";
import { RegistrationWizardSkeleton } from "../../../../../layout/registration/RegistrationWizardSkeleton";
import { T } from "../../../../../util/t";
import { useEventHeaderStyles } from "../../../../../layout/event/useEventHeaderStyles";
import { Breadcrumbs } from "../../../../../layout/navigation/Breadcrumbs";

export const SeriesRegistrationPageSkeleton = () => {
    const headerClasses = useEventHeaderStyles();

    return (
        <Container className="skeleton" role="status" aria-label="loading">
            <div className={headerClasses.container}>
                <Breadcrumbs className="no-background" crumbs={[{}, {}]}/>
                <Typography variant="h3" component="h1" className={headerClasses.name}><T>Member registration</T></Typography>
            </div>
            <RegistrationWizardSkeleton/>
        </Container>
    );
};
