import { makeStyles } from "../../../providers/makeStyles";

export const useNFCTagPageStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        flex: "1 1 auto",
        borderRadius: theme.spacing(2),
        border: `2px solid ${theme.palette.background.low}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    }
}));
