import { AthleteRow } from "./AthleteRow";
import { TeamBreakdown } from "./TeamBreakdown";

export const CompetitorRow = ({ competitor, heat, jersey, position, inDialog, ...rest }) => {
    const { athlete, bib, teamName, teamMembers } = competitor;
    const { config } = heat;

    return (
        <>
            <AthleteRow athlete={athlete} bib={bib} teamName={teamName} jersey={jersey} position={position} heat={heat} {...rest} />
            {inDialog && config.isTeams && config.calculator === "placing" && <TeamBreakdown teamConfig={config.teamConfig} teamMembers={teamMembers} />}
        </>
    );
};
