import { createContext, forwardRef, useCallback, useContext, useRef, useState } from "react";
import { VirtualizedList } from "components/virtualized-list/VirtualizedList";
import { useResizeObserver } from "hooks/useResizeObserver";
import { SearchSelect } from "./SearchSelect";

const LISTBOX_PADDING = 8;

const SearchSelectVirtualizedContext = createContext({});

export const OuterElementType = forwardRef((props, ref) => {
    const { setHeight, ...rest } = useContext(SearchSelectVirtualizedContext);
    return <div ref={ref} {...props} {...rest} />;
});

export const InnerElementType = props => {
    const { setHeight } = useContext(SearchSelectVirtualizedContext);
    const ref = useRef();

    const onResize = useCallback(elements => setHeight(elements[0].contentRect.height), []);
    useResizeObserver(ref, onResize);

    return <div {...props} ref={ref} />;
};

export const ListboxComponent = forwardRef(({ children, ...rest }, ref) => {
    const [height, setHeight] = useState(Infinity);
    const estimatedItemSize = 33;

    const itemData = {
        items: children,
        rowElementType: "div",
        padding: LISTBOX_PADDING,
        renderItemComponent: (item, ref) => <div ref={ref}>{item}</div>
    };

    return (
        <div ref={ref}>
            <SearchSelectVirtualizedContext.Provider value={{ ...rest, setHeight }}>
                <VirtualizedList
                    itemData={itemData}
                    height={Math.min(height, estimatedItemSize * 9) + LISTBOX_PADDING * 2}
                    estimatedItemSize={estimatedItemSize}
                    initialItemsSize={{ 0: 30 }}
                    innerElementType={InnerElementType}
                    outerElementType={OuterElementType}
                />
            </SearchSelectVirtualizedContext.Provider>
        </div>
    );
});

export const SearchSelectVirtualized = props => <SearchSelect {...props} ListboxComponent={ListboxComponent}/>;
