import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { Archive } from "@mui/icons-material";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ARCHIVE_ORGANISATION_PROPERTY, GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../graphql/organisation";
import { useDialogState } from "../../../hooks/useDialogState";
import { T } from "../../util/t";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import * as EventActions from "../../../actions/event";
import * as SeriesActions from "../../../actions/series";
import { usePropertyWidgetState } from "./PropertyWidget";

export const ArchiveButton = ({ property }) => {
    const { organisationId, eventId, seriesId } = usePropertyWidgetState();
    const [dialogOpen, openDialog, closeDialog] = useDialogState();
    const dispatch = useDispatch();

    const [archiveProperty] = useMutation(ARCHIVE_ORGANISATION_PROPERTY, {
        update(cache, { data: { archiveOrganisationProperty: { property } } }) {
            const { organisation } = cache.readQuery({ query: GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, variables: { id: organisationId } });
            const removeProperty = p => p?.uuid !== property.uuid;
            cache.writeQuery({
                query: GET_ORGANISATION_WITH_FEDERATION_PROPERTIES,
                variables: { id: organisationId },
                data: { organisation: { ...organisation, customProperties: organisation.customProperties.filter(removeProperty), federationProperties: organisation.federationProperties.filter(removeProperty) } }
            });
            if (eventId) dispatch(EventActions.get(eventId));
            if (seriesId) dispatch(SeriesActions.get(seriesId));
        }
    });

    const onArchive = useCallback(() => archiveProperty({ variables: { id: organisationId, uuid: property.uuid } }).then(closeDialog), [property.uuid]);

    return (
        <>
            <Button onClick={openDialog} className="left" startIcon={<Archive/>}>
                <T>Archive</T>
            </Button>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeDialog}
                action={onArchive}
                title="Archive field?"
                content="Archiving this field will remove it from your organisation. You can always just deactivate."
                buttonTitle="Archive field"
            />
        </>
    );
};
