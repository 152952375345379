import { createContext, useContext, useEffect, useState } from "react";

const FacebookContext = createContext({});
FacebookContext.displayName = "Facebook";

export const useFacebook = () => useContext(FacebookContext);

export const FacebookProvider = ({ appId, options = {}, children }) => {
    const [FB, setFB] = useState(undefined);
    useEffect(() => {
        initFacebook(setFB, appId, options);
    }, []);

    return (
        <FacebookContext.Provider value={FB}>
            {children}
        </FacebookContext.Provider>
    );
};

const initFacebook = (setFB, appId, options) => {
    window.fbAsyncInit = () => {
        window.FB.init({
            appId: appId,
            version: "v17.0",
            cookie: false,
            status: false,
            xfbml: false,
            ...options
        });

        setFB(window.FB);
    };

    if (window.document.getElementById("facebook-jssdk")) {
        return setFB(window.FB);
    }

    const js = window.document.createElement("script");
    js.id = "facebook-jssdk";
    js.async = true;
    js.defer = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    js.crossOrigin = "anonymous";

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(js);
};
