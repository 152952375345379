import { Suspense } from "react";
import { Route } from "react-router-dom";

import { ErrorBoundary } from "../providers/ErrorBoundary";

export const LazyRoute = ({ fallback: Fallback, ...props }) =>
    <ErrorBoundary>
        <Suspense fallback={<Fallback/>}>
            <Route {...props}/>
        </Suspense>
    </ErrorBoundary>;
