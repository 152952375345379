import { Box } from "@mui/material";
import { Footer } from "./Footer";
import { OrganisationsLogos } from "./OrganisationsLogos";
import { Header } from "./Header";
import { OrganiserPromoCard } from "./OrganiserPromoCard";
import { FeaturedEvents } from "./FeaturedEvents";

export const Section = ({ marginTop = 6, children, maxWidth, ...rest }) =>
    <Box maxWidth={theme => maxWidth || theme.shape.pageMaxWidth} width="100%" mx="auto" marginTop={marginTop} {...rest}>{children}</Box>;

export const HomePage = () => (
    <>
        <Header />
        <FeaturedEvents />
        <OrganiserPromoCard />
        <OrganisationsLogos/>
        <Footer />
    </>
);
