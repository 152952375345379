import { Typography } from "@mui/material";
import { T } from "../../util/t";
import { AthleteRowsHeader } from "./AthleteRowsHeader";
import { CompetitorRow } from "./CompetitorRow";
import { useHeatSummaryStyles } from "./heatStyles";
import { ProgressionRow } from "./ProgressionRow";

export const getCompetitorsByPosition = (competitors) =>
    competitors.reduce((hash, competitor) => {
        hash[competitor.position] = competitor;
        return hash;
    }, {});

export const HeatSummaryStartlist = ({ heat, inDialog, ...rest }) => {
    const progressions = heat.progressions || [];
    let competitors = heat.competitors;
    if (heat.config.runBased) {
        const notEndedHeat = heat.heats.find(h => !h.endTime) || heat.heats[heat.heats.length - 1];
        competitors = heat.competitors.filter(competitor => competitor.heatId === notEndedHeat.id);
    }
    const listSize = Math.max(...competitors.map(c => c.position), progressions.length - 1, -1) + 1;
    const competitorsByPosition = getCompetitorsByPosition(competitors);
    const classes = useHeatSummaryStyles();

    return (
        listSize === 0 ?
            <li className={classes.row}>
                <Typography variant="body1"><T>Athlete lineup is coming soon, stay tuned.</T></Typography>
            </li> :
            <>
                <AthleteRowsHeader heat={heat} {...rest} />
                {[...Array(listSize)].map((_, i) => {
                    const competitor = competitorsByPosition[i],
                        jersey = (heat.config.jerseyOrder || [])[i];

                    if (!competitor && !progressions[i]) return null;

                    return (
                        <li className={classes.row} key={i}>
                            {!competitor
                                ? <ProgressionRow progression={progressions[i]} jersey={jersey} position={i} heat={heat} {...rest} />
                                : <CompetitorRow competitor={competitor} heat={heat} jersey={jersey} position={i} inDialog={inDialog} {...rest} />}
                        </li>
                    );
                })}
            </>
    );
};
