import logo from "../../../assets/logos/LogomarkBrandRedSquare.svg";
import { AvatarOrInitials } from "./AvatarOrInitials";
import { makeStyles } from "../../providers/makeStyles";
import classnames from "classnames";

const useStyles = makeStyles((theme, { withLogo }) => ({
    avatar: {
        backgroundColor: withLogo ? theme.palette.background.paper : theme.palette.background.low,
        color: theme.palette.secondary.contrastText,
    },
}));
export const OrganisationAvatar = ({ size, className, alt, src }) => {
    const classes = useStyles({ withLogo: !!src });

    return (
        <AvatarOrInitials alt={alt} src={src || logo} className={classnames(className, classes.avatar)} size={size} />
    );
};
