import { MaxWidthPageWithHeading } from "../../../layout/page/MaxWidthPageWithHeading";
import { Box } from "@mui/material";
import { Add, ContentCopy, Upload } from "@mui/icons-material";
import { BigBoxList } from "../../../layout/lists/BigBoxList";
import { Fake } from "../../../layout/skeleton/Fake";

export const CreateEventPageSkeleton = () =>
    <MaxWidthPageWithHeading title="Create event" returnTo="/director">
        <Box className="skeleton" role="status" aria-label="loading">
            <BigBoxList items={[
                { icon: <Add/>, text: <Fake size={12}/>, subtext: <Fake size={100}/> },
                { icon: <ContentCopy/>, text: <Fake size={12}/>, subtext: <Fake size={100}/> },
                { icon: <Upload/>, text: <Fake size={20}/>, subtext: <Fake size={123}/> }
            ]}/>
        </Box>
    </MaxWidthPageWithHeading>;
