import { getHeatStatus } from "../heat/HeatStatus";
import { HeatLeaderboardStatus } from "../heat/HeatLeaderboardStatus";

export const LeaderboardStatus = ({ heats, hideTimer }) => {
    const statuses = heats.map(getHeatStatus);
    const isLiveIndex = statuses.findIndex(s => s.isLive);
    const isLive = isLiveIndex > -1;
    const isFinished = statuses.every(s => s.isFinished);
    const heat = isFinished ? null : isLive ? heats[isLiveIndex] : heats[statuses.findIndex(s => s.isUpcoming)];

    return (
        <HeatLeaderboardStatus
            heat={heat}
            hideTimer={hideTimer}
            isFinished={isFinished}
            isLive={isLive}
        />
    );
};
