import { makeStyles } from "../../providers/makeStyles";

export const useOrganisationRowStyles = makeStyles(theme => ({
    search: {
        paddingTop: theme.spacing(1)
    },
    organisation: {
        display: "flex",
        alignItems: "center",
        borderTop: `1px solid ${theme.palette.border.main}`,
        padding: theme.spacing(2),
        "&:last-child": {
            borderBottom: `1px solid ${theme.palette.border.main}`,
        },
    },
    details: {
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
}));
