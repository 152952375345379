import { makeStyles } from "components/providers/makeStyles";

export const useAvatarStyles = makeStyles(theme => ({
    athlete: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        "& > div": {
            marginRight: theme.spacing(2),
            "&:last-child": {
                marginRight: 0
            }
        },
        "@media print": {
            "& .MuiTypography-subtitle3": {
                fontSize: "0.6rem",
            },
            "& .MuiTypography-label2": {
                fontSize: "0.5rem",
            },
            "& .MuiTypography-body2": {
                fontSize: "0.4rem"
            },
            "& > div": {
                fontSize: "0.2rem",
                marginRight: theme.spacing(1)
            }
        },
    },
    avatar: {
        textAlign: "center",
        "& .bib": {
            padding: 0,
            borderTop: `1px solid ${theme.palette.border.light}`,
            borderBottom: `1px solid ${theme.palette.border.light}`,
        }
    },
    jerseyAvatar: {
        "@media print": {
            fontSize: "0.5rem",
            height: "16px",
            width: "16px"
        }
    }
}));
