import { Typography } from "@mui/material";

import classnames from "classnames";
import { makeStyles } from "../../../providers/makeStyles";

const useStyles = makeStyles(theme => ({
    badge: {
        ...Object.keys(theme.palette.jerseys).reduce((map, jersey) => {
            map[`&.${jersey}`] = {
                background: jersey === "white" ?
                    theme.palette.jerseys[jersey].main :
                    theme.palette.jerseys[jersey].priorityBackground,
                color: theme.palette.jerseys[jersey].contrast
            };
            return map;
        }, {})
    },
    priorityText: {
        fontSize: "0.6rem",
    },
    priorityBadge: {
        cursor: "default",
        position: "absolute",
        background: theme.palette.border.main,
        margin: theme.spacing(-1, 0, 0, 2.5),
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        borderRadius: "50%",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
export const PriorityBadge = ({ jersey, priority }) => {
    const classes = useStyles();

    return (<div className={classnames(classes.priorityBadge, jersey, classes.badge)}>
        <Typography variant="label2" className={classes.priorityText}>P{priority}</Typography>
    </div>);
};
