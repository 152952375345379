import { ApolloClient, ApolloProvider as Apollo, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { RequestsQueueProvider } from "./RequestsQueueProvider";
import { AbortableRequestsProvider } from "./AbortableRequestsProvider";

const retryLink = new RetryLink({
    delay: {
        initial: 1000,
        max: 5000,
        jitter: true
    },
    attempts: {
        max: 5,
        retryIf: (error, operation) => {
            return !error.statusCode || error.statusCode > 500;
        }
    }
});

const queryLink = new HttpLink({ uri: "/api/graphql", fetchOptions: { method: "GET" } });
const mutationLink = new HttpLink({ uri: "/api/graphql" });

const splitLink = ApolloLink.from([
    retryLink,
    ApolloLink.split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === "OperationDefinition" && operation === "mutation";
        },
        mutationLink,
        queryLink,
    )
]);

const keepExistingUnlessIncoming = (existing, incoming) => (incoming || typeof existing === "undefined") ? incoming : existing;

export const mergePagedIncomingToExisting = (existing, incoming, page, per) =>
    [...new Array(per)].reduce((existingValue, _, i) => {
        const index = (page - 1) * per + i;
        if (i + 1 > incoming.length) return existingValue.slice(0, index);
        existingValue[index] = incoming[i];
        return existingValue;
    }, existing?.slice(0) || []);

export const getPagedExisting = (existing, page, per) => existing.slice((page - 1) * per, page * per);

const organisationAthletesPolicy = {
    keyArgs: ["id", "search"],
    read: (existing, { args: { page, per } }) => {
        if (!existing) return existing;
        return { ...existing, athletes: getPagedExisting(existing.athletes, page, per) };
    },
    merge: (existing, incoming, { args: { page, per } }) => ({
        ...incoming,
        athletes: mergePagedIncomingToExisting(existing?.athletes, incoming.athletes, page, per)
    })
};

const federationTeamsPolicy = {
    keyArgs: ["id", "search"],
    read: (existing, { args: { page, per } }) => {
        if (!existing) return existing;
        return { ...existing, teams: getPagedExisting(existing.teams, page, per) };
    },
    merge: (existing, incoming, { args: { page, per } }) => ({
        ...incoming,
        teams: mergePagedIncomingToExisting(existing?.teams, incoming.teams, page, per)
    })
};

export const apolloClient = new ApolloClient({
    defaultOptions: {
        watchQuery: {
            refetchWritePolicy: "merge",
        },
    },
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    payment: {
                        read: (_, { args, toReference }) =>
                            toReference({ __typename: "Payment", id: args.id })
                    },
                    organisationAthletes: organisationAthletesPolicy,
                    federationTeams: federationTeamsPolicy,
                    federationAthletes: organisationAthletesPolicy,
                    organisationUsers: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            Heat: {
                fields: {
                    scheduledTime: {
                        merge: keepExistingUnlessIncoming
                    },
                    podium: {
                        merge: keepExistingUnlessIncoming
                    },
                    config: {
                        merge: true
                    },
                    competitors: {
                        merge: keepExistingUnlessIncoming
                    },
                    result: {
                        merge: keepExistingUnlessIncoming
                    },
                    progressions: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            Leaderboard: {
                fields: {
                    config: {
                        merge: true
                    },
                    competitors: {
                        merge: keepExistingUnlessIncoming
                    },
                    result: {
                        merge: keepExistingUnlessIncoming
                    },
                    heats: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            Event: {
                fields: {
                    config: {
                        merge: true
                    },
                    paymentOptions: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            EventDivision: {
                fields: {
                    formatDefinition: {
                        merge: true
                    },
                    heatConfig: {
                        merge: true
                    },
                    heats: {
                        merge: keepExistingUnlessIncoming
                    },
                    leaderboards: {
                        merge: keepExistingUnlessIncoming
                    },
                }
            },
            Organisation: {
                fields: {
                    paymentsReceived: {
                        read: (existing) => existing,
                        merge: keepExistingUnlessIncoming
                    },
                }
            },
            RegistrationsAttribute: {
                keyFields: ["optionId", "id"]
            },
            Competitor: {
                fields: {
                    teamMembers: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            Entry: {
                fields: {
                    teamMembers: {
                        merge: keepExistingUnlessIncoming
                    }
                }
            },
            Series: {
                fields: {
                    paginatedMemberships: {
                        read: (existing) => existing,
                        merge: keepExistingUnlessIncoming
                    }
                }
            }
        }
    }),
    link: splitLink
});

export const ApolloProvider = ({ children }) => (
    <Apollo client={apolloClient}>
        <AbortableRequestsProvider>
            <RequestsQueueProvider>
                {children}
            </RequestsQueueProvider>
        </AbortableRequestsProvider>
    </Apollo>
);
