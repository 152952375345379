import { Field } from "redux-form/immutable";
import { Add } from "@mui/icons-material";
import { Linkify } from "components/util/linkify";

import { plainInput } from "../../../forms";
import { T } from "../../../util/t";
import { SettingsOption } from "components/layout/registrationSettings/SettingsOption";
import { SettingsActions } from "components/layout/registrationSettings/SettingsActions";
import { SettingsItem } from "components/layout/registrationSettings/SettingsItem";
import { SettingsButton } from "components/layout/registrationSettings/SettingsButton";
import { useStyles } from "./Notes";
import { HelpTooltip } from "components/elements/helpTooltip";
import { Box, Typography } from "@mui/material";

export const EmailConfirmationMessage = ({ prefix, options, change, untouch, disabled, defaultMessage, tooltipText }) => {
    const name = "email_confirmation_message";
    const value = options.get(name);
    const field = `${prefix}.${name}`;
    const classes = useStyles();

    return (
        <SettingsOption
            change={change}
            field={field}
            value={value}
            untouch={untouch}
            onEdit={({ revertChanges, exitEditMode }) =>
                <>
                    <Field
                        name={field}
                        component={plainInput}
                        type="textarea"
                        placeholder={defaultMessage}
                        label={<T>Email confirmation message:</T>}/>
                    <SettingsActions revertChanges={revertChanges} exitEditMode={exitEditMode}/>
                </>
            }
            onView={({ startEditing, deleteField }) =>
                value
                    ? <>
                        <p><strong><T>Email confirmation message:</T></strong></p>
                        <p className={classes.notes}>
                            <Linkify>{value}</Linkify>
                        </p>
                        <SettingsItem disabled={disabled} onEdit={startEditing} onDelete={deleteField} />
                    </>
                    : <Box>
                        <SettingsButton disabled={disabled} variant="outlined" onClick={startEditing} startIcon={<Add/>}><T>Edit email confirmation message</T></SettingsButton>
                        <Typography variant="label1">
                            <HelpTooltip><T>{tooltipText}</T></HelpTooltip>
                        </Typography>
                    </Box>
            }
        />
    );
};
