import { Drawer } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(() => ({
    paper: {
        overflow: "hidden",
        maxHeight: "calc(100% - 50px)",
    },
    root: {
        zIndex: 1400
    }
}));

export const ModalContentMobile = ({ open, closeModal, children }) => {
    const classes = useStyles();

    return (
        <Drawer
            open={open}
            onClose={closeModal}
            anchor="bottom"
            classes={{ root: classes.root }}
            PaperProps={{ square: false, className: classes.paper }}
        >
            {children}
        </Drawer>
    );
};
