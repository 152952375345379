import dayjs from "dayjs";
import { makeStyles } from "components/providers/makeStyles";

import { T } from "../../../util/t";
import classnames from "classnames";
import { SHORT_DATE_AND_TIME } from "../../../../utils/dates";

const useStyles = makeStyles(theme => ({
    date: {
        textTransform: "uppercase",
        marginRight: theme.spacing(1),
        color: theme.palette.info.main,
    },
    noPrint: {
        "@media print": {
            display: "none"
        },
    }
}));
export const ScheduledTime = ({ heat, showScheduledTime }) => {
    const classes = useStyles();

    return (
        <span className={classnames(classes.date, heat.config?.hideScheduledTime && classes.noPrint)}>
            <ScheduledTimeOrUpcoming heat={heat} showScheduledTime={showScheduledTime}/>
        </span>
    );
};

export const ScheduledTimeOrUpcoming = ({ heat, showScheduledTime }) =>
    (heat.scheduledTime && (!heat.config.hideScheduledTime || showScheduledTime))
        ? dayjs(heat.scheduledTime).format(SHORT_DATE_AND_TIME)
        : <T>upcoming</T>;
