import { createElement, useRef, useEffect, memo } from "react";
import { areEqual } from "react-window";
import { useResizeObserver } from "hooks/useResizeObserver";
import { setSize } from "./helpers";

export const RawRowRenderer = ({ index, style, data }) => {
    const ref = useRef();

    useResizeObserver(ref, () => setSize(ref, data, index));

    useEffect(() => {
        if (typeof window.ResizeObserver !== "undefined") return;

        const callSetSize = () => setSize(ref, data, index);
        callSetSize();

        window.addEventListener("resize", callSetSize);
        return () => window.removeEventListener("resize", callSetSize);
    }, [data, index]);

    return createElement(data.rowElementType || "div", {
        style: { ...style, top: style.top + (data.padding || 0) }
    }, data.renderItemComponent(data.items[index], ref, index));
};
export const RowRenderer = memo(RawRowRenderer, areEqual);
