import { useCallback } from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

import background1 from "./background-log-in-1.jpg";
import background2 from "./background-log-in-2.jpg";
import background3 from "./background-log-in-3.jpg";
import { T } from "../util/t";

export const Action = ({ text, buttonText, onClick }) => {
    const handleClick = useCallback(e => {
        e.preventDefault();
        onClick();
    }, [onClick]);

    return (
        <div className="login-card-actions">
            <Typography color="textSecondary" component="span"><T>{text}</T></Typography>
            {onClick && " "}
            {onClick && <Link onClick={handleClick}><T>{buttonText}</T></Link>}
        </div>
    );
};

Action.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick: PropTypes.func,
};

const backgroundImages = [background1, background2, background3];
const backgroundImage = backgroundImages[Math.floor(Math.random() * 3)];
const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "absolute",
        paddingTop: 60,
        top: 0,
        width: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundImage: `url(${backgroundImage})`,
        [theme.breakpoints.down("md")]: {
            backgroundImage: "none"
        }
    },
    loginCard: {
        margin: theme.spacing(8, 0),
        [theme.breakpoints.down("md")]: {
            margin: 0
        }
    }
}));
export const AccountFormPage = ({ title, actionText, actionButtonText, actionClick, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <div className={`${classes.loginCard} login-card login-page-card`}>
                <Typography variant="h1"><T>{title}</T></Typography>
                {actionText && <Action text={actionText} buttonText={actionButtonText} onClick={actionClick}/>}
                {children}
            </div>
        </div>
    );
};
