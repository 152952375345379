import { PaddedPage } from "components/layout/page/PaddedPage";
import EmailAthletesHeader from "../header/EmailAthletesHeader";

const EmailAthletesContainer = ({ children }) => {
    return (
        <PaddedPage>
            <EmailAthletesHeader/>
            {children}
        </PaddedPage>
    );
};

export default EmailAthletesContainer;
