import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";
import { useBaseInputStyles } from "./baseInputStyles";
import { T } from "../../../util/t";

export const useRadioStyles = makeStyles(theme => ({
    label: {
        display: "inline-flex",
        alignItems: "center",
        marginRight: theme.spacing(2),
        cursor: "pointer"
    },
    input: {
        width: 24,
        height: 24,
        borderRadius: 24,
        padding: 3,
        marginRight: theme.spacing(1),
        backgroundClip: "content-box",
        "&.error": {
            outline: "none",
        },
        "&:focus-within:not(.error)": {
            outline: `3px solid ${theme.palette.input.focus}`,
            outlineOffset: 2,
        },
        "&:checked": {
            backgroundColor: theme.palette.input.hover
        },
        cursor: "pointer"
    }
}));
export const Radio = ({ field, input, hasError }) => {
    const baseClasses = useBaseInputStyles();
    const classes = useRadioStyles();

    return (
        <div>
            {field.options.map((option, index) =>
                <label className={classes.label} key={index}>
                    <input
                        className={classnames(baseClasses.baseInput, classes.input, { error: hasError })}
                        type="radio"
                        {...input}
                        value={option.value}
                        checked={input.value === option.value}
                        {...field.inputProps}
                        id={`${field.inputProps?.id}-${index}`}
                    />
                    <span><T>{option.label}</T></span>
                </label>
            )}
        </div>
    );
};

