import { makeStyles } from "../../../providers/makeStyles";
import { Box, Typography } from "@mui/material";
import { Fake } from "../../../layout/skeleton/Fake";
import { ChevronRight } from "@mui/icons-material";
import classnames from "classnames";
import { useRowStyles, useTableStyles } from "components/layout/commonStyles/tableStyles";
import { useUserRowStyles } from "components/layout/organisation/useUserRowStyles";
import { AthleteAvatarSkeleton } from "components/layout/skeleton/AthleteAvatarSkeleton";

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(2.5, 0)
    }
}));

export const OrganisationUsersPageSkeleton = () => {
    const classes = { ...useStyles(), ...useTableStyles() };

    return (
        <div role="status" aria-label="loading" className={classnames("skeleton", classes.wrapper)}>
            <Typography variant="h7"><Fake size={12}/></Typography>

            <Box component="table" paddingTop={2} className={classes.table} aria-label="users">
                <tbody>
                    <FakeRow column1={43} column2={46} column3={22} headerRow={true}/>
                    <FakeRow column1={43} column2={46} column3={18} />
                    <FakeRow column1={36} column2={42} column3={18} />
                    <FakeRow column1={32} column2={24} column3={18} />
                </tbody>
            </Box>
        </div>
    );
};

const FakeRow = ({ column1, column2, column3, headerRow }) => {
    const classes = useRowStyles();
    const userRowClasses = useUserRowStyles();

    return (
        <tr className={userRowClasses.row}>
            <td className={`${classes.athlete} ${userRowClasses.athlete}`}><AthleteAvatarSkeleton size={column1} withoutAvatar={headerRow} /></td>
            <td className={userRowClasses.column}><Fake size={column2}/></td>
            <td className={userRowClasses.column}><Fake size={column3}/></td>
            <td className={userRowClasses.chevron}>{!headerRow && <ChevronRight/>}</td>
        </tr>
    );
};

