import { api } from "./api";

export const get = (series_id) =>
    dispatch =>
        api.get(`seasons/${series_id}`)
            .then(res => dispatch({
                type: "SET_SERIES",
                series_id,
                data: res.data
            }));

export const getMembers = (series_id) =>
    dispatch =>
        api.get(`seasons/${series_id}/memberships`)
            .then(res => dispatch({
                type: "SET_SERIES_MEMBERS",
                series_id,
                data: res.data
            }));

export const getRankings = (series_id, division_id) =>
    dispatch =>
        api.get(`seasons/${series_id}/divisions/${division_id}/rankings`)
            .then(res => dispatch({
                type: "SET_SERIES_DIVISION_RANKINGS",
                series_id,
                division_id,
                data: res.data
            }));
