import Immutable from "immutable";
import { useEffect, useRef } from "react";
import { Add } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { dateInput, numberOrEmpty, plainInput, required, selectInput } from "components/forms";
import { valueToInt } from "components/forms/parsers";
import { T } from "components/util/t";
import { Field } from "redux-form/immutable";
import dayjs from "dayjs";
import { SettingsItem } from "../SettingsItem";
import { SettingsButton } from "../SettingsButton";
import { SettingsActions } from "../SettingsActions";
import { SettingsOption } from "../SettingsOption";
import { SettingsOptionContainer } from "../SettingsOptionContainer";
import { useIsTablet } from "hooks/useIsTablet";
import { SHORT_DATE } from "utils/dates";

const validateAge = (index) => (value, allValues, props, field) => {
    const allAgeLimits = allValues.getIn(["registration_options", "divisions"], Immutable.List());
    const name = field.split(".").pop();
    const currentDivisionId = allAgeLimits.getIn([index, "division_id"]);
    const getCurrentDivision = a => a.get("division_id") === currentDivisionId;

    if (allAgeLimits.count(a => a.get(name) && getCurrentDivision(a)) === 2) return "Already set";
    if (name === "max_age" && value !== "" && value < (allAgeLimits.find(a => a.get("min_age") && getCurrentDivision(a)) || Immutable.Map()).get("min_age", 0)) return "Must be older than min";
    if (name === "min_age" && value !== "" && value > (allAgeLimits.find(a => a.get("max_age") && getCurrentDivision(a)) || Immutable.Map()).get("max_age", Infinity)) return "Must be younger than max";

    return numberOrEmpty(value);
};

const useStyles = makeStyles((theme) => ({
    item: {
        marginBottom: theme.spacing(1),
        "&:last-of-type": {
            marginBottom: theme.spacing(2),
        }
    },
    heading: {
        paddingBottom: theme.spacing(2),
        display: "inline-block"
    }
}));

export const AgeRestriction = ({ prefix, options, array, change, untouch, startDate, divisions }) => {
    const name = "divisions";
    const value = options.get(name) || Immutable.List();
    const field = `${prefix}.${name}`;
    const classes = useStyles();

    useEffect(() => {
        const validDivisionIds = divisions.map(d => d.get("division_id"));
        const validAgeRestrictions = value.reduce((allValues, v) => {
            if (validDivisionIds.includes(v.get("division_id"))) return allValues.push(v);
            return allValues;
        }, Immutable.List());
        change(field, validAgeRestrictions);
    }, []);

    const addItem = (startEditing) => () => {
        array.push(field, Immutable.Map({
            division_id: getPossibleDivisionOptions(value.size).first().value,
            by: startDate
        }));
        startEditing();
    };

    const haveBothMinAndMaxAge = division => division.get("min_age") && division.get("max_age");

    const getPossibleDivisionOptions = (index) => {
        const currentDivisionId = value.getIn([index, "division_id"]);

        const setDivisions = value
            .filter(d => d.get("division_id") !== currentDivisionId &&
                (haveBothMinAndMaxAge(d) || value.count(ed => ed.get("division_id") === d.get("division_id")) === 2))
            .map(d => d.get("division_id"))
            .toSet()
            .toList();

        return divisions
            .filter(ed => ed.get("division_id") && !setDivisions.contains(ed.get("division_id")))
            .map(ed => ({
                value: ed.get("division_id"),
                label: ed.get("division_name")
            }));
    };

    const handleAgeChange = (ageField, d, index) => (event, newValue) => {
        const otherRuleIndex = value
            .findIndex((ed, i) => index !== i && ed.get("division_id") === value.getIn([index, "division_id"]));

        if (otherRuleIndex > -1) {
            const otherRuleSameDivision = value.get(otherRuleIndex);
            const currentRule = d.set(ageField, newValue);

            if (haveBothMinAndMaxAge(currentRule) && !otherRuleSameDivision.get("min_age") && !otherRuleSameDivision.get("max_age")) {
                event.preventDefault();
                const newValues = value.update(index, () => currentRule).delete(otherRuleIndex);
                change(field, newValues);
            }
        }
    };

    return (
        <SettingsOption
            change={change}
            field={field}
            value={value}
            untouch={untouch}
            onEdit={() =>
                <>
                    {!!value.size && <Typography className={classes.heading} variant="label1"><T>Set age restrictions</T></Typography>}
                    {value.map((division, index) =>
                        <Fields
                            key={index}
                            index={index}
                            array={array}
                            division={division}
                            field={field}
                            getPossibleDivisionOptions={getPossibleDivisionOptions}
                            handleAgeChange={handleAgeChange}
                        />)}
                </>
            }
            onView={({ startEditing }) => <>
                {!!value.size && <Typography className={classes.heading} variant="label1"><T>Set age restrictions</T></Typography>}
                {value.map((v, i) => (
                    <SettingsItem key={i} onEdit={!!value && startEditing} className={classes.item}>
                        <T divisionName={divisions.find(d => d.get("division_id") === v.get("division_id"))?.get("division_name")} minAge={v.get("min_age")} maxAge={v.get("max_age")} by={dayjs(v.get("by")).format(SHORT_DATE)}>limit_athlete_division</T>
                    </SettingsItem>
                ))}
            </>}
            onBoth={({ startEditing, revertChanges, exitEditMode, isEditMode }) => <>
                {getPossibleDivisionOptions(value.size).size > 0 && (
                    <SettingsButton variant="outlined" onClick={addItem(startEditing)} startIcon={<Add/>}><T>{value.size ? "Add another" : "Set age restrictions"}</T></SettingsButton>
                )}
                {isEditMode && <SettingsActions marginTop revertChanges={revertChanges} exitEditMode={exitEditMode}/>}
            </>}
        />
    );
};

const useFieldsStyles = makeStyles((theme) => ({
    group: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("lg")]: {
            "& > div:nth-of-type(1), & > div:nth-of-type(5), & > header": {
                minWidth: "100%"
            },
        },
        [theme.breakpoints.up("md")]: {
            width: "80%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "60%"
        },
        "& > div": {
            paddingRight: theme.spacing(2),
            flex: 1
        },
        "&& input, && .select-wrapper, && .date-input-wrapper": {
            width: "100%"
        },
        "&& .date-input-wrapper": {
            minWidth: theme.spacing(24)
        },
        "&:not(:last-of-type)": {
            [theme.breakpoints.down("sm")]: {
                paddingBottom: theme.spacing(2),
            }
        },
        "& .formField:last-child": {
            marginBottom: theme.spacing(3)
        }
    },
    by: {
        [theme.breakpoints.down("lg")]: {
            display: "none",
        },
        "&&": {
            alignSelf: "center",
            flex: 0
        }
    }
}));

const Fields = ({ array, field, index, division, getPossibleDivisionOptions, handleAgeChange }) => {
    const validate = useRef(validateAge(index));
    const classes = useFieldsStyles();
    const isTablet = useIsTablet();

    return (
        <section className={classes.group}>
            <SettingsOptionContainer heading={<T x={index + 1}>age_limits_heading</T>} array={array} field={field} index={index} renderHeader={isTablet}>
                <Field
                    name={`${field}[${index}].division_id`}
                    component={selectInput}
                    selectOptions={getPossibleDivisionOptions(index)}
                    validate={required}
                    parse={valueToInt}
                    label={<T>Division:</T>}
                />
                <Field
                    name={`${field}[${index}].min_age`}
                    component={plainInput}
                    type="number" pattern="[0-9]*" step="any"
                    validate={validate.current}
                    normalize={valueToInt}
                    onChange={handleAgeChange("min_age", division, index)}
                    label={<T>Minimum age:</T>}
                />
                <Field
                    name={`${field}[${index}].max_age`}
                    component={plainInput}
                    type="number" pattern="[0-9]*" step="any"
                    validate={validate.current}
                    normalize={valueToInt}
                    onChange={handleAgeChange("max_age", division, index)}
                    label={<T>Maximum age:</T>}
                />
                <div className={classes.by}><T>by</T></div>
                <Field
                    name={`${field}[${index}].by`}
                    component={dateInput}
                    validate={required}
                    label={<T>By the date:</T>}
                    width="100%"
                />
            </SettingsOptionContainer>
        </section>
    );
};
