import { useCallback } from "react";
import { useHistory } from "react-router";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ResponsiveDialog } from "../../../modal";
import { T } from "../../../util/t";

export const UnapprovedScoreDialog = (props) =>
    <ResponsiveDialog className="unapproved-score-dialog"
                      open={props.open}
                      aria-labelledby="unapproved-score-dialog-title"
                      onClose={props.handleClose}>
        <Content {...props}/>
    </ResponsiveDialog>;

export const Content = ({ eventId, endHeats, handleClose, type }) => {
    const history = useHistory();

    const navigateToHeadJudgePage = useCallback(() => {
        handleClose();
        history.push(`/events/${eventId}/head_judge`);
    }, []);

    const handleEndHeats = useCallback(() => {
        handleClose();
        endHeats(null, true);
    }, []);

    return <>
        <DialogTitle id="unapproved-score-dialog-title" className="sticky-dialog-title">
            <T>Review and approve scores?</T>
        </DialogTitle>
        <DialogContent className="sticky-dialog-content">
            <T>{`There are some unapproved scores. You might like to review and approve them before ending the ${type}.`}</T>
        </DialogContent>

        <DialogActions className="sticky-dialog-actions">
            <Button variant="outlined" onClick={handleEndHeats}>
                <T>End without approving</T>
            </Button>
            <Button variant="contained" onClick={navigateToHeadJudgePage} autoFocus>
                <T>Review & approve</T>
            </Button>
        </DialogActions>
    </>;
};
