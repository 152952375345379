import { BrowserRouter } from "react-router-dom";

import { FacebookProvider } from "./FacebookProvider";

import { ReduxProvider } from "./ReduxProvider";
import { ThemeProvider } from "./ThemeProvider";
import { RealtimeProvider } from "./Realtime/RealtimeProvider";
import { ErrorBoundary } from "./ErrorBoundary";
import { ApolloProvider } from "./ApolloProvider";
import { CurrentUserProvider } from "./CurrentUserProvider";
import { LocalizationProvider } from "./LocalizationProvider";

export const LiveHeatsProviders = ({ children }) =>
    <ReduxProvider>
        <ThemeProvider>
            <ErrorBoundary>
                <ApolloProvider>
                    <CurrentUserProvider>
                        <BrowserRouter>
                            <FacebookProvider appId="1569119533402360" options={{ cookie: true }}>
                                <RealtimeProvider>
                                    <LocalizationProvider>
                                        {children}
                                    </LocalizationProvider>
                                </RealtimeProvider>
                            </FacebookProvider>
                        </BrowserRouter>
                    </CurrentUserProvider>
                </ApolloProvider>
            </ErrorBoundary>
        </ThemeProvider>
    </ReduxProvider>;
