import { Paper, Typography } from "@mui/material";
import { makeStyles } from "../../../providers/makeStyles";

const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: `2px solid ${theme.palette.border.light}`,
        borderRadius: theme.spacing(1)
    },
    title: {
        textTransform: "uppercase"
    }
}));
export const PreviewHeatCard = ({ heatTitle, competitorsText }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.card} variant="outlined">
            <Typography className={classes.title} variant="label2" color="textSecondary" component="p">{heatTitle}</Typography>
            <Typography variant="button3">{competitorsText}</Typography>
        </Paper>
    );
};
