import { Avatar, CircularProgress } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";
import { useLayoutEffect, useState } from "react";
import { firstLetters } from "components/util/firstLetters";
import { usePrevious } from "hooks/usePrevious";

export const sizes = {
    sm: 20,
    md: 30, // MUI default
    lg: 44,
    xl: 48,
    xxl: 64,
};

const useStyles = makeStyles((theme, { size, loading }) => ({
    avatar: {
        width: sizes[size],
        height: sizes[size],
        opacity: loading ? 0 : 1, // needed for the spinner to be visible when src is set

    },
    wrapper: {
        position: "relative",
    },
    spinner: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    spinnerIcon: {
        color: theme.palette.background.high
    }
}));

export const AvatarOrInitials = ({ children, className, alt, src, size = "md" }) => {
    const options = src?.slice(-5) === ".webp" && src.includes("images/") && `!width=${sizes[size] * 2}`;
    const [loading, setLoading] = useState(!!src);
    const classes = useStyles({ size, loading });
    const previousSrc = usePrevious(src);

    const onLoad = () => setLoading(false);
    const onError = () => setLoading(false);

    useLayoutEffect(() => {
        if (src && previousSrc != src) setLoading(true); // eslint-disable-line eqeqeq
    }, [previousSrc, src]);

    return (
        <div className={classes.wrapper}>
            <Avatar className={classnames(classes.avatar, className)} alt={alt} src={options ? src + options : src} role={src ? undefined : "img"} aria-label="avatar" onLoad={onLoad} onError={onError}>
                {children || firstLetters(alt)}
            </Avatar>
            {loading && <div className={classes.spinner}>
                <CircularProgress size={sizes[size] / 2} className={classes.spinnerIcon} />
            </div>}
        </div>
    );
};
