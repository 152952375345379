import { useRef, useEffect } from "react";

export const useFirstRender = () => {
    const ref = useRef(true);

    useEffect(() => {
        ref.current = false;
    }, []);

    return ref.current;
};
