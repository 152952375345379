import { makeStyles } from "components/providers/makeStyles";

export const useEventHeaderStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(2, 0, 3),
    },
    eventDate: {
        textTransform: "uppercase",
        marginRight: theme.spacing(1),
    },
    name: {
        "&&": {
            marginTop: theme.spacing(0.5),
        }
    }
}));
