import { useIsMobile } from "hooks/useIsMobile";
import { useHeatRunsStyles } from "../heat/heatStyles";
import { ResultRow } from "../heat/ResultRow";
import { RideCells } from "../eventDivision/leaderboard/RideCells";

export const LeaderboardResultRow = ({ heat, result, competitor, categories, maxRides }) => {
    const isMobile = useIsMobile();
    const classes = useHeatRunsStyles({ canScroll: !isMobile });

    return (
        <>
            <ResultRow runBased heat={heat} competitor={competitor} result={result} />
            {!isMobile && categories && (
                <div className={classes.runs}>
                    {[...Array(maxRides)].map((_, i) => (
                        <RideCells
                            key={i}
                            ride={heat.result.find(r => r.athleteId === result.athleteId).rides[result.athleteId][i]}
                            categories={categories}
                            Component="span"
                        />
                    ))}
                </div>
            )}
        </>
    );
};
