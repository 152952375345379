import { makeStyles } from "components/providers/makeStyles";

export const useListItemStyles = makeStyles((theme, { isSelected, hasError }) => ({
    main: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        wordBreak: "break-word",
    },
    box: {
        outline: isSelected && `2px solid ${theme.palette.border.selected}`,
        borderRadius: isSelected && 10,
        backgroundColor: theme.palette.background.paper,
        "&:focus-visible": {
            outline: `3px solid ${theme.palette.border.focus}`,
            borderRadius: 5,
            outlineOffset: 2,
            backgroundColor: theme.palette.background.paper,
        },
    },
    listItem: {
        borderRadius: theme.shape.borderRadius,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: hasError ? theme.palette.error.main : theme.palette.border.light,
        padding: theme.spacing(2),
    },
}));
