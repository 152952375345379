import { AccountFormPage } from "../../../login/common";
import { FakeFormField } from "../../../layout/forms/FakeFormField";

export const NewOrganisationPageSkeleton = () =>
    <div className="skeleton">
        <AccountFormPage title="Create your organisation account">
            <FakeFormField label={20} field={80}/>
            <FakeFormField label={23} field={80}/>
            <FakeFormField label={30} field={80}/>
        </AccountFormPage>
    </div>;
