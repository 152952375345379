import { filter, SearchSelect, useLoadingText } from "./SearchSelect";

export const CreatableSearchSelect = ({ field, input, hasError, SearchSelectComponent = SearchSelect }) => {
    const loadingText = useLoadingText();

    const filterOptions = (options, params) => {
        const filtered = (field.filterOptions || filter)(options, params);
        const inputValue = params.inputValue?.trim();

        if (field.loading) {
            filtered.push({ label: field.autocompleteProps?.loadingText || loadingText, value: "", disabled: true });
        } else if (!field.loading && inputValue !== "" && !options.some((option) => inputValue?.toLowerCase() === option.label?.toLowerCase()))
            filtered.push({ label: inputValue, value: inputValue, createLabel: field.createLabel });
        return filtered;
    };

    return (
        <SearchSelectComponent
            field={field}
            input={input}
            hasError={hasError}
            filterOptions={filterOptions}
            handleHomeEndKeys
            freeSolo
        />
    );
};
