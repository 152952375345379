import { Paper, Typography } from "@mui/material";
import { heatLeaderboardCardStyles } from "../leaderboard/heatLeaderboardCardStyles";
import classnames from "classnames";
import { makeStyles } from "../../providers/makeStyles";
import { T } from "../../util/t";

const useDetailsStyle = makeStyles(theme => ({
    container: {
        cursor: "default",
        padding: theme.spacing(1, 2)
    }
}));
export const PaymentDetailsSection = ({ title, children }) => {
    const classes = heatLeaderboardCardStyles();
    const detailsClasses = useDetailsStyle();

    return (
        <section>
            <Paper className={classnames(classes.card, detailsClasses.container)} >
                <Typography variant="h5"><T>{title}</T></Typography>
                {children}
            </Paper>
        </section>
    );
};
